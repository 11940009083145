import 'date-fns';
import React, {useState, useCallback, useEffect, Fragment} from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography, TextField, Button, Snackbar, ButtonGroup, Box, Divider, Tab, Tabs, Grid} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {Legend, LineChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer} from 'recharts';
import WeatherPanel from "./Weather.jsx";
import NumericCellEditor from "./NumericCellEditor.jsx";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import {isEmpty} from 'components/MainPanel/Utilities.jsx';
import { Card, CardHeader, CardBody} from 'reactstrap';
import {DropzoneArea} from 'material-ui-dropzone'
import date from 'date-and-time';
import InfoIcon from '@material-ui/icons/Info';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GaugeChart from 'react-gauge-chart'
import domtoimage from 'dom-to-image';
import csv from 'csv';
import CustomizedAccordions from './ProfileInfo.jsx';
var moment  = require('moment');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: theme.spacing(-5),
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  button2: {
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3',
    border : "1px solid"
  },
  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    // borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#eceff1"
  },
  dropzoneArea: {
    height: "80px"
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: "100%",
    backgroundColor : "#ffffff",
    borderRadius: "0px"
  },
  carousel :{
    // height:'100%',
    backgroundColor : "#ffffff",
    border : "1px solid",
  }
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={0}>{children}</Box>
    </Typography>
  );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };

const LPFileDropzone  = (props) => {
  const classes = useStyles();   
  const [state, setState]         = useState({files:[]});

  const handleChange = (files) => { 
    setState(state => {
      return { ...state, files: files }
    });
}

const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles);
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => { 
    let proTable = [];
    csv.parse(reader.result, (err, data) => {
        proTable = data.map(item => {
         return {time: item[0], sumamb:item[1], sumpul:item[2], sumcool:item[3] }
        })
        props.upDateProfile(proTable);
        proTable = [];
      })
    }
    reader.readAsText(acceptedFiles);
  }, []);

  const onDelete = () => { 
    setState(state => {
      return { ...state, files: [] }
    });
  }

  return (
    <DropzoneArea acceptedFiles={['.csv']} maxFileSize={5000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop a .csv file here or click' 
      onChange={handleChange} onDrop={onDrop} onDelete={onDelete} showFileNames="true" showAlerts={true} filesLimit={1} dropzoneClass={classes.dropzoneArea} />
  )  
} 


export default function ProfileDesign(props)  
{
  const initProfile = [
    { profileName: 'Template' , time: '00:00' , sumamb: 30 , sumpul: 0.6, sumcool: 1000 },
    { profileName: 'Template' , time: '01:00' , sumamb: 29.5 , sumpul: 0.6, sumcool: 1000 },
    { profileName: 'Template' , time: '02:00' , sumamb: 29.2 , sumpul: 0.6, sumcool: 1000 },     
    { profileName: 'Template' , time: '03:00' , sumamb: 29 , sumpul: 0.6, sumcool: 3000 },     
    { profileName: 'Template' , time: '04:00' , sumamb: 28.7 , sumpul: 0.6, sumcool: 3000 },
    { profileName: 'Template' , time: '05:00' , sumamb: 28.5 , sumpul: 0.6, sumcool: 3000 },
    { profileName: 'Template' , time: '06:00' , sumamb: 28.2 , sumpul: 0.7, sumcool: 1000 },
    { profileName: 'Template' , time: '07:00' , sumamb: 29.8 , sumpul: 0.8, sumcool: 1000 }, 
    { profileName: 'Template' , time: '08:00' , sumamb: 31.8 , sumpul: 0.9, sumcool: 1000 },
    { profileName: 'Template' , time: '09:00' , sumamb: 33.9 , sumpul: 0.9, sumcool: 1000 },
    { profileName: 'Template' , time: '10:00' , sumamb: 35.9 , sumpul: 0.9, sumcool: 1000	},
    { profileName: 'Template' , time: '11:00' , sumamb: 37.1 , sumpul: 0.9, sumcool: 1000	},
    { profileName: 'Template' , time: '12:00' , sumamb: 38.4 , sumpul: 1.05, sumcool: 1000},
    { profileName: 'Template' , time: '13:00' , sumamb: 38.4 , sumpul: 1.1, sumcool: 1000 },
    { profileName: 'Template' , time: '14:00' , sumamb: 39.6 , sumpul: 1.15, sumcool: 1000 },
    { profileName: 'Template' , time: '15:00' , sumamb: 40 , sumpul: 1.2, sumcool: 1000 },
    { profileName: 'Template' , time: '16:00' , sumamb: 40 , sumpul: 1.15, sumcool: 5000 },
    { profileName: 'Template' , time: '17:00' , sumamb: 39.6 , sumpul: 1.1, sumcool: 5000 },
    { profileName: 'Template' , time: '18:00' , sumamb: 38.2 , sumpul: 1.1, sumcool: 1000 },
    { profileName: 'Template' , time: '19:00' , sumamb: 36.8 , sumpul: 1.1, sumcool: 1000 },
    { profileName: 'Template' , time: '20:00' , sumamb: 35.4 , sumpul: 0.9, sumcool: 1000 },
    { profileName: 'Template' , time: '21:00' , sumamb: 33.9 , sumpul: 0.9, sumcool: 1000 },
    { profileName: 'Template' , time: '22:00' , sumamb: 32.5 , sumpul: 0.8, sumcool: 2000 },
    { profileName: 'Template' , time: '23:00' , sumamb: 31.7 , sumpul: 0.7, sumcool: 1000 },
    { profileName: 'Template' , time: '24:00' , sumamb: 31.7 , sumpul: 0.7, sumcool: 4000 },
  ];

  const classes                       = useStyles();
  const [profile, setProfile]         = useState(initProfile);
  const [profileName, setProfileName] = useState('');
  const [value, setValue]             = useState(0);
  const [gridPApi, setGridPApi]       = useState();
  const [gridLApi, setGridLApi]       = useState();
  const [Xtimate, setXtimate]         = useState(false);
  const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error'});
  const XfrmName                      = props.Xfrmer.XfrmName; 
  const historyProfile                = props.historyProfile;  
  let profileList                     = props.profileList || [];
  var profileDataPoints               = [];
  profile.map(item => {
    let tj = item.time.split(':');  let uj = (parseInt(tj[0] * 60)  + parseInt(tj[1]));
    if(uj % 60 === 0)
    { profileDataPoints.push({ x: date.parse(item.time, 'HH:mm').getTime(),y: parseFloat(item.sumamb), z: parseFloat(item.sumpul), v: parseFloat(item.sumcool)/1000}); }
  });



  function handleXfrmAge ()
  { 

  }

  useEffect(() => 
  {
    if(isEmpty(profileList))
    {
      if(gridLApi !== undefined)
      {
        var res = gridLApi.api.setRowData([]);
      }
    }
  }, [isEmpty(profileList)]);

  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  const columns = [
    { headerName: "Time", field: "time", editable: false, width:100},
    { headerName: "Ambient[C]", field: "sumamb", editable: true,
      cellEditor:'numericCellEditor' 
    },
    { headerName: "Load [p.u]", field: "sumpul", editable: true, width:100,
      cellEditor:'numericCellEditor', 
    },
    { headerName: "Cooling [kW]", field: "sumcool",  editable: true, 
      cellEditor:'numericCellEditor'
    },
  ];
  
  const pfColumns = [
    { headerName: "Load profile name", field: "profileName", checkboxSelection: true },
    { headerName: "Number of 24h cycles", field: "proCycle"},
  ];

  const upDateProfile = (newProfile)=> {
    setProfile(newProfile);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProNameChange = (event) => {
    setProfileName(event.target.value);
  }

  const onCellValueChanged = params => {
    var colId = params.column.getId();
    if (colId === "sumamb") {
        params.node.setDataValue(colId, params.data.sumamb);
        return;
    }
    if (colId === "sumpul") {
        params.node.setDataValue(colId, params.data.sumpul);
        return;
    }
    if (colId === "sumcool") {
        params.node.setDataValue(colId, params.data.sumcool);
        return;
    }   
  }

  const onGridPReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridPApi({ api, columnApi });
      params.api.sizeColumnsToFit();
    },[]
  );

  const onGridListReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridLApi({ api, columnApi });
    params.api.sizeColumnsToFit();
    },[]
  );

  const AddProfile = ()=>
  {           
    let proCycle = 1, lProfile = [];
    if(profileName === 'undefined' || profileName === '')
    {
      alert('Please provide a load profile name !!!');
      return;
    }

    if(profileList.find(item => item.profileName === profileName)!=null)
    {
      alert('This profile already exists !!!');
      return;
    }

    if(isEmpty(profile))
    {
      alert('Please provide a load profile !!!');
      return;
    }

    profileList.push({ profileName: profileName, proCycle: proCycle });
    var res = gridLApi.api.updateRowData({ add: [{profileName:profileName, proCycle:proCycle}] });     
    lProfile = profile.map(item  => { return {xfrmId: XfrmName, profileName: profileName, ...item}});
    props.onLoadProfiles (profileList, lProfile);
    setProfileName('');
  }

  const RemoveProfile = () =>
  {
    var selectedData = gridLApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    let profileToRemove= selectedData[0].profileName;
    var res = gridLApi.api.updateRowData({ remove: selectedData });
    props.onRemoveProfile(profileToRemove);
  }

  const ShowProfile = () =>
  {
    var selectedData = gridLApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    if(historyProfile.find(p => p.profileName === selectedData[0].profileName))
    {
      let profileToShow = historyProfile.filter(p => p.profileName === selectedData[0].profileName);
      setProfile(profileToShow); 
      setProfileName(selectedData[0].profileName);
    }
  }

  useEffect(() =>
  {
    if(!isEmpty(profile))
    {
      profileDataPoints = [];
      profile.map(item => {
        let tj = item.time.split(':');  let uj = (parseInt(tj[0] * 60)  + parseInt(tj[1]));
        if(uj % 60 === 0)
        { 
          profileDataPoints.push({ x: date.parse(item.time, 'HH:mm').getTime(),y: parseFloat(item.sumamb), z: parseFloat(item.sumpul), v: parseFloat(item.sumcool)/1000}) ; 
        }
      });
    } 
    // console.log("Length of the profile data :" + profileDataPoints.length);
  }, [profile]);

  const SetWeatherAmbient = (Ambient) =>
  {
    let newProfile = profile;
    upDateProfile([]);
    let tj=[], aj=0, aj1=0, uj = 0;
    newProfile[0].sumamb = Ambient[0].val;
    for(var i=1; i < Ambient.length; i++)
    {
      for(var j=1; j < newProfile.length; j++)
      {
        tj = newProfile[j].time.split(':'); uj = (parseInt(tj[0] * 60)  + parseInt(tj[1]));
        aj = parseInt(Ambient[i].time); aj1 = parseInt(Ambient[i-1].time); 
        if(aj1 < uj && uj <= aj) { 
            newProfile[j].sumamb = Ambient[i-1].val }
      }
    }
    setProfile(newProfile);
  }

  const handleSaveClick = () => {
    var ImageURLs = [];
    async function getBlobls() {
      //======= vs Ambient =========
      await domtoimage.toBlob(document.getElementById('load-profile')).then(function (blob) { //
        ImageURLs.push({Tag:"load-profile", URL:URL.createObjectURL( blob )});
      });
      props.SaveImages(ImageURLs);
    }
    getBlobls();
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpenSnack({...openSnack, open:false});
      return;
    }
    setOpenSnack({...openSnack, open:false});
  };

  return ( 
    
      <Grid container spacing={2}>
          <Grid item xs={6}>
              <Snackbar open={openSnack.open} autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error"> {openSnack.Message}.</Alert>
               </Snackbar>
              <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                    <AntTab label="Upload Loading File" />
                    <AntTab label="Detailed Edition" />
                    <AntTab label="Tie-in the Weather Forecast" />
                  </AntTabs>
              <TabPanel value={value} index={0}>
                    <Card className={classes.card}>
                      <CardHeader>  
                        <Typography variant="subtitle2" display="inline"> LOAD PROFILE UPLOAD AND EDITION </Typography> 
                      </CardHeader>
                      <CardBody>
                        <form autoComplete="off">
                          <TextField id="standard-name"  variant="outlined" label="Enter the load profile name" className={classes.textField} value={profileName} onChange={handleProNameChange} />
                        </form>
                        <br/>
                        <CustomizedAccordions/>
                        <br/>
                        <div className ={classes.dropzone}>
                          <LPFileDropzone upDateProfile={upDateProfile} />
                        </div> 
                      </CardBody>
                    </Card> 
                    <div className ={classes.controlBar}>
                        <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                          <Button size="small" startIcon={<ArrowDownwardIcon />} aria-label="arrowdown" onClick={AddProfile}> Add </Button>
                          <Button size="small" startIcon={<ArrowUpwardIcon />} aria-label="arrowup" onClick={ShowProfile}> Show </Button>
                          <Button size="small" startIcon={<DeleteForeverIcon />} aria-label="deleteforever" onClick={RemoveProfile}>Discard</Button> 
                        </ButtonGroup> 
                    </div>
                    <Card className={classes.card}>
                      <CardHeader>  <Typography variant="subtitle2" display="inline"> LIST OF LOAD PROFILES </Typography> </CardHeader>
                      <CardBody>
                        <div  className="ag-theme-balham" style={{ width: "100%", height:"312px"}} >
                          <AgGridReact
                              modules={AllCommunityModules}
                              columnDefs={pfColumns}
                              rowData= {profileList}
                              onGridReady={onGridListReady}
                              animateRows={true}
                              rowSelection='Single'
                            />
                        </div> 
                      </CardBody>
                    </Card>        
              </TabPanel>
              <TabPanel value={value} index={1}>
                    <Card className={classes.card}>
                      <CardHeader>  <Typography variant="subtitle2" display="inline"> LIST OF LOAD PROFILES </Typography> </CardHeader>
                      <CardBody>
                      <div  className="ag-theme-balham" style={{ width: "100%", height:"600px"}} >
                        <AgGridReact
                          modules = {AllCommunityModules}
                          columnDefs = {columns}
                          rowData = {profile}
                          onGridReady = {onGridPReady}
                          animateRows = {true}
                          onCellValueChanged = {onCellValueChanged}
                          frameworkComponents ={frameworkComponents}
                          />
                      </div>
                      </CardBody>
                    </Card> 
              </TabPanel>
              <TabPanel value={value} index={2}>
                  <WeatherPanel Xfrmer={ props.Xfrmer } setWeatherData = { SetWeatherAmbient }/>
              </TabPanel>
          </Grid>
          <Grid item xs={6} >
            <Grid container spacing={2} >
            <Grid item xs={12}>
              <br/>  <br/>
              <Card className={classes.card}>
                  <CardHeader> 
                    <Typography variant="subtitle2" display="inline"> 24-h p.u Load and Ambient profiles </Typography> 
                  </CardHeader>
                  <CardBody >
                    <Button size="small"  disabled = {Xtimate}
                        onClick={handleSaveClick} className={classes.button2} startIcon = {<BatteryUnknownIcon/>} >
                       Add to Report
                    </Button>
                    <div id ="load-profile">
                      <ResponsiveContainer width='100%' aspect={3.0/2}>
                        <LineChart data={profileDataPoints} syncId="anyId" margin={{top: 5, right: 5, left: 3, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                          <CartesianGrid strokeDasharray="3 3"/>
                          <XAxis dataKey="x" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm')} tick={{fontSize: 11}} domain={['0:00', '24:00']}/>
                          <YAxis yAxisId="left" label={{ value: 'Ambient [℃]', fontSize:12, angle: -90, position: 'insideCenter' }} tick={{fontSize: 11}} />
                          <YAxis yAxisId="right" orientation="right" label={{ value: 'Load [p.u]', fontSize:12, angle: -90, position: 'insideRight' }} tick={{fontSize: 11}} />
                          <Tooltip/>
                          <Legend layout="horizontal" verticalAlign="top" align="center" style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                          <Line yAxisId="left" type='step' dataKey='y' name='Ambient' stroke='#8884d8' strokeWidth='2' />
                          <Line yAxisId="right" type='step' dataKey='z' name='Load' stroke='red' strokeWidth='2'/>
                        </LineChart> 
                      </ResponsiveContainer> 
                    </div>
                  </CardBody>
              </Card> 
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.card}>
              <CardHeader > <Typography variant="subtitle2" display="inline"> Transformer Age Estimate </Typography> </CardHeader>
                <CardBody >
                    <Button size="large"  disabled = {Xtimate}
                        onClick={handleXfrmAge} className={classes.button2} startIcon = {<BatteryUnknownIcon/>} >
                        Get The Transformer Age Estimate 
                    </Button>
                    <div className = {classes.gauge}>
                      <GaugeChart id="gauge-chart5" nrOfLevels={420} arcsLength={[0.3, 0.5, 0.2]} colors={['#5BE12C', '#F5CD19', '#EA4228']} percent={0.37} arcPadding={0.02}/> 
                    </div>
                </CardBody>
            </Card> 
            </Grid>
          </Grid>
          </Grid>
      </Grid>
  );
}