import React, {useState}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {DropzoneArea} from 'material-ui-dropzone';
import InfoIcon from '@material-ui/icons/Info';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { isEmpty } from 'components/MainPanel/Utilities.jsx';

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    minHeight: '90vh'
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },

  price:{
      padding: theme.spacing(4),
      backgroundColor : "#333",
      borderColor: '#333',
      color:'#FFFFFF', 
      margin: theme.spacing(3),
      display: 'flex', 
      justifyContent: 'center'
    }
}));


const LPFileDropzone  = (props) => {
  const classes = useStyles();   
  const [state, setState]         = useState({files:[]});
  // const [isLoading, setIsLoading] = useState(false);

  const handleChange = (files) => { 
    setState(state => {
      return { ...state, files: files }
    });
  }

  // async function s3Upload(file) {
  //   const filename = `${Date.now()}-${file.name}`;
  
  //   const stored = await Storage.put(filename, file, {
  //     contentType: file.type
  //   });
  
  //   return stored.key;
  // }
  


  const onDelete = () => { 
    setState(state => {
      return { ...state, files: [] }
    });
  }

  return (
    <div  className="ag-theme-balham" style={{ width: "100%"}} >
      <DropzoneArea acceptedFiles={['.pdf', '.jpeg', '.png']} maxFileSize={5000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop your loading files 
      ( accepted files are .pdf, .csv, .xlsx, .png) here or click to select and upload.' 
        onChange={handleChange} onDelete={onDelete} showFileNames="true" showAlerts={true} filesLimit={5} dropzoneClass={classes.dropzoneArea} />
      <br/>
      {/* <Divider/>
      <Button variant="outlined"  onClick={handleSubmit} className={classes.button} startIcon = {<CloudUploadIcon/>}> Upload </Button> */}
    </div>
  )  
} 



export default function Review(props) {
  const classes = useStyles();
  let FFTRfiles   = props.FFTRfiles || [];
  let LoadFiles  = props.LoadFiles  || [];


  const getPrice = () =>
  {
    if(!isEmpty(props.FFTRfiles))
    {
      return (
        <Typography component="h3" variant="h3"> 599 $US </Typography>
      )
    }
    else if(!isEmpty(props.LoadFiles))
    {
      return (
        <Typography component="h3" variant="h3" > 599 $US </Typography>
      )
    }
    else {
      return (
        <Typography component="h3" variant="h3" > 599 $US </Typography>
      )
    }
  }

  return (
    <main className={classes.layout}>
        <br/>
      <form className={classes.form} autoComplete="off">
        <Card className={classes.card}>
          <CardHeader className={classes.cardheader}>
            <div className={classes.comment}>
              <Typography variant="subtitle2"> {<InfoIcon/>} Order summary  </Typography>
                <Divider/>
              <Typography variant="caption" gutterBottom>
                  The loading studies will be performed according to <strong> IEEE Std C57.12.91™, IEC Std 60076-7 </strong> using transformer design data, anticipated loading profiles, 
                  and operational constraints provided.
              </Typography> 
              </div>
            </CardHeader>
            <CardBody className={classes.CardBody}>  
              <Typography variant="body1" className={classes.total} component="p"> Heat-Run and Requirements Files </Typography>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                  {
                    FFTRfiles.map((row, ind) => (
                      <TableRow key={ind}>
                        <TableCell component="th" scope="row"> {row.name}</TableCell>
                        <TableCell align="right">{row.size}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <br/>
              <Typography variant="body1" className={classes.total} component="p"> Load Profiles </Typography>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                  {LoadFiles.map((scene, ind) => (
                    <TableRow key={ind}>
                      <TableCell component="th" scope="row"> {scene.name}</TableCell>
                      <TableCell align="right">{scene.size}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
                <div className ={classes.price}>
                  { getPrice()}
                </div>

                <Grid container spacing={2}>
                  <Typography variant="subtitle1" className={classes.total}> Deliverables </Typography>               
                  <Typography variant="caption" gutterBottom>
                    The loading study consists of :
                    <Typography variant="subtitle2" className={classes.total}> 1. Methodology </Typography>
                    <ul>
                      <li>Reviewing the factory test reports, extracting, compiling, and formatting the necessary inputs for the study.</li>
                      <li>Running an optimization model on loading limits and margins for Normal, Planned, or Emergency loadings </li>
                      <li>Carrying out loading case studies against chosen operational constraints (e.g. temperature, load, Loss-of-life limits, or type of insulation impacts, Plug-In Electrical vehicle loading, etc.)</li>
                      <li>Eventually perform a sensitivity analysis on user-defined inputs. </li>
                    </ul>
                    <Typography variant="subtitle2" className={classes.total}> 2. Report </Typography>
                    Following the completion of the studies, a written report will be provided. The report will detail the following performance indicators:
                    <ul>
                      <li> Insulation aging vs. ambient conditions, time </li>
                      <li> Load and load margins at each cooling stage vs. ambient conditions, time </li>
                      <li> Thermal trends vs. ambient conditions, time </li>
                      <li> Recommendations regarding future operation of the transformer based on the industry published research and technical reports <strong>(IEEE, CIGRE, IEC, Research Bank)</strong>. </li>
                    </ul>
                  </Typography>
                </Grid>
            </CardBody>
            <CardFooter className={classes.CardBody}> 
              {/* <Button variant="outlined"  onClick={handleSubmit} className={classes.button} startIcon = {<CloudUploadIcon/>}> Create </Button> */}
            </CardFooter>
          </Card>
      </form> 
    </main>
  );
}