/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory }  from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import DevicesIcon from '@material-ui/icons/Devices';
import ContactlessIcon from '@material-ui/icons/Contactless';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import Looks4Icon from '@material-ui/icons/Looks4';
import Looks5Icon from '@material-ui/icons/Looks5';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import { Card, CardHeader, CardFooter, CardBody,CardTitle, CardText } from 'reactstrap';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "5px",
    backgroundColor : "#f6f6f6"
  },

  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: '100%',
  },
  card: {
    top: theme.spacing(8),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0),
    width: '90%',
    borderRadius:'0px'
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  link :{
    // padding: "1px",
    // paddingLeft: "5px",
    // borderBottom: "0px",
    alignContent: "center"
  },

  pubBody:{
    backgroundColor: "#f57c00"
  }

}));



const StepContent = (props) =>
{
  const classes   = useStyles();
  let Descriptions = [
    { 
      title: "Create a transformer",
      content: 'Hit the Start button in the top menu to create a loading session.',
      checked : 0,
      icon : <LooksOneIcon/>,
      color : "#f6f6f6"
    },
    { 
      title: "Fill-in the datasheet",
      content: 'Fill-in the transformer datasheet, with data in the Heat-Run Test Results.',
      checked : 0,
      icon : <LooksTwoIcon/>,
      color : "#f6f6f6"
    },
    { 
      title: "Build load profiles",
      content: 'Name, Upload or Design your load and ambient profiles.',
      checked : 0,
      icon : <Looks3Icon/>,
      color : "#f6f6f6"
    },
    { 
      title: "Define loading scenarios",
      content: 'Establish your loading boundaries and sketch the worst case ambient conditions.',
      checked : 0,
      icon : <Looks4Icon/>,
      color : "#f6f6f6"
    },
    { 
      title: "Run a loading session",
      content: 'Hit the Run button in the dashboard menu to run a loading session.',
      checked : 0,
      icon : <Looks5Icon/>,
      color : "#f6f6f6"
    }
  ];

  let allGuideState = props.allGuideState;

  // createXfrmOK
  if(allGuideState.createXfrmOK )
  { 
    Descriptions[0].checked = 1;
    Descriptions[0].color = "#E3FBD4";
  }
  else 
  {
    Descriptions[0].color = "#f6f6f6"
    Descriptions[0].checked = 0;
  }

  // datasheetOk
  if(allGuideState.datasheetOk)
  {
    Descriptions[1].checked = 1;
    Descriptions[1].color = "#E3FBD4";
  }
  else 
  {
    Descriptions[1].checked = 0;
    Descriptions[1].color = "#f6f6f6"
  }

  // profileOK
  if(allGuideState.profileOK)
  {
    Descriptions[2].checked = 1;
    Descriptions[2].color = "#E3FBD4"
  }
  else
  { 
    Descriptions[2].checked = 0;
    Descriptions[2].color = "#f6f6f6"
  }

  // scenarioOK
  if(allGuideState.scenarioOK)
  {
    Descriptions[3].checked = 1;
    Descriptions[3].color = "#E3FBD4";
  }
  else 
  {
    Descriptions[3].checked = 0;
    Descriptions[3].color = "#f6f6f6";
  }

  // sessionOK
  if(allGuideState.sessionOK)
  {
    Descriptions[4].checked = 1;
    Descriptions[4].color = "#E3FBD4";
  }
  else 
  {
    Descriptions[4].checked = 0;
    Descriptions[4].color = "#f6f6f6";
  }

  console.log("Allstate guide is now createXfrmOK: " + allGuideState.createXfrmOK);
  const getIcon = (checked) =>
  {
    switch (checked) {
      case 0:
        {
          return '';
        }
      case 1:
        {
          return <CheckBoxIcon/>;
          break;
        }
    }
  }

  return (
    Descriptions.map((item, key) =>
      (
        <div className={classes.Comment} key={key} style={{ backgroundColor: item.color}}>
          <small> {item.icon} {item.title} </small>
          <Divider/>
          <Typography variant="caption" >
              { getIcon(item.checked)}{item.content}
          </Typography>              
        </div>
      )
    )
  )
}

const SideGuide = (props) =>
{
  const classes = useStyles();
  const history = useHistory();
  console.log("props.guideId :" + props.guideId);

  const handleFeatures = () => {  
    history.push({pathname:"/admin/features" , state:{ }});
  }


  switch(props.guideId)
  {
    case "Home":
      {
        return(
          <div>
            <Card className={classes.card}>
                <CardBody className={classes.CardBody}>
                  <Typography variant="subtitle2"> Features Overview </Typography>
                  <Typography variant="caption" >
                    Maiden release v1.0.0 Beta with new features :
                    <ul>
                      <li>Flexible load profile designs </li>
                      <li>WHAT-IF loading scenarios modeler </li>
                      <li>Tie-in the weather Forecast </li>
                      <li>Multiple thermal models  </li>
                      <li>Worst case ambient conditions</li>
                      <li>Batch simulation </li>
                    </ul>                    
                  </Typography>
                    <Link component="button" className ={classes.link} variant="subtitle2" onClick={handleFeatures}> MORE... </Link>
                </CardBody>
            </Card>
            <br/> 
            
            <Card className={classes.card}>
              <CardBody className={classes.pubBody} >
                <Box display="flex">
                  <Box p={1} bgcolor="text.primary" color="background.paper"> <Typography variant="subtitle2">COMING UP !!!</Typography></Box>
                  <Box p={1}> <DevicesIcon/> </Box>
                  <Box p={1}> <ContactlessIcon/></Box>
                </Box>
                <Typography variant="caption" component="p">
                    Your transformer real-time loading assessments
                    with our SCADA-enabled thin client.
                </Typography>
              </CardBody>
          </Card>
        </div>  
        ); break;
      }
      case "DIY":
      {
        return(
          <Card className={classes.card}>
            <CardHeader className={classes.cardheader}>  <Typography variant="subtitle2" display="inline"> FIVE EASY STEPS </Typography> </CardHeader>
              <CardBody className={classes.CardBody}>
                <StepContent allGuideState = {props.sideState}/>
              </CardBody>
          </Card>       
        ); 
        break;
      }
  }  
}

const Sidebar = ({...props}) => {

  const { classes, color, logo, image, /*logoText,*/  routes, guideId } = props;
  const theme = useTheme();
  // console.log("guideId" + guideId);
  var brand = (
    <div className={classes.logo}>
      <a href="https://www.hvsscorp.com" className={classNames(classes.logoLink, { [classes.logoLinkRTL]: props.rtlActive })} target="_blank" >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </a>
    </div>
  );


  return (
    <div> 
      {/* <Hidden smUp implementation="css"> */}
      {/* <Hidden mdUp implementation="css">
        <Drawer variant="temporary" anchor="left" open={props.open}
          classes={{ paper: classNames(classes.drawerPaper)}}
          onClose={props.handleDrawerToggle} ModalProps={{ keepMounted: true}} >
          {brand}

          <div className={classes.sidebarWrapper}>
            <SideGuide sideState = {props.sideGuideState} guideId = {guideId}/>  
          </div>
          {image !== undefined ? ( <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} /> ) : null}
          <br/>
        </Drawer>
      </Hidden>  */}
      <Hidden smDown implementation="css">
        <Drawer className={classes.drawer} 
          variant= "persistent" 
          anchor="left"
          open={props.open}
          classes={{ paper:classes.drawerPaper}}> 
            {brand}
            <Box display="flex" justifyContent="flex-end" zIndex="4" >
              <IconButton onClick={props.handleDrawerClose}  style={{backgroundColor: "#FFFFFF"}}>
                {/* {theme.direction === 'ltr' ? <ArrowBackIosIcon  fontSize="medium"/> : <ArrowForwardIosIcon fontSize="large"/>} */}
                  {theme.direction === 'ltr' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon/>}
              </IconButton>
            </Box>
            <div className={classes.sidebarWrapper}>
              <SideGuide sideState = {props.sideGuideState} guideId = {guideId}/>  
            </div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  guideId:PropTypes.string,
  //logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

export default withStyles(sidebarStyle)(Sidebar);