//import 'date-fns';
import React, {useState, useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import {isEmpty, printResult } from 'components/MainPanel/Utilities.jsx';
import DateFnsUtils from '@date-io/date-fns';
import date from 'date-and-time';
import NumericCellEditor from "./NumericCellEditor.jsx";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import { Card, CardHeader, CardFooter, CardBody, CardTitle, CardText } from 'reactstrap';
import { ButtonGroup, Typography, Button, Snackbar, Divider, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#eceff1",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "5px",
    backgroundColor: '#eceff1',
  },

  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    // borderTopLeftRadius: "10px",
    //borderRadius: "0px",
    backgroundColor : "#f6f6f6"
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '150px',
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  }
}));

export default function LoadingCase (props)
{
  const classes                       = useStyles();
  const [gridBApi, setGridBApi]       = useState();
  const [gridApi, setGridApi]         = useState();
  const [state, setState]             = useState({ startDate : new Date(), endDate : new Date()});

  let XfrmName                        = props.XfrmName; 
  let Scenario                        = props.Scenario;
  let ScenarioList                    = props.ScenarioList;
  let selectedRow                     = {};

  useEffect(() => 
  {
    if(isEmpty(ScenarioList)) {
      if(gridBApi !== undefined) {
        gridBApi.api.setRowData([]);
      }
    }
  }, [isEmpty(ScenarioList)]);


  // let tapNbr = 0;
  // useEffect(() => 
  // {
  //   if(props.tapNbr > 0) {
  //     tapNbr = props.tapNbr;
  //   }
  // }, [props.tapNbr]);

  const ScenarioColumns =[
    { headerName: 'Variable', field: 'Variable', hide: true },
    { headerName: 'Description', field: 'Description', width: 350},
    { headerName: 'Value', field: 'Value', editable: true, 
        cellEditorSelector:function (params)
        {
          if (params.data.Variable === 'sim_tapPosition')
          { 
              return {
                component: 'agSelectCellEditor',
                params: { values:[...(function*(){let i=1;while(i <= 18)yield i++})()] }
              }; 
          }
          else if (params.data.Variable === 'sim_inslife') {
             return {
              component: 'agSelectCellEditor',
              params: {values: [65000, 138000, 165000]}
            }
          }
          else if (params.data.Variable !== 'sim_ltype') 
          {
              return { component: 'numericCellEditor' };
          }
        }
      },
    { headerName: 'Unit', field: 'Unit'},
  ];

  const CaseColumns= [
      { headerName: "Name", field: "Name", checkboxSelection: true, width: 200},
      { headerName: "HSTm[℃]", field: "HotSpot" },
      { headerName: "TOPm[℃]", field: "TopOil" },
      { headerName: "LOLm[Hour]", field: "LOLm"},
      { headerName: "PUm [p.u]", field: "PUm"},
      { headerName: "BUBm[℃]", field: "BUBm"},
      { headerName: "CPWm[W]", field: "CPWm"},
      { headerName: "Start", field: "start"},
      { headerName: "End", field: "end"},
      { headerName: "Life", field: "inslife"},
      { headerName: "OXY[ppm]", field: "OxyContent"},
      { headerName: "WCP[ppm]", field: "MoisContent"},
      { headerName: "DGA[ppm]", field: "GasContent"},
      { headerName: "HSP[ppm]", field: "HSPressure"},
      { headerName: "Error[%]", field: "OptimError"},
      { headerName: "LTCPos[ppm]", field: "LtcPosition"},
      { headerName: "LTCCap[p.u]", field: "LtcCapacity"},
      // { headerName: "Error[%]", field: "OptimError"},
  ];
 
  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  const handleStartDateChange = (startdt) => {
    setState({...state, startDate: startdt});
  }

  const handleEndDateChange = (endt) => {
    setState({...state, endDate: endt});
  }

  const onGridAReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridApi({ api, columnApi });
    params.api.sizeColumnsToFit();
    },[]
  );

  const onGridBReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridBApi({ api, columnApi });
      params.api.sizeColumnsToFit();
    },[]
  );
      
  const onCellValueChanged = params => {
      var colId = params.column.getId();
      if (colId === "Value") {
        params.node.setDataValue(colId, params.data.Value); 
        return;
      }
  }

  const AddScenario = ()=>
  {
    let newScenario = { 
      Name:         Scenario.find(item => item.Variable ==='sim_ltype').Value,
      HotSpot:      Scenario.find(item => item.Variable ==='sim_hstthresh').Value,
      TopOil:       Scenario.find(item => item.Variable ==='sim_topthresh').Value,
      LOLm:         Scenario.find(item => item.Variable ==='sim_lolthresh').Value,
      PUm:          Scenario.find(item => item.Variable ==='sim_pulthresh').Value,
      BUBm:         Scenario.find(item => item.Variable ==='sim_bubthresh').Value,
      CPWm:         Scenario.find(item => item.Variable ==='sim_cpwthresh').Value,
      start:        date.format(state.startDate, 'HH:mm'),
      end:          date.format(state.endDate, 'HH:mm'),
      inslife:      Scenario.find(item => item.Variable ==='sim_inslife').Value,
      OxyContent:   Scenario.find(item => item.Variable ==='sim_oxygen').Value,
      MoisContent:  Scenario.find(item => item.Variable ==='sim_imois').Value,
      GasContent:   Scenario.find(item => item.Variable ==='sim_gcoil').Value,
      HSPressure:   0.0, //Scenario.find(item => item.Variable ==='sim_hspress').Value,
      OptimError:   0.0, //Scenario.find(item => item.Variable ==='sim_hstacc').Value,  
      LtcPosition:  Scenario.find(item => item.Variable ==='sim_tapPosition').Value,
      LtcAmpacity:  Scenario.find(item => item.Variable ==='sim_tapAmps').Value  
    }

    if(ScenarioList.find(item => item.Name === newScenario.Name))
    {
      alert('This scenario already exists !!!');
      return;
    }
    ScenarioList.push(newScenario);
    props.handleScenarioList(ScenarioList);

    var res = gridBApi.api.updateRowData({ add: [newScenario] });
    // printResult(res);
    // console.log ("List of scenarios...." + JSON.stringify(ScenarioList));
  }

  const RemoveScenario = () =>
  {
    var selectedData = gridBApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    selectedRow = selectedData[0].Name;
    let filteredScenarios = ScenarioList.filter(item => item.Name !== selectedRow);
    props.handleScenarioList(filteredScenarios);
    var res = gridBApi.api.updateRowData({ remove: selectedData });
    //printResult(res);
  }

  return (      
    <Grid container spacing ={2} justify="center">
      <Grid item xs={12}>
        <Card className={classes.card} >
          <CardHeader> <Typography variant="subtitle2"><strong> ESTABLISH A LOADING SCENARIO </strong></Typography> </CardHeader>
            <CardBody>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <Grid item xs={4}>
                    <KeyboardTimePicker ampm={false} variant="inline" label="Start of the loading period" value={state.startDate} onChange={(startdt) => handleStartDateChange(startdt)}/>
                  </Grid>
                  <Grid item xs={2}> </Grid>
                  <Grid item xs={4}>
                    <KeyboardTimePicker ampm={false} variant="inline" label="End of the loading period" 
                    disabled={state.startDate.getHours()> state.endDate.getHours()} value={state.endDate} onChange={(endt) => handleEndDateChange(endt)} />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <div  className="ag-theme-balham">
                  <AgGridReact
                    modules={AllCommunityModules}
                    columnDefs={ScenarioColumns}
                    rowData={Scenario}
                    onGridReady={onGridAReady}
                    domLayout="autoHeight"
                    animateRows={true}
                    onCellValueChanged={onCellValueChanged}
                    singleClickEdit = {true}
                    frameworkComponents = {frameworkComponents}
                    />
              </div> 
            </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
          <div className ={classes.Comment}>
              <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                    <Button size="small" disabled={state.startDate.getHours()> state.endDate.getHours()} startIcon={<ArrowDownwardIcon />} aria-label="arrowdown" onClick={AddScenario}> Add </Button>
                    <Button size="small" startIcon={<DeleteForeverIcon />} aria-label="distro-deleteforever" onClick={RemoveScenario}>Discard</Button> 
              </ButtonGroup> 
          </div>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card} >
        <CardHeader> <Typography variant="subtitle2"><strong> LIST OF SCENARIOS </strong></Typography> </CardHeader>
          <CardBody>
            <div  className="ag-theme-balham" style={{ width: "100%", height:"300px"}} >
                <AgGridReact
                  modules = {AllCommunityModules}
                  columnDefs={CaseColumns}
                  rowData={ScenarioList}
                  onGridReady={onGridBReady}
                  animateRows={true}
                  rowSelection='Single'
                />
            </div>
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  )
}

