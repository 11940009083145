import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import Link from '@material-ui/core/Link';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },

  Comment: {
    padding: "20px",
    border: "1px solid",
    borderLeft: "0px solid",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#ffffff"
  },
  table: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(8),
  },

}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel"
      hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other} >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default function ReleasePolicy() {
const classes = useStyles();
const [value, setValue] = useState(0);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

return (
    <div className={classes.root}> 
      <div className={classes.Comment}>
        <Typography variant="h4" gutterBottom> 
            LoadingHUB Platform Release Policies 
        </Typography>
      </div>
      <Typography variant="h6" gutterBottom component="div"> 1. Versioning strategy </Typography>
      <Typography variant="body2" gutterBottom component="div">
                  Stability ensures that reusable components and libraries, tutorials, tools, and learned practices don't become obsolete unexpectedly. 
                  Stability is essential for the ecosystem around LoadingHub to thrive.

                  This document contains the practices that are followed to provide you with a leading-edge Loading Simulation Platform, balanced with stability, ensuring that 
                  future changes are always introduced in a predictable way.

                  LoadingHub follows <strong><Link href="https://semver.org">Semantic Versioning 2.0.0</Link></strong>. LoadingHub version numbers have three parts: major.minor.patch. 
                  The version number is incremented based on the level of change included in the release.
                  <ul>
                    <li><strong>Major releases</strong> contain significant new features, some but minimal developer assistance is expected during the update.</li>
                    <li><strong>Minor releases</strong> contain important new features. Minor releases are fully backward-compatible; no developer assistance.</li>
                    <li><strong>Patch releases</strong> are low risk, contain bug fixes and small new features. No developer assistance is expected during update. </li>
                  </ul>
      </Typography>

      <Typography variant="h6" gutterBottom component="div"> 2. Release frequency </Typography>
      <Typography variant="body2" gutterBottom component="div">
          A regular schedule of releases helps you plan and coordinate your updates with the continuing evolution of LoadingHub.
          LoadingHub follows.
          In general, you can expect the following release cycle:
          <ul>
            <li>A <strong>major release</strong> every 12 months.</li>
            <li>1-3 <strong>minor release</strong> for each major release.</li>
            <li>A <strong>patch release</strong> every week (anytime for urgent bugfix). </li>
          </ul>
      </Typography>
      <Typography variant="h6" gutterBottom component="div"> 2. Release schedule </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                        <TableCell ><strong>Date</strong> </TableCell>
                        <TableCell ><strong>Version</strong> </TableCell>
                        <TableCell ><strong>Status</strong> </TableCell>
                      </TableRow>
              </TableHead>
            <TableBody>
              <TableRow className={classes.root}>
                  <TableCell > August 2020 </TableCell>
                  <TableCell > v1.0.0 </TableCell>
                  <TableCell > Released </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell> Q2 2021 </TableCell>
                  <TableCell> v2.0.0 </TableCell>
                  <TableCell>{<HourglassFullIcon/>}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell> Q4 2021</TableCell>
                <TableCell> v3.0.0  </TableCell>
                <TableCell > -- </TableCell>
              </TableRow>
            </TableBody>
      </Table>                  
      <br/>
      <div className={classes.facComment} variant="body2" gutterBottom component="div">
        <WarningIcon/> Disclaimer: We operate in a dynamic environment, and things are subject to change. The information provided is intended to outline the general framework direction. 
        It's intended for informational purposes only. We may decide to add/remove new items at any time depending on our capability to deliver while meeting our quality standards. 
        The development, releases and timing of any features or functionality of Material-UI remains at the sole discretion of Material-UI. The roadmap does not represent a commitment, 
        obligation or promise to deliver at any time.
      </div>
              
      <div className={classes.Comment}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
              <Tab label="Version v1.0.0" />
              <Tab label="Version v2.0.0" />                 
              <Tab label="Version v3.0.0" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Typography variant="body2" gutterBottom component="div">
              Industry Standard-based Calculation Methods.
              <br/><strong>Drop In Heat-Run </strong>
              <ul>
                <li>Step-by-step On-demand Loading study with your custom requirements specifications</li>
                <li>Pdf loading study report including:
                  <ul>
                    <li>Your transfromer digital nameplate</li>
                    <li>Simulated loading scenarios. </li>
                    <li>Load profiles  </li>
                    <li>Detailed simulation results : Marginal load vs. Ambient temperature shifts, Daily Temperatures trends, Loss-of-life </li>
                  </ul>
                </li>
              </ul>
              <strong>Do It Yourself Portal with step by step guides</strong>
              <ul>
                    <li>csv upload of load profiles, with 5min...1h time steps, with an increment of 5min.</li>
                    <li>Edition of an uploaded load profile, creation of new load profile from an existing template</li>
                    <li>What if loading scenarios with multiples limiting attributes (temperatures, load, loss of life, gas, tap position) </li>
                    <li>Detailed simulation results : Marginal load vs. Ambient temperature shifts, Daily Temperatures trends, Loss-of-life </li>
                    <li>Selectable thermal models: IEEE Annex G, Clause 7, IEC60076-7</li>
              </ul>
              
              <strong>SimuLoad: On premise Desktop based simulation  </strong>
              <ul>
                  <li>Downloadable binaries </li>
                  <li>Hardware locked license</li>
              </ul>
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.facComment} variant="body2" gutterBottom component="div">
              Integration of losses of cooling (loss of one or more individual fans, one or more individual...ie valve off radiators) impacts on the available marginal load
              Integration of losses calculation due to harmonics current
              Integration of the tap changer and the bushing thermal models
              Integration of load and temperature history
              Implementation in progress....
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={classes.facComment} variant="body2" gutterBottom component="div">
              Integration of Electric Vehicles load profiles and charging Patterns
              Cooling system capacity upgrade
              NOT PLANNED YET ...
            </div>
          </TabPanel>
      </div>              
    </div>
  );
}
