import { drawerWidth, transition, container } from "assets/jss/material-dashboard-react.jsx";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  root: {
    display: 'flex',
    width: "100%",
    // marginLeft: theme.spacing(0),
  },

  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    minHeight: "100vh",
    width: "100%",
    overflowScrolling: "touch",
    //paddingBottom: "5px" // Added by Therence November 25th, 2019
  },

  // content: {
  //   marginTop: "20px",// "70px",
  //   marginLeft: "-40px", // Added by Therence 
  //   marginBottom: "20px",
  //   padding: "30px 15px",
  //   minHeight: "100vh",// "calc(100vh - 123px)"
  //   maxHeight: "100vh",
  //   paddingBottom: "20px",
  // },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  container,
});

export default appStyle;
