import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Grid, Typography, Button, ButtonGroup, Box, Tabs, Tab, Snackbar, Dialog}  from '@material-ui/core';
import NumericCellEditor from "components/MainPanel/NumericCellEditor.jsx";
import { IntervalCellEditor, IntervalRenderer } from "components/MainPanel/NumericCellEditor.jsx";
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SaveIcon from '@material-ui/icons/Save';
import { ApiURL, coolMode, isEmpty } from './Utilities.jsx';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { PushSpinner } from "react-spinners-kit";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },

  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, tabvalue, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={tabvalue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabvalue: PropTypes.any.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  center:
  {
    marginRight: 'auto',
    marginLeft : 'auto',
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  },

  spinner:{
    minWidth:'350px',
    minHeight:'100px',
    borderRadius: '0px',
    padding:"40px",
    paddingTop: "10px"
  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginRight:'5px',
    marginBottom: "10px",
    backgroundColor : "#eceff1"
  },
  yellowBox:
  {
    padding: "2px",
    width:"25px",
    height: "15px",
    // borderColor: "#FFFACD",
    border: "1px solid",
    backgroundColor : "#FFFACD"

  },
  pusheader :
  {
    marginLeft:'5px',
    marginRight:'5px',
    marginTop:'10px',
    padding: "1px",
    marginTop: "5px",
    marginBottom: "10px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    marginLeft: "5px",
    marginRight: "5px",
    borderBottomColor: "#d3d3d3",
    backgroundColor : "#f6f6f6",
  },
}));


const initialNote ='The equipement digital Datasheet requires input values which can typically be found within the transformer OEM commisionning and tests report'+ 
'(Factory Final Test Report, Heat-Run Test, outline drawing), which were typically sent to the transformer owner for approval.' +
'A shapshot below gives a sketch of such commisionning report, as reference only.'

const SpinnerDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog onClose={props.handleClose} aria-labelledby="spinner-dialog-title" open={props.show} maxWidth="sm">
      <div className={classes.pusheader}>
        <Typography variant="subtitle2" component="div" gutterBottom> {props.Message}</Typography>
      </div>
      <div className={classes.spinner}>
        <PushSpinner size={60} color="#686769" loading={props.show} />
      </div>
      <Button size="small"  onClick={props.handleClose} >Cancel</Button>
    </Dialog>
  );
}

export default function DataSheetTable(props) {
  const classes = useStyles();
  const [tabvalue, setTabValue]       = useState(0);
  const [gridAApi, setGridAApi]       = useState(null);
  const [gridBApi, setGridBApi]       = useState(null);
  const [stateTapBush, setTapBuState] = useState({checkedTap: true, checkedBush: true});
  const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error'});
  const [imOpen, setImpOpen]          = useState(true);
  const [stateSpin, setSpinner]       = useState(false);
  
  let dataSheet         = props.dataSheet;
  let userData          = props.userData;
  let Xfrmer            = props.Xfrmer;

  const iXfrmTapSheet = [
      // Rated AMPS  Tap Changer Provision
      { VarName: 'sim_losscooling', Description: ' ', Stage1: props.Xfrmer.Stage1, Stage2: isEmpty(props.Xfrmer.Stage2)? 'NONE': props.Xfrmer.Stage2, 
          Stage3: isEmpty(props.Xfrmer.Stage3)? 'NONE': props.Xfrmer.Stage3,  Unit: '', Info: ''  },
      { VarName:'HRatedAmps', Description:'Primary Rated Amps(H)', Stage1: '10', Stage2:'0', Stage3:'0', Unit:'Amps', Info: 'Rated Load Current in the primary winding.' },
      { VarName:'XRatedAmps', Description:'Secondary Rated Amps(X)', Stage1:'10', Stage2:'0', Stage3:'0', Unit:'Amps', Info:'Rated Load Current in the primary winding.' },
      { VarName:'TRatedAmps', Description:'Tertiary Rated Amps(T)', Stage1:'10', Stage2:'0',  Stage3:'0', Unit:'Amps', Info:'Rated Load Current in the primary winding.' },
      // Rated Voltages
      { VarName: 'sim_blank_0', Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '', Info: ''  },
      { VarName:'HRatedVolt', Description:'Primary Rated Voltage (HV)', Stage1:'10', Stage2:'', Stage3:'', Unit:'kV', Info:'Primary Rated Voltage.'},
      { VarName:'XRatedVolt', Description:'Secondary Rated Voltage (LV)', Stage1:'10', Stage2:'', Stage3:'',  Unit:'kV', Info:'Secondary Rated Voltage.'},
      { VarName:'TRatedVolt', Description:'Tertiary Rated Voltage (TV)', Stage1:'10',  Stage2:'', Stage3:'', Unit:'kV', Info:'Primary Rated Voltage.'},
      // Load Tap Changer Provision
      { VarName: 'sim_blank_1', Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '', Info: ''  },
      { VarName:'LtcTapRange',  Description:'Tap Range [±]', Stage1:'10', Stage2:'', Stage3:'', Unit: '%', Info:'Look-up the value on the transformer nameplate, tapmin <= tap <=tapmax'},
      { VarName:'LtcRatedTapPos', Description:'Rated Tap Position', Stage1:'9', Stage2:'', Stage3: '', Unit:'', Info: 'Look-up the value on the transformer nameplate, tapmin <= tap <=tapmax'},
      { VarName:'LtcTapSteps', Description:'Number of Tap Steps', Stage1:'18', Stage2:'', Stage3:'', Unit:'', Info:'Look-up the value on the transformer nameplate.' },
      { VarName:'LtcWinding', Description:'Winding with the adjustable tap (HV/LV)', Stage1:'HV', Stage2:'',  Stage3:'',  Unit:'', Info:'Look-up the value on the transformer nameplate.'},
      { VarName:'LtcRatedOilRise', Description:'Contact temperature rise over oil at rated current(ΔΘc,ʀ)', Stage1:'20', Stage2:'', Stage3:'', Unit:'Kelvin', Info:'Look-up the value on the transformer nameplate.'},
      { VarName:'LtcLocation', Description:'Location of the LTC (INTEGRATED/SEPARATED)', Stage1:'INTEGRATED',  Stage2:'',    Stage3:'',   Unit:'', Info: 'Look-up the value on the transformer nameplate.'},
      { VarName:'LtcCapacity', Description:'Rated step capacity', Stage1:'9', Stage2:'', Stage3:'',  Unit:'kVA', Info: 'Look-up the value on the transformer nameplate.'}
  ]

  const iXfrmCoolossData = [

    { VarName: 'sim_losscooling', Description: ' ',
      Stage1: props.Xfrmer.Stage1, 
      Stage2: isEmpty(props.Xfrmer.Stage2)? 'NONE': props.Xfrmer.Stage2, 
      Stage3: isEmpty(props.Xfrmer.Stage3)? 'NONE': props.Xfrmer.Stage3,  Unit: ' ', Info: ''  },
    
    // Insulation Settings
    { VarName: 'sim_lins', Description: 'Liquid Insulation Type', Stage1: props.Xfrmer.LiquidInsul, Stage2:'', Stage3:'', Unit: '' , Info: '' },
    { VarName: 'sim_sins', Description: 'Solid Insulation Type', Stage1: props.Xfrmer.SolidInsul, Stage2:'', Stage3:'', Unit: 'kV', Info: ''  },
    
    // Design Data
    { VarName: 'sim_masstank', Description: 'Weight of Tank and Fittings', Stage1: '103500', Stage2:'', Stage3:'', Unit: 'pounds' , Info: ''  },
    { VarName: 'sim_masscocoil', Description: 'Weight of Core and Coils', Stage1: '218000', Stage2:'', Stage3:'', Unit: 'pounds' , Info: '' },
    { VarName: 'sim_puheigths', Description: 'Per Unit Winding Height to Hot Spot', Stage1: '1', Stage2:'', Stage3:'', Unit: 'p.u', Info: 'If the location of the winding hottest spot is unknown, input 1.00 for per unit winding height to the hottestspot  location.'+
          'Values less than 1.00 are used to compare predicted hot spot temperatures with tested values in test windings with imbedded thermocouples or transformers with fiber optic hotspot detectors.'},
    { VarName: 'sim_pueddyloss', Description: 'Per Unit Eddy Loss @ Hot Spot', Stage2:'', Stage3:'', Stage1: '1.52', Unit: 'p.u' , Info: 'If the per unit eddy loss at the winding hot-spot location is unknown, use zero. This gives conservative results' },
    { VarName: 'sim_volfluid', Description: 'Volume of fluid', Stage1: '14190', Stage2:'', Stage3:'', Unit: 'Gallons' , Info: '' },
    { VarName: 'sim_conductor', Description: 'Winding Type', Stage1: 'COPPER', Stage2:'', Stage3:'', Unit: '' , Info: '' },
    { VarName: 'sim_windtcst', Description: 'Winding Time Constant', Stage1: '10', Stage2:'', Stage3:'', Unit: 'Minutes' , Info: 'Typical values of the winding time constant are 3-7 min. Estimates may be obtained from resistance cooling curve data from thermal testing.'+
         'Overloads greater than 1/2 h have a minor effect on the hot-test-spot. temperature calculation. If the time constant is unknown, 5 min is suggested. ' },
    
    // Core Data
    { VarName: 'sim_rambient', Description: 'Rated Ambient (Θᴀ,ʀ)', Stage1: '30', Stage2:'', Stage3:'', Unit: 'Celsius' , Info: '' },
    { VarName: 'sim_corelosses', Description: 'Core Losses, (Pcr)', Stage1: '57500', Stage2:'', Stage3:'', Unit: 'Watts', Info: 'Core loss" a.k.a "No-load " to be entered from test report. Enter at 100% Voltage ' },
    { VarName: 'sim_corelossesovex', Description: 'Core Loss During Over Excitation', Stage1: '0.0', Stage2:'', Stage3:'', Unit: 'Watts' , Info: '' },
    { VarName: 'sim_ovextime', Description: 'Time When Over Excitation Occurs (hour)', Stage1: '0', Stage2:'', Stage3:'', Unit: 'Hour' , Info: 'If overexcitation does not occur, input zero for time overexcitation and normal excitation core loss for core loss during overexcitation.' },
    
    { VarName: 'sim_pwfan', Description: 'Cooling Power per Fan/Coolers', Stage1: '2500', Stage2:'', Stage3:'', Unit: 'Watts' , Info: 'cooling' },
    { VarName: 'sim_pwrad', Description: 'Cooling Power per Radiator', Stage1: '2500', Stage2:'', Stage3:'', Unit: 'Watts' , Info: 'cooling' },
    { VarName: 'sim_pwpump', Description: 'Cooling Power per Pump', Stage1:'2500', Stage2:'', Stage3:'', Unit: 'Watts' , Info: 'cooling' },

    //Temperatures Rises
    { VarName: 'sim_blank_2', Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '', Info: ''  },
    { VarName: 'sim_losscooling', Description: ' ', Stage1: props.Xfrmer.Stage1, 
      Stage2: isEmpty(props.Xfrmer.Stage2)? 'NONE': props.Xfrmer.Stage2, Stage3: isEmpty(props.Xfrmer.Stage3)? 'NONE': props.Xfrmer.Stage3,  Unit: ' ', Info: ''  },
    { VarName: 'sim_pubasekva', Description: 'One Per unit base kVA for load cycle', Stage1: props.Xfrmer.Rating1, Stage2: props.Xfrmer.Rating2, 
      Stage3: props.Xfrmer.Rating3,Unit: 'kVA' , Info: 'Use highest kVA rating, i.e for 10/20/30 use 30,000' },
    { VarName: 'sim_windtempbase', Description: 'Rated Avg. Winding Rise (AWR)', Stage1: '65', Stage2: '65', Stage3: '65' , Unit: 'Kelvin', Info: 'Rated average winding  over ambient.' },
    { VarName: 'sim_avgwindrise', Description: 'Tested Avg. Winding Rise (ΔΘᴡ⁄ᴀ,ʀ)', Stage1: '40.4', Stage2: '48', Stage3: '52.36' , Unit: 'Kelvin', 
      Info: '((ΔΘᴡ⁄ᴀ,ʀ)p + (ΔΘᴡ⁄ᴀ,ʀ)s + (ΔΘᴡ⁄ᴀ,ʀ)t)/3 means (calculate the average  rise  of the primary, secondary and tertiary windings if applicable.)'  },
    { VarName: 'sim_hstrise', Description: 'Hot Spot Rise (ΔΘн,ᴀ)', Stage1: '51.32', Stage2: '68', Stage3: '74.75' , Unit: 'Kelvin', Info: 'Enter the value in the Heat-run at the maximum rating.' },
    { VarName: 'sim_toprise', Description: 'Tested Top Oil Rise (ΔΘᴛᴏ,ʀ)', Stage1: '43.2', Stage2: '50', Stage3: '56.33' , Unit: 'Kelvin', Info: 'Enter the value in the Heat-run at the maximum rating.' },
    { VarName: 'sim_botrise', Description: 'Bottom Oil Rise (ΔΘʙᴏ,ʀ)', Stage1: '26.2', Stage2: '30', Stage3: '25.13', Unit: 'Kelvin', Info: 'Enter the value in the Heat-run at the maximum rating.' },
    
    //Transformer Losses Configuration
    { VarName: 'sim_blank_3', Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '', Info: ''  },
    { VarName: 'sim_lossbasekva', Description: 'Base Rating for Losses', Stage1: props.Xfrmer.Rating1, Stage2: props.Xfrmer.Rating2, 
        Stage3: props.Xfrmer.Rating3,  Unit: 'kVA', Info: '' },
    { VarName: 'sim_lossbasetemp', Description: 'Temperature Base for Losses', Stage1: '85', Stage2: '85', Stage3: '85', Unit: 'Celsius', Info: '' },
    { VarName: 'sim_wI2Rlosses', Description: 'I2R Losses, (Pw)', Stage1: '144000', Stage2: '256000', Stage3: '400000', Unit: 'Watts', 
      Info:'Winding square losses accounts by default for 80% of the load losses when resistance data is not available'},
    { VarName: 'sim_weddylosses', Description: 'Winding Eddy Losses, (Pe)', Stage1: '29175', Stage2: '52000', Stage3: '81249', Unit: 'Watts',
      Info:'Winding square losses accounts by default for 80% of the load losses when resistance data is not available'},
    { VarName: 'sim_straylosses', Description: 'Stray Losses, (Ps)', Stage1: '50000', Stage2: '88889', Stage3: '138888', Unit: 'Watts',
      Info: 'IF resistance value is available as input, Calculate the I2R Losses, Set Eddy Loss = 0 and Stray Losses = Load Losses - I2R Losses.'+ 
            'IF resistance value is NOT available, Entering (Stray Losses value <= 0 or WindI2RLosses <=0)  will set Stray Losses = 20% of Load Losses, Eddy Losses = 0,' +
            'and WindI2RLosses = 80% of Load Losses (as recommended in the IEEE loading guide'},
    
    //Cooling Configuration
    { VarName: 'sim_blank_4', Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '', Info: 'cooling'  },
    { VarName: 'sim_losscooling', Description: '',
      Stage1: props.Xfrmer.Stage1, 
      Stage2: isEmpty(props.Xfrmer.Stage2)? 'NONE': props.Xfrmer.Stage2, 
      Stage3: isEmpty(props.Xfrmer.Stage3)? 'NONE': props.Xfrmer.Stage3,  Unit: '', Info: 'cooling'  },
    { VarName: 'sim_numfan', Description: 'Number of Fans or Coolers', Stage1: '2', Stage2: '2', Stage3: '2', Unit: '', Info: 'cooling' },
    { VarName: 'sim_numrads', Description: 'Number of Radiators', Stage1: '4',  Stage2: '4', Stage3: '4', Unit: '', Info: 'cooling' },
    { VarName: 'sim_numpumps', Description: 'Number of Pumps', Stage1: '2', Stage2: '2', Stage3: '2', Unit: '', Info: 'cooling' },
    { VarName: 'sim_numcooler', Description: 'Number of Coolers', Stage1: '2', Stage2: '2', Stage3: '2', Unit: '', Info: 'cooling' },
  ]

  const [cheatSheet, setCheatSheet] = useState(initialNote);
  const [state, setState] = useState({ XfrmTapSheet :iXfrmTapSheet, XfrmCoolossData :iXfrmCoolossData });
  let XfrmTapSheet      = state.XfrmTapSheet;
  let XfrmCoolossData   = state.XfrmCoolossData;


  useEffect(() =>
  {
    onRefresh(); 
  }, [Xfrmer]);


  const onRefresh = () =>
  {
      const url = ApiURL + '/GetDataSheet';  

      try 
      {
        (async () => {
          let Payload = { sessionId:userData.username, xfrmId: props.Xfrmer.XfrmName};
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(Payload),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          // console.log(result);
          if(!isEmpty(result))
          { 
            setState({XfrmTapSheet:result.XfrmTapSheet, XfrmCoolossData:result.XfrmCoolossData});
          }
          else
          {
            setState({ XfrmTapSheet:iXfrmTapSheet, XfrmCoolossData:iXfrmCoolossData });
          }
        })();
      }   
      catch (error) {
          console.error('Error:', error);
          alert("Could not connect to the server.");
      }

      if(gridBApi!== null)
      {
        if(Xfrmer.Stage2 ==="Select")
          gridBApi.columnApi.setColumnVisible('Stage2', false);
        else 
          gridBApi.columnApi.setColumnVisible('Stage2', true);
      
        if(Xfrmer.Stage3 ==="Select")
          gridBApi.columnApi.setColumnVisible('Stage3', false);
        else
          gridBApi.columnApi.setColumnVisible('Stage3', true);
      }

      if(gridAApi!== null)
      {
        if(Xfrmer.Stage2 ==="Select")
          gridAApi.columnApi.setColumnVisible('Stage2', false);
        else 
          gridAApi.columnApi.setColumnVisible('Stage2', true);
      
        if(Xfrmer.Stage3 ==="Select")
          gridAApi.columnApi.setColumnVisible('Stage3', false);
        else
          gridAApi.columnApi.setColumnVisible('Stage3', true);
      }
  };

  const handleChange = (event) => {
    setTapBuState({ ...stateTapBush, [event.target.name]: event.target.checked });
  };

  /*                                                                   **/ 
  /* ** Parser For Data Imputation                                 ** **/ 
  /*  LoadingHub v1.1                                                  **/ 
  /*  Added January 11th 2022                                          **/ 
  /*  Validating the transformer datasheet prior any simulation session */
  /* ****      Added 2021/17/01                                  ********/

  const handleImputation = () => {

        let XfrmTapSheet      = state.XfrmTapSheet;
        let XfrmCoolossData   = state.XfrmCoolossData;

        let XfrmCooling = [], vStage1 = {}, vStage2 = {}, vStage3 ={}
        let XfrmDataSheet = [
          { xfrmId:props.Xfrmer.XfrmName, VarName:'FluidType',              Value: XfrmCoolossData.find(item => item.VarName ==='sim_lins').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'SolidInsul',             Value: XfrmCoolossData.find(item => item.VarName ==='sim_sins').Stage1 },

          { xfrmId:props.Xfrmer.XfrmName, VarName:'MassTank',               Value: XfrmCoolossData.find(item => item.VarName ==='sim_masstank').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'MassCoreCoil',           Value: XfrmCoolossData.find(item => item.VarName ==='sim_masscocoil').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'PerUnitHeighToHotSpot',  Value: XfrmCoolossData.find(item => item.VarName ==='sim_puheigths').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'PerUnitHSEddyLoss',      Value: XfrmCoolossData.find(item => item.VarName ==='sim_pueddyloss').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'GallonsOfFluid',         Value: XfrmCoolossData.find(item => item.VarName ==='sim_volfluid').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'WindingConductor',       Value: XfrmCoolossData.find(item => item.VarName ==='sim_conductor').Stage1 },
          
          { xfrmId:props.Xfrmer.XfrmName, VarName:'WindTimeCst',            Value: XfrmCoolossData.find(item => item.VarName ==='sim_windtcst').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'RatedAmbient',           Value: XfrmCoolossData.find(item => item.VarName ==='sim_rambient').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'CoreLosses',             Value: XfrmCoolossData.find(item => item.VarName ==='sim_corelosses').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'CoreLossesOverEx',       Value: XfrmCoolossData.find(item => item.VarName ==='sim_corelossesovex').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'TimeOverExcitation',     Value: XfrmCoolossData.find(item => item.VarName ==='sim_ovextime').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_fan',          Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwfan').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_radiator',     Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwrad').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_pump',         Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwpump').Stage1 },
          
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcTapRange',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcTapRange').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcRatedTapPos',         Value: XfrmTapSheet.find(item => item.VarName ==='LtcRatedTapPos').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcTapSteps',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcTapSteps').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcWinding',             Value: XfrmTapSheet.find(item => item.VarName ==='LtcWinding').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcRatedOilRise',        Value: XfrmTapSheet.find(item => item.VarName ==='LtcRatedOilRise').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcLocation',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcLocation').Stage1 },
          { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcCapacity',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcCapacity').Stage1 },
        ]   
        // Stage 1
        vStage1 = { 
            // Temperature Rises
            xfrmId:               props.Xfrmer.XfrmName,
            Status:               'ON',
            PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage1,
            WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage1,
            AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage1,
            HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage1,
            TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage1,
            BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage1,
            AvgOilRise:           0.0,
            
            // Losses 
            LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage1,
            LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage1,
            WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage1,
            WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage1,
            WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage1,
            XfrmerCoolLevel :     '0',
            XfrmerCooling :       props.Xfrmer.Stage1,
            XfrmerRating :        props.Xfrmer.Rating1,
            LoadLoss:             0.0,

            // Cooling Devices Power and number per stage
            numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage1,
            numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage1,
            numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage1,
            numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage1,
            Power:                0.0,
            HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage1,
            TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage1,
            XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage1,
        };
        XfrmCooling.push(vStage1);
               
        // Stage 2
        if(Xfrmer.Stage2 !=="Select")
        {
            vStage2 =  { 
              // Temperature Rises
              xfrmId:               props.Xfrmer.XfrmName,
              Status:               'OFF',
              PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage2,
              WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage2,
              AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage2,
              HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage2,
              TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage2,
              BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage2,
              AvgOilRise:           0.0,
              
              // Losses 
              LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage2,
              LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage2,
              WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage2,
              WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage2,
              WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage2,
              XfrmerCoolLevel :     '50',
              XfrmerCooling :       isEmpty(props.Xfrmer.Stage2)?'': props.Xfrmer.Stage1 + '/' + props.Xfrmer.Stage2,
              XfrmerRating :        props.Xfrmer.Rating2,
              LoadLoss:             0.0,

              // Cooling Devices Power and number per stage
              numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage2,
              numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage2,
              numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage2,
              numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage2,
              Power:                0.0,
              HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage2,
              TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage2,
              XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage2,
            };
            XfrmCooling.push(vStage2);
        }
        
        // Stage 3
        if(Xfrmer.Stage3 !=="Select")
        {
            vStage3 = { 
              // Temperature Rises
              xfrmId:               props.Xfrmer.XfrmName,
              Status:               'OFF',
              PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage3,
              WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage3,
              AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage3,
              HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage3,
              TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage3,
              BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage3,
              AvgOilRise:           0.0,
              
              // Losses 
              LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage3,
              LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage3,
              WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage3,
              WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage3,
              WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage3,
              XfrmerCoolLevel :     '100',
              XfrmerCooling :       isEmpty(props.Xfrmer.Stage3)? '' : props.Xfrmer.Stage1 + '/' + props.Xfrmer.Stage2 + '/' + props.Xfrmer.Stage3,
              XfrmerRating :        props.Xfrmer.Rating3,
              LoadLoss:             0.0,

              // Cooling Devices Power and number per stage
              numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage3,
              numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage3,
              numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage3,
              numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage3,
              Power:                0.0,
              HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage3,
              TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage3,
              XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage3,
            };
            XfrmCooling.push(vStage3);
        }

       let vStage1Cnt =  XfrmCoolossData.filter(p => p.Stage1.toString().indexOf('-') > -1).length;
       let vStage2Cnt =  XfrmCoolossData.filter(p => p.Stage2.toString().indexOf('-') > -1).length;
       let vStage3Cnt =  XfrmCoolossData.filter(p => p.Stage3.toString().indexOf('-') > -1).length;

       if(vStage1Cnt > 0 || vStage2Cnt > 0 || vStage3Cnt > 0)
       {
          let coolingId = [];

          if (vStage1Cnt > 0)
            coolingId.push({ profileName: vStage1.XfrmerCooling, Ambient: "", ExpLoad: "", Harmonics: "", PlugEV: "" });
          
          if (vStage2Cnt > 0)
            coolingId.push({ profileName: vStage2.XfrmerCooling, Ambient: "", ExpLoad: "", Harmonics: "", PlugEV: "" });
          
          if (vStage3Cnt > 0)
            coolingId.push({ profileName: vStage3.XfrmerCooling, Ambient: "", ExpLoad: "", Harmonics: "", PlugEV: "" });
          

          const url = ApiURL + '/DoApproximation'; 
          setSpinner(true);

          try 
          {
            let Payload = { sessionId:userData.username, newXrmer:Xfrmer, dataSheet:XfrmDataSheet, lProfiles:[], 
                            loadCases:[], lCooling:XfrmCooling, loadStds:[], lDistro:coolingId }; 

            // console.error('Payload:', JSON.stringify(Payload));
            (async () => {
              const rawResponse = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(Payload),
                headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
              })
              const result = await rawResponse.json();
              setTimeout(() => {  onRefresh() }, (1000));         
              setSpinner (false); //
            })();
          }   
          catch (error) {
              console.error('Error:', error);
          }  
       }
       else
       {
        setOpenSnack({open: true, Message: "Please provide the boundaries for ubknown values!"});
       }
  }

  const XfrmRatedColumns = [
    { headerName: 'VarName', field: 'VarName', hide:true },
    { headerName: 'Description', field: 'Description', minWidth:'300'},
    { headerName: 'Stage 1', field: 'Stage1', editable: true,
      cellEditorSelector:function (params)
      {
        if (params.data.VarName === "sim_cooling" ||  params.data.VarName === "sim_losscooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))},
            backgroundColor:'lightgray'
        }
        else if (params.data.VarName === "sim_lins") return {
          component: 'agSelectCellEditor',
          params: {values: ['MINERAL OIL', 'ESTER', 'SILICON']}
        };
        else if (params.data.VarName === 'sim_sins') return {
          component: 'agSelectCellEditor',
          params: {values: ['UPGRADED KRAFT', 'NON UPGRADED', 'ARAMID']}
        };
        else if (params.data.VarName === 'sim_conductor') return {
          component: 'agSelectCellEditor',
          params: {values: ['ALUMINUM', 'COPPER']}
        };
        else
        {
          return { component: 'numericCellEditor' };
        };
      },
    },
    { headerName: 'Stage 2', field: 'Stage2', 
      editable:function (params)
      {
        if (params.data.Stage2 === '') 
          return false;
        else return true;
      },
      cellStyle:function (params)
      {
        if (params.data.Stage2 === '' || params.data.Stage3 === '')
        {
          return {'background-color': 'lightgray'};    
        }
      },
      cellEditorSelector:function (params)
      {
        if (params.data.VarName === "sim_cooling" ||  params.data.VarName === "sim_losscooling") return {
          component: 'agSelectCellEditor',
          params: {values: coolMode.map((o=>{return o.value}))}
        }
        else {
          return { component: 'numericCellEditor'};
        };
      }
    },
    { headerName: 'Stage 3', field: 'Stage3', 
      editable:function (params)
      {
        if (params.data.Stage3 === '') 
          return false;
        else return true;
      },
      cellStyle:function (params)
      {
        if (params.data.Stage2 === '' || params.data.Stage3 === '')
        {
          return {'background-color': 'lightgray'};    
        }
      },
      cellEditorSelector:function (params)
      {
        if (params.data.VarName === "sim_cooling" ||  params.data.VarName === "sim_losscooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o=>{return o.value}))}
        }
        else{
          return { component: 'numericCellEditor' };
        };
      } 
    },
    { headerName: 'Unit', field: 'Unit',
      cellStyle:function (params)
      {
          return {'background-color': 'lightgray'};    
      },
    },
    { headerName: 'Info', field: 'Info', hide:true},    
  ];

  const onGridAReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridAApi({api, columnApi});

      if(Xfrmer.Stage2 ==="Select")
        params.columnApi.setColumnVisible('Stage2', false);
      else 
        params.columnApi.setColumnVisible('Stage2', true);
      
      if(Xfrmer.Stage3 ==="Select")
        params.columnApi.setColumnVisible('Stage3', false);
      else
        params.columnApi.setColumnVisible('Stage3', true);

      params.api.sizeColumnsToFit();     
    },[]
  );

  const onGridBReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridBApi({ api, columnApi });

      if(Xfrmer.Stage2 ==="Select")
        params.columnApi.setColumnVisible('Stage2', false);
      else 
        params.columnApi.setColumnVisible('Stage2', true);

      if(Xfrmer.Stage3 ==="Select")
        params.columnApi.setColumnVisible('Stage3', false);
      else
        params.columnApi.setColumnVisible('Stage3', true);

      params.api.sizeColumnsToFit();

    },[]
  );

  const onCellStageChanged = useCallback((params) => {
    let colId = params.column.getId();
    switch(colId)
    {
      case "Stage1": { 
        // params.Stage1 = params.newValue;
        //  console.log ("Stage1 ==<<<" + params.Stage1);
         params.node.setDataValue(colId, params.data.Stage1); 
         break;
        }
      case "Stage2": { 
        // params.Stage2 = params.newValue;
        // console.log ("Stage2 ==<<<" + params.Stage2);
        params.node.setDataValue(colId, params.data.Stage2); 
        break;
      }
      case "Stage3": { 
        // params.Stage3 = params.newValue;
        // console.log ("Stage3 ==<<<" + params.Stage3);
        params.node.setDataValue(colId, params.data.Stage3); 
        break;
      }
    } 
    if(params.data.Stage1.indexOf('-') > -1 || 
          params.data.Stage2.indexOf('-') > -1 ||
          params.data.Stage3.indexOf('-') > -1)
          setImpOpen(false)  
    else 
      setImpOpen(true) 
    },[] 
 );

  const onNotesShowing = params => {
    if(params.data.Info !== '')
      setCheatSheet(params.data.Info);
    else
      setCheatSheet(initialNote);
  }

  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const changeRowColor = params => {
    if ( params.data.VarName === "sim_cooling" ||  
    params.data.VarName === "sim_losscooling" ||
    params.data.VarName === "sim_lins" ||
    params.data.VarName === "sim_sins" ||
    params.data.VarName === "sim_pubasekva" ||
    params.data.VarName === "sim_lossbasekva" ||
    params.data.VarName === "sim_blank_2" ||
    params.data.VarName === "sim_blank_3" ||
    params.data.VarName === "sim_blank_4") {
      return {'background-color': '#dcdcdc'};    
    }
    else
      return {'background-color': '#FFFFFF'};    
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpenSnack({...openSnack, open:false});
      return;
    }
    setOpenSnack({...openSnack, open:false});
  };

  function editStageGrid (params)
  {
  
    if ( params.data.VarName === "sim_cooling" ||  
          params.data.VarName === "sim_losscooling" ||
          params.data.VarName === "sim_lins" ||
          params.data.VarName === "sim_sins" ||
          params.data.VarName === "sim_pubasekva" ||
          params.data.VarName === "sim_lossbasekva" ||
          params.data.VarName === "sim_blank_2" ||
          params.data.VarName === "sim_blank_3" ||
          params.data.VarName === "sim_blank_4")
          return false
    else
          return true;
  };

  function cellStageStyle (params)
  {
    if ( params.data.VarName === "sim_rambient" ||  
          params.data.VarName === "sim_ovextime" ||
          params.data.VarName === "sim_corelossesovex" ||
          params.data.VarName === "sim_windtcst" ||
          params.data.VarName === "sim_conductor" ||
          params.data.VarName === 'sim_windtempbase')
    {
      return {'background-color': '#FFFACD'};   
    }
  };
      
  function cellStageSelector(params)
  {
    if (params.data.VarName === "sim_cooling" ||  params.data.VarName === "sim_losscooling") return {
          component: 'agSelectCellEditor',
          params: {values: coolMode.map((o => {return o.value}))}
    }
    else if (params.data.VarName === "sim_lins") return {
      component: 'agSelectCellEditor',
      params: {values: ['MINERAL OIL', 'ESTER', 'SILICON']}
    }
    else if (params.data.VarName === 'sim_sins') return {
      component: 'agSelectCellEditor',
      params: {values: ['UPGRADED', 'NON-UPGRADED', 'ARAMID']}
    }
    else if (params.data.VarName === 'sim_conductor') return {
      component: 'agSelectCellEditor',
      params: {values: ['ALUMINUM', 'COPPER']}
    }
    else {
          return { component: 'intervalCellEditor'};
    };
  };

  const handleSave = ()=>
  {
    let XfrmTapSheet      = state.XfrmTapSheet;
    let XfrmCoolossData   = state.XfrmCoolossData;
    let vStage1Cnt  = 0, vStage2Cnt = 0, vStage3Cnt = 0;

    if(XfrmCoolossData.find(p => p.Stage1 ==='-'))
     vStage1Cnt =  XfrmCoolossData.filter(p => p.Stage1.toString().indexOf('-') > -1).length;

    if(XfrmCoolossData.find(p => p.Stage2 ==='-'))
      vStage2Cnt =  XfrmCoolossData.filter(p => p.Stage2.toString().indexOf('-') > -1).length;
    
    if(XfrmCoolossData.find(p => p.Stage3 ==='-'))
      vStage3Cnt =  XfrmCoolossData.filter(p => p.Stage3.toString().indexOf('-') > -1).length;

    if(vStage1Cnt > 0 || vStage2Cnt > 0 || vStage3Cnt > 0)
    {
      console.log("Hyphen -" + vStage1Cnt + " " + vStage2Cnt + " " + vStage3Cnt);
      setOpenSnack({open: true, Message: "Please run the imputation routine first prior saving!"});
      return;
    }

    let XfrmCooling = [], vStage1 = {}, vStage2 = {}, vStage3 = {}

    let XfrmDataSheet = [
      { xfrmId:props.Xfrmer.XfrmName, VarName:'FluidType',              Value: XfrmCoolossData.find(item => item.VarName ==='sim_lins').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'SolidInsul',             Value: XfrmCoolossData.find(item => item.VarName ==='sim_sins').Stage1 },

      { xfrmId:props.Xfrmer.XfrmName, VarName:'MassTank',               Value: XfrmCoolossData.find(item => item.VarName ==='sim_masstank').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'MassCoreCoil',           Value: XfrmCoolossData.find(item => item.VarName ==='sim_masscocoil').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'PerUnitHeighToHotSpot',  Value: XfrmCoolossData.find(item => item.VarName ==='sim_puheigths').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'PerUnitHSEddyLoss',      Value: XfrmCoolossData.find(item => item.VarName ==='sim_pueddyloss').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'GallonsOfFluid',         Value: XfrmCoolossData.find(item => item.VarName ==='sim_volfluid').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'WindingConductor',       Value: XfrmCoolossData.find(item => item.VarName ==='sim_conductor').Stage1 },
      
      { xfrmId:props.Xfrmer.XfrmName, VarName:'WindTimeCst',            Value: XfrmCoolossData.find(item => item.VarName ==='sim_windtcst').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'RatedAmbient',           Value: XfrmCoolossData.find(item => item.VarName ==='sim_rambient').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'CoreLosses',             Value: XfrmCoolossData.find(item => item.VarName ==='sim_corelosses').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'CoreLossesOverEx',       Value: XfrmCoolossData.find(item => item.VarName ==='sim_corelossesovex').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'TimeOverExcitation',     Value: XfrmCoolossData.find(item => item.VarName ==='sim_ovextime').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_fan',          Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwfan').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_radiator',     Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwrad').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'power_per_pump',         Value: XfrmCoolossData.find(item => item.VarName ==='sim_pwpump').Stage1 },
      
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcTapRange',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcTapRange').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcRatedTapPos',         Value: XfrmTapSheet.find(item => item.VarName ==='LtcRatedTapPos').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcTapSteps',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcTapSteps').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcWinding',             Value: XfrmTapSheet.find(item => item.VarName ==='LtcWinding').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcRatedOilRise',        Value: XfrmTapSheet.find(item => item.VarName ==='LtcRatedOilRise').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcLocation',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcLocation').Stage1 },
      { xfrmId:props.Xfrmer.XfrmName, VarName:'LtcCapacity',            Value: XfrmTapSheet.find(item => item.VarName ==='LtcCapacity').Stage1 },
    ]   
    // Stage 1
    vStage1 = { 
        // Temperature Rises
        xfrmId:               props.Xfrmer.XfrmName,
        Status:               'ON',
        PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage1,
        WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage1,
        AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage1,
        HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage1,
        TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage1,
        BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage1,
        AvgOilRise:           0.0,
        
        // Losses 
        LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage1,
        LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage1,
        WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage1,
        WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage1,
        WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage1,
        XfrmerCoolLevel :     '0',
        XfrmerCooling :       props.Xfrmer.Stage1,
        XfrmerRating :        props.Xfrmer.Rating1,
        LoadLoss:             0.0,

        // Cooling Devices Power and number per stage
        numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage1,
        numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage1,
        numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage1,
        numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage1,
        Power:                0.0,
        HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage1,
        TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage1,
        XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage1,
    };
    XfrmCooling.push(vStage1);
           
    // Stage 2
    if(Xfrmer.Stage2 !=="Select")
    {
        vStage2 =  { 
          // Temperature Rises
          xfrmId:               props.Xfrmer.XfrmName,
          Status:               'OFF',
          PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage2,
          WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage2,
          AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage2,
          HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage2,
          TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage2,
          BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage2,
          AvgOilRise:           0.0,
          
          // Losses 
          LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage2,
          LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage2,
          WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage2,
          WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage2,
          WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage2,
          XfrmerCoolLevel :     '50',
          XfrmerCooling :       isEmpty(props.Xfrmer.Stage2)?'': props.Xfrmer.Stage1 + '/' + props.Xfrmer.Stage2,
          XfrmerRating :        props.Xfrmer.Rating2,
          LoadLoss:             0.0,

          // Cooling Devices Power and number per stage
          numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage2,
          numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage2,
          numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage2,
          numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage2,
          Power:                0.0,
          HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage2,
          TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage2,
          XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage2,
        };
        XfrmCooling.push(vStage2);
    }
    
    // Stage 3
    if(Xfrmer.Stage3 !=="Select")
    {
        vStage3 = { 
          // Temperature Rises
          xfrmId:               props.Xfrmer.XfrmName,
          Status:               'OFF',
          PerUnitBasekVA:       XfrmCoolossData.find(item => item.VarName ==='sim_pubasekva').Stage3,
          WindingTempBase:      XfrmCoolossData.find(item => item.VarName ==='sim_windtempbase').Stage3,
          AvgWindingRise:       XfrmCoolossData.find(item => item.VarName ==='sim_avgwindrise').Stage3,
          HotSpotRise:          XfrmCoolossData.find(item => item.VarName ==='sim_hstrise').Stage3,
          TopOilRise:           XfrmCoolossData.find(item => item.VarName ==='sim_toprise').Stage3,
          BottomOilRise:        XfrmCoolossData.find(item => item.VarName ==='sim_botrise').Stage3,
          AvgOilRise:           0.0,
          
          // Losses 
          LossBasekVA:          XfrmCoolossData.find(item => item.VarName ==='sim_lossbasekva').Stage3,
          LossTempBase:         XfrmCoolossData.find(item => item.VarName ==='sim_lossbasetemp').Stage3,
          WindI2RLosses:        XfrmCoolossData.find(item => item.VarName ==='sim_wI2Rlosses').Stage3,
          WindEddyLoss:         XfrmCoolossData.find(item => item.VarName ==='sim_weddylosses').Stage3,
          WindStrayLosses:      XfrmCoolossData.find(item => item.VarName ==='sim_straylosses').Stage3,
          XfrmerCoolLevel :     '100',
          XfrmerCooling :       isEmpty(props.Xfrmer.Stage3)? '' : props.Xfrmer.Stage1 + '/' + props.Xfrmer.Stage2 + '/' + props.Xfrmer.Stage3,
          XfrmerRating :        props.Xfrmer.Rating3,
          LoadLoss:             0.0,

          // Cooling Devices Power and number per stage
          numCooler:            XfrmCoolossData.find(item => item.VarName ==='sim_numcooler').Stage3,
          numFan:               XfrmCoolossData.find(item => item.VarName ==='sim_numfan').Stage3,
          numRadiator:          XfrmCoolossData.find(item => item.VarName ==='sim_numrads').Stage3,
          numPumps:             XfrmCoolossData.find(item => item.VarName ==='sim_numpumps').Stage3,
          Power:                0.0,
          HRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='HRatedAmps').Stage3,
          TRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='TRatedAmps').Stage3,
          XRatedAmps:           XfrmTapSheet.find(item => item.VarName ==='XRatedAmps').Stage3,
        };
        XfrmCooling.push(vStage3);
    }

    props.onDataDictionary(XfrmDataSheet, XfrmCooling); 
    setOpenSnack({open: true, Message: "Transformer datasheet saved."});
    setImpOpen(true);
  }

  return (
    <div >
      <SpinnerDialog show = {stateSpin} handleClose = { () => {setSpinner(false)}}/>        
      <Snackbar open={openSnack.open} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert variant="filled" onClose={handleSnackClose} severity={openSnack.severity}>
              { openSnack.Message } !
        </Alert>
      </Snackbar>
      <Grid container >
          <Grid item xs={9}>
            <Grid container >
              <Grid item xs={4}><Typography variant="subtitle2"> {<InfoIcon/>} Cheat Notes </Typography></Grid> 
              <Grid item xs={1}>{<div className={classes.yellowBox}> </div>} </Grid> 
              <Grid item xs={7}><Typography variant="caption"> <strong>Leave yellow cells values as default if unknown.</strong> </Typography> </Grid>
            </Grid>
            <Divider/>
            <Typography variant="caption" gutterBottom> {cheatSheet}</Typography>
          </Grid>
          <Grid item xs={1}>
              <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={2}>
              <div className ={classes.controlBar}>
                <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                  <Button size="small" disabled={imOpen || stateSpin} startIcon={<AssessmentIcon />} aria-label="arrowdown" onClick={handleImputation}> Calibrate </Button>
                  <Button size="small" disabled={stateSpin} startIcon={<SaveIcon />} aria-label="arrowup" onClick={handleSave}> Save </Button>
                </ButtonGroup> 
               </div> 
          </Grid>
      </Grid>
      <AntTabs value={tabvalue} onChange={handleTabChange} aria-label="ant example">
          <AntTab label="Main Tank" />
          <AntTab label="Cooling System" />
          <AntTab label="Ancillary Components" />
      </AntTabs>
      <TabPanel tabvalue={tabvalue} index={0}> 
          <Card className={classes.card}>
            <CardBody>
              <div  className="ag-theme-balham" >
                  <AgGridReact modules={AllCommunityModules} defaultColDef={{ flex: 1, minWidth: 100, resizable: true }}
                        rowData={XfrmCoolossData.filter(p => p.Info !=='cooling')}
                        getRowStyle ={changeRowColor}
                        domLayout="autoHeight"
                        onGridReady={onGridBReady}
                        onCellValueChanged={ onCellStageChanged} 
                        onCellClicked ={ onNotesShowing }
                        singleClickEdit = {true}                      
                        frameworkComponents = { {
                              numericCellEditor: NumericCellEditor,
                              intervalCellEditor: IntervalCellEditor,
                              intervalRenderer: IntervalRenderer
                        }} >
                        <AgGridColumn headerName='VarName' field="VarName" hide={true}/>
                        <AgGridColumn headerName='Description' field="Description" minWidth={300}/>
                        <AgGridColumn headerName='Stage 1' field="Stage1"  editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector}  />   
                        <AgGridColumn headerName='Stage 2' field="Stage2" editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector} />
                        <AgGridColumn headerName='Stage 3' field="Stage3" editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector}/>
                        <AgGridColumn headerName='Info' field="Info" hide={true} editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector}/>
                        <AgGridColumn headerName='Unit' field="Unit" cellStyle={{'background-color': '#dcdcdc'}} />
                  </AgGridReact>
              </div> 
            </CardBody>
          </Card> 
      </TabPanel>
      <TabPanel tabvalue={tabvalue} index={1}> 
          <Card className={classes.card}>
            <CardBody>
              <div  className="ag-theme-balham" >
                  <AgGridReact modules={AllCommunityModules} defaultColDef={{ flex: 1, minWidth: 100, resizable: true }}
                        rowData={XfrmCoolossData.filter(p => p.Info ==='cooling')}
                        getRowStyle ={changeRowColor}
                        domLayout="autoHeight"
                        onGridReady={onGridAReady}
                        onCellValueChanged={ onCellStageChanged} 
                        onCellClicked ={ onNotesShowing }
                        singleClickEdit = {true}
                        enterMovesDown={true}
                        enterMovesDownAfterEdit={true}
                        frameworkComponents = {{
                              numericCellEditor: NumericCellEditor,
                              intervalCellEditor: IntervalCellEditor,
                              intervalRenderer: IntervalRenderer
                        }} >
                        <AgGridColumn headerName='VarName' field="VarName" hide={true}/>
                        <AgGridColumn headerName='Description' field="Description" minWidth={300}/>
                        <AgGridColumn headerName='Stage 1' field="Stage1" editable={editStageGrid}  cellEditorSelector={cellStageSelector}  />   
                        <AgGridColumn headerName='Stage 2' field="Stage2" editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector} />
                        <AgGridColumn headerName='Stage 3' field="Stage3" editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector}/>
                        <AgGridColumn headerName='Info' field="Info" hide={true} editable={editStageGrid} cellStyle={cellStageStyle} cellEditorSelector={cellStageSelector}/>
                        <AgGridColumn headerName='Unit' field="Unit" cellStyle={{'background-color': 'lightgray'}} />
                  </AgGridReact>
              </div> 
            </CardBody>
          </Card> 
      </TabPanel>
      <TabPanel tabvalue={tabvalue} index={2}>
        <Card className={classes.card}>
            <CardHeader> 
              <FormGroup row>
                <FormControlLabel control={<Checkbox checked={stateTapBush.checkedTap} onChange={handleChange} name="checkedTap"  style={{}}/>} label="Specify Tap Changer"/>
                <FormControlLabel control={ <Checkbox checked={stateTapBush.checkedBush} onChange={handleChange} name="checkedBush" color="primary" size="small"/> } label="Specify Bushing"/> 
              </FormGroup>
            </CardHeader>
            <CardBody>
              <div className="ag-theme-balham" >
                  <AgGridReact 
                    modules={AllCommunityModules}
                    columnDefs={XfrmRatedColumns}
                    rowData={XfrmTapSheet}
                    getRowStyle ={changeRowColor}
                    domLayout="autoHeight"
                    onGridReady={onGridAReady}
                    onCellValueChanged={ onCellStageChanged} 
                    onCellClicked ={ onNotesShowing }
                    singleClickEdit = {true}
                    frameworkComponents = { frameworkComponents}/>
                </div>
            </CardBody>
          </Card> 
      </TabPanel>
      {/* <TabPanel tabvalue={tabvalue} index={2}>
          <Grid container >
            <Grid item xs={6}>
              <img src={require("assets/img/XfrmItems/FFTR-1.png")}  alt = "Transformer Active Part Representation" /> 
            </Grid>
            <Grid item xs={6}>
              <img src={require("assets/img/XfrmItems/FFTR-2.png")}  alt = "Transformer Active Part Representation" /> 
            </Grid>
          </Grid>
      </TabPanel> */}
    </div>
  );
}