import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import {Box, Typography} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      margin: theme.spacing(4),
      borderRadius: '0px',
      // marginLeft: theme.spacing(8),
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    facComment: {
      padding: "5px",
      borderLeftColor: "#6699cc",
      borderLeft: "8px solid",
      backgroundColor : "#f6f6f6"
    },
    Comment: {
      padding: "20px",
      border: "1px solid",
      borderLeft: "0px",
      borderRight:"0px",
      marginTop: "20px",
      marginBottom: "20px",
      backgroundColor : "#ffffff"
    },
  
  }));




export default function Payment (props)
{
  const classes   = useStyles();
  return (
    <main className={classes.layout}>
      <br/>
      <form className={classes.form} autoComplete="off" >
        <Card className={classes.card}>
          <CardHeader className={classes.cardheader}>
            <div className={classes.comment}>
              <Box display="flex" flexDirection="row">
              </Box> 
            </div>
          </CardHeader>
          <CardBody className={classes.CardBody}> 
              <Box className ={classes.scan} >
                <Typography> An email has been sent to the indicated recipient address. Please check your email for the registration confirmation. 
                  You can now access {props.Xfrmer.XfrmName} transformer uNAMEPLATE by scanning the provided QR Code or access it via the embedded
                  link </Typography>
                  <hr/>
                <Typography>Thank you for using this service.</Typography>
              </Box>  
          </CardBody>
        </Card> 
      </form>
    </main>
  )
}