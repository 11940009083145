import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import UCreate from './UCreate';
import Payment from './UPayment';
import NamePlate from './NamePlate';
import { isEmpty } from 'components/MainPanel/Utilities.jsx';
import { FormControl, FormControlLabel, Dialog, RadioGroup, Radio, Box,  Grid, Typography} from '@material-ui/core';
// import {Storage } from 'aws-amplify';
import UDataSheet from './UDataSheet';
import { PushSpinner } from "react-spinners-kit";

var nodemailer = require('nodemailer');
var sesTransport = require('nodemailer-ses-transport');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '5px',
    borderRadius: '0px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  stepback:
  {
    borderRight:"1px solid",
    borderRightColor:"#d3d3d3",
    backgroundColor: "#FFFFFF"
  },
  stepper: {
    padding: theme.spacing(1, 0, 1),
    borderBottom: "0px",
    backgroundColor: "#FFFFFF"
  },
  button: {
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  },
  Comment: {
      display: 'flex',
    justifyContent: 'flex-center',
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "0px",
    marginLeft: "300px",
    marginRight: "300px",
    backgroundColor : "#f6f6f6"
  },
    facComment: {
    padding: "5px",
    backgroundColor : "#f6f6f6",
        marginTop: "100px",
  },

}));



export default function MainUForm(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFile] = useState([]);
  const [filesPro, setProFile] = useState([]);
  // const [errlabel, setErrLabel] = useState('');
  const [value, setValue]           = useState('Option 1');
  const [tapNbr, setTapNbr]           = useState(-1);
  const [state, setState] = useState({
      newXrmer:{ },
      dataSheet: [],
      loadCases:[],
      lCooling: [],
      LoadFiles:[],
      lProfiles:[],
      lProfileList:[],
      lDistro:[],
      loadStds:[
        // { pubName: 'IEC60076', pubTitle:'IEC Loading Guide For Power Transformers', pubDate : '2000-04-07'},
        { pubName: 'IEC60354', pubTitle:'IEC Loading Guide For Power Transformers', pubDate : '2000-04-07'},
        { pubName: 'IEEE7', pubTitle: 'IEEE Clause 7 Loading Guide For oil filled Transformers', pubDate : '2000-04-07'},
        { pubName: 'IEEEG', pubTitle:'IEEE Annex G Extended Guide For oil filled Transformers', pubDate : '2000-04-07'}
      ],
      // OptionFiles:[], 
      selectedOption:'Option 1', 
  });


  let username = props.userData.username;

  const steps = ['Transformer Creation', 'Data Checklist', 'QR Code Generation', 'Confirmation'];

  const createXfrmer = (nXfrmer) => {
      setState({...state, newXrmer: nXfrmer});
  };

  const createFFTRFiles = (fFTRFiles) => {
    setFile([files, ...fFTRFiles]);
  }

  const createLoadFiles = (loadFiles) => {
    setProFile([filesPro, ...loadFiles]);
  }

  const createOption = (selectedOption) => {
    setState({...state, selectedOption: selectedOption});
  }

  const createDataSheet = (XfrmDataSheet, XfrmCooling) => {
    setState({...state, dataSheet: XfrmDataSheet, lCooling: XfrmCooling}); 
    setTapNbr(parseInt (XfrmDataSheet.find(item => item.VarName ==='LtcTapSteps').Value));
  }
  
  const createProfile = (profileList, profile, distro) => {
    let lProfiles = [...state.lProfiles, ...profile]; 
    let ldistro =   [...state.lDistro, ...distro]; 
    setState({...state, lProfileList: profileList, lProfiles: lProfiles, lDistro: ldistro});
  }

  const createScenario = (LoadingCases) => {
    setState({ ...state, loadCases: LoadingCases}); 
  }

  const RemoveProfile = (profileName) => {
    let listItems   = state.lProfileList.filter(p => p.profileName !== profileName);
    let profileItem = state.lProfiles.filter(p => p.profilename !== profileName);
    let ldistro = state.lDistro.filter(p => p.profilename !== profileName);
    setState({...state, lProfileList: listItems, lProfiles: profileItem, lDistro: ldistro}); 
  }

  const createScan = (scanURL) =>
  {
    props.createScan(scanURL);
  }

  const setMail = (mailVal) => {
    let xfrmdata = state.dataSheet;
    xfrmdata.find(item => item.VarName ==='Recipient').Value = mailVal;
    setState({...state, dataSheet: xfrmdata }); 
  };

  const setSchedule= (cedule) => {
    let xfrmdata = state.dataSheet;
    xfrmdata.find(item => item.VarName ==='Frequency').Value = cedule;
    setState({...state, dataSheet: xfrmdata }); 
  };


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <UCreate createXfrmer = {createXfrmer}/>  
      case 1:
        return <UDataSheet Xfrmer ={state.newXrmer} Option={value} createDataSheet={createDataSheet} createOption={createOption} createProfile = {createProfile }
                                                     createFFTRFiles = {createFFTRFiles} createLoadFiles = {createLoadFiles} createScenario={createScenario}
                                                     scenarioList = {state.loadCases} profileList = {state.lProfileList} historyProfile = {state.lProfiles} RemoveProfile = {RemoveProfile}/>  
      case 2:      
        return <NamePlate Xfrmer = {state.newXrmer} DataSheet = {state.dataSheet} LoadProfiles ={state.lProfiles} XfrmCooling ={state.lCooling} LoadCases = {state.loadCases}
                                                    lDistro = {state.lDistro} loadStds={state.loadStds} createScan = {createScan} setMail = {setMail} setSchedule = {setSchedule}/>
      case 3: 
        return <Payment Xfrmer = {state.newXrmer}/>
      default: setActiveStep(0);
    }
  }

  const SpinnerDialog = (props) => {
    const classes = useStyles();
    return (
      <Dialog onClose={props.handleClose} aria-labelledby="spinner-dialog-title" open={props.show} maxWidth="sm">
        <div className={classes.spinner}>
          <PushSpinner size={60} color="#686769" loading={props.show} />
        </div>
      </Dialog>
    );
  }

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    createOption (event.target.value);
  };


  const handleNext = () => 
  {
    switch(activeStep)
    {
      case 0:
        {
          if(!isEmpty(state.newXrmer)) 
          {              
            let newXrmer = state.newXrmer;
            if(isEmpty(newXrmer.XfrmName) || newXrmer.XfrmName ==='Template') 
            {
              alert ("Please enter a transformer name");
              return;
            }
            if(newXrmer.XType ==='Select') 
            {
              alert ("Please enter a transformer Type");
              return;
            }
            if(newXrmer.XPhase ==='Select') 
            {
              alert ("Please select the number of phases.");
              return;
            }
            if(newXrmer.Vintage === 'Select') 
            {
              alert ("Please enter the years of services.");
              return;
            }
            if(newXrmer.VClass ==='Select') 
            {
              alert ("Please specify a transformer Voltage class");
              return;
            }
            if(newXrmer.SolidInsul ==='Select') 
            {
              alert ("Please specify the type of solid insulation");
              return;
            }
            if(newXrmer.LiquidInsul ==='Select') 
            {
              alert ("Please specify the type of liquid insulation");
              return;
            }
        
            if(newXrmer.Stage1 ==='Select' && newXrmer.Stage2 ==='Select' && newXrmer.Stage3 ==='Select') 
            {
              alert ("You need to specify at least one cooling stage");
              return;
            }
            if(newXrmer.Rating1 === 0 && newXrmer.Rating2 === 0 && newXrmer.Rating3 === 0) 
            {
              alert ("The number of cooling stages must match the associated ratings ");
              return;
            }
            if(
                 ((newXrmer.Stage1 === 'Select' && newXrmer.Rating1 !==0) || (newXrmer.Stage1!== 'Select' && newXrmer.Rating1 === 0))
              || ((newXrmer.Stage2 === 'Select' && newXrmer.Rating2 !==0) || (newXrmer.Stage2!== 'Select' && newXrmer.Rating2 === 0))
              || ((newXrmer.Stage3 === 'Select' && newXrmer.Rating3 !==0) || (newXrmer.Stage3!== 'Select' && newXrmer.Rating3 === 0))
            ) 
            {
              alert ("A stage must be associated with its corresponding rating, and vice-versa.");
              return;
            }      
          }
          else
          {
            alert("You have not saved your transformer yet");
            return;
          }
          break;
        }
      case 1:
      {
        // if(isEmpty(files) ) 
        // {
        //   alert("You need to select and complete one of the proposed Options : Option 1 or Option 2.")
        //   return;
        // }
        break;
      }
      default: break;
    }
    if(activeStep === steps.length - 1) 
    { 
      handleSubmit();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1); 
  };

  async function  handleSubmit() { 
    // ===== Uploading user files =============================================
    let Payload = { Xfrmer: state.newXrmer};
    let XfrmrName = state.newXrmer.XfrmName;
    // if(!isEmpty(Payload))
    // {
    //   await Storage.put(`${username}/${XfrmrName}/FFTR.json`, JSON.stringify(Payload))
    //       .then (result => console.log(result))
    //       .catch(err => console.log(err));
    // }

    // if(!isEmpty(files))
    // {
    //   await files.map(p => {
    //     Storage.put(`${username}/${XfrmrName}/${p.name}`, p)
    //     .then (result => console.log(result))
    //     .catch(err => console.log(err));
    //   });
    // }
    // if(!isEmpty(filesPro))
    // {
    //   await files.map(p => {
    //     Storage.put(`${username}/${XfrmrName}/${p.name}`, p)
    //     .then (result => console.log(result))
    //     .catch(err => console.log(err));
    //   });
    // }

    // ============Sending an email acknowledging the reviewed files ============
    let frequency =  state.dataSheet.find(item => item.VarName ==='Frequency').Value; 
    let eMail =  state.dataSheet.find(item => item.VarName ==='Recipient').Value; 
    function callback(error, info) {
      if (error) {
        console.log(error);
      } else {
        console.log('Message sent: ' + info.response);
      }
    }
    
    let xfrmer = state.newXrmer;
    var mailOptions = {
      from: 'contact@transformer-analytics.com',
      to: {eMail}, 
      bcc:'contact@transformer-analytics.com',
      subject: `Your [${xfrmer.XfrmName}] Registration Confirmation`,
      // text: 'This is some text',
      html: `<div> Dear <b>${username}</b> <br/><br/><div>This is a notification to confirm that  your transformer:<br/><br/>
      <b>${xfrmer.XfrmName}  ${xfrmer.Rating1 || ''}MVA/${xfrmer.Rating2 || ''}MVA/${xfrmer.Rating3 || ''}MVA</b><br/><br/> has been successfully registered.
      Your transformer Loading capability and load forecast will be delivered to you every <b>${frequency}</b>, to the recipient address: <br/>${eMail}<br/>
      <br/><br/>Thank you for choosing HVSS.<br/>Have a nice day.<br/><br/>
      <div>The HVSS Team</div><br/>
      </div>`
    };

    // Send e-mail using AWS SES
    var sesTransporter = nodemailer.createTransport(sesTransport({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    }));
    sesTransporter.sendMail(mailOptions, callback);

    setState({...state, newXrmer:{}, dataSheet:[],loadCases:[],lCooling:[], LoadFiles:[], lProfiles:[], lProfileList:[], lDistro:[]});

  }

  return (
      <div className={classes.root}> 
       <Grid container spacing={2}>
          <Grid item xs={2} className={classes.stepback}>
            <Grid item xs={12}>
              <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical">
                {
                  steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup row  aria-label="position" name="option" value={value} labelPlacement="top" onChange={handleRadioChange}>
                    <FormControlLabel value="Option 1" control={<Radio />} label={<Typography variant="caption" >"FILL-IN THE DATASHEET YOURSELF"</Typography> }/>
                    <FormControlLabel value="Option 2" control={<Radio />} label={<Typography variant="caption" >"DROP-IN YOUR FILES" </Typography>}/>
                </RadioGroup>
              </FormControl> 
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid item xs={12}>
              <div className={classes.Comment}>
                  <Box flexGrow={1}> { activeStep !== 0 && ( <Button onClick={handleBack} className={classes.button}> Back </Button> )} </Box>
                  <Box><Button variant="contained" color="primary" onClick={handleNext} className={classes.button} > {activeStep === steps.length - 1 ? 'End' : 'Next'} </Button></Box> 
              </div>
            </Grid>
            <Grid item xs={12}>
              { getStepContent(activeStep) }
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
}