/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.7.0";


// core components
import Admin from "layouts/Admin.jsx";
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";
import QuoteUs from "layouts/QuoteRequest";
Amplify.configure(awsmobile);



const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>     
      <Route path="/admin" component={Admin} />
      <Redirect from="/" to="/admin/loadinghub" /> 
      {/* <Route path="/quote-us" component={QuoteUs}/>     */}
       {/* <Redirect from="/quote-us" to="/admin/quote-us" />         */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
