import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="body2"> <strong> Upload a csv file format: Datetime, Load[p.u], Ambient[℃], Cooling[kW] </strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" gutterBottom>
                <ul>
                  <li>Load:(Optional) per unit (p.u) 24-h load cycle. </li>
                  <li>Ambient:(Mandatory) 24-h ambient cycle. Ambient temperature available from weather forecast.</li>
                  <li>Cooling:(Optional). Cooling power at which the transformer is expected to operate.</li>
                </ul>
          </Typography> 
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="body2"> <strong> Transformer Without Known History </strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" gutterBottom>
            This is the case for low power or subordinate transformer in the network. Neither online data nor duration curve of the load or ambient 
            temperature is available. The Aging calculation procedure will :
            <ul>
              <li>Get the average ambient temperature from the weather service</li>
              <li>Get admissible (N - 1) load or guess average load</li>
            </ul>
          </Typography> 
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant="body2">  <strong>Transformer With Known Load History </strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" gutterBottom>
            This is the case for medium power and handover point transformers in the network. These transformers are typically equipped with a 
            load measurement for billing purposes. The Aging calculation procedure will :
            <ul>
              <li>Get the average ambient temperature from the weather service </li>
              <li>Build a daily load profile based on the provided load history.</li>
            </ul>
          </Typography>  
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
          <Typography variant="body2"> <strong>Transformer With Complete Known Load and Ambient Temperature History </strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="caption" gutterBottom>
            This is the case for large power, new or strategic important transformers in the network. These transformers are typically equipped with a 
            load measurement for billing purposes, and an online monitoring of relevant parameters like hot spot temperature, load currents, moisture, gasses,
            ambient and/or cooling air temperature, etc. The Aging calculation procedure will :
            <ul>
              <li> Build a daily ambient temperature. </li>
              <li> Build a daily load profile based on the porvided load history.</li>
            </ul>
          </Typography>  
        </AccordionDetails>
      </Accordion>
    </div>
  );
}