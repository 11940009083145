import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import {DropzoneArea} from 'material-ui-dropzone';
import Typography from '@material-ui/core/Typography';
import {Box, Divider} from '@material-ui/core';
import { isEmpty } from 'components/MainPanel/Utilities';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import WeatherPanel from "components/MainPanel/Weather.jsx";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '20px',
    borderRadius: '0px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardheader:{
    // padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
    backgroundColor: "#f6f6f6"
  },
  card:{
    borderRadius: "0px",
    // height: '100vh',
    minHeight:'100vh',
  },
  CardBody:{
    padding: "1px",
    paddingLeft: "-10px",
  },

  stepper: {
    padding: theme.spacing(1, 0, 1),
    borderBottom: "0px",
    backgroundColor: "#f6f6f6"
  },
  button: {
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  },
  Comment: {
      display: 'flex',
    justifyContent: 'flex-center',
    padding: "1px",
    borderColor: "#d3d3d3",
    border: "1px solid",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "0px",
    marginLeft: "300px",
    marginRight: "300px",
    backgroundColor : "#f6f6f6"
  },
    facComment: {
    padding: "5px",
    backgroundColor : "#f6f6f6",
        marginTop: "100px",
  },

}));

const FinalForm =() =>
{
  const classes = useStyles();
    return (
      <div className = {classes.layout}>
        <div className={classes.facComment}>
          <Typography variant="body2"> {<InfoIcon/>} <strong>Thank you for your Business !!! </strong> </Typography>
          <Divider/>                
          <Typography variant="body2">
                      Your order number is #2001539. We have emailed your order confirmation, and will
                      send you an update when your order has been completed.
                      If there is a need for clarifications, a member of our team will contact you.                    
          </Typography>
        </div>
      </div>
    )
}

export default function AgingCalc(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFile] = useState([]);
  const [state, setState] = useState({
      newXrmer: props.nXfrmer,
      LoadFiles:[],
      selectedOption:'', 
  });
  console.log("============ Tavonte ==== " + JSON.stringify(state.newXrmer))

  let username = props.userData.username;
  const steps = ['Transformer Creation', 'Data Checklist', 'Review and Cost Estimate', 'Place an Order'];

  const handleNext = () => 
  {
    switch(activeStep)
    {
      case 0:
        {
          break;
        }
      case 1:
        {
          break;
        }
        default: break;
    }
    if(activeStep === steps.length - 1) 
    { handleSubmit();}
    // else
    // { 
      setActiveStep(activeStep + 1);

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  async function  handleSubmit() { 
    // ===== Uploading user files =============================================
    let Payload = { Xfrmer: state.newXrmer};
    let XfrmrName = state.newXrmer.XfrmName;
    if(!isEmpty(Payload))
    {
      // await Storage.put(`${username}/${XfrmrName}/FFTR.json`, JSON.stringify(Payload))
      //     .then (result => console.log(result))
      //     .catch(err => console.log(err));
    }
  }


  return (
      <div className={classes.root}> 
        <Card className={classes.card}>
          <CardHeader className={classes.cardheader}  >
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {
                steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardHeader>
          <CardBody className={classes.CardBody}>
            <div className={classes.Comment}>
              <Box flexGrow={1}> { activeStep !== 0 && ( <Button onClick={handleBack} className={classes.button}> Back </Button> )} </Box>
              <Box><Button variant="contained" color="primary" onClick={handleNext} className={classes.button} > {activeStep === steps.length - 1 ? 'Place order' : 'Next'} </Button></Box> 
            </div>
            <div className={classes.Comment}>
                <Typography variant="body2">
                {<InfoIcon/>} <strong>Transformer Without Known History </strong>
                </Typography>
                  <Divider/>
                  <Typography variant="caption" gutterBottom>
                    This is the case for low power or subordinate transformer in the network. Neither online data nor duration curve of the load or ambient 
                    temperature is available. The Aging calculation procedure will :
                    <ul>
                      <li>Get the average ambient temperature from the weather service</li>
                      <li>Get admissible (N - 1) load or guess average load</li>
                    </ul>
                  </Typography>  
              </div>
              <div className={classes.Comment}>
                <Typography variant="body2">
                {<InfoIcon/>} <strong>Transformer With Known Load History </strong>
                </Typography>
                  <Divider/>
                  <Typography variant="caption" gutterBottom>
                    This is the case for medium power and handover point transformers in the network. These transformers are typically equipped with a 
                    load measurement for billing purposes. The Aging calculation procedure will :
                    <ul>
                      <li>Get the average ambient temperature from the weather service </li>
                      <li>Build a daily load profile based on the provided load history.</li>
                    </ul>
                  </Typography>  
              </div>
              <WeatherPanel setWeatherData = { () => {} }/>
              <div className={classes.Comment}>
                <Typography variant="body2">
                {<InfoIcon/>} <strong>Transformer With Complete Known Load and Ambient Temperature History </strong>
                </Typography>
                  <Divider/>
                  <Typography variant="caption" gutterBottom>
                    This is the case for large power, new or strategic important transformers in the network. These transformers are typically equipped with a 
                    load measurement for billing purposes, and an online monitoring of relevant parameters like hot spot temperature, load currents, moisture, gasses,
                    ambient and/or cooling air temperature, etc. The Aging calculation procedure will :
                    <ul>
                      <li> Build a daily ambient temperature. </li>
                      <li> Build a daily load profile based on the porvided load history.</li>
                    </ul>
                  </Typography>  
              </div>
              <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 3, width:'50%', borderColor : '#4486a3'}}/>       
                <DropzoneArea acceptedFiles={['.pdf', '.jpeg', '.png', '.doc', '.docx']} maxFileSize={10000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop your heat-run/ nameplate/ factory
                              final tests results files here (accepted files are .pdf, .jpeg, .png, .doc, docx) here or click to select and upload.' 
                              onChange={() => { }} showFileNames="true" showAlerts={true} filesLimit={5} dropzoneClass={classes.dropzoneArea} />  
          </CardBody>
        </Card>
      </div>

  );
}