import React, {useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {NumericCellEditor } from "components/MainPanel/NumericCellEditor.jsx";
import { NumericValueEditor, IntervalCellEditor, IntervalRenderer } from "components/MainPanel/NumericCellEditor.jsx";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { Typography, Dialog, Button} from '@material-ui/core';
import { coolMode, isEmpty, OpenTank, XfrmTypeList , XfrmVoltList, XfrmPhases, XfrmLiquidInsul, XfrmSolidInsul } from 'components/MainPanel/Utilities.jsx';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Card, CardHeader, CardBody } from 'reactstrap';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  button2: {
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3'
  },

controlBar: {
  padding: "1px",
  borderTopColor: "#d3d3d3",
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderBottomColor: "#d3d3d3",
  borderLeft: "0px",
  borderRight:"0px",
  marginTop: "5px",
  marginBottom: "10px",
  backgroundColor : "#f6f6f6",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "100%",
},
dialog:{
  padding:"0px",
  marginTop:"0px"

},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },  
}));


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="subtitle2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function CreateSession(props) {
  
const iXfrmSheet = [
  // Rated Voltages
   { VarName:'XfrmName', Description: 'New transformer name', Value:'Template' },
   { VarName:'XType', Description:'Transformer type', Value:'Select' },
   { VarName:'XPhase', Description:'Number of phases', Value:'Select' },
   { VarName:'VClass', Description:'Voltage class', Value:'Select' },

   { VarName:'Vintage', Description:'Year of services', Value: 'Select' },
   { VarName:'SolidInsul', Description:'Type of solid insulation', Value: 'Select' },
   { VarName:'LiquidInsul', Description:'Type of liquid insulation', Value:'Select' },
   { VarName:'TankType', Description:'Transformer tank style', Value:'Select'},

   { VarName:'GPSLatitude', Description:'Transformer location latitude', Value:0.0},
   { VarName:'GPSLongitude', Description:'Transformer location longitude', Value:0.0},
   { VarName:'GPSAltitude', Description:'Transformer location altitude', Value:0.0},
];

const iXfrmRateSheet = [
  { VarName: 'Cooling', Description: 'Transformer cooling modes', Stage1: 'Select', Stage2: 'Select', Stage3: 'Select', Unit: ''},
  { VarName: 'Rating', Description: 'Transformer ratings', Stage1: 0.0,  Stage2: 0.0, Stage3: 0.0, Unit: 'MVA'},
];
const defaultXfrm = { 
    XfrmUser: '',
    XfrmName: '',
    XType: 'DISTRIBUTION',
    XPhase: '3-Phase',
    VClass:'<230kV',
    Vintage:'35',
    Stage1: '',
    Stage2: '',
    Stage3: '',
    Rating1: '',
    Rating2: '',
    Rating3: '',
    SolidInsul:'UPGRADED',
    LiquidInsul:'MINERAL OIL',
    TankType:'FREE-BREATHING',
    GPSLatitude: 0.0,
    GPSLongitude: 0.0,
    GPSAltitude: 0.0,
}

const classes = useStyles();
const [transForm, setValues] = useState(defaultXfrm);
const [gridAApi, setGridAApi]     = useState();
const [gridBApi, setGridBApi]     = useState();
const [state, setState] = useState(iXfrmSheet);
const [stateRate, setStateRate] = useState(iXfrmRateSheet);


const XfrmRatedColumns =[
  { headerName: 'VarName', field: 'VarName', hide:true },
  { headerName: 'Description', field: 'Description'},
  { headerName: 'Stage 1', field: 'Stage1', editable: true, 
    cellEditorSelector:function (params)
    {
        // console.log ("Statge 1 : " + params.data.Variable + "  ===> New value is : " + params.data.Value);
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },

  { headerName: 'Stage 2', field: 'Stage2', editable:true,
    cellEditorSelector:function (params)
    {
        // console.log ("Statge 1 : " + params.data.Variable + "  ===> New value is : " + params.data.Value);
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },

  { headerName: 'Stage 3', field: 'Stage3', editable:true,
    cellEditorSelector:function (params)
    {
        // console.log ("Statge 1 : " + params.data.Variable + "  ===> New value is : " + params.data.Value);
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },
  { headerName: 'Unit', field: 'Unit'},
];

const XfrmStaticColumns = [
    { headerName: 'VarName', field: 'VarName', hide: true },
    { headerName: 'Description', field: 'Description'},
    { headerName: 'Value', field: 'Value', editable: true, 
      cellEditorSelector:function (params)
      {
        if (params.data.VarName === "LiquidInsul") return {
          component: 'agSelectCellEditor',
          params: {values: XfrmLiquidInsul.map(o => {return o.value})}
        };
        if (params.data.VarName === 'SolidInsul') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmSolidInsul.map(o => {return o.value})}
        };
        if (params.data.VarName === 'TankType') return {
          component: 'agSelectCellEditor',
          params: {values: OpenTank.map((o => {return o.value}))}
        };
        if (params.data.VarName === 'VClass') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmVoltList.map((o => {return o.value}))}
        };
        if (params.data.VarName === 'XType') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmTypeList.map((o => {return o.value}))}
        };       
        if (params.data.VarName === 'XPhase') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmPhases.map((o => {return o.value}))}
        }; 

        if (params.data.VarName === 'GPSLatitude' 
         || params.data.VarName === 'GPSLongitude'
         || params.data.VarName === 'GPSAltitude'
         || params.data.VarName === 'Vintage') return { component: 'numericCellEditor' };
        
      }
    }  
];


const handleSubmit = event => {
  event.preventDefault();

  transForm.XfrmName    = state.find(item => item.VarName ==='XfrmName').Value;
  transForm.XType       = state.find(item => item.VarName ==='XType').Value;
  transForm.XPhase      = state.find(item => item.VarName ==='XPhase').Value;
  transForm.VClass      = state.find(item => item.VarName ==='VClass').Value;
  transForm.SolidInsul  = state.find(item => item.VarName ==='SolidInsul').Value;
  transForm.LiquidInsul = state.find(item => item.VarName ==='LiquidInsul').Value;
  transForm.TankType    = state.find(item => item.VarName ==='TankType').Value;
  
  transForm.GPSLatitude   = state.find(item => item.VarName ==='GPSLatitude').Value;
  transForm.GPSLongitude  = state.find(item => item.VarName ==='GPSLongitude').Value;
  transForm.GPSAltitude   = state.find(item => item.VarName ==='GPSAltitude').Value;

  transForm.Rating1     = stateRate.find(item => item.VarName ==='Rating').Stage1;
  transForm.Rating2     = stateRate.find(item => item.VarName ==='Rating').Stage2;
  transForm.Rating3     = stateRate.find(item => item.VarName ==='Rating').Stage3;

  transForm.Stage1      = stateRate.find(item => item.VarName ==='Cooling').Stage1;
  transForm.Stage2      = stateRate.find(item => item.VarName ==='Cooling').Stage2;
  transForm.Stage3      = stateRate.find(item => item.VarName ==='Cooling').Stage3;

  //======= Checking values ========
  if(!isEmpty(transForm)) 
  {              
    if(isEmpty(transForm.XfrmName) || transForm.XfrmName ==='Template') 
    {
      alert ("Please enter a transformer name");
      return;
    }
    if(transForm.XType ==='Select') 
    {
      alert ("Please enter a transformer type");
      return;
    }
    if(transForm.XPhase ==='Select') 
    {
      alert ("Please select the number of phases.");
        return;
    }
    if(transForm.Vintage === 'Select') 
    {
      alert ("Please enter the years of services.");
      return;
    }
    if(transForm.VClass ==='Select') 
    {
      alert ("Please specify a transformer voltage class");
      return;
    }
    if(transForm.SolidInsul ==='Select') 
    {
      alert ("Please specify the type of solid insulation");
      return;
    }
    if(transForm.LiquidInsul ==='Select') 
    {
      alert ("Please specify the type of liquid insulation");
      return;
    }
        
    if(transForm.Stage1 ==='Select' && transForm.Stage2 ==='Select' && transForm.Stage3 ==='Select') 
    {
      alert ("You need to specify at least one cooling stage");
      return;
    }
    if(transForm.Rating1 === 0 && transForm.Rating2 === 0 && transForm.Rating3 === 0) 
    {
      alert ("The number of cooling stages must match the associated ratings ");
      return;
    }
    if(
        ((transForm.Stage1 === 'Select' && transForm.Rating1 !==0) || (transForm.Stage1!== 'Select' && transForm.Rating1 === 0))
          || ((transForm.Stage2 === 'Select' && transForm.Rating2 !==0) || (transForm.Stage2!== 'Select' && transForm.Rating2 === 0))
          || ((transForm.Stage3 === 'Select' && transForm.Rating3 !==0) || (transForm.Stage3!== 'Select' && transForm.Rating3 === 0))
      ) 
    {
      alert ("A stage must be associated with its corresponding rating, and vice-versa.");
      return;
    } 
  }     

  // ====== printValues (event) =====;
  props.hideSession(transForm);
  setValues(defaultXfrm);
  setState(iXfrmSheet);
  setStateRate(iXfrmRateSheet);
};

const onGridAReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridAApi({ api, columnApi });
    params.api.sizeColumnsToFit();
  },[]
);

const onGridBReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridBApi({ api, columnApi });
    params.api.sizeColumnsToFit();
  },[]
);

// const frameworkComponents = {
//   numericCellEditor: NumericCellEditor
// }

const onCellValueChanged = params => {
  var colId = params.column.getId();
  if (colId === "Value") {
    params.node.setDataValue(colId, params.data.Value); 
    return;
  }
}

const onCellStageChanged = params => {
  let colId = params.column.getId();
  // console.log (colId + " |||" + params.oldValue + "<<<====>>>" + params.newValue);
  switch(colId)
  {
    case "Stage1": { 
      // params.Stage1 = params.newValue;
      //  console.log ("Stage1 ==<<<" + params.Stage1);
       params.node.setDataValue(colId, params.data.Stage1); 
       break;
      }
    case "Stage2": { 
      // params.Stage2 = params.newValue;
      // console.log ("Stage2 ==<<<" + params.Stage2);
      params.node.setDataValue(colId, params.data.Stage2); 
      break;
    }
    case "Stage3": { 
      // params.Stage3 = params.newValue;
      // console.log ("Stage3 ==<<<" + params.Stage3);
      params.node.setDataValue(colId, params.data.Stage3); 
      break;
    }
  }    
}

return (
      <Dialog onClose={props.hideSession} fullWidth maxWidth='md' aria-labelledby="form-dialog-title" open={props.show} >
        
        <DialogContent dividers>
        <Card className={classes.card} >
          <CardHeader> <DialogTitle id="form-dialog-title" className={classes.dialog} onClose={props.hideSession}> Create new transformer </DialogTitle> </CardHeader>
          <CardBody>
          <div  className="ag-theme-balham" >
            <AgGridReact
              modules={AllCommunityModules}
              columnDefs={XfrmStaticColumns}
              rowData={state}
              domLayout="autoHeight"
              onGridReady={onGridAReady}
              onCellValueChanged={ onCellValueChanged} 
              singleClickEdit = {true}
              enterMovesDown={true}
              enterMovesDownAfterEdit={true}
              frameworkComponents = {{                             
                numericCellEditor: NumericValueEditor, //NumericCellEditor,
                intervalCellEditor: IntervalCellEditor,
                intervalRenderer: IntervalRenderer }} />
          </div> 
          <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 3, width:'50%', borderColor : '#4486a3'}}/>
          <div  className="ag-theme-balham" >
            <AgGridReact
              modules={AllCommunityModules}
              columnDefs={XfrmRatedColumns}
              rowData={stateRate}
              domLayout="autoHeight"
              onGridReady={onGridBReady}
              onCellValueChanged={ onCellStageChanged} 
              singleClickEdit = {true}
              frameworkComponents = {{                             
                numericCellEditor: NumericValueEditor, //NumericCellEditor,
                intervalCellEditor: IntervalCellEditor,
                intervalRenderer: IntervalRenderer }}
            />
          </div> 
          </CardBody>
        </Card>   
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary"  size="small"  variant="outlined" className={classes.button2}> Create </Button>
        </DialogActions>
      </Dialog>
  );
}
