import React , {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory }  from "react-router-dom";
import { Grid, Divider, Button, Typography, Box } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import AppMarkdown from './loadinghub-beta-agreement.md'
import ReactMarkdown from "react-markdown";
import 'react-phone-input-2/lib/style.css'

var nodemailer = require('nodemailer');
var sesTransport = require('nodemailer-ses-transport');
// var AppMarkdown = require('./loadinghub-beta-agreement.md')

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '100',
    minHeight: '90vh'
  },
  button: {
    color: '#4486a3',
    borderRadius:"0px",
  },
  layout: {
    width: 'auto',
    // border: "1px solid",
    marginTop: '4%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
  }},

  infoArea :
  {  
    // height: "200px", 
    marginTop: "10%", 
    marginLeft: "20px", 
    marginRight:"20px", 
    padding:"10px", 
    backgroundColor: "#FFFFFF"
  },
  country:{
    marginTop:'3px',
    borderColor:'#C0C0C0',
    padding : '6px',
    color:'#808080',
    borderRadius:"1px"

  },
  card:{
    borderRadius: "0px",
  },
}));


export default function EULA() {
const classes = useStyles();
const history = useHistory();
const [state, setState] = useState('');


useEffect(() => 
{
  fetch(AppMarkdown)
  .then(res => res.text())
  .then(text => {
    console.log('text decoded:', text);
    setState(text)
  });
}, []);



async function  handleSubmit() { 
  // ===== Uploading user files =============================================
  // if(!isEmpty(state.cuFiles))
  // {
  //   await Storage.put(`Quote-${state.cuFiles}`, state.cuFiles)
  //   .then (result => console.log(result))
  //   .catch(err => console.log(err));
  // }
  // console.log('cuName: ' + state.cuName);
  // console.log('cuCompany: ' + state.cuCompany);
}
  // ============Sending an email acknowledging the reviewed files ============
  


return (
      // <main className={classes.layout}>
        // <br/>
        <Card className={classes.card}>
          <CardHeader> 
             <div style={{ width: '100%' }}>
               <Box display="flex">
                 <Box width="100%">
                   <Typography variant="subtitle1"><strong> End User License Agreement </strong></Typography>
                 </Box>
                 {/* <Box flexShrink={1} >
                   <Button size="small"  variant="outlined"
                     href="https://www.hvsscorp.com/products" className={classes.button} >CANCEL </Button> 
                 </Box> */}
               </Box>
             </div>
           </CardHeader>
           <CardBody className={classes.CardBody}> 
            <ReactMarkdown  children={state}/>
           </CardBody>
         </Card> 
      //  </main>
  );
}