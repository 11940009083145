import React, {Fragment}  from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import LoadingTableHeader from './LoadingTableHeader';
import LoadingTableRows from './LoadingTableRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#cccccc',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        backgroundColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontSize:8,
        fontStyle: 'bold',
        textAlign: 'left',
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontSize: 8
    },
    pubName: {
        width: '20%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    pubTitle: {
        width: '70%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'left',
    },
    pubDate: {
        width: '10%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    }
  });


const StandardTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.pubName}> NAME </Text>
        <Text style={styles.pubTitle}> PUBLICATION </Text>
        <Text style={styles.pubDate}> DATE </Text>
    </View>
);

const StandardTableRows = ({items}) => {
    const rows = items.map((item, index) => 
        <View style={styles.row} key={index}>
            <Text style={styles.pubName}>{item.pubName}</Text>
            <Text style={styles.pubTitle}>{item.pubTitle}</Text>
            <Text style={styles.pubDate}>{item.pubDate}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  
  const StandardTableItems = ({rowData}) => (
    <View style={styles.tableContainer}>
       <StandardTableHeader/>
       <StandardTableRows items={rowData}/>
    </View>
  );
  
  export default StandardTableItems