import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles} from '@material-ui/core/styles';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { Typography, Button, Snackbar, Divider,  Grid } from "@material-ui/core";
import { isEmpty, onlyUnique} from './Utilities.jsx';


// import domtoimage from 'dom-to-image';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
    backgroundColor: '#eceff1',
    width: '100%',
  },
  CardBody:{
    padding: "1px",
    paddingLeft: "-10px",
  },
  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  }
}));

export default function Summary(props) {
  const classes  = useStyles();
  const [gridApi, setGridApi]       = useState();

  var lNameSet = props.NamePlate.map(p => p.name).filter(onlyUnique);
  var ambientSet  =  props.NamePlate.map(q => q.ieeeData.map(r => r.Ambient).filter(onlyUnique))[0]; 

  const onGridReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridApi({ api, columnApi });
      params.api.sizeColumnsToFit();
    },[]
  );

  
  useEffect(() =>
  {
    if(!isEmpty(props.NamePlate))
    {
      lNameSet = props.NamePlate.map(p => p.name).filter(onlyUnique);
      ambientSet  = props.NamePlate.map(q => q.ieeeData.map(r => r.Ambient).filter(onlyUnique))[0]; 
    }
  }, [props]);


  return (
    <div className ={classes.root}>
      <Grid container spacing ={2} justify="center">
        { 
          lNameSet.map((lName, lindex) => { 
            return (
              ambientSet.map((ambient, kindex) =>   {   
                 return (
                    <Grid item xs={12}>
                      <Card className={classes.card} key={`${lindex}-${kindex}`}>
                        <CardHeader> <Typography variant="subtitle2"><strong> {`'${ lName } - Ambient offset: ${ambient }'`}</strong></Typography> </CardHeader>
                            <CardBody>
                              <div  className="ag-theme-balham" id = {`${lindex}-${kindex}`}>
                                <AgGridReact modules={AllCommunityModules} 
                                                  defaultColDef={{ flex: 1, minWidth: 130}}
                                                  rowData={props.NamePlate.find(p => p.name === lName).ieeeData.filter(r => r.Ambient === ambient)} 
                                                  domLayout="autoHeight" onGridReady={onGridReady}>
                                  <AgGridColumn headerName='Hot Spot' field="HotSpot" />
                                  <AgGridColumn headerName='Optimum MVA' field="MVA" />
                                  <AgGridColumn headerName='Base MVA' field="basicMVA"   />   
                                  <AgGridColumn headerName='Top Oil' field="TopOil"  />
                                  <AgGridColumn headerName='MVA Margin [MVA]' field="MVAMargin" hide={true} />
                                  <AgGridColumn headerName='Margin [MVA]' field="Margin" />
                                  <AgGridColumn headerName='Bottom Oil' field="BottomOil" />
                                        <AgGridColumn headerName='LossOfLife' field="LOSSofLife" />
                                        <AgGridColumn headerName='Ambient offset' field="Ambient" />
                                        <AgGridColumn headerName='Cooling' field="Cooling"  hide={true}/>
                                        <AgGridColumn headerName='Duration' field="Overtime"  />
                                        <AgGridColumn headerName='TTP Load [Min]' field="TTPMva"  />
                                        <AgGridColumn headerName='TTP TopOil[Min]' field="TTPTop" />
                                        <AgGridColumn headerName='TTP HotSpot[Min]' field="TTPHot"  />
                                        <AgGridColumn headerName='TTP Bottom[Min]' field="TTPBot" hide={true} />
                                  <AgGridColumn headerName='LimitedBy' field="LimitedBy"  />
                                </AgGridReact>
                              </div>
                            </CardBody>
                      </Card>
                    </Grid>   
                  )
              })
            )
          })
        }
      </Grid> 
    </div>
  )
}