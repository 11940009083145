import React , {useState } from "react";
import PropTypes from "prop-types";
import { useHistory }  from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { makeStyles ,ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";
import {Button, Link, Box, Grid, Toolbar, Divider}  from '@material-ui/core';
import SerratedTabs from 'views/Dashboard/SerratedTabs.jsx';
import { Card, CardText, CardTitle, CardHeader, CardBody } from 'reactstrap';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
// import {Card, CardActions, CardActionArea, CardMedia, CardContent} from '@material-ui/core';
import {Storage } from 'aws-amplify';
import fileDownload from 'js-file-download';
import StartUpPage from "assets/img/SimuLoad/StartUpPage.png";
import Performances from "assets/img/SimuLoad/Performances.png";
import Datasheet from "assets/img/SimuLoad/Datasheet.png";
import Profile from "assets/img/SimuLoad/Profile.png";
import LoadingScenario from "assets/img/SimuLoad/LoadingScenario.png";
import Dashbd from "assets/img/SimuLoad/Dashboard.png";
import LoadingCrvs from "assets/img/SimuLoad/LoadingCurves.png";
import Report from "assets/img/SimuLoad/Report.png";



const baseTheme = createMuiTheme();
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    // margin: '20px',
    // borderRadius: '0px',
  },

  form: 
  {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    minHeight: '90vh'
  },
  layout: {
        width: 'auto',
        // border: "1px solid",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 700,
          marginLeft: 'auto',
          marginRight: 'auto',
  }},
  controlBar: {
        padding: "1px",
        borderTopColor: "#d3d3d3",
        border: "1px solid",
        borderBottomColor: "#d3d3d3",
        borderLeft: "0px",
        borderRight:"0px",
        marginTop: "5px",
        marginBottom: "10px",
        backgroundColor : "#f6f6f6",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
  },   

  comment: {
        padding: "5px",
        borderLeftColor: "#6699cc",
        borderLeft: "8px solid",
        backgroundColor : "#f6f6f6",
        marginTop: theme.spacing(-2),

  },
  cardheader:{
        padding: "1px",
        paddingLeft: "5px",
        borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
    display: "flexRow",
    minHeight : 400
  },
  button:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
    border: "0px",
    color:"#FFFFFF",
  },
  
  media: {
    display: "flexRow",
    justifyContent: 'center',
    padding:  theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor : "#172F38",
    color: 'white',
    height: '100px'
  },

  // licenses :{
  //   margin:theme.spacing(5)
  // },
  licenses:{
    padding: theme.spacing(4),
    backgroundColor : "#FFFFFF",
    margin: theme.spacing(3),
    display: 'flex', 
    justifyContent: 'center'
  }

  }));
  

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  



  const PlatForm = () => 
  {
    const classes           = useStyles();  
    const history           = useHistory();

    async function  handleDownload () {
        Storage.get('SimuLoadv0.1/SimuLoadv0.1.zip', { download: true })
        .then(res => fileDownload(res.Body, 'SimuLoadv0.1.zip')) 
    }

    const handleQuotation= () =>
    {
      history.push({pathname:"/admin/quote-us" , state:{ }});  
    }

    return (
          <div>
              <Typography variant="subtitle2"> Minimum system requirements </Typography>
              <Typography variant="body2" component="div">
                <ul>
                    <li> 2 GHz 32-bit (x86) or 64-bit (x64) processor</li>
                    <li> 2 GB RAM (1 GB Recommended) </li>
                    <li> Cross Platform compatible</li>
                    <li> At least 3 GB of available hard disk space is required for the software and setup files</li>
                    <li> MySql Server </li>
                    <li>.NET Core 3.0+, Windows 8.0, Linux OS. </li>
                </ul>
              </Typography>
              <Divider/>
              <div className = {classes.licenses}>
                <Grid container spacing ={2} justify="center">
                  <Grid item> </Grid>
                  <Grid item>
                    <Card body inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>
                      <CardTitle><Typography variant="subtitle2"> FREE TRIAL </Typography></CardTitle>
                      <CardText>
                      <Typography variant="h4" ><strong>$0.00 </strong></Typography>
                        <Typography variant="body2" component="div">
                          <ul>
                            <li>Latest version for 7-days </li>
                            <li>Full export and import abilities </li>
                            <li>Access to technical support</li>
                            <li>Demo on Request </li>
                          </ul>                    
                        </Typography>
                      </CardText>
                      <Button variant="outlined" onClick={ handleDownload} className={classes.button} startIcon = {<CloudDownloadOutlinedIcon/>}>
                          Download
                      </Button>                    
                    </Card> 
                  </Grid>
                  <Grid item > 
                    <Card body inverse style={{ backgroundColor: '#333', borderColor: '#333' }}>
                      <CardTitle><Typography variant="subtitle2"> BUY A LICENSE  </Typography></CardTitle>
                      <CardText>
                        <Typography variant="h4" ><strong> On Request </strong></Typography>
                        <Typography variant="body2" component="div">
                          <ul>
                            <li>Single License </li>
                            <li>Hardware Locked </li>
                            <li>Free updates and fixes</li>
                            <li>4-Hours Training Program </li>
                          </ul>                     
                        </Typography>
                      </CardText>
                      <Button variant="outlined" onClick={ handleQuotation} className={classes.button} startIcon = {<CloudDownloadOutlinedIcon/>}>
                          Get a quote
                      </Button>                    
                    </Card>  
                  </Grid>                     
                  <Grid item> </Grid>
                </Grid>
              </div>
              <Divider/>
              <div className = {classes.licenses}>
                <Typography variant = "caption">
                    Price is in US dollars. For purchase orders, proforma invoices, and other questions please email <Link>contact@transformer-analytics.com</Link>
                </Typography>
              </div>
          </div>
      )
  }
  


const Description = () =>
{
  const classes  = useStyles();
    return (
        <div>
          <Typography variant="subtitle2"> Preamble </Typography>
          <div style ={{ textAlign:"justify"}}>
            <Typography variant="body2" component="div"> 
              <div> Utilities need to assess transformer capabilities above nameplate rating when :
                <ul>
                  <li>the system planner needs to decide on timely augmentation of an installation </li>
                  <li> the maintenance manager requires timing details before doing off-circuit maintenance </li>
                  <li> the system operator must decide on safe equipment loading after a fault on the system.</li>
                </ul>
              </div>
              <div> 
                To fullfill these needs, a careful review of loading studies practices has been conducted, of the effect of loads and other key factors on oil-transformer ageing, especially the IEEE/IEC loading guides, 
                the SimuLOAD™ v0.1 software offers a unique set of features to assist utilities in planning transformer loading in the context of factors that influence the insulating paper and oil ageing, 
                such as the ambient operating conditions, the electric vehicles (EVs), harmonics, demand response (DR), distributed generation (DG) and experimental loads.
              </div>
            </Typography> 
          </div>
        </div>
    )
}


const KeyBenefits = () => 
{
    return (
        <div >
            <Typography  variant="subtitle2"> Key Benefits </Typography>
            <div style ={{ textAlign:"justify"}}>
              <Typography variant="body2" component="div">
                <ul>
                    <li> Industry standard-based calculation methods.</li>
                    <li> Simple, intuitive graphical interface supplemented by extensive technical help.</li> 
                    <li> Establish the transformer seasonal load and ambient profiles based on existing utility loading practice</li>
                    <li> Determine the transformer existing loading capability and aging prior to monitoring</li>
                    <li> Build and simulate user-defined loading scenarios.</li>
                    <li> Optimize loading limits and margins for Normal and Emergency loadings</li>
                    <li> Utilize transformers closer to their real operating temperatures and life-cycle limits without compromising the assets life expectancy or reliability.</li>
                    <li> Provides printable/exportable unit specific loading assessment report.</li> 
                </ul>
              </Typography>
            </div>
        </div>
    )
}

const Configurations = () =>
{
  return (
    <div>
      <Typography variant ="subtitle2"> Transformer Profile Configurations </Typography>
      <Typography variant="body2" component="div">
        <ul>
          <li>Allows multiples transformer instantiation and initialization </li>
          <li>Provides an editable view of the transformer heat run test data</li>
          <li>Offer standardized guidance to the user inputs edition. </li>
        </ul>
      </Typography>
    </div>
  )
}

const ProfileDesign = () =>
{
  return (
    <div>
      <Typography variant ="subtitle2"> Load Profiles Design </Typography>
      <Typography variant="body2" component="div">
        <ul>
          <li> Allows sequential upload of user defined load profile from external file upload </li>
          <li> Provides an editable view of the uploaded data and a graphical display of ambient and load cycle</li>
          <li> Allows the caption of ambient temperature cycle from the weather forecast</li>
          <li> Maintain a dynamic list of defined load profiles where a load profile can be consulted, modified and removed at will</li>
        </ul>
      </Typography>
    </div>
  )
}

const Scenario = () =>
{
  return (
    <div>
      <Typography variant ="subtitle2"> Loading scenarios</Typography>
      <Typography variant="body2" component="div">
        <ul>
          <li> Presents a set of key loading parameters allowing the user to define the limitiing constraints that their transformer is subjected to</li>
          <li> Allows the user to specify  key performance factors contributing to the degradation of the transformer load margin and aging</li>
          <li> Provides the ability to defined how the ambient temperature rise can impact the transformer loading performances.</li>
          <li> Provides the ability to define experimental load profile, and how it can impact the transformer loading performances.</li>
          <li> Provides the ability to evaluate how the harmonicscan impact the transformer loading performances.</li>
          <li> Maintain a dynamic list of scenarios where a predefined loading scenario can be selected, consulted, modified and removed at will.</li>
        </ul>
      </Typography>
    </div>
  )
}

const Dashboard = () =>
{
  return (
  <div>
    <Typography variant ="subtitle2"> Summary Dashboard  </Typography>
    <Typography variant="body2" component="div">
      <ul>
        <li> Presents a quick summary of the loading assessment outcome </li>
        <li> Features clusters of key loading assessment indicators in terms of optimal limits at given ambient conditions</li>
        <li> Showcases key graphical trends vs. timeline and ambient conditons.</li>
      </ul>
    </Typography>
  </div>
  )
}

const LoadingCurves = () =>
{
  return (
  <div>
    <Typography variant ="subtitle2"> Loading Curves  </Typography>
    <Typography variant="body2" component="div">
      <ul>
        <li> Presents a set of Loading curves at the various cooling stages over time and conditions </li>
        <li> Features the load margins available to the operators as loading regions</li>
        <li> Provides indications of the highest thermal trends over time and ambient conditions. </li>
      </ul>
    </Typography>
  </div>
  )
}

const Results = () =>
{
  return (
  <div>
    <Typography variant ="subtitle2"> Summary Results </Typography>
    <Typography variant="body2" component="div">
      <ul>
        <li> Presents a set of data table presenting a summary of the loading assessment outcome.</li>
        <li> Features the load margins available to the operators as loading regions</li>
        <li> Provides indications of the highest thermal trends over time and ambient conditions.</li>
      </ul>
    </Typography>
  </div>
  )
}


export default function SimuLoad (props) 
{ 
    const classes           = useStyles();
    const [value, setValue] = useState(0);

    return (
        <div className={classes.root}>
          <Typography variant="h6" component="p"> SimuLOAD™ v0.1 Software Fact Sheet and License Tracking</Typography>
          <Toolbar style={{ overflow: 'hidden' }}>
            <ThemeProvider theme={baseTheme}>
                  <SerratedTabs style={{ alignSelf: 'flex-end' }}
                    tabs={[{ label: 'SimuLOAD™'}, { label: 'FEATURES'}, { label: 'LICENSES'}]}
                    tabStyle={{ bgColor: '#4486a3', selectedBgColor: '#ffffff',}}
                    tabProps={{ disableRipple: true,}}
                    value={value}
                    onChange={(e, i) => setValue(i)}/>
            </ThemeProvider>
          </Toolbar> 
              <TabPanel value={value} index={0}>
                <div className={classes.root}>
                  <div className={classes.comment}>
                    <Grid container spacing={2}>
                      <Grid item xs ={5} >
                        <Description/>
                      </Grid>
                      <Grid item xs = {7}>
                        <img src={StartUpPage} alt="xfrmer-configurations"/>
                      </Grid>
                      <Grid item xs ={5} >
                        <KeyBenefits/>
                      </Grid>
                      <Grid item xs = {7}>
                        <img src={Performances}  alt="xfrmer-configurations"/>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className={classes.root}>
                  <div className={classes.comment}>                            
                    <Grid container spacing={3}>
                      <Grid item xs ={5} >
                        <img src={Datasheet} alt="xfrmer-configurations"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <Configurations/>
                      </Grid>
                      <Grid item xs = {5} >
                        <img src={Profile} alt="profile-design"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <ProfileDesign/>
                      </Grid>
                      <Grid item xs = {5} >
                        <img src={LoadingScenario} alt="loading-scenario"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <Scenario/>
                      </Grid>
                      <Grid item xs ={5} >
                        <img src={Dashbd} alt ="loading-dashboard"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <Dashboard/>
                      </Grid>
                      <Grid item xs = {5} >
                        <img src={LoadingCrvs} alt = "loading-curves"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <LoadingCurves/>
                      </Grid>
                      <Grid item xs ={5} >
                        <img src={Report} alt="loading-assessment-results"/> 
                      </Grid>
                      <Grid item xs = {7}>
                        <Results/>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className={classes.root}>
                  <div className={classes.comment}> 
                    <PlatForm/>
                  </div>
                </div>
              </TabPanel>
        </div>
    )
}

