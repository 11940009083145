import React, {Fragment}  from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import LoadingTableHeader from './LoadingTableHeader';
import LoadingTableRows from './LoadingTableRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#cccccc',
    },
    container: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        backgroundColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontSize:8,
        fontStyle: 'bold',
        textAlign: 'left',
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 15,
        fontSize: 8
    },
    Value: {
        width: '30%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    Description: {
        width: '60%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'left',
    },
    Unit: {
        width: '10%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    }
  });


const DesignTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.Description}> DECSRIPTION </Text>
        <Text style={styles.Value}> VALUE </Text>
        <Text style={styles.Unit}> UNIT </Text>
    </View>
);

const DesignTableRows = ({items}) => {
    const rows = items.map((item, index) => 
        <View style={styles.row} key={index}>
            <Text style={styles.Description}>{item.Description}</Text>
            <Text style={styles.Value}>{item.Value}</Text>
            {/* <Text style={styles.Value}>{item.Stage2}</Text>
            <Text style={styles.Value}>{item.Stage3}</Text> */}
            <Text style={styles.Unit}>{item.Unit}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  
  const DesignTableItems = ({rowData}) => (
    <View style={styles.tableContainer}>
       <DesignTableHeader/>
       <DesignTableRows items={rowData}/>
    </View>
  );
  
  export default DesignTableItems