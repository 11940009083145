import React , {useEffect} from "react";
import  { useHistory }  from "react-router-dom";
import { useBeforeunload } from 'react-beforeunload';
import { makeStyles } from '@material-ui/core/styles';
import './rotate.css';
import Welcome2 from "assets/img/XfrmItems/Welcome2.png"
import climate from "assets/img/faces/climate.png"
import constraint from "assets/img/faces/constraint.png"
import dashboard from "assets/img/faces/dashboard.png"
import curve from "assets/img/faces/curve.png"
import digital from "assets/img/faces/digital.png"
import performance from "assets/img/faces/performance.png"
import variable from "assets/img/faces/variable.png"
import LaunchIcon from '@material-ui/icons/Launch';
import {Divider, Box, Button, Typography,  Grid} from "@material-ui/core";
import { Card, CardHeader, CardBody, CardImg } from 'reactstrap';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  card: {
    height: '100%'
  },
  Comment: {
    padding: "20px",
    border: "1px solid",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#ffffff"
  },

  button:
  {
    marginTop:"40%",
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3'
  },
  imgClass:
  {
    width:"80px",
    height:"80px",
    marginLeft:"40%",
  }
}));


export default function Welcome (props) {
    //const classes  = useStyles(props);
    const classes   = useStyles();
    let history    =  useHistory();
    console.log("Dans Welcome ===>" + JSON.stringify(props.transFormers))
    // useEffect(() => { props.setDIYvalue(false); props.setGuideId("DIY");}, []);
    useEffect(() => { props.setDIYvalue(false); props.setGuideId("DIY");}, []);
    useBeforeunload(() => props.setDIYvalue(true));  
    const OpenAssStore = () =>  history.push({pathname:"/admin/multidashboard", state:{ nXfrmer: [] }}) 
    return ( 
      <div className={classes.root}>
        <Grid container spacing={2} >
          <Grid item xs={12} >
            <Typography variant="h5" style={{ marginTop:-15}}> DIY v0.1.0011: Do It Yourself Transformer Rating and Load Planning Toolbox </Typography>
            <hr style={{ marginLeft: 5, height: 10, width:'60%'}}/>
          </Grid>
          <Grid item xs={2}>       
            <img src={Welcome2} style={{width:'200px'}} className="rotate" alt= "Welcome Banner" />
            <Button variant="outlined"  onClick={ OpenAssStore } className={classes.button} startIcon = {<LaunchIcon/>} >
                  Open Assets Store
            </Button>
          </Grid>
          <Grid item xs={10} container spacing={2} > 
              <Grid item xs={3}>
                <Card className={classes.card}>
                    <CardBody> 
                        <Typography variant="subtitle1"> <strong> Optimum Load Assessment</strong> </Typography>
                        <img  className={classes.imgClass} src={ performance} alt="Cat"/>
                        <ul>
                          <li> Unlimited transformer units creation and loading capability assessment </li>
                          <li> What-If User-defined loading scenarios with multiple limiting criteria </li>
                          <li> Optimize and output loading limits, load margins and temperatures for <i>Normal</i> and <i>Emergency</i> loading modes</li>
                          <li> Accurate evaluation of the insulation aging.</li>
                        </ul>
                    </CardBody>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.card}>
                  <CardBody>     
                        <Typography variant="subtitle1"> <strong> Load Profiles and Weather Service</strong> </Typography>
                        <img className={classes.imgClass} src={ curve} alt="Cat"/>
                        <ul>
                          <li> Load profiles design and customization </li>
                          <li> Csv File Upload with <i>24-h-[Timestamp: HH:mm, Per Unit Load, Ambient]</i> inputs cycles </li>
                          <li> Ties in the weather forecast to Build and use a location-based 24-h ambient temperature cycle. </li>
                          <li> Multiple load profiles evaluation in batch mode.</li>
                        </ul>
                  </CardBody>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.card}> 
                  <CardBody>        
                      <Typography variant="subtitle1"> <strong> Digitize the IEEE/IEC Loading Guides</strong></Typography>
                      <img  className={classes.imgClass} src={ digital} alt="Cat"/>
                      <ul>
                        <li> Turn the IEEE/IEC loading guides into actions </li>
                        <li> Selectable Thermal models </li>
                        <li> Implement the C57.109 loading guidelines as inputs to the thermal models, ranging from Annex A up to Annex I </li>
                        <li> Establish Alarms Benchmarks for the transformer condition monitoring. </li>
                      </ul>
                  </CardBody>
                </Card>
              </Grid>
          </Grid>
          <Grid item xs={2}> </Grid>
          <Grid item xs={10} container spacing={2} >         
              <Grid item xs={3}>
                <Card className={classes.card}>
                    <CardBody>
                      <Typography variant="subtitle1"> <strong>Impacts Evaluation of Key Factors</strong></Typography>
                      <img  className={classes.imgClass} src={ variable} alt="Cat"/>
                      <ul>
                        <li> Assess the effects of ambient temperature rises </li>
                        <li> Experimental loads study on the transformer operating temperatures, and the Insulation Aging </li>
                        <li> Study the effect of the harmonics load on the transformer’s Aging.</li>
                        <li> Evaluate the insulation readiness to withstand the widespread adoption of EVs.</li>
                      </ul>
                    </CardBody>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.card}>
                    <CardBody>
                      <Typography variant="subtitle1"> <strong>WHAT-IFs Loading Scenarios</strong></Typography>
                      <img  className={classes.imgClass} src={ constraint} alt="Cat"/>
                      <ul>
                        <li> Establish WHAT-IFs loading scenarios for fine-grained load optimization </li>
                        <li> Define limiting criteria on a range of critical operation parameters </li>
                        <li> Evaluate multiple loading scenarios in Batch mode, with a matrix of Loading scenarios vs. Load profiles.</li>
                      </ul>
                    </CardBody>
                  </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.card}>
                  <CardBody>
                    <Typography variant="subtitle1"> <strong>Loading study reports</strong></Typography>
                    <img  className={classes.imgClass} src={ dashboard} alt="Cat"/>
                    <ul>
                      <li> Produce a customizable and exportable pdf Report.</li>
                      <li> Highligth of operating conditions with Available Load Margins </li>
                      <li> Maximum Safe Loads, Insulation Aging,  and Loading Curves vs. Conditions </li>
                      <li> Snapshots summary results. </li>
                    </ul>
                  </CardBody>
                </Card>
              </Grid>
          </Grid>
        </Grid>
      </div>
    );
}


