import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    minHeight: '90vh'
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },
}));


export default function PaymentForm() {
  const classes = useStyles();
    return (
    <main className={classes.layout}>
      <br/>
      <form className={classes.form} autoComplete="off">
        <Card className={classes.card}>
          <CardHeader className={classes.cardheader}>
          <Typography variant="subtitle2">  Payment method  </Typography>
          </CardHeader>
          <CardBody className={classes.CardBody}>
            <React.Fragment>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField required id="cardName" label="Name on card" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField required id="cardNumber" label="Card number" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField required id="expDate" label="Expiry date" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="cvv"
                      label="CVV"
                      helperText="Last three digits on signature strip"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color="secondary" name="saveCard" value="yes" />}
                      label="Remember credit card details for next time"
                    />
                  </Grid>
                </Grid>
            </React.Fragment>
          </CardBody>
          <CardFooter className={classes.CardBody}> 
                  {/* <Button variant="outlined"  onClick={handleSubmit} className={classes.button} startIcon = {<CloudUploadIcon/>}> Create </Button> */}
          </CardFooter>
        </Card> 
      </form> 
    </main>)

}