import React, {Component, useState,forwardRef, useEffect,  useImperativeHandle, useRef, useCallback} from 'react';
import {Grid, Typography, Box, Tabs, Tab, Snackbar, Button, TextField, Divider}  from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { PushSpinner } from "react-spinners-kit";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ApiURL, coolMode, isEmpty } from './Utilities.jsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  center:
  {
    marginRight: 'auto',
    marginLeft : 'auto',
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  popValue:
  { 
    margin: theme.spacing(1),  
    padding: "5px",
    borderBottom: "1px solid #cccccc",
    borderLeft: "1px solid #cccccc",
    borderTop: "1px solid #cccccc",
    borderRight:"1px solid #cccccc",
    backgroundColor : "#FFFACD"// "#f6f6f6",
  },
  
  spinner:{
    minWidth:'350px',
    minHeight:'100px',
    borderRadius: '0px',
    padding:"40px",
    paddingTop: "10px"
  },

  pusheader :
  {
    marginLeft:'5px',
    marginRight:'5px',
    marginTop:'10px',
    padding: "1px",
    marginTop: "5px",
    marginBottom: "10px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    marginLeft: "5px",
    marginRight: "5px",
    borderBottomColor: "#d3d3d3",
    backgroundColor : "#f6f6f6",
  },
  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  }
}));



const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

const getInitialValue = props => {
  let startValue = props.value;
  const keyPressBackspaceOrDelete = props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE;
  if (keyPressBackspaceOrDelete) {
    startValue = '';
  } else if (props.charPress) {
    startValue = props.charPress;
  }

  if (startValue !== null && startValue !== undefined) {
    return startValue;
  }
  return '';
}

export const SpinnerDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog onClose={props.handleClose} aria-labelledby="spinner-dialog-title" open={props.show} maxWidth="sm">
      <div className={classes.pusheader}>
        <Typography variant="subtitle2" component="div" gutterBottom> {props.Message} </Typography>
      </div>
      <div className={classes.spinner}>
        <PushSpinner size={60} color="#686769" loading={props.show} />
      </div>
      <Button size="small"  onClick={props.handleClose} >Cancel</Button>
    </Dialog>
  );
}

export const IntervalRenderer = forwardRef((props, ref) => {

  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);

  useEffect(() => {
    if(isEmpty(props.value))
    {
      setMinValue(0);
      setMaxValue(0);
    }
    else
    {                  
      setMinValue(parseFloat(props.value.split("-")[0]));
      setMaxValue(parseFloat(props.value.split("-")[1]));
      console.log("IntervalRenderer: " + props.value +  "IntervalRenderer: " + props.value)
    }
  }, [props.value]);


  return (
    <div>
      <Typography> {minValue} - {maxValue} </Typography>
    </div>
  );
});

export const IntervalCellEditor = forwardRef((props, ref) => {
  const classes                 = useStyles();
  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [value, setValue]       = useState(0);
  const [editing, setEditing]   = useState(true);
  const refMax                  = useRef(null);
  const refMin                  = useRef(null);
  const refVal                  = useRef(null);


 useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]);

  useImperativeHandle(ref, () => {
      return {
          getValue() {
              if(!isEmpty(value))
                return value;
              else if(!isEmpty(minValue) && !isEmpty(maxValue))
                return minValue + "-" + maxValue; 
          },
          // Gets called once before editing starts, to give editor a chance to       
          isPopup() {
            return true;
          },
          // Gets called once before editing starts, to give editor a chance to
          isCancelBeforeStart() {
            return false;
          },
          // Gets called once when editing is finished (eg if Enter is pressed).
          isCancelAfterEnd() {
            // our editor will reject any value greater than 1000
            if(isEmpty(minValue) && isEmpty(maxValue) && isEmpty(value))
              return true; 
        }
      };
  });

  return (
    <div className={classes.popValue} >
      <Typography variant="caption"> If you don't know the value, provide at least upper and lower bounds to the best of your knowledge </Typography>
      <hr/>
      <Grid container spacing={2}>
        <Grid item xs={6}>
            <TextField className={classes.textfield}  id="outlined-val" label="Value" size="small" type="number" InputLabelProps={{ shrink: true, }} variant="outlined"
                ref={refVal} onChange={ event => {setValue(event.target.value)} } disabled={ minValue || maxValue } /></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <TextField className={classes.textfield}  size="small" id="outlined-min" label="Minimum" type="number" InputLabelProps={{ shrink: true, }} variant="outlined"
                ref={refMin} onChange={event => { setMinValue(event.target.value)}} disabled={ value } />
        </Grid>
        <Grid item xs={4}>
          <TextField className={classes.textfield} id="outlined-max" size="small" label="Maximum" type="number" InputLabelProps={{ shrink: true, }} variant="outlined"
                ref={refMax} onChange={event => { setMaxValue(event.target.value)}} disabled={ value } />
        </Grid>
      </Grid>
      <br/>
      <Button variant="contained" color="primary" onClick={event => {setEditing(false)}} className={classes.button} > SUBMIT </Button>   
    </div>
  );
});


export const NumericValueEditor = forwardRef((props, ref) => {
  const classes                 = useStyles();
  const [value, setValue]       = useState(0);
  const [editing, setEditing]   = useState(true);
  const refVal                  = useRef(null);

//  useEffect(() => {
//     if (!editing) {
//       props.api.stopEditing();
//     }
//   }, [editing]);

useEffect(() => {
   setTimeout(() => refVal.current.focus())
}, []);


  useImperativeHandle(ref, () => {
      return {
          getValue() {
              if(!isEmpty(value))
                return value;
              // else if(!isEmpty(minValue) && !isEmpty(maxValue))
              //   return minValue + "-" + maxValue; 
          },
          // Gets called once before editing starts, to give editor a chance to       
          isPopup() {
            return true;
          },
          // Gets called once before editing starts, to give editor a chance to
          isCancelBeforeStart() {
            return false;
          },
          
          focusIn(){
            return true;
          }
          // Gets called once when editing is finished (eg if Enter is pressed).
          //   isCancelAfterEnd() {
          //     // our editor will reject any value greater than 1000
          //     if(isEmpty(minValue) && isEmpty(maxValue) && isEmpty(value))
          //       return true; 
          // }
      };
  });

  return (
    // <div className={classes.popValue} >
      <TextField className={classes.textfield} ref={refVal} id="outlined-val" size="small" type="number" 
            InputLabelProps={{ shrink: true, }} variant="outlined" onChange={ event => { setValue(event.target.value)} }  />
    //   <br/>
    //   <Button variant="contained" color="primary" onClick={event => {setEditing(false)}} className={classes.button} > SUBMIT </Button>   
    // </div>
  );
});


export default class NumericCellEditor extends Component {
    constructor(props) {
        super(props);

        this.cancelBeforeStart = this.props.charPress && ('1234567890'.indexOf(this.props.charPress) < 0);
        this.state = this.createInitialState(props);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    createInitialState(props) {
        let startValue;
        let highlightAllOnFocus = true;

        if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
            // if backspace or delete pressed, we clear the cell
            startValue = '';
        } else if (props.charPress) {
            // if a letter was pressed, we start with the letter
            startValue = props.charPress;
            highlightAllOnFocus = false;
        } else {
            // otherwise we start with the current value
            startValue = props.value;
            if (props.keyPress === KEY_F2) {
                highlightAllOnFocus = false;
            }
        }

        return {
            value: startValue,
            highlightAllOnFocus
        }
    }

    componentDidMount() {
        this.refs.input.addEventListener('keydown', this.onKeyDown);

    }

    componentWillUnmount() {
        this.refs.input.removeEventListener('keydown', this.onKeyDown);
    }

    afterGuiAttached() {
        // get ref from React component
        const eInput = this.refs.input;
        eInput.focus();
        if (this.state.highlightAllOnFocus) {
            eInput.select();

            this.setState({
                highlightAllOnFocus: false
            })
        } else {
            // when we started editing, we want the carot at the end, not the start.
            // this comes into play in two scenarios: a) when user hits F2 and b)
            // when user hits a printable character, then on IE (and only IE) the carot
            // was placed after the first character, thus 'apply' would end up as 'pplea'
            const length = eInput.value ? eInput.value.length : 0;
            if (length > 0) {
                eInput.setSelectionRange(length, length);
            }
        }
    }

    getValue() {
        return this.state.value;
    }

    isCancelBeforeStart() {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
        return this.state.value > 1000000;
    };

    onKeyDown(event) {
        if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
            event.stopPropagation();
            return;
        }

        if (!this.isKeyPressedNumeric(event)) {
            if (event.preventDefault) event.preventDefault();
        }
    }

    isLeftOrRight(event) {
        return [37, 39].indexOf(event.keyCode) > -1;
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    getCharCodeFromEvent(event) {
        event = event || window.event;
        return (typeof event.which === "undefined") ? event.keyCode : event.which;
    }

    isCharNumeric(charStr) {
        return !!/\d/.test(charStr);
    }
    isCharDecimal(charStr) {
        return !!/\./.test(charStr);
    }

    isKeyPressedNumeric(event) {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr) || this.isCharDecimal(charStr);
    }

    render() {
        return (
            <input ref="input"
                   value={this.state.value}
                   onChange={this.handleChange}
                   style={{width: "100%"}}
            />
        );
    }

    deleteOrBackspace(event) {
        return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
    }
}