import React , {useState, useEffect} from "react";
import { useHistory }  from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Divider, CardContent, CardActions, CardActionArea,  Typography, Button ,Grid, Box } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import LaunchIcon from '@material-ui/icons/Launch';
import utilities from 'assets/img/XfrmItems/utilities.png';
import DIYPortal from 'assets/img/XfrmItems/DIYPortal.png';
import SimuLoad from 'assets/img/XfrmItems/SimuLoad.png';
import uNamePlate from 'assets/img/XfrmItems/uNamePlate.png';

var ls = require('local-storage');

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  button:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
    border: "0px",
    color:"#FFFFFF",
  },
  button2: {
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3'
  },
  card: {
    borderRadius: "0px",
    display: 'block',
    height: '100%'
  },
  media: {
    display: 'block',
    // height:'140px'
  },
  cardContent :{
     width: 'fit-content',
    marginBottom: theme.spacing(0),
    paddingBottom : "0px"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    height: "100%",
    marginTop:'15vh',
    color: theme.palette.text.secondary,
  },
  scan:
  {
    marginTop:'5vh',
    // padding: theme.spacing(2),
    textAlign: 'left',
    height: "100%",
  },
  img: {
    marginTop:theme.spacing(-2),
    padding: theme.spacing(0),
    height: 150,
    maxWidth: 150,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },

  actions: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default function LoadingHub (props) {

    const classes  = useStyles(props.theme);
    const history = useHistory();
    const [state, setState] = useState("hidden");

    // const getURL = () =>
    // {
    //   if(ls.get('scanURL')!= null)
    //     return ls.get('scanURL')
    // }

    useEffect(() => 
    {
      props.setGuideId("Home");
      props.setDIYvalue(true);
      if(ls.get('scanURL')!= null)
      {
        setState("visible");
      }
      // console.log("REACT_APP_AWS_SECRET_ACCESS_KEY :" + process.env.REACT_APP_AWS_SECRET_ACCESS_KEY); 
      // console.log("REACT_APP_AWS_ACCESS_KEY_ID:" + process.env.REACT_APP_AWS_ACCESS_KEY_ID);
      // console.log("REACT_APP_AWS_REGION:" + process.env.REACT_APP_AWS_REGION);

      // console.log("REACT_APP_SIMULOAD_API_URL:" + process.env.REACT_APP_SIMULOAD_API_URL);
      // console.log("REACT_APP_WEATHER_API_KEY:" + process.env.REACT_APP_WEATHER_API_KEY);
    }, []);

    const handleWebLaunch = () => {
      let userState = props.userData;
      if(userState.userstatus ==="signIn")
      {
        history.push({pathname:"/admin/welcome", state:{}});  
      }
      else if (userState.userstatus ==="signOut"){
          history.push({pathname:"/admin/login", state:{ }});  
      }  
    };

    const handleQuote = () => {
      let userState = props.userData;
      // console.log("user Status is now =========>>>>>" + userState.userstatus);
      // if(userState.userstatus ==="signIn")
      // {     
        history.push({pathname:"/admin/mainform", state:{userState:userState}});  
      // }
      // else if (userState.userstatus ==="signOut"){
      //     history.push({pathname:"/admin/login", state:{ }});  
      // }    
    };

    const handleSimuLoad = () => {
      let userState = props.userData;
      
      if(userState.userstatus ==="signIn")
      {           
        // console.log("Inside Simu download=====>>>>");
        history.push({pathname:"/admin/simuload", state:{userState:userState}});  
      }
      else if (userState.userstatus ==="signOut"){
          history.push({pathname:"/admin/login", state:{ }});  
      }    
    };

    const handleDemo = () => {
      let userState = props.userData;  
        history.push({pathname:"/admin/contact-us" , state:{userState:userState}});  

    };

    const handleNamePlate = () =>
    {
      let userState = props.userData;
      if(userState.userstatus ==="signIn")
      {   
      history.push({pathname:"/admin/nameplate" , state:{userState:userState}});  
      }
      else if (userState.userstatus ==="signOut"){
          history.push({pathname:"/admin/login", state:{ }});  
      }  
    }
    const handleQuotation= () =>
    {
      history.push({pathname:"/admin/quote-us" , state:{ }});  
    }
    

    return ( 
      <div className={classes.root}>
        <Grid container >
          <Grid item  xs={4}>
            <div className ={classes.paper}>
              <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 10, width:'60%', borderColor : '#4486a3'}}/>
              <Typography variant="h3"> Loading<strong>HUB™</strong> </Typography> 
              <Typography variant="h6"> Your Transformer Loading/Rating Studies and Simulations Platform </Typography>
              <Box display="flex">
                <Box p={1}>
                <Button variant="outlined" onClick={handleDemo} className={classes.button} >
                  Request a Demo
                </Button>                
              </Box>
                <Box p={1} >
                  <Button variant="outlined" onClick={handleQuotation} className={classes.button2} >
                    Request a Quote
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={7} container spacing ={2} justify="center">
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardActionArea>
                      <CardMedia className={classes.media} image={utilities}  title="loading-order" component="img"/>
                      <CardContent className={classes.cardContent}>              
                        <Typography variant="h6" component="div"> Drop-In Heat-Run Files </Typography>
                        <Typography variant="caption" component="div">
                            Order your transformers loading studies
                            <ul>
                              <li>With your Heat-Run tests</li>
                              <li>With your own loading specifications </li>
                              <li>Multiple Thermal models </li>
                              <li>Printed loading study report. </li>
                            </ul>
                        </Typography>
                      </CardContent>
                      </CardActionArea>
                  <CardActions style={{justifyContent: 'center'}}>
                    <Button variant="outlined" onClick={handleQuote} className={classes.button2} startIcon = {<FormatQuoteIcon/>} endIcon={<FormatQuoteIcon />} >
                      Get Started
                    </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia className={classes.media} image={DIYPortal}  title="loading-order" component="img"/>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" component="div"> DIY Loading Studies Portal </Typography>
                        <Typography variant="caption" component="div">
                            Do It Yourself loading studies portal
                            <ul>
                              <li> Web portal with a step-by-step guide </li>
                              <li> Unlimited loading studies </li>
                              <li> No ownership cost, use it when needed </li>
                              <li> Bulk report for the studied sessions. </li>
                            </ul>
                        </Typography>
                      </CardContent>
                  </CardActionArea>
                  <CardActions  style={{justifyContent: 'center'}}>
                    <Button variant="outlined"  onClick={handleWebLaunch} className={classes.button2} startIcon = {<LaunchIcon/>}>
                      Launch
                    </Button>
                  </CardActions>
              </Card>
            </Grid> 
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia className={classes.media} image={SimuLoad} title="simuload-software" component="img"/>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h6" component="div"> SimuLOAD™ </Typography>
                    <Typography variant="caption" component="div">
                      Load Planning & Asset Management
                      <ul>
                        <li>Perform your loading studies at all time. </li>
                        <li>WHAT-IFs loading scenarios design</li>
                        <li>Multiple load profiles design options </li>
                        <li>Implement the <strong>IEEE C57.91/IEC60076</strong> loading guides. </li>
                        <li>Batch simulations</li>
                      </ul>
                    </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions style={{justifyContent: 'center'}}>
                    <Button variant="outlined"  onClick={handleSimuLoad} className={classes.button2} startIcon = {<CloudDownloadOutlinedIcon/>}>
                      Download
                    </Button>
                  </CardActions>
              </Card>       
            </Grid>          
            <Grid item xs={6}>
                <Card className={classes.card}>
                  <CardActionArea>
                    <CardMedia className={classes.media} image={uNamePlate} title="loading-order" component="img"/>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h6" component="div"> Ubiquitous Nameplate </Typography>
                      <Typography variant="caption" component="div">
                          Transformer nameplate every time everywhere
                          <ul>
                            <li>Registration with Heat-Runs and Nameplate</li>
                            <li> Thermal and Marginal load capabilities </li>
                            <li> Secured access everytime everywhere </li>
                            <li><strong>DIN VDE V0170-100</strong> compliant</li>
                            <li> Passive Load Monitoring at your finger-tip.  </li>
                          </ul>
                      </Typography>
                    </CardContent>
                    </CardActionArea>
                    <CardActions style={{justifyContent: 'center'}}>
                      <Button variant="outlined"  onClick={handleNamePlate} className={classes.button2} startIcon = {<CloudDownloadOutlinedIcon/>}>
                          Register
                      </Button>
                    </CardActions>
                </Card>
            </Grid>
          </Grid>
      </Grid>        
      </div>
    );
}


