import 'date-fns';
import React, {useState, useCallback, useEffect, Fragment} from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography, TextField, Button, ButtonGroup, Box, Divider, Tab, Tabs, Grid} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {AreaChart, LineChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer} from 'recharts';
import NumericCellEditor from "components/MainPanel/NumericCellEditor.jsx";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { initProfile, isEmpty} from 'components/MainPanel/Utilities.jsx';
import { Card, CardHeader, CardBody} from 'reactstrap';
import {DropzoneArea} from 'material-ui-dropzone'
import date from 'date-and-time';
import InfoIcon from '@material-ui/icons/Info';
import csv from 'csv';
var moment  = require('moment');


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: theme.spacing(-5),
  },
  card: {
    borderRadius: "0px",
    minHeight: '100%',
  },

  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  Comment: {
    padding: "5px",
    // borderLeftColor: "#6699cc",
    // borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6"
  },
  dropzoneArea: {
    height: "80px"
  },
  textField: {
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    backgroundColor:"#FFFFFF",
    width: "100%",
  },
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };


const LPFileDropzone  = (props) => {
  const classes = useStyles();   
  const [state, setState]         = useState({files:[]});

  const handleChange = (files) => { 
    setState(state => {
      return { ...state, files: files }
    });
  }

const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles);
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => { 
      let proTable = [];
      csv.parse(reader.result, (err, data) => {
        proTable = data.map(item => {
         return {time: item[0], sumamb:item[1], sumpul:item[2], sumcool:item[3] }
        })
        props.upDateProfile(proTable);
        proTable = [];
      })
    }
    reader.readAsText(acceptedFiles);
  }, []);

  const onDelete = () => { 
    setState(state => {
      return { ...state, files: [] }
    });
  }

  return (
    <DropzoneArea acceptedFiles={['.csv']} maxFileSize={5000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop a .csv file here or click' 
      onChange={handleChange} onDrop={onDrop} onDelete={onDelete} showFileNames="true" showAlerts={true} filesLimit={1} dropzoneClass={classes.dropzoneArea} />
  )  
} 


export default function UProfiles(props)  
{

  const classes                       = useStyles();
  const [profile, setProfile]         = useState(initProfile);
  const [profileName, setProfileName] = useState('');
  const [value, setValue]             = useState(0);
  const [gridPApi, setGridPApi]       = useState();
  const [gridLApi, setGridLApi]       = useState();
  const XfrmName                      = props.Xfrmer.XfrmName; 
  const historyProfile                = props.historyProfile;  
  let profileList                     = props.profileList || [];
  var profileDataPoints               = [];
  profile.map(item => {
    let tj = item.time.split(':');  let uj = (parseInt(tj[0] * 60)  + parseInt(tj[1]));
    if(uj % 60 === 0)
    { profileDataPoints.push({ x: date.parse(item.time, 'HH:mm').getTime(),y: parseFloat(item.sumamb), z: parseFloat(item.sumpul), v: parseFloat(item.sumcool)/1000}); }
  });


  useEffect(() => 
  {
    if(isEmpty(profileList))
    {
      if(gridLApi !== undefined)
      {
        var res = gridLApi.api.setRowData([]);
      }
    }
  }, [isEmpty(profileList)]);

  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  const columns = [
    { headerName: "Time", field: "time", editable: false, width:100},
    { headerName: "Ambient[C]", field: "sumamb", editable: true,
      cellEditor:'numericCellEditor' 
    },
    { headerName: "Load [p.u]", field: "sumpul", editable: true, width:100,
      cellEditor:'numericCellEditor', 
    },
    { headerName: "Cooling [kW]", field: "sumcool",  editable: true, 
      cellEditor:'numericCellEditor'
    },
  ];
  
  const pfColumns = [
    { headerName: "Load profile name", field: "profileName", checkboxSelection: true },
    { headerName: "Number of 24h cycles", field: "proCycle"},
  ];

  const upDateProfile = (newProfile)=> {
    setProfile(newProfile);
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProNameChange = (event) => {
    setProfileName(event.target.value);
  }

  const onCellValueChanged = params => {
    var colId = params.column.getId();
    if (colId === "sumamb") {
        params.node.setDataValue(colId, params.data.sumamb);
        return;
    }
    if (colId === "sumpul") {
        params.node.setDataValue(colId, params.data.sumpul);
        return;
    }
    if (colId === "sumcool") {
        params.node.setDataValue(colId, params.data.sumcool);
        return;
    }   
  }

  const onGridPReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridPApi({ api, columnApi });
      params.api.sizeColumnsToFit();
    },[]
  );

  const onGridListReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridLApi({ api, columnApi });
    params.api.sizeColumnsToFit();
    },[]
  );

  const AddProfile = ()=>
  {           
    let proCycle = 1, lProfile = [];
    let Harmonics = [
      { harmId: 1, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 3, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 5, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 7, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 9, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 11, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 13, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 15, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 17, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 19, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 21, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 23, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
      { harmId: 25, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    ];

    if(profileName === 'undefined' || profileName === '')
    {
      alert('Please provide a load profile name !!!');
      return;
    }

    if(profileList.find(item => item.profileName === profileName)!=null)
    {
      alert('This profile already exists !!!');
      return;
    }

    if(isEmpty(profile))
    {
      alert('Please provide a load profile !!!');
      return;
    }

    profileList.push({ profileName: profileName, proCycle: proCycle });
    var res = gridLApi.api.updateRowData({ add: [{profileName:profileName, proCycle:proCycle}] });     
    profile.map(item  => { 
      return lProfile.push({ xfrmId: XfrmName, profileName: profileName, time: item.time, sumamb: item.sumamb, sumpul: item.sumpul, sumcool:item.sumcool });
    });

    // Adding Distributions
    let newDistro = [{ profileName: profileName, Ambient: JSON.stringify({ ambMean:10, ambStd: 2 }), ExpLoad: JSON.stringify({ puMean: 0.1, puStd:0.0001 }), 
    Harmonics: JSON.stringify(Harmonics), PlugEV: JSON.stringify({ pevVar : '', pevRate: 2.0, pevLoad: 0.001, pevSDate : new Date(), pevEDate : new Date() }) }];
    // console.log("----------------------Profile ----------------" + profileName);
    // console.log("Load profiles " + JSON.stringify(lProfile));
    // Creating Profiles and Related Distributions
    props.createProfile(profileList, lProfile, newDistro);
    setProfileName('');
  }



  const RemoveProfile = () =>
  {
    var selectedData = gridLApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    let profileToRemove= selectedData[0].profileName;
    var res = gridLApi.api.updateRowData({ remove: selectedData });
    props.RemoveProfile(profileToRemove);
  }

  const ShowProfile = () =>
  {
    var selectedData = gridLApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    if(historyProfile.find(p => p.profileName === selectedData[0].profileName))
    {
      let profileToShow = historyProfile.filter(p => p.profileName === selectedData[0].profileName);
      setProfile(profileToShow); 
      setProfileName(selectedData[0].profileName);
    }
  }

  useEffect(() =>
  {
    if(!isEmpty(profile))
    {
      profileDataPoints = [];
      profile.map(item => {
        let tj = item.time.split(':');  let uj = (parseInt(tj[0] * 60)  + parseInt(tj[1]));
        if(uj % 60 === 0)
        { 
          profileDataPoints.push({ x: date.parse(item.time, 'HH:mm').getTime(),y: parseFloat(item.sumamb), z: parseFloat(item.sumpul), v: parseFloat(item.sumcool)/1000}) ; 
        }
      });
    } 
    // console.log("Length of the profile data :" + profileDataPoints.length);
  }, [profile]);

  return ( 
      <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardheader}>  <Typography variant="subtitle2" display="inline"> LOAD PROFILE UPLOAD AND EDITION </Typography> </CardHeader>
              <CardBody className={classes.CardBody}>
                <div className ={classes.Comment}>
                  <TextField id="standard-name" label="Enter the load profile name" size="small" className={classes.textField} value={profileName} onChange={handleProNameChange} />
                  <AntTabs value={value} onChange={handleTabChange} aria-label="ant example">
                    <AntTab label="Upload Loading File" />
                    <AntTab label="Detailed Edition" />
                  </AntTabs>
                  <TabPanel value={value} index={0}>
                    <div className={classes.Comment}>
                      <Typography variant="caption">
                        {<InfoIcon/>} <strong>Upload a csv file format: Datetime, Load[p.u], Ambient[℃] </strong>
                      </Typography>
                        <Divider/>
                        <Typography variant="caption" gutterBottom>
                          <ul>
                            <li>Load:(Optional) per unit (p.u) 24-h load cycle. </li>
                            <li>Ambient:(Mandatory) 24-h ambient cycle. Ambient temperature available from weather forecast.</li>
                          </ul>
                        </Typography> 
                    </div>
                    <br/>
                    <div className ={classes.dropzone}>
                      <LPFileDropzone upDateProfile={upDateProfile} />
                    </div> 
                    <br/>
                    <div className ={classes.controlBar}>
                        <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                          <Button size="small" startIcon={<ArrowDownwardIcon />} aria-label="arrowdown" onClick={AddProfile}> Add </Button>
                          <Button size="small" startIcon={<ArrowUpwardIcon />} aria-label="arrowup" onClick={ShowProfile}> Show </Button>
                          <Button size="small" startIcon={<DeleteForeverIcon />} aria-label="deleteforever" onClick={RemoveProfile}>Discard</Button> 
                        </ButtonGroup> 
                    </div>
                    <div  className="ag-theme-balham" style={{ width: "100%", height:"200px"}} >
                      <AgGridReact
                          modules={AllCommunityModules}
                          columnDefs={pfColumns}
                          rowData= {profileList}
                          onGridReady={onGridListReady}
                          animateRows={true}
                          rowSelection='Single'
                        />
                    </div>         
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <div  className="ag-theme-balham" style={{ width: "100%", height:"600px"}} >
                        <AgGridReact
                          modules = {AllCommunityModules}
                          columnDefs = {columns}
                          rowData = {profile}
                          onGridReady = {onGridPReady}
                          animateRows = {true}
                          onCellValueChanged = {onCellValueChanged}
                          frameworkComponents ={frameworkComponents}
                          />
                      </div>
                  </TabPanel>
                </div>
              </CardBody>
            </Card> 
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardheader}> <Typography variant="subtitle2" display="inline"> LOAD PROFILE PREVIEW </Typography> </CardHeader>
                <CardBody className={classes.CardBody}>
                  <div id ="load-profile" className ={classes.Comment}>
                    <ResponsiveContainer width='100%' aspect={3.0/2.0}>
                      <LineChart data={profileDataPoints} syncId="anyId" margin={{top: 5, right: 5, left: 3, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="x" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm')} tick={{fontSize: 11}} domain={['0:00', '24:00']}/>
                        <YAxis yAxisId="left" label={{ value: 'Ambient [℃]', fontSize:12, angle: -90, position: 'insideCenter' }} tick={{fontSize: 11}} />
                        <YAxis yAxisId="right" orientation="right" label={{ value: 'Load [p.u]', fontSize:12, angle: -90, position: 'insideRight' }} tick={{fontSize: 11}} />
                        <Tooltip/>
                        <Line yAxisId="left" type='step' dataKey='y' stroke='#8884d8' strokeWidth='2' />
                        <Line yAxisId="right" type='step' dataKey='z' stroke='red' strokeWidth='2'/>
                      </LineChart> 
                    </ResponsiveContainer> 
                  </div>
                </CardBody>
            </Card> 
          </Grid>
      </Grid>
  );
}