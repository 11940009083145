import 'date-fns';
import React, {useState, useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { ButtonGroup, Typography, Button, Snackbar, Divider, Tabs, Tab, Box, Grid } from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Summary from "components/MainPanel/Summary.jsx";
import {getTimeFromMins, isEmpty, onlyUnique} from './Utilities.jsx';
import { SpinnerDialog } from './NumericCellEditor';
import {AreaChart, LineChart, Line, Area, XAxis, YAxis, BarChart, Bar, ReferenceLine, Brush,CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import PropTypes from 'prop-types';
import domtoimage from 'dom-to-image';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import { PushSpinner } from "react-spinners-kit";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // minHeight: '100%',
    marginTop: theme.spacing(-4.5),
  },

  cardheader:{
    padding: "1px",
    // paddingLeft: "5px",
    borderBottom: "0px",
    margin: "0px",
  },
  card:{
    borderRadius: "0px",
    // minHeight: '100%',
    backgroundColor: '#eceff1',
  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6"
  }
}));


const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, tabvalue, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={tabvalue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabvalue: PropTypes.any.isRequired,
};

const GrayVec = ['#F0F0F0', '#E8E8E8', '#E0E0E0', '#DCDCDC', '#D8D8D8', '#D3D3D3', '#D0D0D0',
'#C8C8C8', '#C0C0C0', '#BEBEBE', '#B8B8B8', '#B0B0B0', '#A9A9A9', '#A8A8A8', '#A0A0A0', '#989898',
 '#909090', '#888888', '#808080', '#787878', '#707070', '#696969', '#686868', '#606060','#585858',
 '#505050', '#484848', '#404040', '#383838', '#303030', '#282828', '#202020', '#181818', '#101010'
]

const BlackVec = ['#999', '#7f7f7f', '#666', '#4c4c4c', '#333']

const LeftThermalChart = (props) => { 
  const classes = useStyles();
  var   ambientSet    =   [], durationSet = [], coolingSet =[];
  var  ieeeG7Iec      =   props.NamePlate;  
  var  ieeeGTh7Iec    =   props.ThermalResults;
  var  condVar        =   props.condVar;

  let ieeeGThermal = [], ieeeData =[], ieeeGTSlot =[]; 
  let minMargin = 0, maxMargin = 0;
  

  if(ieeeG7Iec.length > 0)
  {    
    durationSet = ieeeG7Iec[0].ieeeData.map(p => p.Overtime).filter(onlyUnique)
    ambientSet = ieeeG7Iec[0].ieeeData.map(p => p.Ambient).filter(onlyUnique)
    coolingSet = ieeeG7Iec[0].ieeeData.map(p => p.Cooling).filter(onlyUnique)

    ieeeGThermal = ieeeGTh7Iec[0].ieeeData.filter(p => p.Overtime === parseFloat(condVar.selTimeVar))
                                          .filter(q => q.mvaCooling === parseFloat(condVar.selCoolingVar[0]))
                                          .filter(r => r.mvaAmbient === parseFloat(condVar.selAmbientVar));
    
    ieeeGTSlot = ieeeGTh7Iec[0].ieeeData.filter(q => q.mvaCooling === parseFloat(condVar.selCoolingVar[0]))
                                          .filter(r => r.mvaAmbient === parseFloat(condVar.selAmbientVar));
  }

  
  const handleSaveClick = () => {
    var ImageURLs = [];
    
    async function getBlobls() {
      props.openSpinner();
      //======= vs Ambient =========
      await domtoimage.toBlob(document.getElementById('load-vs-ambient')).then(function (blob) { //
        ImageURLs.push({Tag:"load-vs-ambient", URL:URL.createObjectURL( blob )});
      });

      await domtoimage.toBlob(document.getElementById('hotspot-vs-ambient')).then(function (blob) { //
        ImageURLs.push({Tag:"hotspot-vs-ambient", URL:URL.createObjectURL( blob )});
      });

      await domtoimage.toBlob(document.getElementById('loss-vs-ambient')).then(function (blob) { //
        ImageURLs.push({Tag:"loss-vs-ambient", URL:URL.createObjectURL( blob )});
      });

      // ====== vs Timeline =========
      await domtoimage.toBlob(document.getElementById('temperature-vs-time')).then(function (blob) { //
        ImageURLs.push({Tag:"temperature-vs-time", URL:URL.createObjectURL( blob )});
      });

      await domtoimage.toBlob(document.getElementById('margin-vs-time')).then(function (blob) { //
        ImageURLs.push({Tag:"margin-vs-time", URL:URL.createObjectURL( blob )});
      });

      await domtoimage.toBlob(document.getElementById('loss-vs-time')).then(function (blob) {
        ImageURLs.push({Tag:"loss-vs-time", URL:URL.createObjectURL( blob )});
      });

      props.SaveImages(ImageURLs);
      props.closeSpinner();
    }
    getBlobls();

  }

  return (
    <Grid container spacing ={2} justify="center">
          <Grid item xs={12}>
              <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                <Button size="small" > Loading Curves vs. Ambient Temperature Shift </Button>
                <Divider orientation="vertical" flexItem />
                <Button size="small" startIcon={<AddBoxIcon />} onClick={handleSaveClick}  > Add to Report </Button>
              </ButtonGroup> 
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader><Typography variant="subtitle2"><strong>Load margin vs. Average Ambient Temperature Shift</strong></Typography> </CardHeader>
                <CardBody> 
                  <div id ="load-vs-ambient"> 
                      <ResponsiveContainer width='100%' aspect={3.0/1.5} >
                        <LineChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }} >
                            <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="Ambient" type="number" margin={{ bottom: 20 }} label={{ value: 'Ambient Temperature shift[℃]', 
                                    fontSize:12, position: 'insideCenter'}} tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                                <YAxis label={{ value: 'Load Margins [MVA]', fontSize:12, angle: -90,  position: 'insideCenter'}} tick={{fontSize: 11}} domain={[0, 450]}/>
                                <Tooltip/>
                                <Legend layout="horizontal" verticalAlign="top" align="right" style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                                {                            
                                  coolingSet.map((u, ind) => 
                                  {
                                    return (
                                      ieeeG7Iec.map((cid,index) => {
                                        return (
                                          <Line dataKey="MVA" data={cid.ieeeData.filter(p => p.Cooling === u)
                                          .filter(p => p.Overtime === parseFloat(condVar.selTimeVar))} name={cid.name + " ψ = " + u + "%"}  
                                                  key={cid.name + " ψ = " + u }  stroke={BlackVec[ind]} strokeWidth={2}/>)
                                                  
                                      })
                                    )
                                  })
                                }
                        </LineChart> 
                      </ResponsiveContainer>          
                  </div>                  
                </CardBody>
            </Card>
          </Grid>                  
          <Grid item xs={6}>
            <Card className={classes.card}>
                <CardHeader><Typography variant="subtitle2"><strong>24-h Temperatures and Load Timeline</strong></Typography> </CardHeader>
                <CardBody> 
                  <div id ="temperature-vs-time">
                      <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                          <LineChart data={ieeeGThermal} syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="time" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => getTimeFromMins(unixTime)} tick={{fontSize: 11}} domain={['0:00', '24:00']} padding={{left: 0, right: 0}}/>
                            <YAxis yAxisId="left" label={{ value: 'Temperatures[℃]', fontSize:12, angle: -90, position: 'insideCenter', padding:10 }} tick={{fontSize: 11}} domain={[0, 200]}/>
                            <YAxis yAxisId="right" orientation="right" label={{ value: 'Load[MVA]', fontSize:12, angle: -90, position: 'insideCenter' , padding:10 }}  tick={{fontSize: 11}} />
                            <Tooltip/>              
                            <Legend layout="horizontal" verticalAlign="top" align="right"   style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                            <Line type='number' yAxisId="left" dataKey='Ambient' data={ieeeGThermal} name='Ambient' stroke='#FFC300' strokeWidth={2}/> 
                            <Line type='number' yAxisId="left" dataKey='BottomOil' data={ieeeGThermal} name="BottomOil" stroke='#58D68D' strokeWidth={2}/>)
                            <Line type='number' yAxisId="left" dataKey='TopOil' data={ieeeGThermal} name="TopOil" stroke='#9B59B6' strokeWidth={2}/>)
                            <Line type='number' yAxisId="left" dataKey='HotSpot' data={ieeeGThermal} name="HotSpot"  stroke='#A93226' strokeWidth={2}/>
                            <Line type='step'   yAxisId="right" dataKey='basicMVA'  data={ieeeGThermal} name="basic Load" stroke='#5F6A6A' strokeWidth={2} strokeDasharray="3 3"/>
                            <Line type='step'   yAxisId="right" dataKey="MVA"  data={ieeeGThermal} name="Optimum Load" stroke='#17202A' strokeWidth={2} strokeDasharray="5 5"/>)
                          </LineChart> 
                      </ResponsiveContainer>
                  </div>
                </CardBody>
            </Card> 
          </Grid>                 
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader><Typography variant="subtitle2"><strong>Temperatures vs. Average Ambient Temperature Shift</strong></Typography></CardHeader>
              <CardBody> 
                <div id ="hotspot-vs-ambient">       
                    <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                        <LineChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }} >
                                  <CartesianGrid strokeDasharray="3 3"/>
                                  <XAxis dataKey="Ambient" type="number" label={{ value: 'Ambient Temperature shift[℃]', fontSize:12, position: 'insideCenter', marginTop:100 }} 
                                      tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                                  <YAxis label={{ value: 'Hot Spot Temperature[℃]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}} domain={[0, 200]}/>
                                  <Tooltip/>
                                  <Legend layout="horizontal" verticalAlign="top" align="center" style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                                  {                                                                   
                                    coolingSet.map((u, ind) => 
                                    {
                                      return (
                                        ieeeG7Iec.map((cid,index) => {
                                          return (
                                            <Line dataKey="HotSpot" data={cid.ieeeData.filter(p => p.Cooling === u)
                                                                                      .filter(p => p.Overtime === parseFloat(condVar.selTimeVar))} 
                                                name={cid.name + " ψ  = " + u + "%"}  key={cid.name + " ψ = " + u} stroke={BlackVec[ind]} strokeWidth={2}/>)
                                        })
                                      )
                                    })
                                  }
                        </LineChart> 
                    </ResponsiveContainer>
                </div>
              </CardBody>
            </Card>
          </Grid> 
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader><Typography variant="subtitle2"><strong> 24-h Load Margin Timeline </strong></Typography> </CardHeader>
              <CardBody> 
                <div id ="margin-vs-time">
                  <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                    <BarChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }} data={ieeeGTSlot}>
                      <CartesianGrid strokeDasharray="3 3"/>
                      <XAxis dataKey="time" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => getTimeFromMins(unixTime)} tick={{fontSize: 11}} domain={['0:00', '24:00']} padding={{left: 0, right: 0}}/>
                      <YAxis  label={{ value: 'Load Margin[℃]', fontSize:12, angle: -90, position: 'insideCenter' }} tick={{fontSize: 11}} domain={[minMargin, maxMargin]}/>  
                      <Tooltip/>
                      {/* <Legend layout="vetical" verticalAlign="top" align="right" style={{fontSize: '1rem', fontFamily: 'Times New Roman'}} /> */}
                       {
                          durationSet.map((u, ind) => 
                          {
                            return (
                              <Bar type="step" dataKey="Margin" data={ieeeGTSlot.filter(p => p.Overtime === u)} name={"Load Margin =" + u}  
                                      key={"Load Margin =" + u } fill={GrayVec[ind]} barSize={20}/>
                            )
                          })                        
                       }
                    </BarChart>
                  </ResponsiveContainer>
                </div>        
              </CardBody>
            </Card> 
          </Grid>
          <Grid item xs={6}>
            <Card className={classes.card}>
                <CardHeader><Typography variant="subtitle2"><strong>Temperatures vs. Average Ambient Temperature Shift</strong></Typography></CardHeader>
                <CardBody> 
                  <div id ="loss-vs-ambient">
                    <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                        <LineChart syncId="anyId" data={ieeeG7Iec} margin={{top: 5, right: 30, left: 20, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }}>
                          <CartesianGrid strokeDasharray="3 3"/>
                          <XAxis dataKey="Ambient" type="number" label={{ value: 'Ambient Temperature shift[℃]', fontSize:12, position: 'insideCenter' }} 
                                tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                          <YAxis type="number" label={{ value: 'LOSSofLIFE [%]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}}/>
                          <Tooltip/>
                          <Legend layout="horizontal" verticalAlign="top" align="center" style={{fontSize:7, fontFamily: 'Times New Roman'}} />
                          <ReferenceLine y={0} stroke='#000'/>
                            {
                              coolingSet.map((u, ind) => 
                              {
                                return (
                                  ieeeG7Iec.map((cid,index) => {
                                    return (
                                            <Line dataKey="LOSSofLife" data={cid.ieeeData.filter(p => p.Cooling === u)
                                            .filter(p => p.Overtime === parseFloat(condVar.selTimeVar))} 
                                            name={cid.name + " ψ  = " + u + "%"}  key={cid.name + " ψ = " + u}  stroke={BlackVec[ind]} strokeWidth={1}  />)
                                    })
                                )
                              })
                            }
                        </LineChart> 
                    </ResponsiveContainer>
                  </div>
                </CardBody>
              </Card>
          </Grid>             
          <Grid item xs={6}>
            <Card className={classes.card}>
                <CardHeader ><Typography variant="subtitle2"><strong> 24-h LOSSofLIFE Timeline</strong></Typography> </CardHeader>
                <CardBody > 
                  <div id ="loss-vs-time">  
                    <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                        <LineChart data={ieeeGTSlot} syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="time" padding={{ left: 10 }} type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => getTimeFromMins(unixTime)} tick={{fontSize: 11}} domain={['0:00', '24:00']} 
                                    padding={{left: 0, right: 0}}/>
                                <YAxis label={{ value: 'LOSSofLIFE [%]', fontSize:12, angle: -90, position: 'outsideCenter' }} tick={{fontSize: 11}}/>
                                <Tooltip/>
                                <Legend layout="horizontal" verticalAlign="top" align="center"   style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                                {
                                    durationSet.map((u, ind) => 
                                    {
                                      return (
                                        <Line dataKey='LOSSofLife' type='monotone' data={ieeeGTSlot.filter(p => p.Overtime === u)} name={"𝛿 =" + u + "min"} 
                                          key={"LoL =" + u } stroke={GrayVec[ind]} strokeWidth={1} />)

                                    })
                                }
                        </LineChart>
                    </ResponsiveContainer>
                  </div> 
                </CardBody>
            </Card> 
          </Grid>
    </Grid>
  );
}

const LoadVsTSlotChart = (props) => { 
  const classes = useStyles();
  var ambientSet = [], durationSet = [];
  var ieeeG7Iec = props.NamePlate;
  var condVar   = props.condVar;

  // O being the selected configuration
  if(ieeeG7Iec.length > 0)
  { 
    // ieeeG7Iec.map(p =>
    //   {
    //     durationSet[p.name] = ieeeG7Iec.filter(q => q.name === p.name).ieeeData.map(p => p.Overtime).filter(onlyUnique)
    //   });   

    ieeeG7Iec = ieeeG7Iec[0].ieeeData.filter(p => p.Cooling === parseFloat(condVar.selCoolingVar[0]));
    durationSet = ieeeG7Iec.map(p => p.Overtime).filter(onlyUnique)
    ambientSet = ieeeG7Iec.map(p => p.Ambient).filter(onlyUnique)

  }

  const handleSaveClick = () => {
    const ImageURLs = [];
    async function getBlobls() {
      props.openSpinner();
      await domtoimage.toBlob(document.getElementById('load-vs-duration')).then(function (blob) {
        ImageURLs.push({Tag:"load-vs-duration", URL:URL.createObjectURL( blob )});
      });
      await domtoimage.toBlob(document.getElementById('loss-vs-duration')).then(function (blob) {
        ImageURLs.push({Tag:"loss-vs-duration", URL:URL.createObjectURL( blob )});
      });
      await domtoimage.toBlob(document.getElementById('hotspot-vs-duration')).then(function (blob) {
        ImageURLs.push({Tag:"hotspot-vs-duration", URL:URL.createObjectURL( blob )});
      });
      props.SaveImages(ImageURLs);
      props.closeSpinner();
      
    }

    getBlobls();

  }
  // value: 'Overloading Time [min]', 
  return (
    <Grid container spacing ={2} justify="center">
      <Grid item xs={12}>
          <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
            <Button size="small" > Load and Loss-of-Life vs. Overload duration  </Button>
            <Divider orientation="vertical" flexItem />
            <Button size="small" startIcon={<AddBoxIcon />} onClick={handleSaveClick} > Add to Report </Button>
          </ButtonGroup> 
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
            <CardHeader className={classes.cardheader}><Typography variant="subtitle2"><strong>Load curves vs. Overloading Time at ΔΘᴀ </strong></Typography> </CardHeader>
            <CardBody className={classes.CardBody}> 
              <div id ="load-vs-duration">
                  <ResponsiveContainer width='100%' aspect={3.0/2}>
                       <LineChart margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }}>
                          <CartesianGrid strokeDasharray="3 3"/>
                          <XAxis dataKey="Overtime" type="number" scale='time' label={{ fontSize:12, position: 'insideCenter' }} 
                                tickFormatter = {(overtime) => getTimeFromMins(overtime)} tick={{fontSize: 11}} domain={[Math.min(...durationSet), Math.max(...durationSet)]}/>
                          <YAxis label={{ value: 'LOAD[MVA]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}}/>
                          <Tooltip/>
                          <Legend layout="horizontal" verticalAlign="top" align="center"   style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                            {
                              ambientSet.map((u, ind) => 
                              {
                                // return (
                                //   ieeeG7Iec.map((cid,index) => {
                                    return (
                                      <Line dataKey="MVA" data={ieeeG7Iec.filter(p => p.Ambient === u)} name={ "ΔΘᴀ =" + u + "K" } 
                                            key={" ΔΘᴀ = " + u } stroke="#000000" label={ "ΔΘᴀ =" + u}  strokeWidth={1} />)
                                  // })
                                // )
                              })
                            }
                        </LineChart> 
                  </ResponsiveContainer>
              </div> 
            </CardBody>
        </Card>  
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
            <CardHeader className={classes.cardheader}><Typography variant="subtitle2"><strong>Loss-of-life vs.Overloading Time at ΔΘᴀ </strong></Typography> </CardHeader>
            <CardBody className={classes.CardBody}> 
              <div id ="loss-vs-duration">  
                <ResponsiveContainer width='100%' aspect={3.0/2}>
                  <LineChart margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="Overtime" type="number" scale='time' label={{ fontSize:12,  position: 'insideCenter' }} 
                        tickFormatter = {(overtime) => getTimeFromMins(overtime)} tick={{fontSize: 11}} domain={[Math.min(...durationSet), Math.max(...durationSet)]}/>
                    <YAxis label={{ value: 'LOSSofLIFE [%]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}}/>
                    <Tooltip/>
                    <Legend layout="horizontal" verticalAlign="top" align="rigth"   style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                      {
                        ambientSet.map((u, ind) => 
                        {
                          return (
                            // ieeeG7Iec.map((cid,index) => {
                            //   return (
                                <Line dataKey="LOSSofLife" data={ieeeG7Iec.filter(p => p.Ambient === u)} name= { " ΔΘᴀ =" + u + "K"} 
                                      key={" ΔΘᴀ = " + u } stroke="#808080" strokeWidth={1}/>)
                          //     })
                          // )
                        })
                      }
                    </LineChart> 
                </ResponsiveContainer>
              </div> 
            </CardBody>
        </Card> 
      </Grid>
      <Grid item xs={6}>
        <Card className={classes.card}>
            <CardHeader className={classes.cardheader}><Typography variant="subtitle2"><strong>Hot Spot curves vs. Overloading Time at ΔΘᴀ </strong></Typography> </CardHeader>
            <CardBody className={classes.CardBody}> 
              <div id ="hotspot-vs-duration">  
                <ResponsiveContainer width='100%' aspect={3.0/2}>
                  <LineChart margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="Overtime" type="number" scale='time' label={{ fontSize:12,  position: 'insideCenter' }} 
                           tickFormatter = {(overtime) => getTimeFromMins(overtime)} tick={{fontSize: 11}} domain={[Math.min(...durationSet), Math.max(...durationSet)]}/>
                    <YAxis label={{ value: 'Hot Spot Temperature [℃]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}} 
                           domain={[0, 200]}/>
                    <Tooltip/>
                    <Legend layout="horizontal" verticalAlign="top" align="center"   style={{fontSize: 7, fontFamily: 'Times New Roman'}} />
                      {
                        ambientSet.map((u, ind) => 
                        {
                          // return (
                          //   ieeeG7Iec.map((cid,index) => {
                              return (
                                <Line dataKey="HotSpot" data={ieeeG7Iec.filter(p => p.Ambient === u)} name= { "ΔΘᴀ =" + u + "K"} 
                                      key={ " ΔΘᴀ = " + u } stroke="#FF0000" strokeWidth={1}/>)
                          //     })
                          // )
                        })
                      }
                    </LineChart> 
                </ResponsiveContainer>
              </div> 
            </CardBody>
        </Card> 
      </Grid>
      <Grid item xs={6}> </Grid>
    </Grid>
  );
}


export default function LoadingCurves(props) {
const classes = useStyles();
const [tabvalue, setTabValue]       = useState(0);
const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error'});
const [stateSpin, setSpinner]       = useState(false);

const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
}

const SaveImages = (ImagURLs) =>
{
  if(!isEmpty(ImagURLs)) 
  {   
    props.SaveImages(ImagURLs); 
    setOpenSnack({open: true, Message: "Saved in your loading report."});
    return;
  } 
}

const handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    setOpenSnack({...openSnack, open:false});
    return;
  }
  setOpenSnack({...openSnack, open:false});
};


return ( 
  <div className ={classes.root}>
   <SpinnerDialog show = {stateSpin} handleClose = { () => { setSpinner(false)} } Message ="Images saving in progress ..."/>    
    <Snackbar open={openSnack.open} autoHideDuration={6000} onClose={handleSnackClose}>
       <Alert onClose={handleSnackClose} severity="info">
          {openSnack.Message}!!!
       </Alert>
    </Snackbar>
    <AntTabs value={tabvalue} onChange={handleTabChange} aria-label="ant example">
      <AntTab label="Thermal Forecast" />
      <AntTab label="Load vs. Duration" />
      <AntTab label="Margins Forecast" />
    </AntTabs>
    <TabPanel tabvalue={tabvalue} index={0}>
        <LeftThermalChart Xfrmer= {props.Xfrmer} NamePlate= {props.NamePlate} ThermalResults={props.ThermalResults} 
                          condVar={props.condVar} SaveImages = { SaveImages }  
                          openSpinner = {() => { setSpinner(true) }} closeSpinner = { () => { setSpinner(false) }}/>
    </TabPanel>
    <TabPanel tabvalue={tabvalue} index={1}> 
        <LoadVsTSlotChart Xfrmer= {props.Xfrmer} NamePlate= {props.NamePlate} ThermalResults={props.ThermalResults} 
                          condVar={props.condVar} SaveImages = { SaveImages }
                          openSpinner = {() => { setSpinner(true) }} closeSpinner = { () => { setSpinner(false) }}/>   
    </TabPanel>
    <TabPanel tabvalue={tabvalue} index={2}> 
        <Summary Xfrmer= {props.Xfrmer} NamePlate= {props.NamePlate} condVar={props.condVar}/>   
    </TabPanel>
  </div>
  );
}