import React , {useState, useEffect} from "react";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SaveIcon from '@material-ui/icons/Save';
import StopIcon from '@material-ui/icons/Stop';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Slide from '@material-ui/core/Slide';
import LandingBoard from "./LandingPage";
import SerratedTabs from './SerratedTabs.jsx';
import LoadingReport from "../LoadingSummary/LoadingReport.jsx";
import { ThemeProvider, makeStyles, useTheme   } from "@material-ui/styles";
import DataSheetTable from "components/MainPanel/Datasheet.jsx";
import Scenario from "components/MainPanel/Scenarii.jsx"
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ProfileDesign from "components/MainPanel/ProfileDesign.jsx"
import LoadingCurves from "components/MainPanel/LoadingCurves.jsx"
import { ApiURL, initProfile, isEmpty, onlyUnique } from 'components/MainPanel/Utilities.jsx';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { ResponsiveContainer} from 'recharts';
import MenuIcon from '@material-ui/icons/Menu';
import { Beforeunload } from 'react-beforeunload';
import { PushSpinner } from "react-spinners-kit";
import { PDFViewer } from '@react-pdf/renderer';
import { ListItemSecondaryAction, Checkbox, Snackbar, Button, ListItem, List, Dialog, ListItemText, FormControl,
  FormHelperText, createMuiTheme, ButtonGroup, Drawer, NativeSelect, Box} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const baseTheme = createMuiTheme();
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-4.3),
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
    minHeight: '100vh',
    backgroundColor: '#eceff1',
  },
  maincard:{
    borderRadius: "0px",
    minHeight: '100vh',
    minWidth:'inherit',
    backgroundColor: '#eceff1',
  },
  CardBody:{
    padding: "1px",
    paddingLeft: "5px",
  },

  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  pusheader :
  {
    marginLeft:'5px',
    marginRight:'5px',
    marginTop:'10px',
    padding: "1px",
    marginTop: "5px",
    marginBottom: "10px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    marginLeft: "5px",
    marginRight: "5px",
    borderBottomColor: "#d3d3d3",
    backgroundColor : "#f6f6f6",

  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6",
    width: '100%',
  },
  pdfreport: {
    padding: "5px",
    marginTop: "50px",
    backgroundColor : "#f6f6f6",
  },

  select:{
    width: "200px",
  },
  native:{
    marginTop:"10px",
    marginBottom: "10px"
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'Black',
    borderRadius:"0px",
  },

  submitButton:
  {
    marginTop:"20px"
  },
  spinner:{
    minWidth:'350px',
    minHeight:'100px',
    borderRadius: '0px',
    padding:"40px",
    paddingTop: "10px"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SpinnerDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog onClose={props.handleClose} aria-labelledby="spinner-dialog-title" open={props.show} maxWidth="sm">
      <div className={classes.pusheader}>
        <Typography variant="subtitle2" component="div" gutterBottom> Simulation in progress ... </Typography>
      </div>
      <div className={classes.spinner}>
        <PushSpinner size={60} color="#686769" loading={props.show} />
      </div>
      <Button size="small"  onClick={props.handleClose} >Cancel</Button>
    </Dialog>
  );
}

// const XfrmHeader = (props)=> {
//   let Cooling  = props.Stage1 + '/' + props.Stage2+ '/' + props.Stage3;
//   let Rating = props.Rating1 + '/' + props.Rating2 + '/' + props.Rating3;
//   return(
//     <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
//       <Button size="small" > {props.XfrmName} </Button>
//       <Button size="small" > {props.XType} </Button>
//       <Button size="small" > {props.VClass}&nbsp;(kV) </Button> 
//       <Button size="small" >{Rating}&nbsp;(MVA) </Button> 
//       <Button size="small" >{Cooling}</Button> 
//     </ButtonGroup> 
//   )
// }

const Report = (props) =>
{
  const classes  = useStyles();
  let Rating1 = parseFloat(props.xfrmer.Rating1)/1000;
  let Rating2 = parseFloat(props.xfrmer.Rating2)/1000;
  let Rating3 = parseFloat(props.xfrmer.Rating3)/1000;

  return (

   <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition} >
     <CssBaseline />
      <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: 'transparent', color:'black',  maxHeight:'50px',}} >       
        <Toolbar>
          <IconButton edge="start" color="inherit"  onClick={props.handleClose}  aria-label="close" >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"> 
          {"< " + props.xfrmer.XfrmName + " " + Rating1 + "/" + Rating2 + "/" + Rating3 + "MVA >"} Load Study Report </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.pdfreport}>
        <PDFViewer width="100%" style={{ height: '100vh' }}>
          <LoadingReport xfrmer ={props.xfrmer} dataSheet = {props.dataSheet} loadScenarios = {props.loadScenarios}  NamePlate= {props.NamePlate}
            loadStandards = {props.loadStandards} lCooling={props.lCooling} ImagURLs ={props.ImagURLs}/>
        </PDFViewer>
      </div>
    </Dialog> 
   )
}

export default function Dashboard (props) 
{ 
  //var dprops = props.location.state.nXfrmer;
  var dprops = props.nXfrmer;
  var sessionId = props.userData.username;
  const classes  = useStyles();
  const theme = useTheme();
  const [stateVar, setStateVar] = useState({
      // selProfileVar: [],
      selProfileVar:'',  
      selScenarioVar:[],
      selCoolingVar: [],
      selStdVar:'',
      selAmbientVar:0,
      selTimeVar: -1
  });

  const [condVar, setCondVar] = useState({
      selProfileVar: '',
      selScenarioVar:[],
      selCoolingVar: [],
      selStdVar:'',
      selAmbientVar:0,
      selTimeVar: -1
  });

  var  Diagnostics = [ 
      { varName: 'Optimum MVA',      categorie:'LOAD'},
      { varName: 'Nominal MVA',      categorie:'LOAD'},
      { varName: 'MVA Margin' ,      categorie:'LOAD'},
      { varName: 'TTPeak[Min]',      categorie:'LOAD'},

      { varName: 'LOSSofLife[%]:',         categorie:'AGING'},
      { varName: 'Aging[Hour]',            categorie:'AGING'},

      { varName: 'HI HOTSPOT[℃]',          categorie:'HOTSPOT'},
      { varName: 'TTPeak[Min]',            categorie:'HOTSPOT'},

      { varName: 'HI TOPOIL[℃]',           categorie:'TOPOIL'},
      { varName: 'TTPeak[Min]',            categorie:'TOPOIL'},

      { varName: 'HI BOTTOM[℃]',           categorie:'BOTTOM'},
      { varName: 'TTPeak[Min]' ,           categorie:'BOTTOM'},

      { varName: 'Total Gas[ppm]',         categorie:'MOISTURE'},
      { varName: 'Moisture in oil[ppm]',   categorie:'MOISTURE'},
      { varName: 'Bubbling margin[K]' ,    categorie:'MOISTURE'},   
      { varName: 'Limited By' ,            categorie:'MISC'},
  ];

  const [drawState, setDrawState] = useState({
      Ambient: [],
      Cooling :[],
      Scenarios:[],
      Profiles: [],
      Duration: []
  });

  const [ieeeG7Iec, setIeeeG7Iec]     = useState({ieeeNamePlate:[], ieeeThermal:[], ieeeDiagnostics:[]});
  const [value, setValue]             = useState(0);
  const [openDrawer, setDrawer]       = useState(false);
  const [checked, setChecked]         = useState([1]);
  const [cochecked, setcoChecked]     = useState([1]);
  const [tapNbr, setTapNbr]           = useState(-1);
  const [submitVar, setSubmitVar]     = useState(true);
  const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error'});

  const [runIt, setRunIt]             = useState(true);
  const [printIt, setPrintIt]         = useState(false); 
  const [saveIt, setSave]           = useState(true); 
  const [reopen, setReopen]           = useState(false);
  const [state, setState]             = useState({
        sessionId:sessionId,
        newXrmer:
        {
          XfrmName    : dprops.XfrmName,
          XType       : dprops.XType,
          XPhase      : dprops.XPhase,
          VClass      : dprops.VClass,
          Rating1     : dprops.Rating1,
          Rating2     : dprops.Rating2,
          Rating3     : dprops.Rating3,  
          Stage1      : dprops.Stage1,
          Stage2      : dprops.Stage2,
          Stage3      : dprops.Stage3,
          SolidInsulation: dprops.SolidInsul,
          LiquidInsulation: dprops.LiquidInsul,
        
          TankType      : dprops.TankType,       
          GPSLatitude   : dprops.GPSLatitude,
          GPSLongitude  : dprops.GPSLongitude,
          GPSAltitude   : dprops.GPSAltitude,
          Vintage       : dprops.Vintage,
          coolingMode: dprops.Stage1 + '/' + dprops.Stage2 + '/' + dprops.Stage3,
          Rating: dprops.Rating1 + '/' + dprops.Rating2 + '/' + dprops.Rating3,
        },

        dataSheet: [],
        lProfiles: initProfile,
        loadCases: [],
        lCooling:  [],
        loadStds:  [],
        lDistro:   [],
        lProfileList: [], 
        ImagURLs: [],
        Spinner: false,
  });


  useEffect(() => 
  {
      CanRunSession ();
      CanPrintReport ();
      CanSaveSession();
  },[state.dataSheet, state.lProfileList, state.loadStds, state.loadCases, state.lDistro]);

  useEffect(() =>
  {
      CanHandleSubmit ();
  },[stateVar.selProfileVar, stateVar.selAmbientVar,stateVar.selStdVar , stateVar.selCoolingVar, stateVar.selScenarioVar, stateVar.selTimeVar]);

  useEffect(() => 
  {
      CanPrintReport (); 
  },[ieeeG7Iec.ieeeNamePlate, ieeeG7Iec.ieeeThermal]);

  useEffect(() => 
  {
      // props.setGuideId("DIY");
      // props.setDIYvalue(false);
  }, []);

  // Collect load profiles and profile list
  useEffect(() =>
  {
      ClearSession();
      // ===== Get the load profiles of the newly set transformer =====
      const url1 = ApiURL + '/GetProfiles'; 
      const url2 = ApiURL + '/GetScenarios'; 
      const url3 = ApiURL + '/GetDistros'; 
      let Payload = { sessionId:state.sessionId, xfrmId:dprops.XfrmName};
      console.log("Retrieved promises : " +  " " + JSON.stringify(Payload));
      const reqOtions = {
        method: 'POST',
        body: JSON.stringify(Payload),
        headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
      }
     
      try 
      {
        (async () => {
            let profile = [];
            const rawResponse = await fetch(url1, reqOtions);
            const result = await rawResponse.json();
            if(!isEmpty(result))
            { 
              profile = result.profiles.map(p => { return { XfrmName: p.XfrmName, profileName:p.profileName, time: p.time, sumamb: p.sumamb, sumpul: p.sumpul, sumcool: p.sumcool} });
            }
            
            const rawResponse1 = await fetch(url2, reqOtions);
            const loadRCases = await rawResponse1.json();

            const rawResponse2 = await fetch(url3, reqOtions);
            const loDistro = await rawResponse2.json();
            if(!isEmpty(profile) && !isEmpty(loadRCases) && !isEmpty(loDistro))
              setState ({...state,  lProfileList:result.profileList, lProfiles:profile, loadCases:loadRCases, lDistro:loDistro,  Spinner:false});
            // console.log("Retrieved load profile :" + JSON.stringify(profile));
            // console.log("Retrieved loading scenrios :" + JSON.stringify(loadRCases));
            // console.log("Retrieved loading distribution :" + JSON.stringify(loDistro));
        })();
      }   
      catch (error) {
        console.error('Error:', error);
      } 
      
  }, [dprops.XfrmName]);

  const onDataDictionary = (XfrmDataSheet, XfrmCooling) => {
      setState({...state, dataSheet: XfrmDataSheet, lCooling: XfrmCooling}); 
      setTapNbr(parseInt (XfrmDataSheet.find(item => item.VarName ==='LtcTapSteps').Value));
      // props.setSideGuide("datasheetOK");
  }

  const onLoadProfiles = (profileList, profile) => {
      let lProfiles = [...state.lProfiles, ...profile];   
      setState({...state, lProfileList: profileList, lProfiles: lProfiles });
      // props.setSideGuide("profileOK");
  }

  const onRemoveProfile = (profileName) => {
      let listItems   = state.lProfileList.filter(p => p.profileName !== profileName);
      let profileItem = state.lProfiles.filter(p => p.profilename !== profileName);
      setState({...state, lProfileList: listItems, lProfiles: profileItem}); 
  }

  const onLoadingStd = (LoadingStd) => {
      setState({...state, loadStds: LoadingStd}); 
  }

  const onLoadingCases = (LoadingCases) => {
      setState({ ...state, loadCases: LoadingCases}); 
  }

  const onDistribution = (Distributions) => {
      setState({ ...state, lDistro: Distributions}); 
  }
  
  const onScenario = () => {
      // props.setSideGuide("scenarioOK");
  }
    
  const getAmbient = (profileVar) =>
    {
      let ambArray = [];
      if(!isEmpty(state.lDistro))
      {
        let ambientVar    = JSON.parse(state.lDistro.find(p => p.profileName === profileVar).Ambient);
        let mean = parseFloat(ambientVar.ambMean), stdDev =  parseFloat(ambientVar.ambStd);

        for (let amb = mean - (3 * stdDev); amb < mean + (3 * stdDev); amb = amb + stdDev)
        {      
          ambArray.push(amb);
        }
      }
      return ambArray;
  }

  const RunRatingSession = () =>
  {
      const url = ApiURL + '/DoNewRating';  
      // props.setSideGuide("sessionOK");    
      let selAmbientVal = 0;
      let lProfiles     = state.lProfiles.filter(p => p.profileName!=='Template');
      let profileVar    = lProfiles.map(p => p.profileName).filter(onlyUnique);
      let scenarioVar   = [].concat(state.loadCases.map(p => p.Name));
      let coolingVar    = [].concat(state.lCooling[0].XfrmerCoolLevel);
      let stdVar        = state.loadStds[0].pubName;
      // let ambientVar    = getAmbient (profileVar);
      // setDrawState({...drawState, Ambient:ambientVar}); 

      // if(!isEmpty(state.lDistro))
      // {    
      //   selAmbientVal    = JSON.parse(state.lDistro.find(p => p.profileName === profileVar).Ambient).ambMean;
      // }

      let Payload = { sessionId:state.sessionId, newXrmer:dprops, dataSheet:state.dataSheet, lProfiles:lProfiles, 
                      loadCases:state.loadCases, lCooling:state.lCooling, loadStds:state.loadStds, lDistro:state.lDistro }; 

      console.log(dprops.XfrmName + " New Rating session ===>" + JSON.stringify(Payload));

      setState ({...state, Spinner:true});
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(Payload),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          console.log("================");
          console.log(result);
          setState ({...state, Spinner:false});
          if(!isEmpty(result.NamePlateResults))
          {
            let Duration  = result.NamePlateResults.map(p => p.overtime).filter(onlyUnique)
            let Ambient   = result.NamePlateResults.map(p => p.mvaAmbient).filter(onlyUnique)
            setDrawState({...drawState, Duration: Duration, Ambient: Ambient});
            var loadingVar = { selProfileVar: profileVar[0], selScenarioVar: scenarioVar, selCoolingVar:coolingVar
                                , selStdVar:stdVar, selAmbientVar:Ambient[0], selTimeVar:Duration[0]};

            DisplayData(result, loadingVar);
          }
        })();
      }   
      catch (error) {
          console.error('Error:', error);
      }
  }

  const CanRunSession = () =>
    {
      if(state.lProfileList.length > 0 && state.loadStds.length > 0 
        && state.dataSheet.length > 0)
      { 
        setRunIt(false);
      }
      else 
        setRunIt(true);
  }

  const PrintReport = () => { 
      if(isEmpty(state.ImagURLs)) 
      {        
        setOpenSnack({open: true, Message: " There is no data available yet to generate your report. Please perform a loading calculation first and then add the loading charts to your report."});
        return;
      } 
      setReopen(true);
  }

  const CanPrintReport = () =>
    {
      if(ieeeG7Iec.ieeeNamePlate.length > 0 && ieeeG7Iec.ieeeThermal.length > 0 )
      { 
        setPrintIt(false);
      }
      else 
        setPrintIt(true);
  }

  const ClearSession = () => {
      const url = ApiURL + '/ClearRating'; 
      setIeeeG7Iec ({ieeeNamePlate:[], ieeeThermal:[], ieeeDiagnostics:[]});
      let  lProfileList = state.lProfileList, lDistro = state.lDistro, loadCases = state.loadCases, loadStds = state.loadStds, lCooling = state.lCooling, dataSheet = state.dataSheet, lProfiles = state.lProfiles;
      lProfileList.length = 0; lDistro.length = 0; loadCases.length = 0; loadStds.length = 0; lCooling.length = 0; dataSheet.length = 0; lProfiles.length = 0;
      
      setState ({...state, dataSheet:dataSheet, lProfiles: lProfiles, loadCases:loadCases, lCooling:lCooling, loadStds:loadStds, lDistro:lDistro, lProfileList:lProfileList, Spinner:true });
      
      let Payload = { sessionId:state.sessionId, xfrmId:dprops.XfrmName};
      console.log("ClearRating : " +  " " + JSON.stringify(Payload));
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(Payload),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          await rawResponse.json();
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
      setState ({...state, Spinner:false});
  }

  const SaveSession = () =>
    {
      const url = ApiURL + '/Get'; 
      let Payload = { sessionId:state.sessionId, newXrmer:dprops};
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(Payload),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
  }

  const CanSaveSession = () =>
    {
      let saveSession = false;
      return saveSession;
  }

  const handleChange = (event) => {
      setStateVar({...stateVar, [event.target.name]: event.target.value });
  }
    
  const toggleDrawer = (open) => event => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }   
      setDrawer(open);
  };

  const SaveImages = (ImagURLs) => {
      setState({...state, ImagURLs:[...state.ImagURLs, ...ImagURLs]});
  }

  const handleSubmit = () => {
      const url   = ApiURL + '/GetRatings';  
      let Payload = { XfrmName:dprops.XfrmName, sessionId:state.sessionId, selProfileVar: stateVar.selProfileVar, selStdVar:stateVar.selStdVar, selAmbientVar: stateVar.selAmbientVar,
        selScenarioVar: stateVar.selScenarioVar,  selCoolingVar: stateVar.selCoolingVar};

      var loadingVar = {selProfileVar: stateVar.selProfileVar, selScenarioVar: stateVar.selScenarioVar, selCoolingVar:stateVar.selCoolingVar, 
        selStdVar:stateVar.selStdVar, selAmbientVar:stateVar.selAmbientVar, selTimeVar:stateVar.selTimeVar};

      setState ({...state, Spinner:true});
      // console.log("Request : " + JSON.stringify(Payload))
      
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(Payload),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          // console.log(result)
          if(!isEmpty(result))
          {
            DisplayData(result, loadingVar);
          }
        })();
      }   
      catch (error) {
        console.error('Error:', error);
      }
      // Cleaning user inputs for conditions view
      setState ({...state, Spinner:false});
      setStateVar ({ selProfileVar: '', selScenarioVar:[], selCoolingVar: [], selStdVar:'', selAmbientVar:0 });
      setChecked([1]); setcoChecked([1]);
  }

  const CanHandleSubmit = () =>
    {
      if((isEmpty(stateVar.selProfileVar) || stateVar.selProfileVar === "Please select") 
      && (isEmpty(stateVar.selAmbientVar) || stateVar.selAmbientVar === "Please select") 
      && (isEmpty(stateVar.selStdVar) || stateVar.selStdVar === "Please select") 
      && isEmpty(stateVar.selCoolingVar)
      && isEmpty(stateVar.selScenarioVar)) 
      { 
        setSubmitVar(true);
      }
      else
        setSubmitVar(false);
  }

  const DisplayData = (result, loadingVar) => 
  {
      setValue(0);
      let ieeeGserie      = [], ieee7serie  = [], iecserie  = [];  
      let ieeeTGserie     = [], ieee7Tserie = [], iecTserie = [];
      let ieeeGStage      = [], ieee7Stage  = [], iecStage  = [];
      let ieeeGTData      = [], ieee7TData  = [], iecTData  = [];
      let coolingSet      = loadingVar.selCoolingVar.filter(onlyUnique); //new Set(loadingVar.selCoolingVar.map(p => p));
      // let coolingSet      = result.NamePlateResults.map(p => p.mvaCooling).filter(onlyUnique); 

      var selScenarioVar  = loadingVar.selScenarioVar[0];
      var selCoolingVar   = loadingVar.selCoolingVar[0];
      var selProfileVar   = loadingVar.selProfileVar;
      var selTimeVar      = loadingVar.selTimeVar;
      var selAmbientVar   = loadingVar.selAmbientVar;
      let scenarioSet     = loadingVar.selScenarioVar.filter(onlyUnique); // new Set(loadingVar.selScenarioVar.map(p => p));

      // console.log(" loadingVar.selTimeVar :====> " + selScenarioVar + " " + selProfileVar + " " + selTimeVar + " " + 
      // selAmbientVar + " " + JSON.stringify(scenarioSet));

      switch(loadingVar.selStdVar) {
        case 'IEEEG': 
        {   
         
          for (let scenItem of scenarioSet) 
          {  
            // for (let profile of profileSet) 
            // {  
              let ieeeGData = [], ieeeGTData = [];  
              // Checking whether data exists or not
              if((result.NamePlateResults.filter(p => p.profileName === selProfileVar)
                                         .filter(u => u.LoadType === scenItem).length === 0)
                  || result.ThermalResults.filter(p => p.profileName === selProfileVar)
                                          .filter(r => r.LoadType   === selScenarioVar).length === 0)
              {
                continue;
              }
              
              // Processing the Upper Limits Results      
              result.NamePlateResults.filter(p => p.profileName === selProfileVar).filter(u => u.LoadType === scenItem).map (q =>
              {
                ieeeGData.push({
                  HotSpot: q.IEEEGPeakHotSpot.toFixed(2), MVA: q.IEEEGPeakMVA.toFixed(2), basicMVA: q.baPeakMVA.toFixed(2), TopOil: q.IEEEGPeakTopOil.toFixed(2), 
                  MVAMargin:[q.IEEEGPeakMVA.toFixed(2), q.baPeakMVA.toFixed(2)], 
                  Margin: parseFloat(q.IEEEGMargin).toFixed(2), BottomOil: parseFloat(q.IEEEGPeakBottom).toFixed(2), 
                  LOSSofLife: q.IEEEGPeakLoL.toFixed(4), Ambient: q.mvaAmbient, Cooling: q.mvaCooling, Overtime:parseInt(q.overtime),
                  TTPMva:q.IEEEGttpMVA.toFixed(2), TTPTop:q.IEEEGttpTOT.toFixed(2), TTPHot:q.IEEEGttpHST.toFixed(2), TTPBot:q.IEEEGttpBOT.toFixed(2), 
                  LimitedBy:q.IEEEGLimitedBy});
              });  
              ieeeGserie.push({ name: selProfileVar + " - " + scenItem, ieeeData: ieeeGData });

              // Processing Thermal Results
              result.ThermalResults.filter(p => p.profileName === selProfileVar).filter(r => r.LoadType   === selScenarioVar).map(v =>
              {          
                ieeeGTData.push({
                  time: v.time, Ambient: parseFloat(v.sumamb).toFixed(2), basicMVA: parseFloat(v.basicMVA).toFixed(2), MVA: parseFloat(v.IEEEGMva).toFixed(2), 
                  HotSpot: parseFloat(v.IEEEGHST).toFixed(2), TopOil: parseFloat(v.IEEEGTOT).toFixed(2), BottomOil: parseFloat(v.IEEEGBOT).toFixed(2), 
                  LOSSofLife: parseFloat(v.IEEEGLOL).toFixed(4), MVAMargin: [parseFloat(v.IEEEGMargin).toFixed(2),parseFloat(v.basicMVA).toFixed(2)], 
                  Margin:parseFloat(v.IEEEGMargin).toFixed(2), mvaCooling: v.mvaCooling, mvaAmbient: v.mvaAmbient, Overtime:parseInt(v.overtime)});
              });
              ieeeTGserie.push({ name: selProfileVar + " - " + scenItem, ieeeData: ieeeGTData });
            // }
          } 

          // Processing Results to be displayed on the Dashboard
          // let coolingSet      = result.NamePlateResults.map(p => p.mvaCooling).filter(onlyUnique); 
          for (let coolItem of coolingSet) 
          { 
            if(result.NamePlateResults.filter(p => p.profileName === selProfileVar)
                                      .filter(u => u.LoadType    === selScenarioVar)
                                      .filter(p => p.overtime    === parseFloat(selTimeVar))
                                      .filter(p => p.mvaAmbient  === parseFloat(selAmbientVar))
                                      .filter(p => p.mvaCooling  === parseFloat(coolItem)).length === 0) 
                continue ; 
            
            
            result.NamePlateResults.filter(p => p.profileName === selProfileVar)
                                     .filter(u => u.LoadType    === selScenarioVar)
                                     .filter(p => p.overtime    === parseFloat(selTimeVar))
                                     .filter(p => p.mvaAmbient  === parseFloat(selAmbientVar))
                                     .filter(p => p.mvaCooling  === parseFloat(coolItem))
                                     .map (q =>
            {
                // Diagnostics Summary
                let newStage = 'Stage-'+ coolItem;
                Diagnostics[0][newStage]  = q.IEEEGPeakMVA.toFixed(2);
                Diagnostics[1][newStage]  = q.baPeakMVA.toFixed(2);
                Diagnostics[2][newStage]  = parseFloat(q.IEEEGMargin).toFixed(2);
                Diagnostics[3][newStage]  = q.IEEEGttpMVA.toFixed(2);      
                Diagnostics[4][newStage]  = q.IEEEGPeakLoL.toFixed(4);
                Diagnostics[5][newStage]  = q.IEEEGPeakLoL.toFixed(4);      
                Diagnostics[6][newStage]  = q.IEEEGPeakHotSpot.toFixed(2);
                Diagnostics[7][newStage]  = q.IEEEGttpHST.toFixed(2);      
                Diagnostics[8][newStage]  = q.IEEEGPeakTopOil.toFixed(2);
                Diagnostics[9][newStage]  = q.IEEEGttpTOT.toFixed(2);     
                Diagnostics[10][newStage] = parseFloat(q.IEEEGPeakBottom).toFixed(2);
                Diagnostics[11][newStage] = q.IEEEGttpBOT.toFixed(2);     
                Diagnostics[12][newStage] = 0.0;
                Diagnostics[13][newStage] = 0.0;
                Diagnostics[14][newStage] = 0.0;     
                Diagnostics[15][newStage] = q.IEEEGLimitedBy;
            })
          } 
          setIeeeG7Iec ({ieeeNamePlate:ieeeGserie, ieeeThermal:ieeeTGserie, ieeeDiagnostics: Diagnostics}); break;
        }

        case 'IEEE7': 
        {   
          for (let scenItem of scenarioSet) 
          {  
            for (let coolItem of coolingSet) 
            {  
              let ieee7Data = []; //, ieee7TData = [];

              if(result.NamePlateResults.filter(p => p.profileName === selProfileVar)
                                        .filter(p => p.mvaCooling === parseFloat(coolItem))
                                        .filter(u => u.LoadType === scenItem).length === 0 ||            
                  result.ThermalResults.filter(p => p.profileName === selProfileVar)
                                        .filter(q => q.mvaCooling === parseFloat(selCoolingVar))
                                        .filter(s => s.mvaAmbient === parseFloat(selAmbientVar))
                                        .filter(r => r.LoadType   === selScenarioVar))
                  continue;

              // Processing NamePlate Results
              result.NamePlateResults.filter(p => p.profileName === selProfileVar).filter(p => p.mvaCooling === parseFloat(coolItem)).filter(u => u.LoadType === scenItem).map (q =>
              {
                ieee7Data.push({ 
                  HotSpot: q.IEEE7PeakHotSpot.toFixed(2), MVA: q.IEEE7PeakMVA.toFixed(2), basicMVA: q.baPeakMVA.toFixed(2), 
                  TopOil: q.IEEE7PeakTopOil.toFixed(2), MVAMargin:[q.IEEE7PeakMVA.toFixed(2), q.baPeakMVA.toFixed(2)],Margin: parseFloat(q.IEEEMargin).toFixed(2), BottomOil: 0, 
                  LOSSofLife: q.IEEE7PeakLoL.toFixed(5), Ambient: q.mvaAmbient, Cooling: q.mvaCooling, Overtime:parseInt(q.overtime),
                  TTPMva:q.IEEE7ttpMVA.toFixed(2), TTPTop:q.IEEE7ttpTOT.toFixed(2), TTPHot:q.IEEE7ttpHST.toFixed(2), TTPBot:0, LimitedBy:q.IEEE7LimitedBy});
              }); 

              ieee7serie.push({ name: "cool=" + coolItem + "% -" + scenItem, ieeeData:ieee7Data });  
              ieee7Stage[coolItem]    = ieee7Data.filter(p => p.Ambient === parseFloat(selAmbientVar))
                                                 .filter(p => p.Overtime === parseInt(selTimeVar));

              // Diagnostics Summary
              let newStage = 'Stage-'+ coolItem;
              Diagnostics[0][newStage]= ieee7Stage[coolItem].MVA;
              Diagnostics[1][newStage]= ieee7Stage[coolItem].basicMVA;
              Diagnostics[2][newStage]= ieee7Stage[coolItem].Margin;
              Diagnostics[3][newStage]= ieee7Stage[coolItem].TTPMva;     
              Diagnostics[4][newStage]= ieee7Stage[coolItem].LOSSofLife;
              Diagnostics[5][newStage]= ieee7Stage[coolItem].LOSSofLife;     
              Diagnostics[6][newStage]= ieee7Stage[coolItem].HotSpot;
              Diagnostics[7][newStage]= ieee7Stage[coolItem].TTPHot;     
              Diagnostics[8][newStage]= ieee7Stage[coolItem].TopOil;
              Diagnostics[9][newStage]= ieee7Stage[coolItem].TTPTop;      
              Diagnostics[10][newStage]= ieee7Stage[coolItem].BottomOil;
              Diagnostics[11][newStage]= ieee7Stage[coolItem].TTPBot;
        
              Diagnostics[12][newStage]= 0.0;
              Diagnostics[13][newStage]= 0.0;
              Diagnostics[14][newStage]= 0.0;     
              Diagnostics[15][newStage]= ieee7Stage[coolItem].LimitedBy;
            }
          }
          // Processing Thermal Results
          result.ThermalResults.filter(p => p.profileName === selProfileVar)
          .filter(q => q.mvaCooling === parseFloat(selCoolingVar))
          .filter(s => s.mvaAmbient === parseFloat(selAmbientVar))
          .filter(r => r.LoadType   === selScenarioVar)
          .map(v =>
          {          
            ieee7TData.push({time: v.time, Ambient: parseFloat(v.sumamb).toFixed(2), basicMVA: parseFloat(v.basicMVA).toFixed(2), MVA: parseFloat(v.IEEE7Mva).toFixed(2), 
                                        HotSpot: parseFloat(v.IEEE7HST).toFixed(2), TopOil: parseFloat(v.IEEE7TOT).toFixed(2), BottomOil: 0.0, 
                                        LOSSofLife: parseFloat(v.IEEE7LOL).toFixed(4), MVAMargin: [parseFloat(v.IEEE7Margin).toFixed(2),parseFloat(v.basicMVA).toFixed(2)], 
                                        Margin:parseFloat(v.IEEE7Margin).toFixed(2), mvaCooling: v.mvaCooling, mvaAmbient: v.mvaAmbient, Overtime:parseInt(v.overtime)});
          });
          setIeeeG7Iec ({ieeeNamePlate:ieee7serie, ieeeThermal:ieee7TData, ieeeDiagnostics: Diagnostics}); break;
        }
        case 'IEC60354': 
        {
          for (let scenItem of scenarioSet) 
          {  
            for (let coolItem of coolingSet) 
            {  
              let iecData = []; iecTData = [];
              // Processing NamePlate Results
              result.NamePlateResults.filter(p => p.profileName === selProfileVar).filter(p => p.mvaCooling === parseFloat(coolItem)).filter(u => u.LoadType === scenItem).map (q =>
              {   
                iecData.push({
                  HotSpot: q.IECPeakHotSpot.toFixed(2),  MVA: q.IECPeakMVA.toFixed(2), basicMVA: q.baPeakMVA.toFixed(2), 
                  TopOil: q.IECPeakTopOil.toFixed(2), MVAMargin:[q.IECPeakMVA.toFixed(2), q.baPeakMVA.toFixed(2)], 
                  Margin: parseFloat(q.IECMargin).toFixed(2), BottomOil: 0, 
                  LOSSofLife: q.IECPeakLoL.toFixed(5), Ambient: q.mvaAmbient, Cooling: q.mvaCooling, Overtime:parseInt(q.overtime),
                  TTPMva:q.IECttpMVA.toFixed(2), TTPTop:q.IECttpTOT.toFixed(2), TTPHot:q.IECttpHST.toFixed(2), TTPBot:0 , 
                  LimitedBy:q.IECLimitedBy});
              });

              iecserie.push({ name: "cool=" + coolItem + "% -" + scenItem, ieeeData:iecData });
              iecStage[coolItem ] = iecData.filter(p => p.Overtime === parseInt(selTimeVar))
                                           .filter(p => p.Ambient === parseFloat(selAmbientVar));

              // Processing Diagnostics Summary
              let newStage = 'Stage-'+ coolItem;
              Diagnostics[0][newStage]  = iecStage[coolItem].MVA;
              Diagnostics[1][newStage]  = iecStage[coolItem].basicMVA;
              Diagnostics[2][newStage]  = iecStage[coolItem].Margin;
              Diagnostics[3][newStage]  = iecStage[coolItem].TTPMva;       
              Diagnostics[4][newStage]  = iecStage[coolItem].LOSSofLife;
              Diagnostics[5][newStage]  = iecStage[coolItem].LOSSofLife;
          
              Diagnostics[6][newStage]  = iecStage[coolItem].HotSpot;
              Diagnostics[7][newStage]  = iecStage[coolItem].TTPHot;        
              Diagnostics[8][newStage]  = iecStage[coolItem].TopOil;
              Diagnostics[9][newStage]  = iecStage[coolItem].TTPTop;       
              Diagnostics[10][newStage] = iecStage[coolItem].BottomOil;
              Diagnostics[11][newStage] = iecStage[coolItem].TTPBot;
          
              Diagnostics[12][newStage] = 0.0;
              Diagnostics[13][newStage] = 0.0;
              Diagnostics[14][newStage] = 0.0;      
              Diagnostics[15][newStage]= iecStage[coolItem].LimitedBy;
            }
          }
          // Processing Thermal Results
          result.ThermalResults.filter(p => p.profileName === selProfileVar)
          .filter(r => r.LoadType   === selScenarioVar)
          .filter(q => q.mvaCooling === parseFloat(selCoolingVar))
          .filter(s => s.mvaAmbient === parseFloat(selAmbientVar))
          .map(v =>
          {          
            iecTData.push({time: v.time, Ambient: parseFloat(v.sumamb).toFixed(2), basicMVA: parseFloat(v.basicMVA).toFixed(2), MVA: parseFloat(v.IECMva).toFixed(2), 
                                        HotSpot: parseFloat(v.IECHST).toFixed(2), TopOil: parseFloat(v.IECTOT).toFixed(2), BottomOil: 0.0, 
                                        LOSSofLife: parseFloat(v.IECLOL).toFixed(4), MVAMargin: [parseFloat(v.IECMargin).toFixed(2),parseFloat(v.basicMVA).toFixed(2)],  
                                        Margin:parseFloat(v.IECMargin).toFixed(2), mvaCooling: v.mvaCooling, mvaAmbient: v.mvaAmbient, Overtime:parseInt(v.overtime)});
          });
          //iecTserie.push({ name: "cool=" + coolItem + "% -" + scenItem, ieeeData:iecTData });
          setIeeeG7Iec ({ieeeNamePlate:iecserie, ieeeThermal:iecTData, ieeeDiagnostics: Diagnostics}); 
          break;
        }
      }
      setCondVar ({ selProfileVar:selProfileVar, selScenarioVar:loadingVar.selScenarioVar,
                    selCoolingVar: loadingVar.selCoolingVar, selStdVar:loadingVar.selStdVar,
                    selAmbientVar:selAmbientVar, selTimeVar: selTimeVar});
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    ;
    if (currentIndex === -1) 
    {
      newChecked.push(value);
    } 
    else 
    {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    // if(stateVar.selScenarioVar.find(p => p === value)) return;
    setStateVar({...stateVar, selScenarioVar: stateVar.selScenarioVar.concat([value]) });
  };

  const handleCoolToggle = (value) => () => {
    const currentIndex = cochecked.indexOf(value);
    const newChecked = [...cochecked];
    
    if (currentIndex === -1) 
    {
      newChecked.push(value);
    } 
    else 
    {
      newChecked.splice(currentIndex, 1);
    }
    setcoChecked(newChecked);
    // if(stateVar.selCoolingVar.find(p => p === value)) return;
    setStateVar({...stateVar, selCoolingVar: stateVar.selCoolingVar.concat([value]) });
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack({...openSnack, open:false});
  };

return ( 
    <div> 
      <SpinnerDialog show = {state.Spinner} handleClose={ () => setState ({...state, Spinner:false})}/>  
      
      <Report xfrmer={dprops} open={reopen} handleClose ={()=> setReopen(false)} dataSheet = {state.dataSheet} loadScenarios = {state.loadCases} NamePlate= {ieeeG7Iec.ieeeNamePlate}
        loadStandards = {state.loadStds} lCooling={state.lCooling} ImagURLs ={state.ImagURLs}/>

      <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
      <ResponsiveContainer>
        <Card className={classes.maincard} id ={dprops.XfrmName}>
          <CardHeader className={classes.cardheader}>
            <Box display="flex" >
              <Box flexGrow={1}>
                {/* <XfrmHeader {...dprops} />  */}
              </Box>
              <Box flexGrow={1}>
                <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group" > 
                  <Button size="small" startIcon={<PlayCircleFilledIcon />} onClick={RunRatingSession} disabled={runIt}> Run </Button>
                  <Button size="small" startIcon={<SaveIcon />} onClick={SaveSession} disabled = {saveIt}> Save </Button> 
                  <Button size="small" startIcon={<ClearAllIcon/>} onClick={ClearSession}> Clear </Button> 
                  <Button size="small" startIcon={<StopIcon />} onClick={PrintReport} disabled = {printIt}>Print</Button> 
                </ButtonGroup> 
              </Box>
              <Box>
                <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
                  <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}>
                      <MenuIcon />
                  </IconButton>
                </ButtonGroup> 

                <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)} PaperProps={{ style: { position: 'absolute' } }} variant="persistent">
                  <Card className={classes.card} id ={dprops.XfrmName}>
                      <CardHeader className={classes.cardheader}>
                        <IconButton onClick={toggleDrawer(false)}  style={{backgroundColor: "#eceff1"}}>
                          {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon/>}
                        </IconButton>
                         <Typography variant="subtitle2" display="inline">  DISPLAY CONTROL PANEL</Typography> </CardHeader>
                      <CardBody>
                        <FormControl className={classes.formControl}>
                              <div height={30} p={1} className={classes.native}>
                                <NativeSelect name="selStdVar" value={stateVar.selStdVar} onChange={handleChange} className={classes.select}>
                                  <option >Please select</option>
                                  { 
                                    state.loadStds.map((o, key) => (
                                    <option key={key} value={o.pubName}>{o.pubName}</option>
                                    ))
                                  }
                                </NativeSelect>
                                <FormHelperText><Typography variant="caption">Loading standard</Typography></FormHelperText>
                              </div>
                              <br/>
                              <div height={30} p={1} className={classes.native}>
                                <NativeSelect name="selProfileVar" value={stateVar.selProfileVar} onChange={handleChange} className={classes.select}>
                                  <option >Please select</option>
                                  {
                                      state.lProfileList.map((o, key) => (
                                      <option key={key} value={o.profileName}>{o.profileName}</option>
                                      ))
                                  }
                                </NativeSelect>
                                <FormHelperText><Typography variant="caption">Load profiles</Typography> </FormHelperText>
                              </div>
                              {/* <Box height={100} p={1} bgcolor="grey.300">
                               <List dense >
                                  {
                                    state.lProfileList.map((o, key) => {
                                //     <option key={key} value={o.profileName}>{o.profileName}</option>)
                                //     )
                                //   }
                                // </NativeSelect>
                                // <FormHelperText><Typography variant="caption">Load profiles</Typography> </FormHelperText>
                                //   }
                                    const labelId = `checkbox-list-secondary-label-${o.profileName}`;
                                      return (
                                        <ListItem key={o.profileName} button>
                                          <ListItemText variant="caption" id={labelId} primary={`${o.profileName}`} />
                                          <ListItemSecondaryAction>
                                            <Checkbox edge="end" onChange={handleProToggle(o.profileName)} checked={prochecked.indexOf(o.profileName) !== -1} inputProps={{ 'aria-labelledby': labelId }} />
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      );
                                      })
                                    }
                                </List>
                              </Box> */}
                              <br/>
                              <FormHelperText><Typography variant="caption">Previously defined loading scenarios</Typography> </FormHelperText>
                              <Box height={150} p={1} bgcolor="grey.300">
                                <List dense >
                                  {
                                    state.loadCases.map(p => {
                                      const labelId = `checkbox-list-secondary-label-${p.Name}`;
                                      return (
                                        <ListItem key={p.Name} button>
                                          <ListItemText variant="caption" id={labelId} primary={`${p.Name}`} />
                                          <ListItemSecondaryAction>
                                            <Checkbox edge="end" onChange={handleToggle(p.Name)} checked={checked.indexOf(p.Name) !== -1} inputProps={{ 'aria-labelledby': labelId }} />
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      );
                                    })
                                  }
                                </List>
                              </Box>
                              <br/>
                              <FormHelperText><Typography variant="caption">Available cooling power</Typography></FormHelperText>
                              <Box height={150} p={1} bgcolor="grey.300">
                                <List dense >
                                  {
                                    state.lCooling.map(p => {
                                      const labelId = `checkbox-list-secondary-label-${p.XfrmerCoolLevel}`;
                                      return (
                                        <ListItem key={p.XfrmerCoolLevel} button>
                                          <ListItemText variant="caption" id={labelId} primary={`${p.XfrmerCoolLevel}%-(${p.XfrmerCooling})`} />
                                          <ListItemSecondaryAction>
                                            <Checkbox edge="end" onChange={handleCoolToggle(p.XfrmerCoolLevel)} checked={cochecked.indexOf(p.XfrmerCoolLevel) !== -1} inputProps={{ 'aria-labelledby': labelId }} />
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                      );
                                    })
                                  }
                                </List>
                              </Box>
                              <br/>
                              <div height={30} p={1} className={classes.native}>
                                <NativeSelect name="selAmbientVar" value={stateVar.selAmbientVar} onChange={handleChange} className={classes.select}>
                                  <option > Please select</option>
                                  { 
                                    drawState.Ambient.map(o => (
                                        <option key={o} >{o}</option>
                                    ))
                                  }
                                </NativeSelect>
                                <FormHelperText><Typography variant="caption">Average ambient shift</Typography></FormHelperText>
                              </div>
                              <br/>
                              <div height={30} p={1} className={classes.native}>
                                  <NativeSelect name="selTimeVar" value={stateVar.selTimeVar} onChange={handleChange} className={classes.select}>
                                    <option > Please select</option>
                                    { 
                                      drawState.Duration.map(o => (
                                          <option key={o} >{o}</option>
                                      ))
                                    }
                                  </NativeSelect>
                                  <FormHelperText><Typography variant="caption">Overload duration</Typography></FormHelperText>
                              </div>
                              <br/>
                              <Button className= {classes.submitButton} disabled={submitVar} variant="outlined" onClick= {handleSubmit} >SUBMIT</Button>
                        </FormControl>
                        <Snackbar open={openSnack.open} autoHideDuration={6000} onClose={handleSnackClose}>
                              <Alert onClose={handleSnackClose} severity="error">
                                {openSnack.Message}!!!
                              </Alert>
                        </Snackbar>
                      </CardBody>
                  </Card>   
                </Drawer>
                {/* Region :  The Right hand panel  */}
              </Box>
            </Box>
          </CardHeader>
            <CardBody className={classes.CardBody}>
              <div>
                <Toolbar style={{ overflow: 'hidden', top:'-10px', left:'0px' }}>
                  <ThemeProvider theme={baseTheme}>
                    <SerratedTabs style={{ alignSelf: 'flex-end' }}
                      tabs={[{ label: 'DASHBOARD'},{ label: 'DATASHEET'}, { label: 'LOAD PROFILES'}, { label: 'SCENARIO DESIGN'}, { label: 'RESULTS'}]}
                      tabStyle={{ bgColor: '#4486a3', selectedBgColor: '#ffffff' }} tabProps={{ disableRipple: true }} value={value} onChange={(e, i) => setValue(i)}/>
                  </ThemeProvider>
                </Toolbar> 
                <TabPanel value={value} index={0}>
                    <div className={classes.root}>
                      <div className={classes.Comment}>
                        <LandingBoard Xfrmer = {dprops} NamePlate= {ieeeG7Iec.ieeeNamePlate} ThermalResults={ieeeG7Iec.ieeeThermal} 
                                                        Diagnostics = {ieeeG7Iec.ieeeDiagnostics} condVar = {condVar} />
                      </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className={classes.root}>
                      <div className={classes.Comment}>
                        <DataSheetTable Xfrmer = {dprops} dataSheet = {state.dataSheet} onDataDictionary = {onDataDictionary} userData={props.userData} SaveImages ={SaveImages}/>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className={classes.root}>
                      <div className={classes.Comment}>
                        <ProfileDesign Xfrmer = {dprops} profileList = {state.lProfileList} historyProfile = {state.lProfiles} onLoadProfiles = {onLoadProfiles} 
                        onRemoveProfile = {onRemoveProfile} SaveImages ={SaveImages}/>  
                    </div>
                  </div>
                  </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className={classes.root}> 
                      <div className={classes.Comment}>
                        <Scenario Xfrmer = {dprops} profiles ={state.lProfiles} profileList = {state.lProfileList} 
                              loadScenarios = {state.loadCases}  distroList ={ state.lDistro} loadStandards = {state.loadStds} tapNbr = {tapNbr}
                              onScenario ={onScenario} onLoadingCases = {onLoadingCases} onDistribution = {onDistribution} onLoadingStd = {onLoadingStd}/> 
                      </div>
                  </div>   
                </TabPanel>
                <TabPanel value={value} index={4}>
                      <LoadingCurves Xfrmer= {dprops} NamePlate= {ieeeG7Iec.ieeeNamePlate} ThermalResults={ieeeG7Iec.ieeeThermal}  condVar = {condVar} SaveImages ={SaveImages}/>
                </TabPanel>        
              </div>
          </CardBody>
        </Card>
      </ResponsiveContainer>
    </div>
    );
}
