//import 'date-fns';
import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Grid, Typography, Box, Tabs, Tab} from '@material-ui/core';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { isEmpty} from 'components/MainPanel/Utilities.jsx';
import PreviewPanel from './PreviewPanel';
import LoadingCase from './LoadingCase';
import LoadingStandard from './LoadingStandard';
import Distribution from './Distribution';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    borderRadius: "0px",
    minHeight: "100%"
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, tabvalue, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={tabvalue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabvalue: PropTypes.any.isRequired,
};


export default function Scenario(props) { 
  const classes                       = useStyles();
  const [tabvalue, setTabValue]       = useState(0);
  const [state, setState]             = useState({
  Scenario :[
       { Variable: 'sim_ltype', Description: 'Loading Scenario Name', Value: '24-h Normal', Unit: '-' },
       { Variable: 'sim_hstthresh', Description: 'Hot spot temperature limit', Value: '120', Unit: 'Celsius' },
       { Variable: 'sim_topthresh', Description: 'Top oil temperature limit', Value: '110', Unit: 'Celsius'  },
       { Variable: 'sim_bubthresh', Description: 'Bubbling temperature limit', Value: '105', Unit: 'Celsius'  },
       { Variable: 'sim_lolthresh', Description: 'Permissible insulation loss of life rate per cycle', Value: '650', Unit: 'Hours' },
       { Variable: 'sim_pulthresh', Description: 'Permissible load, p.u of maximum nameplate rating', Value: '1.3', Unit: 'p.u' },     
       { Variable: 'sim_cpwthresh', Description: 'Cooling power limit', Value: '1.8', Unit: '%' },
       { Variable: 'sim_inslife', Description: 'Insulation life expectancy', Value: '65000', Unit: 'Hours' },
       { Variable: 'sim_gcoil', Description: 'Gas content in oil', Value: '10', Unit: '%' },
       { Variable: 'sim_imois', Description: 'Average insulation moisture content', Value: '1.8', Unit: '%' },
      //  { Variable: 'sim_hspress', Description: 'Gas pressure at hot spot', Value: '13', Unit: 'mbar' },
       { Variable: 'sim_oxygen', Description: 'Average oxygen level', Value: '1.8', Unit: 'ppm' },
      //  { Variable: 'sim_hstacc', Description: 'optimization accuracy', Value: '0.01', Unit: '-' },

       { Variable: 'sim_tapPosition', Description: 'Selected Tap Position', Value: '-1', Unit: '-' },
       { Variable: 'sim_tapAmps', Description: 'Ampacity, p.u. of the maximum nameplate rating', Value: '0.01', Unit: 'p.u.' }
   ],
   ScenarioList : props.loadScenarios,
   distroList : props.distroList,
   LoadingStds: props.loadStandards,
   Distro: {}, 
  });

  let loadScenarios     = props.loadScenarios;
  let distroList        = props.distroList;
  let loadStandards     = props.loadStandards;
  let profileList       = props.profileList;


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);}

  const handleScenarioList = (ScenaList) => {
    let loadCases = [];
    setState({ ...state, ScenarioList: ScenaList});
    ScenaList.map((item, key) =>
    {
      loadCases.push({ xfrmId:props.Xfrmer.XfrmName, ...item });
    })
    props.onLoadingCases(loadCases);
  }

  const handleDistroList = (DistroList) => {
    let distro = [];
    setState({ ...state, DistroList: DistroList})
    DistroList.map((item, key) => {
      return distro.push ({xfrmId:props.Xfrmer.XfrmName, ...item});
    })
    props.onDistribution(distro);
  }

  const handleStdChange = (LoadingStds) => {
    let loadStds = [];
    setState({ ...state, LoadingStds: LoadingStds})
    LoadingStds.map((item, key) => {
      return loadStds.push({xfrmId:props.Xfrmer.XfrmName, ...item})
    })
    props.onLoadingStd(loadStds);
  }

  const handleChart = (distro) => {    
    setState({ ...state, Distro: distro});
  }

  useEffect(() =>
  {
    if(!isEmpty(state.LoadingStds) && !isEmpty(state.DistroList) && !isEmpty(state.ScenarioList))
    {
      props.onScenario();
    }
  }, [state.LoadingStds,state.DistroList, state.ScenarioList]);

  return ( 
      <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <Card className={classes.card}>
              <CardHeader className={classes.cardheader}>  <Typography variant="subtitle2" display="inline"> SCENARIOS DEFINITION </Typography> </CardHeader>
              <CardBody className={classes.CardBody}> */}
                <AntTabs value={tabvalue} onChange={handleTabChange} aria-label="ant example">
                  <AntTab label="Loading constraints" />
                  <AntTab label="Performance Factors" />
                  <AntTab label="Thermal Models" />
                </AntTabs>
                <TabPanel tabvalue={tabvalue} index={0}> 
                  <LoadingCase XfrmName={props.XfrmName} tapNbr = {props.tapNbr} Scenario = {state.Scenario} ScenarioList = {loadScenarios}  handleScenarioList= {handleScenarioList}/>
                </TabPanel>
                <TabPanel tabvalue={tabvalue} index={1}>
                  <Distribution XfrmName={props.XfrmName} profileList = {profileList} DistroList = {distroList} handleDistroList = {handleDistroList} handleChart={handleChart} />
                </TabPanel>
                <TabPanel tabvalue={tabvalue} index={2}>
                  <LoadingStandard XfrmName={props.XfrmName} LoadingStds = {loadStandards} handleStdChange = {handleStdChange} />
                </TabPanel>
              {/* </CardBody>
            </Card>  */}
          </Grid>
          <Grid item xs={6}>
            {/* <Card className={classes.card}>
              <CardHeader className={classes.cardheader}>  <Typography variant="subtitle2" display="inline"> SCENARIOS PREVIEW </Typography> </CardHeader>
                <CardBody className={classes.CardBody}>  */}
                    <PreviewPanel XfrmName = {props.XfrmName}  Distribution = { state.Distro } profiles ={props.profiles}/>
                {/* </CardBody>      
            </Card>  */}
          </Grid>
      </Grid>
  );
}