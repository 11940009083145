import React, {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import InfoIcon from '@material-ui/icons/Info';
// ===============For DropZone and AWS Upload ================
import {DropzoneArea} from 'material-ui-dropzone';
// import PropTypes from "prop-types";
// import DataSheetForm from './DataSheetForm.jsx';
// import ScenarioForm from './ScenarioForm.jsx';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import {Divider,  Grid, Typography} from '@material-ui/core';
import FFTRstudy from 'assets/img/XfrmItems/FFTR-study.png';
import LoadProVSTime from 'assets/img/XfrmItems/LoadProVSTime.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  layout: {
    width: 'auto',
  //   border: "1px solid",
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
  //     width: 700,
  //     marginLeft: 'auto',
  //     marginRight: 'auto',
  // }
},

  paper: {
    marginTop: theme.spacing(-2),
    width: '100%',
    overflowX: 'auto', 
    marginBottom: theme.spacing(2),
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
    height : '95vh'
  },
  
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6"
  },

  infoArea :
  {  
    height: "200px", 
    marginTop: "50%", 
    marginLeft: "20px", 
    marginRight:"20px", 
    padding:"10px", 
    border: "1px", 
    backgroundColor: "#FFFFFF"
  },

  dropzoneArea :{
    // marginTop: "5%",
    // marginRight: 'auto',
    // marginLeft: 'auto',
    backgroundColor: "#FFFFFF",
    fontSize: "10px",
    width: 'auto',
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
 
  textField: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
}));



const Option3 = 'Set the restrictions or limits for the operational parameters, be it in  Normal or Emergency loading conditions, by answering to the best of your knowledge the'+
'following questions. Limits set for the operational parametrers must be compliant with the  established IEEE/IEC loading guides';

const Option2 = () =>
{ 
  const classes   = useStyles();
  return (
    <div className={classes.Comment}>
      <Typography variant="body2">
      {<InfoIcon/>} <strong>Upload your heat-run factory final test results and your transformer nameplate </strong>
      </Typography>
        <Divider/>
        <Typography variant="caption" gutterBottom>
          By dropping your factory final test results/ nameplate, you mandate us to extract the relevant data to build on your behalf the transformer datasheet. 
          The resulting datasheet will be included in the final loading study report. The sketch of extracted inputs follows the IEEE C57.2012 and 
          IEC 60354 loading guides requirements on inputs for transformers loading and thermal assessments.
        </Typography>  
    </div>
  )
}

const Option4 = () =>
{ 
  const classes   = useStyles();
  return (
    <div className={classes.Comment}>
      <Typography variant="body2">
      {<InfoIcon/>} <strong>Upload a csv file that respect the inputs format: Datetime, Load[p.u], Ambient[℃] </strong>
      </Typography>
        <Divider/>
        <Typography variant="caption" gutterBottom>
          <ul>
            <li> 
              Load : per unit (p.u) load to be applied to the transformer, during a single 24 hour cycle or a train of 24 hour cycles. 
            </li>
            <li>
              Ambient : ambient temperature at which the transformer is expected to operate. This field can be
              omitted if choice is made to retrieve the ambient temperature from the weather forecast.
            </li>
          </ul>
        </Typography>           
    </div>
  )
}

export default function DataForm(props) {
  const classes                     = useStyles();
  const [value, setValue]           = useState('Option 1');
  const [tabValue, setTabValue]     = useState(0);
  const [state, setState]           = useState({
    Scenario :[
         { Variable: 'sim_ltype', Description: 'Loading scenario name', Value: '24-h Normal', Unit: '-' },
         { Variable: 'sim_hstthresh', Description: 'What is the Hot spot temperature limit?', Value: '110', Unit: 'Celsius' },
         { Variable: 'sim_topthresh', Description: 'What is the Top oil temperature limit?', Value: '105', Unit: 'Celsius'  },
         { Variable: 'sim_bubthresh', Description: 'Is there a bubbling? if yes what is the  Bubbling temperature limit?', Value: '105', Unit: 'Celsius'  },
         { Variable: 'sim_lolthresh', Description: 'What is the limit of insulation loss of life ?', Value: '0.037', Unit: 'Hours' },
         { Variable: 'sim_pulthresh', Description: 'Permissible load, p.u of maximum nameplate rating', Value: '1', Unit: 'p.u' },     
         { Variable: 'sim_cpwthresh', Description: 'Cooling power limit', Value: '1.8', Unit: '%' },
         { Variable: 'sim_inslife', Description: 'Insulation life expectancy', Value: '65000', Unit: 'Hours' },
         { Variable: 'sim_gcoil', Description: 'Gas content in oil', Value: '10', Unit: '%' },
         { Variable: 'sim_imois', Description: 'Average insulation moisture content', Value: '1.8', Unit: '%' },
         { Variable: 'sim_hspress', Description: 'Gas pressure at hot spot', Value: '13', Unit: 'mbar' },
         { Variable: 'sim_oxygen', Description: 'Average oxygen level', Value: '1.8', Unit: 'ppm' },
        //  { Variable: 'sim_hstacc', Description: 'optimization accuracy', Value: '0.01', Unit: '-' },
       // How much in average does the ambient temperature varies from the normal?
     ],
     ScenarioList : props.loadScenarios,
     distroList : props.distroList,
     LoadingStds: props.loadStandards,
     Distro: {}, 
  });


  const handleFFTRChange = (files) => { 
    props.createFFTRFiles(files);
  }

  const handleProfileChange = (files) => { 
    props.createLoadFiles(files);
  }


  return (
    <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardheader}>
                <Option2/>
              </CardHeader>
              <CardBody className={classes.CardBody}>  
                <div className={classes.layout}>
                  <img src={FFTRstudy}  alt = "Transformer Active Part Representation" />  
                </div> 
                <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 3, width:'50%', borderColor : '#4486a3'}}/>       
                <DropzoneArea acceptedFiles={['.pdf', '.jpeg', '.png', '.doc', '.docx']} maxFileSize={10000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop your heat-run/ nameplate/ factory
                              final tests results files here (accepted files are .pdf, .jpeg, .png, .doc, docx) here or click to select and upload.' 
                              onChange={handleFFTRChange} showFileNames="true" showAlerts={true} filesLimit={5} dropzoneClass={classes.dropzoneArea} />            
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={6}>
          <Card className={classes.card}>
              <CardHeader className={classes.cardheader}>
                <Option4/>
              </CardHeader>
              <CardBody className={classes.CardBody}>  
                <div className={classes.layout}>
                  <img src={LoadProVSTime}  alt = "Transformer Active Part Representation" />   
                </div>  
                <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 3, width:'50%', borderColor : '#4486a3'}}/>     
                <DropzoneArea acceptedFiles={['.csv', '.xlsx', '.xls', '.txt']} maxFileSize={10000000} showPreviewsInDropzone={true} dropzoneText='Drag and drop your load
                    profiles files here (accepted files are .csv, .xlsx, .xls, .txt) here or click to select and upload.' 
                            onChange={handleProfileChange} showFileNames="true" showAlerts={true} filesLimit={5} dropzoneClass={classes.dropzoneArea} />     
                </CardBody>
              </Card>
          </Grid>
        </Grid>
     </div>
  );
}