import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import PaymentForm from './PaymentForm';
import Review from './Review';
import DataForm from './DataForm';
import {Box, Divider} from '@material-ui/core';
import CreateForm from './CreateForm';
import { isEmpty } from 'components/MainPanel/Utilities';
import {Storage } from 'aws-amplify';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';

var nodemailer = require('nodemailer');
var sesTransport = require('nodemailer-ses-transport');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '20px',
    borderRadius: '0px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardheader:{
    // padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
    backgroundColor: "#f6f6f6"
  },
  card:{
    borderRadius: "0px",
    // height: '100vh',
    minHeight:'100vh',
  },
  CardBody:{
    padding: "1px",
    paddingLeft: "-10px",
  },

  stepper: {
    padding: theme.spacing(1, 0, 1),
    borderBottom: "0px",
    backgroundColor: "#f6f6f6"
  },
  button: {
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  },
  Comment: {
      display: 'flex',
    justifyContent: 'flex-center',
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "0px",
    marginLeft: "300px",
    marginRight: "300px",
    backgroundColor : "#f6f6f6"
  },
    facComment: {
    padding: "5px",
    backgroundColor : "#f6f6f6",
        marginTop: "100px",
  },

}));

const FinalForm =() =>
{
  const classes = useStyles();
    return (
      <div className = {classes.layout}>
        <div className={classes.facComment}>
          <Typography variant="body2"> {<InfoIcon/>} <strong>Thank you for your Business !!! </strong> </Typography>
          <Divider/>                
          <Typography variant="body2">
                      Your order number is #2001539. We have emailed your order confirmation, and will
                      send you an update when your order has been completed.
                      If there is a need for clarifications, a member of our team will contact you.                    
          </Typography>
        </div>
      </div>
    )
}

export default function MainForm(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFile] = useState([]);
  const [filesPro, setProFile] = useState([]);
  const [errlabel, setErrLabel] = useState('');
  const [state, setState] = useState({
      newXrmer:{ },
      dataSheet: [],
      loadCases: [],
      lCooling:  [],
      loadStds:  [],
      lDistro:   [],
      LoadFiles:[],
      // OptionFiles:[], 
      selectedOption:'', 
  });


  let username = props.userData.username;

  const steps = ['Transformer Creation', 'Data Checklist', 'Review and Cost Estimate', 'Place an Order'];

  const createXfrmer = (nXfrmer) => {
      setState({...state, newXrmer: nXfrmer});
  };

  const createFFTRFiles = (fFTRFiles) => {
    setFile([files, ...fFTRFiles]);
  }

  const createLoadFiles = (loadFiles) => {
    setProFile([filesPro, ...loadFiles]);
  }

  // const createOption = (selectedOption) => {
  //   setState({...state, selectedOption: selectedOption});
  // }

  // const createDataSheet = (DataSheet) => {
  //   setState({...state, dataSheet: DataSheet});
  // }

  // const createCooling = (CoolingData) => {
  //   setState({...state, lCooling: CoolingData}); 
  // }

  // const createScenario = (LoadingCases) => {
  //   setState({ ...state, loadCases: LoadingCases}); 
  // }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CreateForm createXfrmer={createXfrmer}/>  
      case 1:
        return <DataForm Xfrmer ={state.newXrmer} dataSheet = {state.dataSheet} createFFTRFiles = {createFFTRFiles} createLoadFiles = {createLoadFiles}/>  
      case 2:      
        return <Review Xfrmer = {state.newXrmer} FFTRfiles ={files} LoadFiles ={filesPro}/>;
      case 3: 
        return <PaymentForm />
      case 4: 
        return <FinalForm />
      default:
         setActiveStep(0);
    }
  }

  const handleNext = () => 
  {
    switch(activeStep)
    {
      case 0:
        {
          if(!isEmpty(state.newXrmer)) 
          {              
            let newXrmer = state.newXrmer;
            if(isEmpty(newXrmer.XfrmName) || newXrmer.XfrmName ==='Template') 
            {
              alert ("Please enter a transformer name.");
              return;
            }
            if(newXrmer.XType ==='Select') 
            {
              alert ("Please enter a transformer Type.");
              return;
            }
            if(newXrmer.XPhase ==='Select') 
            {
              alert ("Please select the number of phases.");
              return;
            }
            if(newXrmer.Vintage === 'Select') 
            {
              alert ("Please enter the years of services.");
              return;
            }
            if(newXrmer.VClass ==='Select') 
            {
              alert ("Please specify a transformer Voltage class.");
              return;
            }
            if(newXrmer.SolidInsul ==='Select') 
            {
              alert ("Please specify the type of solid insulation.");
              return;
            }
            if(newXrmer.LiquidInsul ==='Select') 
            {
              alert ("Please specify the type of liquid insulation.");
              return;
            }
        
            if(newXrmer.Stage1 ==='Select' && newXrmer.Stage2 ==='Select' && newXrmer.Stage3 ==='Select') 
            {
              alert ("You need to specify at least one cooling stage.");
              return;
            }
            if(newXrmer.Rating1 === 0 && newXrmer.Rating2 === 0 && newXrmer.Rating3 === 0) 
            {
              alert ("The number of cooling stages must match the associated ratings.");
              return;
            }
            if(
                 ((newXrmer.Stage1 === 'Select' && newXrmer.Rating1 !==0) || (newXrmer.Stage1!== 'Select' && newXrmer.Rating1 === 0))
              || ((newXrmer.Stage2 === 'Select' && newXrmer.Rating2 !==0) || (newXrmer.Stage2!== 'Select' && newXrmer.Rating2 === 0))
              || ((newXrmer.Stage3 === 'Select' && newXrmer.Rating3 !==0) || (newXrmer.Stage3!== 'Select' && newXrmer.Rating3 === 0))
            ) 
            {
              alert ("A stage must be associated with its corresponding rating, and vice-versa.");
              return;
            }      
          }
          else
          {
            alert("You have not saved your transformer yet.");
            return;
          }
          break;
        }
      case 1:
        {
          if(isEmpty(files) ) 
          {
            alert("You need to select and complete one of the proposed options : Option 1 or Option 2.")
            return;
          }
          break;
        }
        default: break;
    }
    if(activeStep === steps.length - 1) 
    { handleSubmit();}
    // else
    // { 
      setActiveStep(activeStep + 1);

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  async function  handleSubmit() { 
    // ===== Uploading user files =============================================
    let Payload = { Xfrmer: state.newXrmer};
    let XfrmrName = state.newXrmer.XfrmName;
    if(!isEmpty(Payload))
    {
      await Storage.put(`${username}/${XfrmrName}/FFTR.json`, JSON.stringify(Payload))
          .then (result => console.log(result))
          .catch(err => console.log(err));
    }

    if(!isEmpty(files))
    {
      await files.map(p => {
        Storage.put(`${username}/${XfrmrName}/${p.name}`, p)
        .then (result => console.log(result))
        .catch(err => console.log(err));
      });
    }
    if(!isEmpty(filesPro))
    {
      await files.map(p => {
        Storage.put(`${username}/${XfrmrName}/${p.name}`, p)
        .then (result => console.log(result))
        .catch(err => console.log(err));
      });
    }

    // ============Sending an email acknowledging the reviewed files ============
    function callback(error, info) {
      if (error) {
        console.log(error);
      } else {
        console.log('Message sent: ' + info.response);
      }
    }
    
    let xfrmer = state.newXrmer;
    var mailOptions = {
      from: 'contact@transformer-analytics.com',
      to: 'info2metlab@gmail.com', 
      bcc:'contact@transformer-analytics.com',
      subject: `Your [${xfrmer.XfrmName}] Loading Study Order`,
      // text: 'This is some text',
      html: `<div> Dear <b>${username}</b> <br/><br/><div>This is a notification to acknowledge your loading study order for transformer:<br/><br/>
      <b>${xfrmer.XfrmName}  ${xfrmer.Rating1 || ''}MVA/${xfrmer.Rating2 || ''}MVA/${xfrmer.Rating3 || ''}MVA</b><br/><br/>
      It will take us up to <b>(3)</b>open business days to complete your request. <br/><br/>Upon completion the loading study will be sent to the email address you provided when signing up.<br/>
      <br/><br/>Thank you for choosing HVSS.<br/>Have a nice day.<br/><br/>
      <div>The HVSS Team</div><br/>
      </div>`,
    };

    // Send e-mail using AWS SES
    var sesTransporter = nodemailer.createTransport(sesTransport({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION
    }));
    sesTransporter.sendMail(mailOptions, callback);
  }

  return (
      <div className={classes.root}> 
        <Card className={classes.card}>
          <CardHeader className={classes.cardheader}  >
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {
                steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardHeader>
          <CardBody className={classes.CardBody}>
            <div className={classes.Comment}>
              <Box flexGrow={1}> { activeStep !== 0 && ( <Button onClick={handleBack} className={classes.button}> Back </Button> )} </Box>
              <Box><Button variant="contained" color="primary" onClick={handleNext} className={classes.button} > {activeStep === steps.length - 1 ? 'Place order' : 'Next'} </Button></Box> 
            </div>
              <React.Fragment>
                {
                  getStepContent(activeStep)
                }
              </React.Fragment>
          </CardBody>
        </Card>
      </div>

  );
}