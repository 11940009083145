//import 'date-fns';
import React, {useState, useCallback, useEffect} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PageviewIcon from '@material-ui/icons/Pageview';
import SendIcon from '@material-ui/icons/Send';
import {Divider, Box, Typography, NativeSelect, Grid, ButtonGroup, FormHelperText, FormControl, InputLabel, TextField, Button, InputAdornment} from "@material-ui/core";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import { isEmpty, printResult} from './Utilities.jsx';
import InfoIcon from '@material-ui/icons/Info';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import NumericCellEditor from "./NumericCellEditor.jsx";
import Pagination from '@material-ui/lab/Pagination';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import { Card, CardHeader, CardBody, CardText} from 'reactstrap';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
    border: "0px",
    color:"#FFFFFF",
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#eceff1"
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },

  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    // borderLeft: "0px solid",
    backgroundColor : "#f6f6f6"
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60%',
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },

  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  cardBody:{
    padding: "1px",
    // paddingLeft: "-10px",
    backgroundColor : "#FFFFE0",
    // Heigth: "100%"
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


function TabPanel(props) {
  const { children, tabvalue, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={tabvalue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tabvalue: PropTypes.any.isRequired,
};






export default function Distribution (props)
{
  const classes = useStyles();
  const harmonicRows= [
    { harmId: 1, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 3, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 5, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 7, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 9, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 11, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 13, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 15, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 17, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 19, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 21, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 23, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
    { harmId: 25, stg1Amps: 0.0, stg2Amps: 0.0, stg3Amps : 0.0 },
  ];

  const [gridDApi, setGridDApi]       = useState();
  const [state, setState]             = useState(
    { 
      Ambient: { ambMean:10, ambStd: 2 }, 
      ExpLoad: { puMean: 0.1, puStd:0.0001 }, 
      Harmonics : harmonicRows, 
      PlugEV : { pevVar : '', pevRate: 2.0, pevLoad: 0.001, pevSDate : new Date(), pevEDate : new Date() }
    }
  );
  const [pevArgs, setPevArgs]         = useState({ pevVar : '', pevRate: 2.0, pevLoad: 0.001, pevSDate : new Date(), pevEDate : new Date() });
  const [selProfileVar, setProfilVar] = useState('none');
  const [puStd, setPuStdValue]        = useState(0.0001);
  const [puMean, setPuMean]           = useState(0.1);
  const [ambMean, setAmbMean]         = useState(10);
  const [ambStd, setAmbStd]           = useState(2);
  const [selPevVar, setPevVar]        = useState('UC');
  const [expanded, setExpanded]       = React.useState('panel1');
  
  let DistroList                      = props.DistroList;
  let profileList                     = props.profileList;
  let pfOptions                       = [<option label="" value={"select"}> Please select </option>];


  useEffect(() => 
  {
    if(isEmpty(DistroList)) {
      if(gridDApi !== undefined) {
        gridDApi.api.setRowData([]);
      }
    }
  }, [isEmpty(DistroList)]);


  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  // =============================== Harmonics Settings ====================
  const harmonicColumns = [
    { headerName: 'Harmonic No.', field: 'harmId',  width: 120},
    { headerName: 'Amps @ Stage1', field: 'stg1Amps', width: 120, editable: true, 
      cellEditor:'numericCellEditor' },
    { headerName: 'Amps @ Stage2', field: 'stg2Amps',width: 120, editable: true, 
      cellEditor:'numericCellEditor' },
    { headerName: 'Amps @ Stage3', field: 'stg3Amps',width: 120, editable: true, 
      cellEditor:'numericCellEditor' },
  ];

  const onHarmCellValueChanged = params => {
    var colId = params.column.getId();
    if (colId === "Value") {
      params.node.setDataValue(colId, params.data.Value); 
      return;
    }
  }

  const SubmitHarmonics = () =>
  {
    setState({...state, Harmonics: harmonicRows});
  }
  // ======================= PEV Charging Methods ===========================
  const pevOptions = [
    { label: 'Controlled Charging',value: 'CC'},
    { label: 'Uncontrolled Charging', value: 'UC' }
  ];

  const handlePevStrategy = (event) => {
    console.log ("Selected Charging Method :" + event.target.value);
    setPevVar(event.target.value);
    setPevArgs({...pevArgs, pevVar: event.target.value });
  }

  const handlePEVChange = (event) => { setPevArgs({...pevArgs, [event.target.name]: event.target.value }); };

  const getPEVStrategy = (pevVar) =>
  {
    switch (pevVar)
    {
      case 'CC':
        return (
            <Grid container spacing={2} justify="space-around">
              <Grid item>
                <TextField id="time" label="start clock" type="time" defaultValue="12:00" className={classes.textField}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                />
              </Grid>
              <Grid item>
                <TextField id="time" label="end clock" type="time" defaultValue="06:00" className={classes.textField}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                />
              </Grid>
              <Grid item>
                <TextField id="amLoad" label="load budget" type="number" defaultValue='0.00' className={classes.textField}
                    InputLabelProps={{ shrink: true, }}
                    inputProps={{                      
                      step: 0.01, // 0.01 p.u
                    }}
                  InputProps={{ startAdornment: <InputAdornment position="start">p.u</InputAdornment>}}
                />
              </Grid>
            </Grid>
        );
      case 'UC':
        return (
          <div>
            <Grid container spacing={2} justify="space-around">
                <Grid item xs ={6}>
                  <FormControl className={classes.formControl}>
                    <TextField id="pevRate" label=" Plug-In Rate" type="number" defaultValue='2' className={classes.textField}
                        value={pevArgs.pevRate}
                        onChange={handlePEVChange} type="number"
                        InputLabelProps={{ shrink: true, }}
                        inputProps={{                      
                          step: 0.5, // 5 min
                        }}
                      InputProps={{ startAdornment: <InputAdornment position="start">Vehicle/h</InputAdornment>}}
                    />
                    <FormHelperText>Vehicles plug-in rate </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs ={6}>
                  <FormControl className={classes.formControl}>
                    <TextField id="pevLoad" label="Maximum p.u. Load" type="number" defaultValue='0.001' className={classes.textField}
                        value={pevArgs.pevLoad}
                        onChange={handlePEVChange} type="number"
                        InputLabelProps={{ shrink: true, }}
                        inputProps={{                      
                          step: 0.01, // 5 min
                        }}
                      InputProps={{ startAdornment: <InputAdornment position="start">kWh</InputAdornment>}}
                    />
                    <FormHelperText>Maximum load per vehicle</FormHelperText>
                  </FormControl>
                </Grid>
            </Grid>
            <Grid container justify="space-around">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs ={6}>
                  <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Start Charging period"
                      value={pevArgs.pevSDate}
                      // onChange={handlePevSDateChange}
                      onChange={handlePEVChange}
                      KeyboardButtonProps={{
                        'aria-label': 'start change time',
                      }}
                    />
                </Grid>
                <Grid item xs ={6}>
                    <KeyboardTimePicker
                      margin="normal"
                      id="pevend-picker"
                      label="End Charging Period"
                      value={pevArgs.pevEDate}
                      onChange={handlePEVChange}
                      // onChange={handlePevEDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'end change time',
                      }}
                    />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </div>
        );
      default : break;
    }  
  }

  const SubmitPevDistro = ( ) => { setState({...state, PlugEV: pevArgs }); }

  const DistroColumns= [
    { headerName: 'profileName', field: 'profileName', checkboxSelection: true, headerCheckboxSelection: true, width: 200 },
    { headerName: 'Ambient', field: 'Ambient' },
    { headerName: 'ExpLoad', field: 'ExpLoad' },
    { headerName: 'Harmonics', field: 'Harmonics'},
    { headerName: 'PlugEV', field: 'PlugEV'},
  ];

  const handleProfileChange = (event) => { setProfilVar(event.target.value); }

  const handleAmbMeanChange = (event) => { setAmbMean(event.target.value); }

  const handleAmbStdChange = (event) => { setAmbStd(event.target.value); }

  const handlePUMeanChange = (event) => { setPuMean(event.target.value); }

  const handlePUStdChange = (event) => { setPuStdValue(event.target.value); }

  const AddDistro = () =>
  {     
    let newDistro = { profileName: selProfileVar, Ambient: JSON.stringify(state.Ambient), ExpLoad: JSON.stringify(state.ExpLoad), 
      Harmonics: JSON.stringify(state.Harmonics), PlugEV: JSON.stringify(state.PlugEV) };
    if(DistroList.length > 0 && DistroList.find(item => item.profileName === newDistro.profileName))
    {
        alert('Performance settings already exist for this reference profile !!!');
        return;
    }
    DistroList.push(newDistro);
    props.handleDistroList(DistroList);
    var res = gridDApi.api.updateRowData({ add: [newDistro] });
    // printResult(res);
    // console.log ("List of Distribution..." + JSON.stringify(DistroList));
  }

  const RemoveDistro = () =>
  {
    var selectedData = gridDApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    let selectedRow = selectedData[0].profileName;
    let filteredDistros = DistroList.filter(item => item.profileName !== selectedRow);
    props.handleDistroList(filteredDistros);
    var res = gridDApi.api.updateRowData({ remove: selectedData });
    printResult(res);
  }

  const SubmitAmbDistro =() => { setState({...state, Ambient: {ambMean:ambMean, ambStd:ambStd}}); }
  const SubmitExpLoad =() => { setState({...state, ExpLoad: {puMean:puMean, puStd:puStd}}); }

  if(!isEmpty(profileList))
  {
    pfOptions.push(profileList.map(o => (
        <option label={o.profileName} value={o.profileName}> {o.profileName} </option>
    )));
  }

  const onGridAReady = params => {
    params.api.sizeColumnsToFit();
  }
   
  const onViewingChart = ()=>
  {  
    let newDistro = { profileName: selProfileVar, Ambient: state.Ambient, ExpLoad: state.ExpLoad, Harmonics: state.Harmonics, PlugEV: state.PlugEV };
    props.handleChart(newDistro);
  }
  
  const onGridDistroReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridDApi({ api, columnApi });
    params.api.sizeColumnsToFit();
    },[]
  );

  const handleExChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (            
    <div>
      <Card className={classes.card}>
        <CardHeader>  <Typography variant="subtitle2" display="inline"> DEFINE LOAD IMPACTING FACTORS</Typography> </CardHeader>
          <CardBody>
          <Grid container spacing= {2} >
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="uncontrolled-native">Select a profile name</InputLabel>
                    <NativeSelect value={selProfileVar} onChange={handleProfileChange}>
                      {pfOptions}
                    </NativeSelect>
                  <FormHelperText>From previously defined load profiles</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleExChange('panel1')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
                      <div className={classes.facComment}>
                          <Typography variant="subtitle2"> Ambient Temperature Rise Variation</Typography>
                          <Divider/>
                          <Typography variant="caption" gutterBottom>
                            The possibility that θa rise may impact distribution transformer life through dielectric degradation
                          </Typography>           
                      </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form className ={classes.form}>
                          <Grid container spacing ={2} justify="space-around">
                            <Grid item xs={12}>
                              <TextField label="Mean Ambient Rise µ(∆θa)" value={ambMean}  onChange={handleAmbMeanChange} type="number" className={classes.textField} 
                                  InputLabelProps={{ shrink: true }} margin="normal"
                                  InputProps={{ startAdornment: <InputAdornment position="start">Kelvin</InputAdornment>}} />                                    
                            </Grid>
                            <Grid item xs={12}>
                                  <TextField label="Standard Deviation σ(∆θa)" value={ambStd} onChange={handleAmbStdChange} type="number" className={classes.textField}
                                    InputLabelProps={{ shrink: true }} margin="normal" />
                            </Grid>
                            <Grid item xs={12}>
                              <Button size="small" className={classes.button} startIcon={<SendIcon />} aria-label="exp-submit" onClick={SubmitAmbDistro} width='100%'> SUBMIT </Button> 
                            </Grid>
                          </Grid>
                    </form>
                  </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleExChange('panel2')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
                    <div className={classes.facComment}>
                      <Typography variant="subtitle2"> Experimental load profiles </Typography>
                        <Divider/>
                        <Typography variant="caption" gutterBottom>
                            Study of different loads shift that might impact the θh and LOL of the transformer. They are of experimental nature.
                        </Typography>           
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form className ={classes.form}>
                      <Grid container spacing ={2} justify="space-around">
                              <Grid item xs={12}>
                                <TextField label="Mean p.u. Load µ(∆pu)" value={puMean}  onChange={handlePUMeanChange} type="number" className={classes.textField}  margin="normal" />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField label="Standard Deviation σ(∆pu)" value={puStd} onChange={handlePUStdChange} type="number" className={classes.textField} margin="normal" />
                              </Grid>
                              <Grid item xs={12}>
                                <Button size="small" startIcon={<SendIcon />} className={classes.button} aria-label="exp-submit" onClick={SubmitExpLoad} width='100%' >SUBMIT</Button> 
                              </Grid>
                      </Grid>
                    </form> 
                  </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleExChange('panel3')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
                    <div className={classes.facComment}>
                      <Typography variant="subtitle2"> {<InfoIcon/>} Harmonics Currents Inputs </Typography>
                      <Divider/>
                      <Typography variant="caption" gutterBottom>
                        Studies focusing on the effect of harmonics on the transformer’s Loss-of-Life. 
                      </Typography>           
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing ={2} justify="space-around">
                              <Grid item xs={12}>
                                  <div  className="ag-theme-balham" style={{ width: "90%", height:"200px"}} >
                                    <AgGridReact
                                        modules={AllCommunityModules}
                                        columnDefs={harmonicColumns}
                                        rowData={harmonicRows}
                                        onGridReady={onGridAReady}
                                        // domLayout="autoHeight"
                                        animateRows={true}
                                        onCellValueChanged={onHarmCellValueChanged}
                                        frameworkComponents={frameworkComponents}
                                  />
                              </div>                          
                            </Grid>
                            <Grid item xs={12}>
                              <Button size="small" startIcon={<SendIcon />} className={classes.button} aria-label="harm-submit" onClick={SubmitHarmonics} width='100%' >SUBMIT</Button>           
                            </Grid>
                    </Grid>
                  </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleExChange('panel4')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
                      <div className={classes.facComment}>
                        <Typography variant="subtitle2"> {<InfoIcon/>} Define Electric Vehicle Load </Typography>
                        <Divider/>
                        <Typography variant="caption" gutterBottom>
                            Studies to evaluate if the transformer insulation temperature could or not withstand the widespread adoption of EVs.
                        </Typography>           
                      </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <form className ={classes.form}>      
                              <Grid container spacing={2}>
                                <Grid item xs ={12}>
                                  <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="uncontrolled-native">Select a Charging Method</InputLabel>
                                      <NativeSelect value={selPevVar} onChange={handlePevStrategy} className={classes.textField}>
                                      { 
                                        pevOptions.map(o => (
                                                    <option value={o.value}>{o.label}</option>
                                        ))
                                      }
                                      </NativeSelect>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  { getPEVStrategy(selPevVar)}
                                </Grid>
                                <Grid item xs={12}>
                                  <Button size="small" startIcon={<SendIcon />} className={classes.button} aria-label="pev-submit" onClick={SubmitPevDistro}>SUBMIT</Button> 
                                </Grid>
                            </Grid>
                    </form>
                  </AccordionDetails>
              </Accordion>
              </Grid>
            </Grid>
        </CardBody>
      </Card> 
      <div className ={classes.Comment}>
        <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
          <Button size="small" startIcon={<PageviewIcon />} aria-label="pageview" Tooltip="Preview the impacts of the chosen parameters" onClick={onViewingChart}> Preview </Button>
          <Button size="small" startIcon={<ArrowDownwardIcon />} aria-label="autorenew" onClick={AddDistro}> Add </Button>
          <Button size="small" startIcon={<DeleteForeverIcon />} aria-label="distro-deleteforever" onClick={RemoveDistro}>Discard</Button> 
        </ButtonGroup> 
      </div>
      <Card className={classes.card}>
        <CardHeader>  <Typography variant="subtitle2" display="inline">  LIST OF IMPACTING FACTORS </Typography> </CardHeader>
          <CardBody>
            <div  className="ag-theme-balham" style={{ width: "100%", height:"200px"}} >
              <AgGridReact
                modules={AllCommunityModules}
                columnDefs={DistroColumns}
                rowData={DistroList}
                // domLayout="autoHeight"
                onGridReady={onGridDistroReady}
                frameworkComponents = {frameworkComponents}
                animateRows={true}
              />
          </div>
        </CardBody>
      </Card> 
    </div>                  
  )
}
