import React , {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, makeStyles  } from "@material-ui/styles";
import HeatSheet from "./HeatSheet.jsx";
import UScenario from "./UScenario.jsx"
import UProfiles from "./UProfiles.jsx"
import { createMuiTheme,  Box, Divider, Toolbar} from '@material-ui/core';
import SerratedTabs from 'views/Dashboard/SerratedTabs.jsx';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const baseTheme = createMuiTheme();
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    // marginLeft: theme.spacing(-3),
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
    minHeight: '100vh',
  },
  maincard:{
    borderRadius: "0px",
    minHeight: '100vh',
    minWidth:'inherit',
  },
  CardBody:{
    padding: "1px",
    paddingLeft: "5px",
  },

  Comment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6",
    width: '100%',
  },
  select:{
    width: "200px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: 'Black',
    borderRadius:"0px",
  },
  spinner:{
    borderLeft: "50%",
    borderRight:"50%",
    borderRadius: '0px',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



export default function UDashboard (props) 
{ 
  const classes  = useStyles();
  const [value, setValue]             = useState(0);
  const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error'});
  const [runIt, setRunIt]             = useState(true);
  
  const [state, setState]             = useState({
    Scenario :[
         { Variable: 'sim_ltype', Description: 'Loading Scenario Name', Value: '24-h Normal', Unit: '-' },
         { Variable: 'sim_hstthresh', Description: 'Hot spot temperature limit', Value: '110', Unit: 'Celsius' },
         { Variable: 'sim_topthresh', Description: 'Top oil temperature limit', Value: '105', Unit: 'Celsius'  },
         { Variable: 'sim_bubthresh', Description: 'Bubbling temperature limit', Value: '105', Unit: 'Celsius'  },
         { Variable: 'sim_lolthresh', Description: 'Permissible insulation loss of life rate per cycle', Value: '0.037', Unit: 'Hours' },
         { Variable: 'sim_pulthresh', Description: 'Permissible load, p.u of maximum nameplate rating', Value: '1', Unit: 'p.u' },     
         { Variable: 'sim_cpwthresh', Description: 'Cooling power limit', Value: '1.8', Unit: '%' },
         { Variable: 'sim_inslife', Description: 'Insulation life expectancy', Value: '65000', Unit: 'Hours' },
         { Variable: 'sim_gcoil', Description: 'Gas content in oil', Value: '10', Unit: '%' },
         { Variable: 'sim_imois', Description: 'Average insulation moisture content', Value: '1.8', Unit: '%' },
         { Variable: 'sim_hspress', Description: 'Gas pressure at hot spot', Value: '13', Unit: 'mbar' },
         { Variable: 'sim_oxygen', Description: 'Average oxygen level', Value: '1.8', Unit: 'ppm' },
        //  { Variable: 'sim_hstacc', Description: 'optimization accuracy', Value: '0.01', Unit: '-' },
         { Variable: 'sim_tapPosition', Description: 'Selected Tap Position', Value: '-1', Unit: '-' },
         { Variable: 'sim_tapAmps', Description: 'Ampacity, p.u. of the maximum nameplate rating', Value: '0.01', Unit: 'p.u.' }
     ],
     ScenarioList : props.scenarioList || [],
  });

  const createScenario = (ScenaList) => {
      let loadCases = [];
      setState({ ...state, ScenarioList: ScenaList});
      ScenaList.map((item, key) =>
      {
        loadCases.push({ xfrmId:props.Xfrmer.XfrmName, ...item });
      })
      props.createScenario(loadCases);
  }
  
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpenSnack({...openSnack, open:false});
  };

  return ( 
    <div className={classes.root}>
        <Toolbar style={{ overflow: 'hidden', top:'0px', left:'0px' }}>
          <ThemeProvider theme={baseTheme}>
            <SerratedTabs style={{ alignSelf: 'flex-end' }}
              tabs={[{ label: 'Datasheet'}, { label: 'Load Profiles'}, { label: 'WHAT IFs'}]}
              tabStyle={{ bgColor: '#4486a3', selectedBgColor: '#ffffff' }} tabProps={{ disableRipple: true }} value={value} onChange={(e, i) => setValue(i)}/>
          </ThemeProvider>
        </Toolbar> 
        <TabPanel value={value} index={0}>
          <div className={classes.root}>
              <div className={classes.Comment}>
                <HeatSheet Xfrmer = {props.Xfrmer} createDataSheet = {props.createDataSheet} />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.root}>
            <div className={classes.Comment}>
              <UProfiles Xfrmer = {props.Xfrmer} createProfile = {props.createProfile} profileList = {props.profileList} historyProfile = {props.historyProfile} />  
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.root}>
            <div className={classes.Comment}>
              <UScenario Xfrmer = {props.Xfrmer} Scenario = {state.Scenario} scenarioList = {state.ScenarioList} createScenario = {createScenario} /> 
            </div>
          </div>   
        </TabPanel>       
    </div>
  );
}
