import React, {useState, useEffect}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import {WeatherApiKey, WeatherURL, WeatherIconsURL} from "components/MainPanel/Utilities.jsx";
import TextField from '@material-ui/core/TextField';
import { ButtonGroup } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { Card, CardHeader, CardBody} from 'reactstrap';
var moment = require('moment');

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin:'3px',
        border:'5px'
      },
    card: {
      borderRadius: "0px",
      backgroundColor: '#eceff1',
    },

    formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
    },
    controlBar: {
        padding: "1px",
        borderTopColor: "#d3d3d3",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderBottomColor: "#d3d3d3",
        borderLeft: "0px",
        borderRight:"0px",
        marginTop: "5px",
        marginBottom: "10px",
        backgroundColor : "#f6f6f6"
    },
}));


const DayCard = ({ reading }) =>
{
  const classes = useStyles();
  let newDate = new Date();
  const weekday = reading.dt * 1000
  newDate.setTime(weekday)
  const imgURL = WeatherIconsURL + `${reading.weather[0].icon}.png`
  return (
    <Card className={classes.card}>
        <CardBody>
             <Avatar alt="Load" size="large" src={imgURL} className={classes.avatar} />
             <Typography variant="subtitle2" gutterBottom>
                {moment(newDate).format('dddd')}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {moment(newDate).format('MMMM Do')} 
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                {Math.round(reading.main.temp)} °C
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                {Math.round(reading.main.humidity)} %
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {reading.weather[0].description}
            </Typography>
        </CardBody>
    </Card>
  )
}

export default function WeatherPanel(props) 
{
    var Xfrmer = props.Xfrmer;
    const classes = useStyles();
    const [state, setState]                 = useState({ fullData: [], dailyData: [] });
    const [selLatVar, setLatValue]          = useState(props.Xfrmer.GPSLatitude);
    const [selLongVar, selLongValue]        = useState(props.Xfrmer.GPSLongitude);

    function handleLatChange(event){
      setLatValue(event.target.value);
    }

    function handleLongChange(event){
      selLongValue(event.target.value);
    }

    useEffect(() =>
    {
      handleWeatherData ();

    }, [selLatVar, selLongVar]);

    function handleWeatherData (){
      const weatherURL = WeatherURL + `?lat=${props.Xfrmer.GPSLatitude}&lon=${props.Xfrmer.GPSLongitude}&units=metric&appid=${WeatherApiKey}`
      fetch(weatherURL)
      .then(res => res.json())
      .then(data => {
        const dailyData = data.list.filter(reading => reading.dt_txt.includes("18:00:00"))
        setState({fullData: data.list || [], dailyData: dailyData || []})
        console.log(data)})
      .catch(() => { console.log('Error feching data from OpenWeather') })
    }


    const handleSenseData = (event, reading) =>
    {
      let newDate = new Date().setTime(reading.dt * 1000);
      let selectedDay = moment(newDate).format('YYYY-MM-DD')
      let Ambient = state.fullData.filter(p => p.dt_txt.includes(selectedDay)).map((p, ind) => {
        return {time:ind * 3* 60, val:p.main.temp};
      });

      Ambient.push({time: 1440, val: Ambient[Ambient.length - 1].val});
      alert("You've selected " + selectedDay);
      // console.log("============");
      // console.log(Ambient);
      props.setWeatherData(Ambient);       
    }

    return (
      <Card className={classes.card}>
      <CardHeader>  <Typography variant="subtitle2" display="inline"> DAILY FORECAST </Typography> </CardHeader>
      <CardBody>
        <div className ={classes.controlBar}>
          <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
            <form autoComplete="off">
              <TextField id="latitude-name" size="small" label="Latitude"
                InputProps={{startAdornment: <InputAdornment position="start">deg</InputAdornment>,}}
                className={classes.textField} value={props.Xfrmer.GPSLatitude} onChange={handleLatChange}/>
            </form>
            <form autoComplete="off">
              <TextField id="longitude-name" size="small" label="Longitude"
                InputProps={{startAdornment: <InputAdornment position="start">deg</InputAdornment>,}}
                className={classes.textField} value={props.Xfrmer.GPSLongitude} onChange={handleLongChange}/>
            </form>
            {/* <Button size="small" startIcon={<CloudDownloadIcon />} aria-label="clouddown" onClick={handleWeatherData}> Retrieve </Button> */}
          </ButtonGroup>                      
        </div>         
        <br/>
          <Box display="flex" flexWrap="wrap" p={1} m={1}>
            {
              state.dailyData.map((reading, index) => {
                return (
                  <Box p={1} bgcolor="grey.300" > 
                    <Button  value="left" aria-label="left aligned"   onClick={event => handleSenseData(event, reading)}  >
                      <DayCard reading={reading} key={index} />
                    </Button>
                  </Box>
                );
             })
            }
          </Box>
          </CardBody>
        </Card>    
    );
}