import React from "react";
import { Typography, Button } from "@material-ui/core";
import {  makeStyles  } from "@material-ui/styles";
import './rotate.css';
import Welcome2 from 'assets/img/XfrmItems/Welcome2.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px',
    borderRadius: '0px',
  },
  Welcome: {
    padding: "1px",
    borderLeft: "0px",
    borderRight:"0px",
    margin: "300px",
  },
  button:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
    border: "0px",
    color:"#FFFFFF",
  },
}));

export default function GoodBye (props) {
    const classes  = useStyles();

          return ( 
            <div className="centered">
              <Typography variant="h3">  Welcome to Your Transformer Loading<strong>HUB™</strong> </Typography>         
              <Typography> Your request has been submitted. Thank You...Thank You very much. We will be in touch very shortly....
              </Typography>
              <br/>
              <img src={Welcome2} className="rotate" alt= "Welcome Banner" />
            </div>
          )
}


