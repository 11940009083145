import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import DIYPortal from "assets/img/XfrmItems/DIYPortal.png"
import SimuLoad from "assets/img/XfrmItems/SimuLoad.png";
import DropInImage from "assets/img/XfrmItems/DropInFeatures.png";
import uNamePlate from "assets/img/XfrmItems/uNamePlate.png";
import uNamePlate2 from "assets/img/XfrmItems/uNamePlate2.png";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  Comment: {
    padding: "20px",
    border: "1px solid",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#ffffff"
  },

}));

const LoadPlanners = () =>
{ 
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom>LOAD PLANNERS  </Typography>    
        <Divider/>
      </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs= {6}>  
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Load Planners Ask </strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> How much marginal load capability do we have now and/or will we have at today’s peak ambient temp?</li>
                <li> Is it safe to shift added load to this unit today’s or at some future temperature scenario? </li>
              </ul>
            </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Load Planners Ask </strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> What’s our average unit temperature and how much margin remains there across the fleet?</li>
                <li> What incremental or reduction in load may be applied to problematic unit(s) in summer Months? </li>
                <li> What opportunities exist for added unit load in Winter? </li>
              </ul>
            </Typography>           
        </div>
      </Grid>
    </Grid>
  )
}

const AssetManagers = () =>
{ 
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom>ASSET MANAGERS </Typography>    
        <Divider/>
      </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Asset Managers Ask </strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> Are we operating units within IEEE/IEC and company loading policy?</li>
                <li> Is the unit(s) hotter than predicted at simulated ambient temp and load?</li>
                <li> What is maximum load at the current ambient temperature ? </li>
              </ul>
            </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Asset Managers Ask</strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> Do the manufacturers thermal model apply as predicted? Or not?</li>
                <li> Which units are at full potential in normal and emergency modes?</li>
                <li> How much margin or time is there before it needs to be replaced? </li>
              </ul>
            </Typography>           
        </div>
      </Grid>
    </Grid>
  )
}

const Engineers = () =>
{ 
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom>MAINTENANCE PLANNING ENGINEERS </Typography>    
        <Divider/>
      </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Maintenance Planning Engineers Ask </strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> Should we limit substation load capacity due to excessive loss of insulation life ? </li>
                <li> Are recent changes in DGA values due to added temperature at this load?</li>
                <li> Is this unit hotter than “Normal” loading? By How much? </li>
              </ul>
            </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Maintenance Planning Engineers Ask</strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> Do we have a cooling problem?  How long has it been, or can we operate like this?</li>
                <li> Can we safely shift load to neighboring unit(s) during scheduled or sudden outage ?</li>
                <li> How much hotter will it run? How much insulation life will be consumed ?</li>
              </ul>
            </Typography>           
        </div>
      </Grid>
    </Grid>
  )
}


const Manufacturers = () =>
{ 
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs={4}>
        <Typography variant="h6" gutterBottom>MANUFACTURERS (OEMs) </Typography>    
        <Divider/>
      </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs={4}> </Grid>
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2">
          {<InfoIcon/>} <strong>When Manufacturers Ask </strong>
          </Typography>
            <Divider/>
            <Typography variant="caption" gutterBottom>
              <ul>
                <li> How can I track the performance of a commissioned transformer? </li>
                <li> Is the unit(s) hotter than predicted at varying weather conditions?</li>
                <li> What is maximum load at the current ambient temperature ? </li>
                <li>How can I use the learning experience from a fully operating unit to improve my future design?</li>
              </ul>
            </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
       
      </Grid>
    </Grid>
  )
}

const DropInFeatures = () =>
{
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong>Hosted service </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Drop-in areas for certified factory final (heat run) test results, load profiles, and other specific requirements</li>
                <li> Operation conditions and constraints specification </li>
                <li> Default thermal models to IEEE C57.91-2011, and IEC 60354 - 60076, “Loading Guide for Oil-Immersed Power Transformers</li>
                <li> User-specified custom thermal models.</li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong> Load Study Report Highlights </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Detailed transformer datasheet and load profiles</li>
                <li> Studied WHAT Ifs loading scenarios </li>
                <li> Maximum limits of temperatures and loads</li>
                <li> Loading Curves and Marginal load and status (Underloaded, Normal, Overloaded) </li>
                <li> Insulation loss of life</li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs = {12}> 
        <div className={classes.layout}>
            <img src={DropInImage}  alt = "Drop In Features" />  
        </div> 
      </Grid>
    </Grid>
  )

}

const DIYFeatures = () =>
{
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong>Hosted service </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Step-by-step guide from unit creation to study run</li>
                <li> Csv Formatted 24-h Load Profile File Upload  </li>
                <li> Unlimited loading studies.</li>
                <li> No ownership cost, Pay-As-You Go </li>
                <li> Industry standard-based calculation methods (IEEE/IEC). </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong> Highlights </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Impacts study of the loss of cooling or tap position change </li>
                <li> Use the weather forecast for accurate load planning </li>
                <li> Experimental load study </li>
                <li> Bulk report for the studied units. </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs = {12}> 
        <div className={classes.layout}>
            <img src={DIYPortal}  alt = "Do It YourSelf " />  
        </div> 
      </Grid>
    </Grid>
  )
}

const SimuLoadFeatures = () =>
{
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs= {6}>
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong>Hosted service </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Perform your loading studies at all-time.</li>
                <li> License-based, one-time ownership cost </li>
                <li> Simple, intuitive graphical interface supplemented by extensive technical help.</li>
                <li> Seasonal load and ambient profiles design based on existing utility loading practice</li>
                <li> Optimize loading limits and margins for Normal and Emergency loadings. </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong> Highlights </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Multiple units' creation and load planning </li>
                <li> Impacts study of the loss of cooling or tap position change </li>
                <li> Seasonal load and ambient profiles design </li>
                <li> Custom design of WHAT IFs loading scenarios </li>
                <li> Printable/exportable unit specific loading assessment report. </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs = {12}> 
        <div className={classes.layout}>
            <img src={SimuLoad}  alt = "Do It YourSelf " />  
        </div> 
      </Grid>
    </Grid>
  )
}



const UNamePlateFeatures = () =>
{
  const classes   = useStyles();
  return (
    <Grid container spacing={2} >
      <Grid item xs= {12}>
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong>An IP Protected Hosted Service </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Process and compile your transformers configurations and heat-run test results.</li>
                <li> Batch generation of a secured unique ubiquitous nameplate </li>
                <li> uNameplate printing solutions available</li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong> Highlights </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Secured access to your nameplate every time, everywhere </li>
                <li> Your transformer loading capability at a fingertip </li>
                <li> Loading curves, Marginal loads and status (underloaded, normal, overloaded) </li>
                <li> Thermal performance </li>
                <li> All delivered for the worst-case ambient conditions in both normal and emergency modes. </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs= {6}> 
        <div className={classes.facComment}>
          <Typography variant="body2"> <strong> Highlights </strong> </Typography>
          <Divider/>
          <Typography variant="caption" gutterBottom>
              <ul>
                <li> Service history  </li>
                <li> Performance Index </li>
                <li> Transformer True Failure History (number, rate) </li>
                <li> Availability (Uptime vs. downtime) </li>
                <li> Other OEM specific KPIs.  </li>
              </ul>
          </Typography>           
        </div>
      </Grid>
      <Grid item xs = {12}> 
        <div className={classes.layout}>
            <img src={uNamePlate}  alt = "Ubiquitous NamePlate" />  
        </div> 
      </Grid>
    </Grid>
  )
}



export default function PlatformFeatures() {
const classes = useStyles();
const [expanded, setExpanded] = useState('panel3');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

return (
    <div className={classes.root}> 
      <div className={classes.Comment}>
        <Typography variant="h4" gutterBottom> Flexible Loading Software and Services Platform Provides ANSWERS </Typography>
      </div>
      
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Typography>
            <strong>Problems : </strong> How much Energy Can I Pass Through My Transformer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <LoadPlanners/>
            </Grid>
            <Grid item xs={12}> 
              <AssetManagers/> 
            </Grid>
            <Grid item xs={12}> 
              <Engineers/>
            </Grid>
            <Grid item xs={12}> 
              <Manufacturers/>  
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" >
          <Typography>
            <strong>Drop-In Heat Run :</strong> Drop-In Heat Run Files For Transformer Load Study
          </Typography>       
        </AccordionSummary>
        <AccordionDetails>
          <DropInFeatures/>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header" >
          <Typography>
            <strong>DIY Portal :</strong> A Do It Youself Hosted Service For Transformer Load Study
          </Typography>  
        </AccordionSummary>
        <AccordionDetails>
          <DIYFeatures/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header" >
        <Typography>
          <strong>SimuLOAD™ : </strong>On-premise Transformer Rating and Load Planning Software </Typography>  
        </AccordionSummary>
        <AccordionDetails>
          <SimuLoadFeatures/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header" >
        <Typography>
          <strong>uNamePlate™ : </strong>Ubiquitous Nameplate For OEMs Transformers </Typography>  
        </AccordionSummary>
        <AccordionDetails>
          <UNamePlateFeatures/>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
