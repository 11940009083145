import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import hvsslogo from "assets/img/hvss-logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  }

}));



export default function AboutUs() {
const classes = useStyles();

return (
    <div className={classes.root}> 
      <Grid container xs={12} spacing={2} >
        <Grid item xs= {5}>
                    <Typography variant="h3" gutterBottom> Our Mission </Typography>
                    <Divider/>
                    <div style ={{ textAlign:"justify"}}>
                      <Typography variant="body2" gutterBottom component="text">
                          Our mission is to design, digitize and manufacture <strong> Subject Matter Expertise (SME) </strong>  accross silos of industrial disciplines to make  "Know-Hows" accessible, useful, and low-cost.
                      </Typography> 
                    </div>
                  </Grid>
        <Grid item xs= {7}>

        </Grid>
        <Grid item xs={6}  >
          <img src={hvsslogo} alt ="hvss-logo" /> 
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom> Why ? </Typography>
          <Divider/>
          <div style ={{ textAlign:"justify"}}>
            <Typography variant="body2" gutterBottom component="text"> The Electric Transmission and Distribution (T&D) Industry is facing an unprecedented retirement exodus leaving a shortage of equipement expertise. 
              As a result, Power Utilities face losing the collective experience and expertise of these workers while simultaneously competing in disrupted markets.
              As such, the electrical grid is a stellar candidate for Digital SMEs Manufacture. 
            </Typography>
          </div> 
        </Grid>
        <Grid item xs= {5}>
                    <Typography variant="h3" gutterBottom> Who ? </Typography>
                    <Divider/>
                    <div style ={{ textAlign:"justify"}}>
                      <Typography variant="body2" gutterBottom component="text">
                          The High Voltage Software Services R & D team is the part of the METLAB Research group tasked to de-tribalize and to encode the Know-Hows for Power Utilities.
                          The group consists of retired SMEs and Products Developers.
                      </Typography>
                    </div> 
                  </Grid>
        <Grid item xs= {7}>

        </Grid>
        <Grid item xs={6}  >
  
                  </Grid>
        <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom> What ? </Typography>
                    <Divider/>
                    <div style ={{ textAlign:"justify"}}>
                      <Typography variant="body2" gutterBottom component="text"> With <strong>Power transformers</strong> being one of the most expensive piece of equipments in the T&D chains of infrastructure,
                      our entry point for the  SMEs digitization in High Voltage Assets targets Power Transformers and their related accessories. To that end, the Transformer Analytics Platform is introduced to lay out a range of 
                      services and products culminating the expertise in the area of transformer operation, maintenance and performance monitoring. </Typography> 
                    </div>
                  </Grid>
      </Grid>               
    </div>
  );
}
