import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import LoadingTableHeader from './LoadingTableHeader';
import LoadingTableRows from './LoadingTableRows';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#cccccc',
    },
});

  const LoadingTableItems = ({rowData}) => (
    <View style={styles.tableContainer}>
       <LoadingTableHeader/>
       <LoadingTableRows items={rowData}/>
    </View>
  );
  
  export default LoadingTableItems