import {Auth} from "aws-amplify";
import { makeStyles } from '@material-ui/core/styles';

var moment  = require('moment');
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  }
}));

export const ApiURL = process.env.REACT_APP_SIMULOAD_API_URL;
export const WeatherApiKey = process.env.REACT_APP_WEATHER_API_KEY;
export const WeatherURL = process.env.REACT_APP_WEATHER_API_URL; 
export const WeatherIconsURL = process.env.REACT_APP_WEATHER_ICONS; 

export const colors = ['#E74C3C','#AF7AC5', '#2980B9', '#6f6b6b', '#8884d8', '#1ABC9C', '#F4D03F', '#CA6F1E', '#95A5A6', '#273746'];

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}



export const DefaultXfrm = 
{
      XfrmName: 'Template',
      Company: 'London',
      XType: 'DISTRIBUTION',
      XPhase      : '',
      Stage1: 'ONAN',
      Stage2: 'ONAF',
      Stage3: 'ONAF',
      Rating1: '10',
      Rating2: '20',
      Rating3: '40',
      VClass:'<110kV',
      SolidInsul:'UPGRADED',
      LiquidInsul:'MINERAL OIL',
      TankType      : '',       
      GPSLatitude   : '',
      GPSLongitude  : '',
      GPSAltitude   : '',
      Vintage       : '',
      coolingMode:'',
      Rating:''
};

export const XfrmSolidInsul = [
    { label:'nonupgraded', value:'NON UPGRADED'},
    { label:'upkraft', value:'UPGRADED KRAFT'},
    { label:'uparamid', value: 'UPGRADED ARAMID'},
];

export const XfrmPhases = [
  { label:'singlephase', value:'1_PHASE'},
  { label:'threephase', value:'3_PHASE'},
];

export const XfrmType = [
  { label:'minoil', value:'MINERAL OIL'},
  { label:'ester', value:'ESTER'},
  { label:'silicon', value: 'SILICON'},
  { label:'dryair', value: 'DRY AIR'},
];

export const XfrmLiquidInsul = [
    { label:'minoil', value:'MINERAL OIL'},
    { label:'ester', value:'ESTER'},
    { label:'silicon', value: 'SILICON'},
    { label:'dryair', value: 'DRY AIR'},
];

export const OpenTank = [
  { label:'free-breathing', value:'FREE BREATHING'},
  { label:'sealed-tank', value:'SEALED TANK'},
];

export const XfrmTypeList = [
    { label:'distro', value:'DISTRIBUTION'},
    { label:'power', value:'POWER'},
    { label:'auto', value:'AUTOTRANSFORMER'},
    { label: 'genstepup', value: 'GENERATOR STEP_UP'},
];

export const XfrmVoltList = [
    { label:'VClass1', value:'< 110kV'},
    { label:'VClass2', value:'110kV_220kV'},
    { label: 'VClass3', value: '> 220kV'},
];

export const coolMode = [
    { label:'ONAN', value:''},
    { label:'ONAN', value:'ONAN'},
    { label:'ONAF', value:'ONAF'},
    { label:'ODAF', value:'ODAF'},
    { label:'ODWF', value:'ODWF'},
    { label:'OFWF', value:'OFWF'},
    { label:'OFAF', value:'OFAF'},
    { label:'OFAN', value:'OFAN'},
    { label:'OA', value:'OA'},
    { label:'FOA', value:'FOA'},  
    { label:'FA', value:'FA'}
];

export const substationList = [
    { label: 'DEFAULT', value: 'default'},
];

export const weatherData =[

]

export var currentXfrm = {
    XfrmName: '',
    XType: '',
    Stage1: '',
    Stage2: '',
    Stage3: '',
    Rating1: '',
    Rating2: '',
    Rating3: '',
    VClass:'',
    SolidInsul:'',
    LiquidInsul:''
}

export function getCurrentUsername() {
  return new Promise((resolve, reject) => {
   Auth.currentAuthenticatedUser()
   .then(user => {
     if (user.username) {
      console.log("Local user ====>>>>>" + user.username)
      resolve(user.username)
     } 
     else 
     {
      console.log(user)
      resolve(null)
     }
    })
    .catch(err => { console.log(err); resolve(null)});
  })
}

export function getTimeFromMins(mins) {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
  if (mins > 24 * 60 || mins < 0) {
      throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
  }
  var h = mins / 60 | 0,
      m = mins % 60 | 0;
  return moment.utc().hours(h).minutes(m).format("HH:mm");
}

export const LoadingScenario = [
    // Loading Constraints
    { XfrmId: 'Template', Variable: 'sim_ltype', Description: 'Loading Type', Value: '24-h Normal', Unit: '-' },
    { XfrmId: 'Template', Variable: 'sim_hstthresh', Description: 'Hot spot temperature limit', Value: '110', Unit: 'Celsius' },
    { XfrmId: 'Template', Variable: 'sim_topthresh', Description: 'Top oil temperature limit', Value: '105', Unit: 'Celsius'  },
    { XfrmId: 'Template', Variable: 'sim_bubthresh', Description: 'Bubbling temperature limit', Value: '105', Unit: 'Celsius'  },
    { XfrmId: 'Template', Variable: 'sim_lolthresh', Description: 'Permissible insulation loss of life rate per cycle', Value: '0.037', Unit: 'Hours' },
    { XfrmId: 'Template', Variable: 'sim_pulthresh', Description: 'Permissible load, p.u of maximum nameplate rating', Value: '1', Unit: 'p.u' },     
    { XfrmId: 'Template', Variable: 'sim_cpwthresh', Description: 'Cooling power limit', Value: '1.8', Unit: '%' },

    { XfrmId: 'Template', Variable: 'sim_gcoil', Description: 'Gas content in oil', Value: '10', Unit: '%' },
    { XfrmId: 'Template', Variable: 'sim_imois', Description: 'Average insulation moisture content', Value: '1.8', Unit: '%' },
    { XfrmId: 'Template', Variable: 'sim_oxygen', Description: 'Average oxygen level', Value: '1.8', Unit: 'ppm' },
    { XfrmId: 'Template', Variable: 'sim_hstacc', Description: 'optimization accuracy', Value: '0.01', Unit: '-' },
]

export const initProfile = [
  { profileName: 'Template' , time: '00:00' , sumamb: 30 , sumpul: 0.6, sumcool: 1000 },
  { profileName: 'Template' , time: '01:00' , sumamb: 29.5 , sumpul: 0.6, sumcool: 1000 },
  { profileName: 'Template' , time: '02:00' , sumamb: 29.2 , sumpul: 0.6, sumcool: 1000 },     
  { profileName: 'Template' , time: '03:00' , sumamb: 29 , sumpul: 0.6, sumcool: 3000 },     
  { profileName: 'Template' , time: '04:00' , sumamb: 28.7 , sumpul: 0.6, sumcool: 3000 },
  { profileName: 'Template' , time: '05:00' , sumamb: 28.5 , sumpul: 0.6, sumcool: 3000 },
  { profileName: 'Template' , time: '06:00' , sumamb: 28.2 , sumpul: 0.7, sumcool: 1000 },
  { profileName: 'Template' , time: '07:00' , sumamb: 29.8 , sumpul: 0.8, sumcool: 1000 }, 
  { profileName: 'Template' , time: '08:00' , sumamb: 31.8 , sumpul: 0.9, sumcool: 1000 },
  { profileName: 'Template' , time: '09:00' , sumamb: 33.9 , sumpul: 0.9, sumcool: 1000 },
  { profileName: 'Template' , time: '10:00' , sumamb: 35.9 , sumpul: 0.9, sumcool: 1000	},
  { profileName: 'Template' , time: '11:00' , sumamb: 37.1 , sumpul: 0.9, sumcool: 1000	},
  { profileName: 'Template' , time: '12:00' , sumamb: 38.4 , sumpul: 1.05, sumcool: 1000},
  { profileName: 'Template' , time: '13:00' , sumamb: 38.4 , sumpul: 1.1, sumcool: 1000 },
  { profileName: 'Template' , time: '14:00' , sumamb: 39.6 , sumpul: 1.15, sumcool: 1000 },
  { profileName: 'Template' , time: '15:00' , sumamb: 40 , sumpul: 1.2, sumcool: 1000 },
  { profileName: 'Template' , time: '16:00' , sumamb: 40 , sumpul: 1.15, sumcool: 5000 },
  { profileName: 'Template' , time: '17:00' , sumamb: 39.6 , sumpul: 1.1, sumcool: 5000 },
  { profileName: 'Template' , time: '18:00' , sumamb: 38.2 , sumpul: 1.1, sumcool: 1000 },
  { profileName: 'Template' , time: '19:00' , sumamb: 36.8 , sumpul: 1.1, sumcool: 1000 },
  { profileName: 'Template' , time: '20:00' , sumamb: 35.4 , sumpul: 0.9, sumcool: 1000 },
  { profileName: 'Template' , time: '21:00' , sumamb: 33.9 , sumpul: 0.9, sumcool: 1000 },
  { profileName: 'Template' , time: '22:00' , sumamb: 32.5 , sumpul: 0.8, sumcool: 2000 },
  { profileName: 'Template' , time: '23:00' , sumamb: 31.7 , sumpul: 0.7, sumcool: 1000 },
  { profileName: 'Template' , time: '24:00' , sumamb: 31.7 , sumpul: 0.7, sumcool: 4000 },
];

                                                
                        {/* <AreaChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }} >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="Ambient" type="number" margin={{ bottom: 20 }} label={{ value: 'Ambient Temperature shift[℃]', 
                                    fontSize:12, position: 'insideCenter'}} tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                                <YAxis label={{ value: 'Load Margins [MVA]', fontSize:12, angle: -90,  position: 'insideCenter'}} tick={{fontSize: 11}}/>
                                <Tooltip/>
                                <Legend />
                                {
                                  durationSet.map((u, ind) => 
                                  {
                                    return (
                                    ieeeG7Iec.map((cid,index) => 
                                    {
                                      return(
                                        <Area dataKey="Margin" data={cid.ieeeData.filter(p => p.Overtime === parseInt(u))} name={`${cid.name}`-`${u}`}  key={index}  strokeWidth={2}/>
                                      )
                                    })
                                    )
                                  })
                                }
                        </AreaChart>  */}

 // <div className={classes.card}>
        /* <CardHeader className={classes.cardheader}> 
            <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
              <Button size="small" > Loading Curves vs. Ambient Temperature Shift </Button>
              <Divider orientation="vertical" flexItem />
              <Button size="small" startIcon={<AddBoxIcon />}  onClick={handleSaveClick}  > Add to Report </Button>
            </ButtonGroup>          
        </CardHeader>
        <CardBody className={classes.CardBody}>        
          {/* <div className={classes.controlBar}><Typography variant="caption"> Load vs.  Average Ambient Temperature Shift</Typography></div>        
          <div id ="load-vs-ambient">
              <ResponsiveContainer width='100%' aspect={3.0/2} >
                  <LineChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }} >
                      <CartesianGrid strokeDasharray="3 3"/>
                      <XAxis dataKey="Ambient" type="number" label={{ value: 'Ambient Temperature shift[℃]', fontSize:12, position: 'insideCenter', marginTop:100 }} 
                        tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                      <YAxis label={{ value: 'Load [MVA]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}}  
                       domain={[Math.min(...minArray) - 10, Math.max(...maxArray) + 10]}/>
                      <Tooltip/>
                      <Legend/>
                      {
                        ieeeG7Iec.map((cid,index) => {
                          return (
                              <Line dataKey="MVA" data={cid.ieeeData} name={cid.name} key={cid.name} stroke={colors[index]} strokeWidth={2}/>
                            )
                        })
                      }
                  </LineChart> 
              </ResponsiveContainer>
          </div> */

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

var hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function printResult(res) {
  console.log("---------------------------------------");
  if (res.add) {
    res.add.forEach(function(rowNode) {
      console.log("Added Row Node", rowNode);
    });
  }
  if (res.remove) {
    res.remove.forEach(function(rowNode) {
      console.log("Removed Row Node", rowNode);
    });
  }
  if (res.update) {
    res.update.forEach(function(rowNode) {
      console.log("Updated Row Node", rowNode);
    });
  }
}
