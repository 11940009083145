//import 'date-fns';
import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import { isEmpty} from './Utilities.jsx';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { ButtonGroup, Typography, Button, Snackbar, Divider, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
}));


export default function LoadingStandard (props) 
{
  const classes                       = useStyles();
  const [gridRApi, setGridRApi]       = useState();
  let loadStandards                   = props.LoadingStds;

  const thermalModels = [
    // { pubName: 'IEC60076', pubTitle:'IEC Loading Guide For Power Transformers', pubDate : '2000-04-07'},
    { pubName: 'IEC60354', pubTitle:'IEC Loading Guide For Power Transformers', pubDate : '2000-04-07'},
    { pubName: 'IEEE7', pubTitle: 'IEEE Clause 7 Loading Guide For oil filled Transformers', pubDate : '2000-04-07'},
    { pubName: 'IEEEG', pubTitle:'IEEE Annex G Extended Guide For oil filled Transformers', pubDate : '2000-04-07'}
  ];

  const thermalColumns=[
    { headerName: 'Name', field: 'pubName', headerCheckboxSelection: true, checkboxSelection: true, width: 150},
    { headerName: 'Publication', field: 'pubTitle', width: 450},
    { headerName: 'Date', field: 'pubDate',width: 100},
  ];

  const onThermalChanged  = params => {
    let selectedData = gridRApi.api.getSelectedRows();
    props.handleStdChange (selectedData);
  }

  const onGridStdReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridRApi({ api, columnApi });
    params.api.sizeColumnsToFit();
    },[]
  );

  
  useEffect(() =>
  {
    if(isEmpty(loadStandards) && (gridRApi !== undefined)) {
        var res = gridRApi.api.deselectAll();
    }
  }, [!isEmpty(loadStandards)]);

  return (      
    <Card className={classes.card} >
      <CardHeader> <Typography variant="subtitle2"><strong> THERMAL MODELS </strong></Typography> </CardHeader>
      <CardBody>
        <div  className="ag-theme-balham" style={{ width: "100%", height:"200px"}} >
          <AgGridReact
              modules={AllCommunityModules}
              columnDefs={thermalColumns}
              rowData={thermalModels}
              domLayout="autoHeight"
              onGridReady={onGridStdReady}
              animateRows={true}
              rowSelection='Multiple'
              onSelectionChanged={onThermalChanged}
          />
        </div>
      </CardBody>
    </Card>                   
  )
}