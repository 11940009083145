//import 'date-fns';
import React from "react";
import { makeStyles} from '@material-ui/core/styles';
import { colors} from './Utilities.jsx';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import { isEmpty} from 'components/MainPanel/Utilities.jsx';
import date from 'date-and-time';
import { ButtonGroup, Typography, Button, Snackbar, Divider, Grid } from "@material-ui/core";
import { Card, CardHeader, CardBody } from 'reactstrap';

var moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },
  card: {
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
}));


export default function PreviewPanel (props)
{
  const classes       = useStyles();
  var ambDataRange    = [];
  var puDataRange     = [];
  let ambMean         = 0;  let ambStd          = 0;
  let puMean          = 0;  let puStd           = 0;

  let selProfileVar   = props.Distribution.profileName || ""; 
  let profiles        = props.profiles || [];
  let AmbRise         = props.Distribution.Ambient || {};
  let ExpLoad         = props.Distribution.ExpLoad || {};
  let Harmonics       = props.Distribution.Harmonics || {};
  let PlugEV          = props.Distribution.PlugEV || {};
  
  if(!isEmpty(props.Distribution.Ambient))
  {
    ambMean           = AmbRise.ambMean; 
    ambStd            = AmbRise.ambStd;
    puMean            = ExpLoad.puMean;
    puStd             = ExpLoad.puStd;
  }

  if(!isEmpty(profiles.filter(p => p.profileName === selProfileVar)))
  {
    let profile =  profiles.filter(p => p.profileName === selProfileVar);
    ambDataRange = profile.map(item => {
      return {x: date.parse(item.time, 'HH:mm').getTime(), y: [parseFloat(item.sumamb) + parseFloat(ambMean) + 3 * parseFloat(ambStd), 
           parseFloat(item.sumamb) + parseFloat(ambMean) - 3 * parseFloat(ambStd)]}
    });
      
    puDataRange = profile.map(item => {
      return {x: date.parse(item.time, 'HH:mm').getTime(), y: [ parseFloat(item.sumpul) + parseFloat(puMean) + 3 * parseFloat(puStd), 
          parseFloat(item.sumamb) + parseFloat(puMean) - parseFloat(puStd)]}
    });
  }

  // console.log(" ======== puDataRange ================");
  // console.log(JSON.stringify(puDataRange));

  // console.log(" ======== ambDataRange ================");
  // console.log(JSON.stringify(ambDataRange));
          
  return (
    <Grid container spacing ={2} justify="center">
      <Grid item xs={12}>
        <div className={classes.Comment}>
          <Typography variant="caption"> Load and Ambient temperature variation ranges </Typography>
        </div>
      </Grid>
        <Grid item xs={12}>
          <Card className={classes.card} >
            <CardHeader> <Typography variant="subtitle2"><strong> 24-h Ambient Temperature Range </strong></Typography> </CardHeader>
              <CardBody>
                <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                  <AreaChart data={ambDataRange} syncId="anyId" margin={{top: 5, right: 10, left: 3, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                    <XAxis dataKey="x" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm')} tick={{fontSize: 11}} domain={['0:00', '24:00']}/>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis offset={0} label={{ value: 'Ambient Range [℃]', angle: -90, fontSize:12, position: 'insideCenter', textAnchor: 'middle'  }} tick={{fontSize: 11}}/>
                    <Tooltip/>
                    <Area type='monotone' dataKey="y" fill={colors[0]} stroke={colors[0]} strokeWidth={2} />
                  </AreaChart> 
                </ResponsiveContainer> 
              </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12}>
            <Card className={classes.card} >
              <CardHeader> <Typography variant="subtitle2"><strong> 24-h p.u Load Profile Range </strong></Typography> </CardHeader>
                <CardBody>
                  <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                    <AreaChart data={puDataRange} syncId="anyId" margin={{top: 5, right: 10, left: 3, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }}>
                      <XAxis dataKey="x" type = 'number' name = 'Time' scale='time' tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm')} tick={{fontSize: 11}} domain={['0:00', '24:00']}/>
                      <CartesianGrid strokeDasharray="3 3" />
                      <YAxis offset={0} label={{ value: 'p.u. Load Profile Range [℃]', fontSize:12, angle: -90, position: 'insideCenter'}} tick={{fontSize: 11}}/>
                      <Tooltip/>
                      <Area type='monotone' dataKey="y" fill={colors[1]} stroke={colors[1]} strokeWidth={2} />
                    </AreaChart>
                  </ResponsiveContainer>
                </CardBody>
            </Card>
        </Grid>
    </Grid> 
  );
}
