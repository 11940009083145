//import 'date-fns';
import React, {useState, useEffect, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from "@material-ui/core";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { isEmpty, printResult } from "components/MainPanel/Utilities.jsx";
import DateFnsUtils from '@date-io/date-fns';
import date from 'date-and-time';
import NumericCellEditor from "components/MainPanel/NumericCellEditor.jsx";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },

  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '150px',
  },
  card: {
    paddingTop: "0px",
    paddingBottom: "0px",
    marginTop: "0px",
    marginBottom: "0px",
    borderRadius: "0px",
  },
  cardheader:{
    padding: "1px",
    backgroundColor : "#cccccc",
    borderRadius:'0px',
    paddingLeft: "50px",
  },
}));

export default function UScenario (props)
{
  const classes = useStyles();
  const [gridBApi, setGridBApi]       = useState();
  const [gridApi, setGridApi]         = useState();
  const [state, setState]             = useState({
    startDate : new Date(),
    endDate : new Date(),
  });
  // let Scenario = [
  //   { Variable: 'sim_ltype', Description: 'Loading Scenario Name', Value: '24-h Normal', Unit: '-' },
  //   { Variable: 'sim_hstthresh', Description: 'Hot spot temperature limit', Value: '110', Unit: 'Celsius' },
  //   { Variable: 'sim_topthresh', Description: 'Top oil temperature limit', Value: '105', Unit: 'Celsius'  },
  //   { Variable: 'sim_bubthresh', Description: 'Bubbling temperature limit', Value: '105', Unit: 'Celsius'  },
  //   { Variable: 'sim_lolthresh', Description: 'Permissible insulation loss of life rate per cycle', Value: '0.037', Unit: 'Hours' },
  //   { Variable: 'sim_pulthresh', Description: 'Permissible load, p.u of maximum nameplate rating', Value: '1', Unit: 'p.u' },     
  //   { Variable: 'sim_cpwthresh', Description: 'Cooling power limit', Value: '1.8', Unit: '%' },
  //   { Variable: 'sim_inslife', Description: 'Insulation life expectancy', Value: '65000', Unit: 'Hours' },
  //   { Variable: 'sim_gcoil', Description: 'Gas content in oil', Value: '10', Unit: '%' },
  //   { Variable: 'sim_imois', Description: 'Average insulation moisture content', Value: '1.8', Unit: '%' },
  //   { Variable: 'sim_hspress', Description: 'Gas pressure at hot spot', Value: '13', Unit: 'mbar' },
  //   { Variable: 'sim_oxygen', Description: 'Average oxygen level', Value: '1.8', Unit: 'ppm' },
  //   // { Variable: 'sim_hstacc', Description: 'optimization accuracy', Value: '0.01', Unit: '-' },
  //   { Variable: 'sim_tapPosition', Description: 'Selected Tap Position', Value: '-1', Unit: '-' },
  //   { Variable: 'sim_tapAmps', Description: 'Ampacity, p.u. of the maximum nameplate rating', Value: '0.01', Unit: 'p.u.' }
  // ];
  let ScenarioList                    = props.scenarioList;
  let Scenario                        = props.Scenario;
  let selectedRow                     = {};

  console.log(JSON.stringify(ScenarioList));

  useEffect(() => 
  {
    if(isEmpty(ScenarioList)) {
      if(gridBApi !== undefined) {
        gridBApi.api.setRowData([]);
      }
    }
  }, [isEmpty(ScenarioList)]);


  const ScenarioColumns =[
    { headerName: 'Variable', field: 'Variable', hide: true },
    { headerName: 'Description', field: 'Description', width: 350},
    { headerName: 'Value', field: 'Value', editable: true, 
        cellEditorSelector:function (params)
        {
          if (params.data.Variable === 'sim_tapPosition')
          { 
              return {
                component: 'agSelectCellEditor',
                params: { values:[...(function*(){let i=1;while(i <= 18)yield i++})()] }
              }; 
          }
          else if (params.data.Variable === 'sim_inslife') {
             return {
              component: 'agSelectCellEditor',
              params: {values: [65000, 138000, 165000]}
            }
          }
          else if (params.data.Variable !== 'sim_ltype') 
          {
              return { component: 'numericCellEditor' };
          }
        }
      },
    { headerName: 'Unit', field: 'Unit'},
  ];


  const CaseColumns= [
      { headerName: "Name", field: "Name", checkboxSelection: true, width: 200},
      { headerName: "HSTm[℃]", field: "HotSpot" },
      { headerName: "TOPm[℃]", field: "TopOil" },
      { headerName: "LOLm[Hour]", field: "LOLm"},
      { headerName: "PUm [p.u]", field: "PUm"},
      { headerName: "BUBm[℃]", field: "BUBm"},
      { headerName: "CPWm[W]", field: "CPWm"},
      { headerName: "Start", field: "start"},
      { headerName: "End", field: "end"},
      { headerName: "Life", field: "inslife", hide:true},
      { headerName: "OXY[ppm]", field: "OxyContent"},
      { headerName: "WCP[ppm]", field: "MoisContent"},
      { headerName: "DGA[ppm]", field: "GasContent"},
      { headerName: "HSP[ppm]", field: "HSPressure", hide:true},
      // { headerName: "Error[%]", field: "OptimError", hide:true},
      { headerName: "LTCPos[ppm]", field: "LtcPosition"},
      { headerName: "LTCCap[p.u]", field: "LtcCapacity"},
  ];
 
  const frameworkComponents = {
    numericCellEditor: NumericCellEditor
  }

  const handleStartDateChange = (startdt) => {
    setState({...state, startDate: startdt});
  }

  const handleEndDateChange = (endt) => {
    setState({...state, endDate: endt});
  }

  const onGridAReady = params => {
    const { api, columnApi } = params;
    setGridApi({ api, columnApi });
    params.api.sizeColumnsToFit();
  }

  const onGridBReady = useCallback((params) => {
      const { api, columnApi } = params;
      setGridBApi({ api, columnApi });
      params.api.sizeColumnsToFit();
    },[]
  );
      
  const onCellValueChanged = params => {
      var colId = params.column.getId();
      if (colId === "Value") {
        // console.log("Updating the row node : ======= " + params.data.Value);
        params.node.setDataValue(colId, params.data.Value); 
        return;
      }
  }

  const AddScenario = ()=>
  {
    let newScenario = { 
      Name:         Scenario.find(item => item.Variable ==='sim_ltype').Value,
      HotSpot:      Scenario.find(item => item.Variable ==='sim_hstthresh').Value,
      TopOil:       Scenario.find(item => item.Variable ==='sim_topthresh').Value,
      LOLm:         Scenario.find(item => item.Variable ==='sim_lolthresh').Value,
      PUm:          Scenario.find(item => item.Variable ==='sim_pulthresh').Value,
      BUBm:         Scenario.find(item => item.Variable ==='sim_bubthresh').Value,
      CPWm:         Scenario.find(item => item.Variable ==='sim_cpwthresh').Value,
      start:        date.format(state.startDate, 'HH:mm'),
      end:          date.format(state.endDate, 'HH:mm'),
      inslife:      Scenario.find(item => item.Variable ==='sim_inslife').Value,
      OxyContent:   Scenario.find(item => item.Variable ==='sim_oxygen').Value,
      MoisContent:  Scenario.find(item => item.Variable ==='sim_imois').Value,
      GasContent:   Scenario.find(item => item.Variable ==='sim_gcoil').Value,
      HSPressure:   Scenario.find(item => item.Variable ==='sim_hspress').Value,
      // OptimError:   Scenario.find(item => item.Variable ==='sim_hstacc').Value,  
      LtcPosition:  Scenario.find(item => item.Variable ==='sim_tapPosition').Value,
      LtcAmpacity:  Scenario.find(item => item.Variable ==='sim_tapAmps').Value   
    }

    if(!isEmpty(ScenarioList) && ScenarioList.find(item => item.Name === newScenario.Name))
    {
      alert('This scenario already exist !!!');
      return;
    }
    ScenarioList.push(newScenario);
    props.createScenario(ScenarioList);
    var res = gridBApi.api.updateRowData({ add: [newScenario] });
    // printResult(res);
    // console.log ("List of scenarios...." + JSON.stringify(ScenarioList));
  }

  const RemoveScenario = () =>
  {
    var selectedData = gridBApi.api.getSelectedRows();
    if(selectedData.length === 0) return;
    selectedRow = selectedData[0].Name;
    let filteredScenarios = ScenarioList.filter(item => item.Name !== selectedRow);
    props.createScenario(filteredScenarios);
    var res = gridBApi.api.updateRowData({ remove: selectedData });
    //printResult(res);
  }

  return (      
    <div>             
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <Grid item xs={4}>
            <KeyboardTimePicker ampm={false} variant="inline" label="Start of the loading period" value={state.startDate} onChange={(startdt) => handleStartDateChange(startdt)}/>
          </Grid>
          <Grid item xs={4}> </Grid>
          <Grid item xs={4}>
            <KeyboardTimePicker ampm={false} variant="inline" label="End of the loading period" value={state.endDate} onChange={(endt) => handleEndDateChange(endt)} />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <br/>
      <div  className="ag-theme-balham" style={{ width: "100%", height:"340px"}} >
          <AgGridReact
            modules={AllCommunityModules}
            columnDefs={ScenarioColumns}
            rowData={Scenario}
            onGridReady={onGridAReady}
            animateRows={true}
            onCellValueChanged={onCellValueChanged}
            frameworkComponents = {frameworkComponents}/>
        </div>
      <div className ={classes.Comment}>
          <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
            <Button size="small" startIcon={<ArrowDownwardIcon />} aria-label="arrowdown" onClick={AddScenario}> Add </Button>
            <Button size="small" startIcon={<DeleteForeverIcon />} aria-label="distro-deleteforever" onClick={RemoveScenario}>Discard</Button> 
          </ButtonGroup> 
      </div>
      <div  className="ag-theme-balham" style={{ width: "100%", height:"300px"}} >
          <AgGridReact
            modules = {AllCommunityModules}
            columnDefs={CaseColumns}
            rowData={ScenarioList}
            onGridReady={onGridBReady}
            animateRows={true}
            rowSelection='Single'/>
        </div>
    </div>   
  )
}

