import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import {Button , Divider, Typography, Grid} from '@material-ui/core';
import { ApiURL, isEmpty, SimpleAlerts } from 'components/MainPanel/Utilities.jsx';
import {Button, ButtonGroup, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Grid, 
  InputLabel, NativeSelect, TextField, Typography, Snackbar, Divider, Box, } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Alert from '@material-ui/lab/Alert';
import fileDownload from 'js-file-download';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      margin: theme.spacing(4),
      borderRadius: '0px',
      // marginLeft: theme.spacing(8),
    },
    layout: {
      minWidth: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      minHeight: '90vh'
      // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //   width: 600,
      //   marginLeft: 'auto',
      //   marginRight: 'auto',
      // },
    },
      CardBody: {
        minHeight: '90vh'
    },
    card: {
      borderRadius: "0px",
      minHeight: '100%',
    },
    button: {
      color: '#4486a3',
      borderRadius:"0px",
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
      minHeight: '90vh'
    },
    scan:{
      minWidth: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      margin: theme.spacing(4),
    },
    margin: {
      margin: theme.spacing(4),
    },
    schedule:{
      borderRadius: "0px",
      Height: '90vh',
      backgroundColor: '#f6f6f6',
    },
    Comment: {
      padding: "20px",
      border: "1px solid",
      borderLeft: "0px",
      borderRight:"0px",
      marginTop: "20px",
      marginBottom: "20px",
      backgroundColor : "#ffffff"
    },
  
}));

const QRCode = 'At this step, a QR code is generated. The code MUST be saved to a place of choice where it can be scanned to collect the transformer marginal load forecasts.'+
                 'A scan can be done from any mobile device equipped of a scanning application.'+
                 'The QRcode can be carried and accessed every time everywhere.'




export default function NamePlate(props) 
{
  const classes   = useStyles();
  const [stateUrl, setURLState] = useState({  });
  const [value, setValue]       = useState('Daily');
  const [mailVal, setMail]      = useState('');
  const [openSnack, setOpenSnack]     = useState({ open: false, Message: 'Error', severity:'error'});
  let sessionId = 'QRUSER';


  const DoXfrmQRCode = () =>
  {
    if(isEmpty(mailVal)){
      setOpenSnack({open: true, Message: "There is no Email address specified to the recipients. Please provide one", severity:'error'});
      return;
    }

    const url = ApiURL + '/DoXfrmQRCode';  
    let lProfiles     = props.LoadProfiles.filter(p => p.profileName!=='Template');
    // console.log("=============== Load profiles =============");
    console.log(lProfiles);
    let Payload = { sessionId:sessionId, newXrmer:props.Xfrmer, dataSheet:props.DataSheet, lProfiles:lProfiles, 
                    loadCases:props.LoadCases, lCooling:props.XfrmCooling, loadStds: props.loadStds, lDistro:props.lDistro };
    // console.log(Payload);
    try 
    {
      (async () => {
        await fetch(url, {
          method: 'POST',
          body: JSON.stringify(Payload),
          headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
        })
        .then(response => response.blob())
        .then(QRResult => { 
          setURLState(URL.createObjectURL(QRResult));
          props.createScan(URL.createObjectURL(QRResult));
          fileDownload(URL.createObjectURL(QRResult), props.Xfrmer.XfrmName + ".png");
        })
      })();
    }   
    catch (error) {
        console.error('Error:', error);
    }
  }

  // const handleSave = () =>
  // {
  //   fileDownload(stateUrl, props.Xfrmer.XfrmName + ".png");
  // }

  const handleChange = (event) => {
    setValue(event.target.value);
    props.setSchedule(event.target.value);
  };

  const handleMail= (event) => {
    setMail(event.target.value);
    props.setMail(event.target.value);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      setOpenSnack({...openSnack, open:false});
      return;
    }
    setOpenSnack({...openSnack, open:false});
  };
  return (
    <main className={classes.layout}>
      <br/>
      <Snackbar open={openSnack.open} autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert variant="filled" onClose={handleSnackClose} severity={openSnack.severity}>
              { openSnack.Message } — check it out!
        </Alert>
      </Snackbar>
      <Card className={classes.card}>
        <CardHeader className={classes.cardheader}>
            <div className={classes.comment}>
              <Box display="flex" flexDirection="row">
                <Box item xs={10}>
                      <Typography variant="subtitle2"> {<InfoIcon/>} Your Transformer Secured Nameplate QR Code Generation </Typography>     
                      <Divider/>
                      <Typography variant="caption" gutterBottom> {QRCode}</Typography>
                </Box>
                <Box item xs={1}>
                  <Divider orientation="vertical" />
                </Box>
              </Box> 
            </div>
          </CardHeader>
        <CardBody className={classes.CardBody}> 
            <Grid container spacing ={2} >
                <Grid item xs={7} >
                  <Card className={classes.schedule}>
                  <CardHeader className={classes.cardheader}>
                    <Box display="flex" style={{padding:"0px", margin:"0px"}} >
                      <Box flexGrow={1}>
                        <Typography variant="subtitle2" display="inline"> On Demand Notification with QR Code </Typography>  
                      </Box>
                      <Box >
                        <Button size="small" variant="outlined" color="primary" className={classes.button} startIcon={<SaveAltIcon />} aria-label="arrowdown" onClick={DoXfrmQRCode}> Get QR Code</Button>
                      </Box>
                    </Box>
                  </CardHeader>
                    <CardBody className = {classes.CardBody}>
                      <img src={stateUrl} className={classes.scan}  alt = "Ubiquitous NamePlate" />
                    </CardBody>
                  </Card>  
                </Grid>
                <Grid item xs={5}>
                  <Card className={classes.schedule}>
                  <CardHeader className={classes.cardheader}><Typography variant="subtitle2" display="inline"> Periodic Forecast Notification </Typography></CardHeader>
                  <CardBody className = {classes.CardBody}>
                    <RadioGroup className={classes.margin} aria-label="schedule" name="schedule" value={value} onChange={handleChange}>
                      <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                      <FormControlLabel value="bidaily" control={<Radio />} label="Bi-Daily" />
                      <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
                      <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    </RadioGroup>
                    <Divider/>
                    <TextField className={classes.margin} id="input-with-icon-textfield" label="Recipient Email Address" onChange ={handleMail} 
                      type="email" InputProps={{ startAdornment: ( <InputAdornment position="start"> <EmailIcon/> </InputAdornment> )}}/>
                  </CardBody>
                  </Card>  
                </Grid>
            </Grid> 
          </CardBody>
      </Card> 
    </main>
  )
}