/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
// import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
// core components/views for Admin layout
import Dashboard from "views/Dashboard/Dashboard.jsx";
import Welcome from "views/Begin/Welcome.jsx";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Unarchive,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/welcome",
    name: "Welcome",
    rtlName: "لوحة القيادة",
    icon: Notifications,
    component: Welcome,
    layout: "/admin"
  },
  // ,
  // {
  //   path: "/welcome",
  //   name: "Welcome",
  //   rtlName: "لوحة القيادة",
  //   icon: Notifications,
  //   component: Welcome,
  //   layout: "/admin"
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },

];

export default dashboardRoutes;
