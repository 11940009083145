import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import UDashboard from './UDashboard';
import UDataForm from './UDataForm';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(-3),
  },
}));

const initialNote ='The equipement digital Datasheet requires input values which can typically be found within the transformer OEM commisionning and tests report'+ 
'(Factory Final Test Report, Heat-Run Test, outline drawing), which were typically sent to the transformer owner for approval.' +
'A shapshot below gives a sketch of such commisionning report, as reference only.'



export default function UDataSheet(props) {
  const classes = useStyles();
  // const [value, setValue]           = useState('Option 1');
  // const [cheatSheet, setCheatSheet] = useState(initialNote);
  // const [activeStep, setActiveStep] = useState(0);
  // const [files, setFile] = useState([]);
  // const [filesPro, setProFile] = useState([]);
  // const [errlabel, setErrLabel] = useState('');
  
  const getDataContent =() =>
  {
    if(props.Option === 'Option 1')
    {
      return <UDashboard Xfrmer ={props.Xfrmer} profileList = {props.profileList} historyProfile = {props.historyProfile} scenarioList = {props.scenarioList}
                         createDataSheet = {props.createDataSheet} createProfile ={props.createProfile} createScenario = {props.createScenario}/>
    }
    else
    {
      return <UDataForm Xfrmer ={props.Xfrmer} createFFTRFiles = {props.createFFTRFiles} createLoadFiles = {props.createLoadFiles} />
    }
  }

  return (
    <React.Fragment>   
        { getDataContent()}
    </React.Fragment>
  );
}