import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import DIYPortal from "assets/img/XfrmItems/DIYPortal.png"
import SimuLoad from "assets/img/XfrmItems/SimuLoad.png";
import DropInImage from "assets/img/XfrmItems/DropInFeatures.png";
import SimResult from "assets/img/UseCase/marginal-thermal-load.png";
import LossVsAmbient from "assets/img/UseCase/LossLifeVSAmbient.png";
import loadProfile from "assets/img/UseCase/power-system-sketch-and-load-profile.png"
import DetailedResult from "assets/img/UseCase/detailed-simulation-results.png"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(4),
    borderRadius: '0px',
    // marginLeft: theme.spacing(8),
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  facComment: {
    padding: "5px",
    borderLeftColor: "#6699cc",
    borderLeft: "8px solid",
    backgroundColor : "#f6f6f6"
  },
  Comment: {
    padding: "20px",
    border: "1px solid",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#ffffff"
  },
  table: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },

}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel"
      hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other} >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default function UseCases() {
const classes = useStyles();
const [value, setValue] = useState(0);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

return (
    <div className={classes.root}> 
      <div className={classes.Comment}>
        <Typography variant="h4" gutterBottom> 
            Case Study: Simulating the overload capability of a 42/70 MVA oil-filled unit 
        </Typography>
      </div>
      <Typography variant="h6" gutterBottom component="div"> 1. Overview </Typography>
              <Typography variant="body2" gutterBottom component="div">
                Consider the small power system illustrated in “Fig.1(a)”. Transformer U1 and U2 are both 42/70MVA - ONAN/ONAF, Z= 6%, 
                both with oil-filled liquid insulation and serving a maximum city load of 90MW. The generator G1 is offline and located on a 69kV bus. 
                The system operator would like to know the maximum rating U2 would reliably be able to maintain if U1 fails, and for how long. 
                The system operator wants to have at least 2 hours emergency rating since it could take this long to put the G1 generator online 
                and alleviate the contingency overload. Based on city load demand and the rating capacity of both U1 and U2, if U1 fails, 
                U2 could experience an overload as high as 1.3 p.u. To find out the maximum emergency rating U2 can handle and for how long, 
                a rating simulation is performed by using the SimuLoad prototype of the loading platform. 
                <br/>
                For a rating analysis, it is common to use a rating philosophy or criterion determined by utility engineers. In addition, 
                unit U2 subject to the projected overload flow rerouting from U1 is relatively old (15 Years in service), with a recent 
                inspection report suggesting that the last time it has been overloaded above 1.2 p.u of its nameplate rating, there was Hydrogen(H2) gas detected. 
                The loading scenario presented next, summarizes the parameters in consideration and their limiting value, all set forth by the utility engineers.
                <br/>
                A typical summer load profile depicted in “Fig. 1(b)” has been submitted. As for the ambient profile, the average daily ambient temperature during 
                this time of the year could fluctuates in a manner that could underestimate or overestimate the marginal load available. To account for such a fluctuation, 
                the ambient temperature shift is quantified as a normal distribution N (0, σ =3K). The average ambient temperature is then shifted in a range of [-3σ, 3σ]. 
                Transformer U2 has been created and its heat-run factory final test report processed with the related data extracted, compiled, and entered into SimuLoad. 
                Additionally, the loading scenario, load and temperature profiles provided by utility engineers has been entered.
              </Typography>

              <Typography variant="h6" gutterBottom component="div">
                  <i><strong>Question:</strong> Can the criteria listed in Table 1 be maintained for 2 hours if the load is kept at 1.3 p.u?</i>         
              </Typography>

              <Grid container space ={1}>
                  <Typography variant="h6" gutterBottom component="div"> 2. Loading scenario setup by utility engineers </Typography>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell > <strong>Operation Constraint</strong> </TableCell>
                        <TableCell >   </TableCell>
                        <TableCell align="right"> <strong>Limit</strong> </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.root}>
                        <TableCell > Transformer U2 current operation mode </TableCell>
                        <TableCell > = </TableCell>
                        <TableCell align="right"> ONAF </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell> Winding Hottest spot temperature </TableCell>
                        <TableCell> ≤ </TableCell>
                        <TableCell align="right"> 140°C</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell> Permissible Insulation Loss-of-life</TableCell>
                        <TableCell> ≤ </TableCell>
                        <TableCell align="right"> 2% </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Top oil temperature limit</TableCell>
                          <TableCell> ≤ </TableCell>
                          <TableCell align="right"> 110°C</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Bubbling temperature limit </TableCell>
                          <TableCell> ≤  </TableCell>
                          <TableCell align="right"> 150°C</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Permissible maximum load (per the IEEE Standard) </TableCell>
                          <TableCell> ≤</TableCell>
                          <TableCell align="right"> 2.0 p.u </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Start time of the overload period </TableCell>
                          <TableCell> = </TableCell>
                          <TableCell align="right"> 6:00 PM</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> End time of the load period</TableCell>
                          <TableCell> = </TableCell>
                          <TableCell align="right"> 8:00 PM </TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Tap position </TableCell>
                          <TableCell> = </TableCell>
                          <TableCell align="right"> Rated, unchanged</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Water activity in oil </TableCell>
                          <TableCell> = </TableCell>
                          <TableCell align="right"> 0.05 p.u</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell> Load level when the Hydrogen(H2) gas was detected </TableCell>
                          <TableCell> = </TableCell>
                          <TableCell align="right"> 1.2 p.u</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>             
              </Grid>
              <br/>
              <Typography variant="h6" gutterBottom component="div"> 3. Simulation Results </Typography>
              <Typography variant="body2" gutterBottom component="div">
                The following illustrates how the SimuLoad prototype assess the established loading scenario with the the load profile and transformer
                U2 heat-run factory test results as inputs.
              </Typography>
              
              <div className={classes.Comment}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
                  <Tab label="System setup" />
                  <Tab label="Graphical Results" />                 
                  <Tab label="Detailed Results" />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Typography variant="body2" gutterBottom component="div">
                    After running the simulation, the results are compiled in the detailed results tab, in terms of parameters subjected to the limits set, 
                    and per cooling mode. At the first stage (ONAN mode), U2 will be able to deliver a marginal load of up to 35 MVA, and a maximum of up
                    to 58 MVA in the second stage (ONAN/ONAF) on the top of its nominal rating without violating the thresholds set, with a modest 0.032% 
                    of loss of life. 

                    The simulation demonstrates that the system operator will have enough time to put the generator online and relieve transformer U2 load 
                    if transformer U1 fails. “Fig.2(a)” depicts what the marginal load looks like during the 2 hours, in ONAN mode, while “Fig. 2(b)” illustrates 
                    the thermal trends which evidence the peak temperatures within the 2 hours’ time period under consideration. “On Fig. 2(c)”, one can see 
                    the impact-less effect of the ambient temperature shift within the [-9K, 9K] range on the available marginal load, regardless the cooling mode operation. 
                    However, and as expected “Fig. 2(d)” depicts how the Hot spot temperature increases linearly by 5K for every 3K shift of the average ambient temperature. 
                    The overall impact of the ambient temperature shift is best captured by the hottest spot temperature and the resulting loss-of-life depicted in “Fig. 3(e)”. 
                    Based on the simulation outcome, transformers U1 and U2 now has an emergency rating of 1.2 p.u to satisfy system operation requirements. 
                    The load limitation is imposed by the load level generating the hydrogen (H2) gas. As a direct application, the new emergency ratings for both power 
                    transformers will need to be implemented in protective relays so that they do not trip before the maximum emergency ratings of the transformer has been reached. 
                    By allowing protective relays to trip when the maximum rating has been reached, the transformer will not overheat, and the insulation damage will be prevented. 
                    This case study is an example of how Planning Engineers can provide a system planning ratings of types A, B, C to be used in case of emergencies, with 
                    loading scenarios, performance factors, and load profiles of their choice.
                  </Typography>
                  <div className ={classes.img}>
                    <img src={loadProfile} alt="load-profile"/>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className ={classes.img}>
                    <img src={SimResult} alt="simulation-result"/>
                  </div>

                  <div className ={classes.img}>
                    <img src={LossVsAmbient} alt="loss-of-life"/>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className ={classes.img}>
                    <img src={DetailedResult} alt="detailed-simulation-result"/>
                  </div>
                </TabPanel>
              </div>              
    </div>
  );
}
