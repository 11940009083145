import React , {useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory }  from "react-router-dom";
import { Grid, Divider, Button, Typography, Box } from '@material-ui/core';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


var nodemailer = require('nodemailer');
var sesTransport = require('nodemailer-ses-transport');

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '100',
    minHeight: '90vh'
  },
  button: {
    color: '#4486a3',
    borderRadius:"0px",
  },
  layout: {
    width: 'auto',
    // border: "1px solid",
    marginTop: '4%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
  }},

  infoArea :
  {  
    // height: "200px", 
    marginTop: "10%", 
    marginLeft: "20px", 
    marginRight:"20px", 
    padding:"10px", 
    backgroundColor: "#FFFFFF"
  },
  country:{
    marginTop:'3px',
    borderColor:'#C0C0C0',
    padding : '6px',
    color:'#808080',
    borderRadius:"1px"

  },
  card:{
    borderRadius: "0px",
  },
}));


export default function QuoteUs() {
const classes = useStyles();
const history = useHistory();
const [personName, setPersonName] =  useState([]);
const [state, setState] = useState({
  cuName:'',
  cuCompany:'',
  cuPhone:'',
  cuEmail:'',
  cuCountry:'US',
  cuRegion:'Washington',
  cuZipcode:'',
  cuAddress1:'',
  cuAddress2:'',
  cuProduct:'',
  cuMessage:'',
  cuFile:[]
});


async function  handleSubmit() { 
  // ===== Uploading user files =============================================
  // if(!isEmpty(state.cuFiles))
  // {
  //   await Storage.put(`Quote-${state.cuFiles}`, state.cuFiles)
  //   .then (result => console.log(result))
  //   .catch(err => console.log(err));
  // }
  console.log('cuName: ' + state.cuName);
  console.log('cuCompany: ' + state.cuCompany);
  console.log('cuPhone: ' + state.cuPhone);
  console.log('cuEmail: ' + state.cuEmail);
  console.log('cuCountry: ' + state.cuCountry);
  console.log('cuRegion: ' + state.cuRegion);
  console.log('cuZipcode: ' + state.cuZipcode);
  console.log('cuAddress1: ' + state.cuAddress1);
  console.log('cuAddress2: ' + state.cuAddress2);
  console.log('cuProduct: ' + state.cuProduct);
  console.log('cuMessage: ' + state.cuMessage);
  // ============Sending an email acknowledging the reviewed files ============
  function callback(error, info) {
    if (error) {
      console.log(error);
    } else {
      console.log('Message sent: ' + info.response);
    }
  }
  

  var mailOptions = {
    from: 'contact@transformer-analytics.com',
    to: 'info2metlab@gmail.com', 
    // bcc:'contact@transformer-analytics.com',
    subject: `New Quote Request [${state.cuName}-${state.cuCompany}]-${state.cuProduct}`,
    // text: 'This is some text',
    html: 
    `<div> This is a notification to signal a new quote request from :<br/><br/>
      <b> Customer Name : ${state.cuName} </b><br/><br/>
      <b> Company Name  : ${state.cuCompany} </b><br/><br/>
      <b> Phone Number  : ${state.cuPhone} </b><br/><br/>
      <b> Email Address : ${state.cuEmail} </b><br/><br/>
      <b> Country Name  : ${state.cuCountry} </b><br/><br/>
      <b> Product Name  : ${state.cuProduct} </b><br/><br/>
      <b> Customer Address  : ${state.cuAddress1} </b><br/><br/>
      <b> Customer Address  : ${state.cuAddress2} </b><br/><br/>
      <b> Customer Address  : ${state.cuMessage} </b><br/><br/>
    </div>`,
  };

  // Send e-mail using AWS SES
  var sesTransporter = nodemailer.createTransport(sesTransport({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION
  }));
  sesTransporter.sendMail(mailOptions, callback);
  history.push({pathname:"/admin/goodbye" , state:{ }});  
}

return (
      <main className={classes.layout}>
        <br/>
        <Card className={classes.card}>
          <CardHeader> 
            <div style={{ width: '100%' }}>
              <Box display="flex">
                <Box width="100%">
                  <Typography variant="subtitle1"><strong>Quote Request Form</strong></Typography>
                </Box>
                {/* <Box flexShrink={1} >
                  <Button size="small"  variant="outlined"
                    href="https://www.hvsscorp.com/products" className={classes.button} >CANCEL </Button> 
                </Box> */}
              </Box>
            </div>
          </CardHeader>
          <CardBody className={classes.CardBody}> 
            <Form className={classes.paper} >
            <FormGroup row>
              <Label for="username" sm={2}> Name </Label>
              <Col sm={10}>
                <Input type="text" name="cuName" id="username" placeholder="Enter your name" onChange={(e) => setState({...state, cuName:e.target.value })} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="company" sm={2}> Company </Label>
              <Col sm={10}>
                <Input type="text" name="cuCompany" id="company" placeholder="Enter your company name" onChange={(e) => setState({...state, cuCompany:e.target.value })}/>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="usermail" sm={2}>Email</Label>
              <Col sm={10}>
                <Input type="email" name="cuEmail" id="usermail" placeholder="Enter your email address" onChange={(e) => setState({...state, cuEmail:e.target.value })}/>
              </Col>
            </FormGroup>
            <FormGroup row>              
                <Label for="country" sm={2}>Country</Label>
                <Col sm={10}>
                <div style={{ width: '100%' }}>
                  <Box display="flex">
                    <Box >
                      <CountryDropdown className={classes.country} value={state.cuCountry} onChange={ country => setState({...state, cuCountry:country })}  tabIndex={1000} />               
                    </Box>
                    <Box  >
                      <RegionDropdown className={classes.country} value={state.cuRegion} country={state.cuCountry} onChange={region => setState({...state, cuRegion:region })} style={{ backgroundColor: 'white'}}  tabIndex={1001} />  
                    </Box>
                  </Box>
                </div>
                </Col> 
            </FormGroup>          
            <FormGroup row> 
              <Label for="cuPhone" sm={2}>Phone </Label>
              <Col sm={10}>
                <PhoneInput country={'us'} value={state.cuPhone} onChange={ phone => setState({...state, cuPhone:phone })} />
              </Col>  
            </FormGroup>
            <FormGroup row>
              <Label for="address1" sm={2}>Address</Label>
              <Col sm={10}>
                <Input type="text" name="cuAddress1" id="address1" placeholder="1234 Main St" onChange={(e) => setState({...state, cuAddress1:e.target.value })}/> 
              </Col>  
            </FormGroup>
            <FormGroup row>
              <Label for="address2" sm={2}>Address 2</Label>
              <Col sm={10}>
                <Input type="text" name="cuAddress2" id="address2" placeholder="Apartment, studio, or floor" onChange={(e) => setState({...state, cuAddress2:e.target.value })}/>
              </Col>  
            </FormGroup>
            <FormGroup row>
              <Label for="product" sm={2}>Select Product</Label>
              <Col sm={10}>
                <Input type="select" name="cuProduct" id="product" multiple onChange={(e) => setState({...state, cuProduct:e.target.value })}>
                  <option>SimuLoad</option>
                  <option>uNamePlate</option>
                  <option>DIY Portal</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="message" sm={2}>Specific Message</Label>
              <Col sm={10}>
                <Input type="textarea" name="cuMessage" id="message" placeholder="Describe any specific requirement you want to add, to customize this product to your needs."  onChange={(e) => setState({...state, cuMessage:e.target.value })}/>
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Label for="File" sm={2}> Add File</Label>
              <Col sm={10}>
                <Input type="file" name="cuFile" id="File" onChange={(e) => setState({...state, cuFile:e.target.value })}/>
              </Col>
            </FormGroup> */}
            <FormGroup check row>
              <Col sm={{ size: 10, offset: 2 }}>
                <Button variant="outlined"  onClick={handleSubmit} className={classes.button} >SUBMIT</Button>
              </Col>
            </FormGroup>
          </Form>
          </CardBody>
        </Card> 
      </main>
  );
}