import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, useHistory } from "react-router-dom";
import clsx from 'clsx';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import Welcome from "views/Begin/Welcome.jsx";
import StartUp from "views/Begin/StartUp.jsx";
import GoodBye from "views/Begin/GoodBye.jsx";
import LoadingHub from "views/Begin/LoadingHub.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import MultiDashboard from "views/Dashboard/MultiDashboard.jsx";
import AgingCalc from "views/Dashboard/AgingCalc.jsx";
import FixedSignUp from "views/Begin/FixedSignUp.jsx";
import SimuLoad from "components/Products/SimuLoad.jsx";
import ContactUs from "./ContactUs.jsx";
import AboutUs from './AboutUs.jsx';
import EULA from './EULA.jsx';
import QuoteUs from './QuoteRequest';
import PlatformFeatures from "views/Features/Platformv1.0.jsx"
import routes from "routes.js";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/hvss-logo.png";
import { isEmpty, getCurrentUsername } from "components/MainPanel/Utilities";
import {Auth, Hub, Logger} from "aws-amplify";
import MainUForm from "views/uNamePlate/UNamePlate";
import MainForm from "views/Begin/MainForm";
import UseCases from "../views/Features/UseCasesv1.jsx";
import ReleasePolicy from "../views/Features/ReleasePolicy.jsx";

var ls = require('local-storage');


let baseTheme = createMuiTheme();
baseTheme = responsiveFontSizes(baseTheme);


let ps;

class Mainboard extends React.Component {
  state = {
    image: image,
    color: "blue",
    hasImage: true,
    fixedClasses: "dropdown",
    mobileOpen: true,
    startDIY: true,
    guideId: "Home",
    // transformers: [],
    isLogged: false,
    sideState :
    { 
      createXfrmOK: false,
      datasheekOk: false,
      profileOK: false,
      scenarioOK: false,
      sessionOK: false,
    },
    userData :{username:'Me', userstatus:'signOut'},
    scanURL :''
  };

  constructor() {
    super();
    this.listener = this.listener.bind(this)
    
    // this.setSideGuide = this.setSideGuide.bind(this);
   }

  //  const logger = new Logger('My-Logger');
  mainPanel = React.createRef();


  
  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  
  handleDrawerOpen = () => {
    this.setState({ mobileOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {    
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    window.addEventListener("resize", this.resizeFunction);
    this.listener();
    Hub.listen('auth', this.listener);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
    Hub.remove('auth');
  }

  setSideGuide = (varName) => {   
    switch(varName)
    {
      case "createXfrmOK":
        {
            this.setState(prevState => ({
              sideState: {                   
                  ...prevState.sideState,    
                  createXfrmOK: !prevState.sideState.createXfrmOK       
              }
            }))
          break;
        }
      case "datasheetOK":
        {
            this.setState(prevState => ({
              sideState: {                   
                  ...prevState.sideState,    
                  datasheetOk: !prevState.sideState.datasheetOk       
              }
            }))
            break;
        }
      case "profileOK":
        {
          this.setState(prevState => ({
            sideState: {                   
                ...prevState.sideState,    
                profileOK: !prevState.sideState.profileOK       
            }
          }))
          break;
        }
      case "scenarioOK":
        {
          this.setState(prevState => ({
            sideState: {                   
                ...prevState.sideState,    
                scenarioOK: !prevState.sideState.scenarioOK       
            }
          }))
          break;
        }
      case "sessionOK":
       { 
          this.setState(prevState => ({
            sideState: {                   
                ...prevState.sideState,    
                sessionOK: !prevState.sideState.sessionOK       
            }
          }))
          break;
       }
       default: break;
    }
  }

  setUserData = (authData) => {
      if(!isEmpty(authData)){
        this.setState({userData: authData});
      } 
  }

  async listener(data)
 {
    if(!isEmpty(data))
    {
      switch (data.payload.event) {
        case 'signIn':
        {
          // logger.error('user signed in'); 
          const username = await getCurrentUsername();
          if (username) 
          {
            this.setUserData ({username:username, userstatus:'signIn'});
            // console.log ('===>>> user signed in' + "===" + userData.username);  
          }
          break;
        }
        case 'signUp':
          {   
            this.setUserData ({username:'Me', userstatus:'signUp'});
            break;
          }
        case 'signOut':
          {
            // console.log ('===>>> user signed out');
            this.setUserData ({username:'Me', userstatus:'signOut'});
            break;
          }
        case 'signIn_failure':
          {
            console.log ('===>>> user sign in failed');
            this.setUserData ({username:'Me', userstatus:'signIn_failure'});
            break;
          }
        case 'configured':
          {
            console.log ('===>>> the Auth module is configured');
            this.setUserData ({username:'Me', userstatus:'configured'});
            break;
          }
          default: break;
      }
    }
    else {
      const username = await getCurrentUsername();
      if (username) 
      {
        this.setUserData ({username:username, userstatus:'signIn'});
      }
    }
  }

  
  async MeSignOut () {
    await Auth.signOut({global: true})    
  }  

  setDIYvalue =(diyStatus) =>
  {
    this.setState({ startDIY: diyStatus});
  }

  setGuideId = (guideId) =>
  {
    this.setState({ guideId: guideId});
  }

  createScan =(scanURL) =>
  {
    this.setState({ scanURL: scanURL});
    ls.set('scanURL', scanURL);
  }

  // // Adding new Asset to the list of assets
  // AddNewAsset = (transFormers) =>
  // { 
  //   // console.log("Admin Transformer list : ===" + JSON.stringify(transFormers));
  //   this.setState({ transformers: this.state.transformers.concat(transFormers) });
  // }


  render() {
    const { classes, ...rest } = this.props;
    return (    
       <MuiThemeProvider theme={baseTheme}>
         <div className={classes.root}>
          <Sidebar routes={routes} logo={logo} image={this.state.image} handleDrawerClose={this.handleDrawerClose}  open={this.state.mobileOpen} color={this.state.color} sideGuideState ={this.state.sideState} guideId = {this.state.guideId}/>
          <Navbar routes={routes} handleDrawerOpen={this.handleDrawerOpen} open ={this.state.mobileOpen} startDIY ={this.state.startDIY}  
                setSideGuide ={this.setSideGuide} AddNewAsset={this.AddNewAsset} userData ={this.state.userData} MeSignOut={this.MeSignOut} {...rest} />
          <main className={clsx(classes.content, { [classes.contentShift]: this.state.mobileOpen})} ref={this.mainPanel}>
            <div className={classes.drawerHeader} />
              <Switch>
                <Route path="/admin/dashboard"  render={(props) => <Dashboard   userData ={this.state.userData}  setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId} setSideGuide ={this.setSideGuide} {...rest}/>}/>
                <Route path="/admin/multidashboard" render={(props) => <MultiDashboard   userData ={this.state.userData}  setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId} setSideGuide ={this.setSideGuide} {...rest}/>}/>              
                <Route path="/admin/lifestimation" render={(props) => <AgingCalc userData ={this.state.userData} setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId}/>}/>     
                <Route path="/admin/welcome"    render={(props) => <Welcome     userData ={this.state.userData}  transFormers ={this.state.transFormers}  setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId}  {...rest} />}/>
                <Route path="/admin/startup"    render={(props) => <StartUp     userData ={this.state.userData}     setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId}/>}/>
                <Route path="/admin/loadinghub" render={(props) => <LoadingHub theme={baseTheme}  userData = {this.state.userData}     setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId} scanURL={this.state.scanURL}/>}/>
                <Route path="/admin/mainform"   render={(props) => <MainForm    userData ={this.state.userData}     setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId}/>}/>
                <Route path="/admin/simuload"   render={(props) => <SimuLoad    userData ={this.state.userData}     setDIYvalue = {this.setDIYvalue}  setGuideId = {this.setGuideId}/>}/>
                <Route path="/admin/login"      render={(props) => <FixedSignUp setUserData ={this.setUserData}     setDIYvalue = {this.setDIYvalue}/>}/>
                <Route path="/admin/contact-us" render={(props) => <ContactUs   setDIYvalue = {this.setDIYvalue}/>}/>
                <Route path="/admin/about-us"   render={(props) => <AboutUs   setDIYvalue = {this.setDIYvalue}/>}/>
                <Route path="/admin/eula"       render={(props) => <EULA   setDIYvalue = {this.setDIYvalue}/>}/>
                <Route path="/admin/features"   render={(props) => <PlatformFeatures setDIYvalue = {this.setDIYvalue} setGuideId = {this.setGuideId}/>}/>
                <Route path="/admin/nameplate"  render={(props) => <MainUForm userData ={this.state.userData}  setDIYvalue = {this.setDIYvalue} setGuideId = {this.setGuideId} createScan ={this.createScan} />}/>
                <Route path="/admin/quote-us"   render={(props) => <QuoteUs  setDIYvalue = {this.setDIYvalue}/>}/>   
                <Route path="/admin/goodbye"    render={(props) => <GoodBye  setDIYvalue = {this.setDIYvalue}/>}/> 
                <Route path="/admin/usecase"    render={(props) => <UseCases setDIYvalue = {this.setDIYvalue}/>}/>
                <Route path="/admin/release"    render={(props) => <ReleasePolicy setDIYvalue = {this.setDIYvalue}/>}/>                                        
              </Switch>
          </main>        
          <Footer />
          {/* <FixedPlugin setSideGuide ={this.setSideGuide} handleFixedClick={this.handleFixedClick} fixedClasses={this.state.fixedClasses} userData ={this.state.userData} setDIYvalue = {this.setDIYvalue} {...rest}/> */}
        </div>
      </MuiThemeProvider>
    );
  }
}

Mainboard.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Mainboard);
