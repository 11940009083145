import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import { Card, CardBody} from 'reactstrap';
import hvsslogo from "assets/img/hvss-logo.png";

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '100',
    // width: 'fit-content',
    minHeight: '90vh'
  },

  layout: {
    width: 'auto',
    // border: "1px solid",
    marginTop: '10%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
  }},

  infoArea :
  {  
    // height: "200px", 
    marginTop: "10%", 
    marginLeft: "20px", 
    marginRight:"20px", 
    padding:"10px", 
    backgroundColor: "#FFFFFF"
  },
  
  card:{
    borderRadius: "0px",
  },
}));



export default function ContactUs() {
const classes = useStyles();

return (
      <main className={classes.layout}>
        <br/>
         <form className={classes.form} >
          <Card className={classes.card}>
            <CardBody className={classes.CardBody}> 
                    <img src={hvsslogo} alt="hvss-logo"/>    
              <div className = {classes.infoArea}>
                <Typography variant="h5" gutterBottom> {<InfoIcon/>} Contact Us </Typography>
                  <Divider/>
                  <br/>
                  <Typography variant="h6" gutterBottom> HIGH VOLTAGE SOFTWARE SERVICES </Typography>
                  <Typography >
                    <Typography>4453 Beaubien-East, Suite #5</Typography> 
                    <Typography>Montreal, QC, CA H1T 1T3</Typography>
                    <Typography><strong> E-mail : </strong> contact@transformer-analytics.com</Typography>
                    <Typography><strong>Tel. : </strong> 514.637.1395</Typography>
                    <Typography>A Division of METLAB Research Inc.</Typography>
                  </Typography>           
              </div>   
            </CardBody>
          </Card> 
        </form>
      </main>
  );
}
