// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1696c7b9-2bbf-4c74-b203-dd2aeb61c957",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Su1SZjd9k",
    "aws_user_pools_web_client_id": "6c75qf0sjhlc83ivmtbjn5qj8",
    "oauth": {},
    "aws_user_files_s3_bucket": "ratingbucket120415-analytics",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "amplify-loadrating-hvss-225555-deployment-analytics",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d8jlukk84hmt0.cloudfront.net"
};


export default awsmobile;
