import React, { useEffect, useStyles } from 'react';
import styled from '@react-pdf/styled-components';
import {Table, TableCell,TableHeader,TableBody, DataTableCell} from "@david.kucsai/react-pdf-table";
import { Document, Page, Text, Link, Font, View, Image, StyleSheet } from '@react-pdf/renderer';
import logo from "assets/img/hvss-logo.png";
import {colors, getTimeFromMins, isEmpty, onlyUnique} from 'components/MainPanel/Utilities.jsx';
import LoadingTableItems from './LoadingTableItems';
import TempRiseTableItems from './TempRiseTableItems';
import DesignTableItems from './DesignTableItems';
import StandardTableItems from './StandardTableItems';
import { getAmplifyUserAgent } from '@aws-amplify/core';
import { Typography } from '@material-ui/core';


const Title = styled.Text`
  font-size: 16px;
  text-align: center;
  font-family: 'Oswald';
`;


const Figure = styled.Text`
  font-weight: bold;
`;

const Subtitle = styled.Text`
margin-top: 5px;
  font-size: 14px;
  font-family: 'Oswald';
`;

const SubtitleFlow = styled.Text` 
  margin-top: 5px;
  margin-left: 12px;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: 'Oswald';
`;

const Author = styled.Text`
  font-size: 12px;
  text-align: center;
  margin-bottom: 40px;
`;

const Caption = styled.Text`
  font-size: 10px;
  text-align: center;
  margin-bottom: 40px;
`;

const Paragraph = styled.Text`
  margin: 11px;
  text-align: justify;
  font-family: 'Times-Roman';
`;

const List = styled.Text`
  margin-left:25px;
  text-align: justify;
  font-family: 'Times-Roman';
`;

const Picture = styled.Image`
  height: 200px;     
  width: 320px; 
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom : 5px;
`;

const Logo = styled.Image`
  height: 50px;     
  width: 200px;  
  align-self: flex-start;
  margin-left: -25px;
`;

const HeaderRigth = styled.View`
  display: flex;  
  flex: 1;
  flex-direction: column;  
  color: grey;
  font-size: 8px;
  align-self: flex-end;
`;

const HeaderLeft = styled.View`
  display: flex;  
  flex: 1;
  align-self: flex-end;
`;

const HeaderText = styled.Text`
  color: grey;
  font-size: 8px;
  align-self: flex-end;
`;

const MainHeader = styled.View`
  display: flex;  
  flex-direction: row;
  margin-top: 0px;
  border-bottom: 1px;
`;

const Footer = styled.Text`
  left: 0px;
  right: 0px;
  color: grey;
  bottom: 30px;
  // font-size: 12px;
  position: absolute;
  text-align: center;
`;


Font.register(
  'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  { family: 'Oswald' },
);


const Body = styled.Page`
  display: flex;  
  flex: 1;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 60px;
  padding-right: 30px;
  padding-left: 30px;
  font-family: 'Helvetica';
  font-size: 11px;
`;

const styles = StyleSheet.create({
  row: {
      flexDirection: 'row',
      // borderBottomColor: '#cccccc',
      // borderBottomWidth: 1,
      marginTop: '25px',
      marginBottom: '2px',
      alignItems: 'center',
      height: 230,
      fontSize: 9
  },
  rowb: {
    flexDirection: 'row',
    // borderBottomColor: '#cccccc',
    // borderBottomWidth: 1,
    marginTop: '10px',
    marginBottom: '2px',
    alignItems: 'center',
    height: 230,
    fontSize: 9
},
Comment: {
  padding: "5px",
  borderLeftColor: "#6699cc",
  borderLeft: "8px solid",
  backgroundColor : "#f6f6f6"
},
  Left: {
      width: '45%',
      textAlign: 'center',
      // borderRightColor:  '#cccccc',
      // borderRightWidth: 1,
      paddingLeft: 8,
  },
  Right: {
      width: '45%',
      textAlign: 'center',
      // borderRightColor:  '#cccccc',
      // borderRightWidth: 1,
      paddingLeft: 8,
  },
  caption: {
    fontSize: 9
  }
});

export default function LoadingReport (props) {
  var dataSheet     = props.dataSheet || [];
  var lCoolossData  = props.lCooling || [];
  var lRatedData    = props.lCooling || [];
  var loadStandards = props.loadStandards|| [];
  let Company       = props.Company || '';
  let XfrmName      = props.xfrmer.XfrmName || '';
  let Rating1       = props.xfrmer.Rating1 || '';
  let Rating2       = props.xfrmer.Rating2 || '';
  let Rating3       = props.xfrmer.Rating3 || '';
  let ImagURLs      = isEmpty(props.ImagURLs)? [ { Tag:'load-profile', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'load-vs-ambient', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'temperature-vs-time', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'hotspot-vs-ambient', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'margin-vs-time', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'loss-vs-ambient', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'loss-vs-time', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'load-vs-duration', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="},
                                          { Tag:'loss-vs-duration', URL:"data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="}] : props.ImagURLs;
  let NamePlate     = props.NamePlate || [];
  var lNameSet      = new Set(props.NamePlate.map(p => p.name));

  console.log ("============================");
  console.log ("====dataSheet======" + JSON.stringify(dataSheet));
  console.log ("\n\n");
  console.log ("====lCooling======" + JSON.stringify(lCoolossData));

  useEffect(() =>
  {
    if(!isEmpty(dataSheet)) {
      dataSheet  = [
        { Description: 'Liquid Insulation Type',                  Value: dataSheet.find(item => item.VarName ==='FluidType').Value, Unit: '-'},
        { Description: 'Solid Insulation Type',                   Value: dataSheet.find(item => item.VarName ==='SolidInsul').Value, Unit: 'kV'},
        // Design Data
        { Description: 'Weight of Tank and Fittings',             Value: dataSheet.find(item => item.VarName ==='MassTank').Value, Unit: 'pounds' },
        { Description: 'Weight of Core and Coils',                Value: dataSheet.find(item => item.VarName ==='MassCoreCoil').Value, Unit: 'pounds' },
        { Description: 'Per Unit Winding Height to Hot Spot',     Value: dataSheet.find(item => item.VarName ==='PerUnitHeighToHotSpot').Value, Unit: 'p.u'},
        { Description: 'Per Unit Eddy Loss @ Hot Spot',           Value: dataSheet.find(item => item.VarName ==='PerUnitHSEddyLoss').Value, Unit: 'p.u' },
        { Description: 'Volume of fluid',                         Value: dataSheet.find(item => item.VarName ==='GallonsOfFluid').Value, Unit: 'Gallons' },
        { Description: 'Winding Type',                            Value: dataSheet.find(item => item.VarName ==='WindingConductor').Value, Unit: '-' },
        { Description: 'Winding Time Constant',                   Value: dataSheet.find(item => item.VarName ==='WindTimeCst').Value, Unit: 'Minutes'},
        // Core Data
        { Description: 'Rated Ambient (Θᴀ,ʀ)',                    Value: dataSheet.find(item => item.VarName ==='RatedAmbient').Value, Unit: 'Celsius' },
        { Description: 'Core Losses, (Pcr)',                      Value: dataSheet.find(item => item.VarName ==='CoreLosses').Value, Unit: 'Watts'},
        { Description: 'Core Loss During Over Excitation',        Value: dataSheet.find(item => item.VarName ==='CoreLossesOverEx').Value, Unit: 'Watts' },
        { Description: 'Time When Over Excitation Occurs (hour)', Value: dataSheet.find(item => item.VarName ==='TimeOverExcitation').Value, Unit: 'Hour'},
        
        { Description: 'Cooling Power per Fan/Coolers',           Value: dataSheet.find(item => item.VarName ==='power_per_fan').Value, Unit: 'Watts' },
        { Description: 'Cooling Power per Radiator',              Value: dataSheet.find(item => item.VarName ==='power_per_radiator').Value, Unit: 'Watts' },
        { Description: 'Cooling Power per Pump',                  Value: dataSheet.find(item => item.VarName ==='power_per_pump').Value, Unit: 'Watts' },
        // Load Tap Changer Provision
        { Description: '', Value: '', Unit: '', Info: ''  },
        { Description:'Tap Range [±]',                            Value: dataSheet.find(item => item.VarName ==='LtcTapRange').Value, Unit: '%'},
        { Description:'Rated Tap Position',                       Value: dataSheet.find(item => item.VarName ==='LtcRatedTapPos').Value, Unit:'-'},
        { Description:'Number of Tap Steps',                      Value: dataSheet.find(item => item.VarName ==='LtcTapSteps').Value, Unit:'-' },
        { Description:'Winding with the adjustable tap (HV/LV)',  Value: dataSheet.find(item => item.VarName ==='LtcWinding').Value,  Unit:'-'},
        { Description:'Contact temperature rise over oil at rated current(ΔΘc,ʀ)',  Value: dataSheet.find(item => item.VarName ==='LtcRatedOilRise').Value, Unit:'Kelvin'},
        { Description:'Location of the LTC (INTEGRATED/SEPARATED)',                 Value: dataSheet.find(item => item.VarName ==='LtcLocation').Value,   Unit:'-'},
        { Description:'Rated step capacity',                                        Value: dataSheet.find(item => item.VarName ==='LtcCapacity').Value,  Unit:'kVA'}
      ];
    }
    if(!isEmpty(lRatedData)) {
      if(lRatedData.length === 1)
      {
        lCoolossData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling,  Unit: ' '},
          { Description: 'One Per unit base kVA for load cycle', Stage1: Rating1, Unit: 'kVA' },
          { Description: 'Rated Avg. Winding Rise (AWR)', Stage1: lRatedData[0].WindingTempBase,  Unit: 'Kelvin'},
          { Description: 'Tested Avg. Winding Rise (ΔΘᴡ⁄ᴀ,ʀ)',  Stage1: lRatedData[0].AvgWindingRise,  Unit: 'Kelvin'},
          { Description: 'Hot Spot Rise (ΔΘн,ᴀ)', Stage1: lRatedData[0].HotSpotRise, Unit: 'Kelvin'},
          { Description: 'Tested Top Oil Rise (ΔΘᴛᴏ,ʀ)', Stage1: lRatedData[0].TopOilRise, Unit: 'Kelvin'},
          { Description: 'Bottom Oil Rise (ΔΘʙᴏ,ʀ)', Stage1: lRatedData[0].BottomOilRise,  Unit: 'Kelvin' },
        ]

        lRatedData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling,  Unit: ' '},
          { Description: 'Base Rating for Losses', Stage1: Rating1, Unit: 'kVA'},
          { Description: 'Temperature Base for Losses', Stage1: isEmpty(lRatedData[0].LossTempBase)? '': lRatedData[0].LossTempBase, Unit: 'Celsius'},
          { Description: 'I2R Losses, (Pw)',  Stage1: isEmpty(lRatedData[0].WindI2RLosses)? '': lRatedData[0].WindI2RLosses,  Unit: 'Watts'},
          { Description: 'Winding Eddy Losses, (Pe)', Stage1: isEmpty(lRatedData[0].WindEddyLoss)? '': lRatedData[0].WindEddyLoss, Unit: 'Watts'},
          { Description: 'Stray Losses, (Ps)',  Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, Unit: 'Watts' },
          //Cooling Configuration
          { Description: '', Stage1: '',  Unit: '' },  
          { Description: ' ',Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling, Unit: ' '},
          { Description: '', Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, Unit: ' '},
          { Description: 'Number of Fans or Coolers', Stage1: isEmpty(lRatedData[0].numFan)? '': lRatedData[0].numFan, Unit: '-'},
          { Description: 'Number of Radiators', Stage1: isEmpty(lRatedData[0].numRadiator)? '': lRatedData[0].numRadiator, Unit: 'Watts' },
          { Description: 'Number of Pumps', Stage1: isEmpty(lRatedData[0].numPumps)? '': lRatedData[0].numPumps,  Unit: '-' },
          { Description: '', Stage1: '',  Unit: '' },
          { Description:'Primary Rated Amps(H)',  Stage1: isEmpty(lRatedData[0].HRatedAmps)? '': lRatedData[0].HRatedAmps, Unit: 'Amps' },
          { Description:'Secondary Rated Amps(X)',Stage1: isEmpty(lRatedData[0].XRatedAmps)? '': lRatedData[0].XRatedAmps, Unit: 'Amps' },
          { Description:'Tertiary Rated Amps(T)', Stage1: isEmpty(lRatedData[0].TRatedAmps)? '': lRatedData[0].TRatedAmps, Unit: 'Amps' },
        ]
      }
      else if(lRatedData.length === 2)
      {
        lCoolossData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling, Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling,  Unit: ' '},
          { Description: 'One Per unit base kVA for load cycle', Stage1: Rating1, Stage2: Rating2, Unit: 'kVA' },
          { Description: 'Rated Avg. Winding Rise (AWR)', Stage1: lRatedData[0].WindingTempBase, Stage2: lRatedData[1].WindingTempBase, Unit: 'Kelvin'},
          { Description: 'Tested Avg. Winding Rise (ΔΘᴡ⁄ᴀ,ʀ)',  Stage1: lRatedData[0].AvgWindingRise, Stage2: isEmpty(lRatedData[1].AvgWindingRise)? '': lRatedData[1].AvgWindingRise, Unit: 'Kelvin'},
          { Description: 'Hot Spot Rise (ΔΘн,ᴀ)', Stage1: lRatedData[0].HotSpotRise, Stage2: isEmpty(lRatedData[1].HotSpotRise)? '': lRatedData[1].HotSpotRise, Unit: 'Kelvin'},
          { Description: 'Tested Top Oil Rise (ΔΘᴛᴏ,ʀ)', Stage1: lRatedData[0].TopOilRise, Stage2: isEmpty(lRatedData[1].TopOilRise)? '': lRatedData[1].TopOilRise, Unit: 'Kelvin'},
          { Description: 'Bottom Oil Rise (ΔΘʙᴏ,ʀ)', Stage1: lRatedData[0].BottomOilRise, Stage2: isEmpty(lRatedData[1].BottomOilRise)? '': lRatedData[1].BottomOilRise, Unit: 'Kelvin' },
        ]

        lRatedData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling, Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling,  Unit: ' '},
          { Description: 'Base Rating for Losses', Stage1: Rating1, Stage2: Rating2,  Unit: 'kVA'},
          { Description: 'Temperature Base for Losses', Stage1: isEmpty(lRatedData[0].LossTempBase)? '': lRatedData[0].LossTempBase, 
                                                        Stage2: isEmpty(lRatedData[1].LossTempBase)? '': lRatedData[1].LossTempBase, Unit: 'Celsius'},
          { Description: 'I2R Losses, (Pw)',  Stage1: isEmpty(lRatedData[0].WindI2RLosses)? '': lRatedData[0].WindI2RLosses, 
                                              Stage2: isEmpty(lRatedData[1].WindI2RLosses)? '': lRatedData[1].WindI2RLosses, Unit: 'Watts'},
          { Description: 'Winding Eddy Losses, (Pe)', Stage1: isEmpty(lRatedData[0].WindEddyLoss)? '': lRatedData[0].WindEddyLoss, 
                                                      Stage2: isEmpty(lRatedData[1].WindEddyLoss)? '': lRatedData[1].WindEddyLoss, Unit: 'Watts'},
          { Description: 'Stray Losses, (Ps)',  Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, 
                                                Stage2: isEmpty(lRatedData[1].WindStrayLosses)? '': lRatedData[1].WindStrayLosses, Unit: 'Watts' },
          
          //Cooling Configuration
          { Description: '', Stage1: '', Stage2: '', Unit: ''},
          { Description: ' ',Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling 
                            ,Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling, Unit: ' '},
          { Description: '', Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, 
                            Stage2: isEmpty(lRatedData[1].WindStrayLosses)? '': lRatedData[1].WindStrayLosses, Unit: ' '},
          { Description: 'Number of Fans or Coolers', Stage1: isEmpty(lRatedData[0].numFan)? '': lRatedData[0].numFan, 
                                                      Stage2: isEmpty(lRatedData[1].numFan)? '': lRatedData[1].numFan, Unit: '-'},
          { Description: 'Number of Radiators', Stage1: isEmpty(lRatedData[0].numRadiator)? '': lRatedData[0].numRadiator, 
                                                Stage2: isEmpty(lRatedData[1].numRadiator)? '': lRatedData[1].numRadiator, Unit: 'Watts' },   
          { Description: 'Number of Pumps', Stage1: isEmpty(lRatedData[0].numPumps)? '': lRatedData[0].numPumps, 
                                                  Stage2: isEmpty(lRatedData[1].numPumps)? '': lRatedData[1].numPumps, Unit: '-' },    
          { Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: '' },
          { Description:'Primary Rated Amps(H)',  Stage1: isEmpty(lRatedData[0].HRatedAmps)? '': lRatedData[0].HRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].HRatedAmps)? '': lRatedData[1].HRatedAmps, Unit: 'Amps' },   
          { Description:'Secondary Rated Amps(X)',Stage1: isEmpty(lRatedData[0].XRatedAmps)? '': lRatedData[0].XRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].XRatedAmps)? '': lRatedData[1].XRatedAmps, Unit: 'Amps' },   
          { Description:'Tertiary Rated Amps(T)', Stage1: isEmpty(lRatedData[0].TRatedAmps)? '': lRatedData[0].TRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].TRatedAmps)? '': lRatedData[1].TRatedAmps, Unit: 'Amps' },
        ]
      }
      else
      {
        lCoolossData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling 
                            , Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling 
                            , Stage3: isEmpty(lRatedData[2].XfrmerCooling)? '': lRatedData[2].XfrmerCooling,  Unit: ' '},

          { Description: 'One Per unit base kVA for load cycle', Stage1: Rating1, Stage2: Rating2, Stage3: Rating3, Unit: 'kVA' },
          { Description: 'Rated Avg. Winding Rise (AWR)', 
                          Stage1: lRatedData[0].WindingTempBase, 
                          Stage2: lRatedData[1].WindingTempBase,  
                          Stage3: lRatedData[2].WindingTempBase, Unit: 'Kelvin'},

          { Description: 'Tested Avg. Winding Rise (ΔΘᴡ⁄ᴀ,ʀ)',  Stage1: lRatedData[0].AvgWindingRise,  
                                                                Stage2: isEmpty(lRatedData[1].AvgWindingRise)? '': lRatedData[1].AvgWindingRise,  
                                                                Stage3: isEmpty(lRatedData[2].AvgWindingRise)? '': lRatedData[2].AvgWindingRise, Unit: 'Kelvin'},

          { Description: 'Hot Spot Rise (ΔΘн,ᴀ)', Stage1: lRatedData[0].HotSpotRise,  
                                                  Stage2: isEmpty(lRatedData[1].HotSpotRise)? '': lRatedData[1].HotSpotRise,  
                                                  Stage3: isEmpty(lRatedData[2].HotSpotRise)? '': lRatedData[2].HotSpotRise, Unit: 'Kelvin'},

          { Description: 'Tested Top Oil Rise (ΔΘᴛᴏ,ʀ)', Stage1: lRatedData[0].TopOilRise, 
                                                        Stage2: isEmpty(lRatedData[1].TopOilRise)? '': lRatedData[1].TopOilRise, 
                                                        Stage3: isEmpty(lRatedData[2].TopOilRise)? '': lRatedData[2].TopOilRise, Unit: 'Kelvin'},

          { Description: 'Bottom Oil Rise (ΔΘʙᴏ,ʀ)', Stage1: lRatedData[0].BottomOilRise, 
                                                    Stage2: isEmpty(lRatedData[1].BottomOilRise)? '': lRatedData[1].BottomOilRise, 
                                                    Stage3: isEmpty(lRatedData[2].BottomOilRise)? '': lRatedData[2].BottomOilRise, Unit: 'Kelvin' },
        ]

        lRatedData = [
          { Description: ' ', Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling 
          , Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling 
          , Stage3: isEmpty(lRatedData[2].XfrmerCooling)? '': lRatedData[2].XfrmerCooling,  Unit: ' '},

          { Description: 'Base Rating for Losses', Stage1: Rating1, Stage2: Rating2, Stage3: Rating3,  Unit: 'kVA'},
          { Description: 'Temperature Base for Losses', Stage1: isEmpty(lRatedData[0].LossTempBase)? '': lRatedData[0].LossTempBase, 
                                                        Stage2: isEmpty(lRatedData[1].LossTempBase)? '': lRatedData[1].LossTempBase, 
                                                        Stage3: isEmpty(lRatedData[2].LossTempBase)? '': lRatedData[2].LossTempBase, Unit: 'Celsius'},

          { Description: 'I2R Losses, (Pw)',  Stage1: isEmpty(lRatedData[0].WindI2RLosses)? '': lRatedData[0].WindI2RLosses, 
                                              Stage2: isEmpty(lRatedData[1].WindI2RLosses)? '': lRatedData[1].WindI2RLosses, 
                                              Stage3: isEmpty(lRatedData[2].WindI2RLosses)? '': lRatedData[2].WindI2RLosses, Unit: 'Watts'},

          { Description: 'Winding Eddy Losses, (Pe)', Stage1: isEmpty(lRatedData[0].WindEddyLoss)? '': lRatedData[0].WindEddyLoss, 
                                                      Stage2: isEmpty(lRatedData[1].WindEddyLoss)? '': lRatedData[1].WindEddyLoss, 
                                                      Stage3: isEmpty(lRatedData[2].WindEddyLoss)? '': lRatedData[2].WindEddyLoss, Unit: 'Watts'},

          { Description: 'Stray Losses, (Ps)',  Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, 
                                                Stage2: isEmpty(lRatedData[1].WindStrayLosses)? '': lRatedData[1].WindStrayLosses, 
                                                Stage3: isEmpty(lRatedData[2].WindStrayLosses)? '': lRatedData[2].WindStrayLosses, Unit: 'Watts' },
          
          //Cooling Configuration
          { Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: ''},
          
          { Description: ' ',Stage1: isEmpty(lRatedData[0].XfrmerCooling)? '': lRatedData[0].XfrmerCooling 
                            ,Stage2: isEmpty(lRatedData[1].XfrmerCooling)? '': lRatedData[1].XfrmerCooling 
                            ,Stage3: isEmpty(lRatedData[2].XfrmerCooling)? '': lRatedData[2].XfrmerCooling,  Unit: ' '},

          { Description: '', Stage1: isEmpty(lRatedData[0].WindStrayLosses)? '': lRatedData[0].WindStrayLosses, 
                            Stage2: isEmpty(lRatedData[1].WindStrayLosses)? '': lRatedData[1].WindStrayLosses, 
                            Stage3: isEmpty(lRatedData[2].WindStrayLosses)? '': lRatedData[2].WindStrayLosses,  Unit: ' '},

          { Description: 'Number of Fans or Coolers', Stage1: isEmpty(lRatedData[0].numFan)? '': lRatedData[0].numFan, 
                                                      Stage2: isEmpty(lRatedData[1].numFan)? '': lRatedData[1].numFan,
                                                      Stage3: isEmpty(lRatedData[2].numFan)? '': lRatedData[2].numFan, Unit: '-'},

          { Description: 'Number of Radiators', Stage1: isEmpty(lRatedData[0].numRadiator)? '': lRatedData[0].numRadiator, 
                                                Stage2: isEmpty(lRatedData[1].numRadiator)? '': lRatedData[1].numRadiator, 
                                                Stage3: isEmpty(lRatedData[2].numRadiator)? '': lRatedData[2].numRadiator, Unit: 'Watts' },
          
          { Description: 'Number of Pumps', Stage1: isEmpty(lRatedData[0].numPumps)? '': lRatedData[0].numPumps, 
                                                  Stage2: isEmpty(lRatedData[1].numPumps)? '': lRatedData[1].numPumps, 
                                                  Stage3: isEmpty(lRatedData[2].numPumps)? '': lRatedData[2].numPumps, Unit: '-' },
          
          { Description: '', Stage1: '', Stage2: '', Stage3: '', Unit: ''},
          { Description:'Primary Rated Amps(H)',  Stage1: isEmpty(lRatedData[0].HRatedAmps)? '': lRatedData[0].HRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].HRatedAmps)? '': lRatedData[1].HRatedAmps, 
                                                  Stage3: isEmpty(lRatedData[2].HRatedAmps)? '': lRatedData[2].HRatedAmps, Unit: 'Amps' },
          
          { Description:'Secondary Rated Amps(X)',Stage1: isEmpty(lRatedData[0].XRatedAmps)? '': lRatedData[0].XRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].XRatedAmps)? '': lRatedData[1].XRatedAmps, 
                                                  Stage3: isEmpty(lRatedData[2].XRatedAmps)? '': lRatedData[2].XRatedAmps, Unit: 'Amps' },
          
          { Description:'Tertiary Rated Amps(T)', Stage1: isEmpty(lRatedData[0].TRatedAmps)? '': lRatedData[0].TRatedAmps, 
                                                  Stage2: isEmpty(lRatedData[1].TRatedAmps)? '': lRatedData[1].TRatedAmps, 
                                                  Stage3: isEmpty(lRatedData[2].TRatedAmps)? '': lRatedData[2].TRatedAmps, Unit: 'Amps' },
        ]
      }
    }
  }, []);


  return(
  <Document>
{/* 
    <Body wrap>
      <MainHeader fixed>
        <Logo src={logo} />
        <HeaderLeft/>
        <HeaderRigth >
          <HeaderText> The Digital Machine Developer</HeaderText>
          <HeaderText> www.hvsscorp.com</HeaderText>
          <HeaderText> info@hvsscorp.com</HeaderText>
        </HeaderRigth>
      </MainHeader>
      <Title>{"< " + XfrmName + " " + (parseFloat(Rating1)/1000) + "/" + (parseFloat(Rating2)/1000) + "/" + (parseFloat(Rating3)/1000) + "MVA >"} Load Study Report</Title>
      <SubtitleFlow> Abstract </SubtitleFlow>
      <Paragraph>
        Generally, transformers are designed to function within their nameplate ratings, yet, in certain situations, 
        they are loaded over the nameplate ratings due to a failure or fault in the power system, 
        the existence of possible contingencies on the transmission lines and/or economic considerations. The currrent 
        loading study is performed to assess the loading capability of the {"< " + XfrmName + " " + (parseFloat(Rating1)/1000) + "/" + (parseFloat(Rating2)/1000) + 
          "/" + (parseFloat(Rating3)/1000) + "MVA >"} transformer. The present report constitutes a summary of inputs provided by the user, and outputs generated as results of the study.
      </Paragraph>

      <Subtitle> I. General Inputs Provided by Customer </Subtitle>
      <List>The following data are provided as inputs to the study, by {Company} the approriate personnel (Engineers, Load dispatchers, or Assets managers) </List>
      <List> - Nameplate data and Factory test results</List>
      <List> - Factory temperature rise test results </List>
      <List> - Load profiles </List>
      <List> - Loading scenarios: User-defined loading boundaries  </List>  
      <View>  
        <SubtitleFlow> 1.1 Nameplate and Mechanical data </SubtitleFlow>  
        <Paragraph>
          The transformer nameplate data and the Heat-run factory final test results attributes and values are tabulated in the datasheet presented below. The datasheet complies with 
          the inputs requirements outlined in the IEEE/IEC loading guidelines.
        </Paragraph>   
        <DesignTableItems rowData = {dataSheet} />
        <SubtitleFlow> 1.2 Temperature rise test attributes </SubtitleFlow>    
        <TempRiseTableItems rowData = {lCoolossData} />
        <SubtitleFlow> 1.3 Losses and cooling configuration </SubtitleFlow>      
        <TempRiseTableItems rowData = {lRatedData} />
      </View>
      <View> 
        <SubtitleFlow> 1.4 Load profiles </SubtitleFlow> 
        <Paragraph>
          The following constant load profile with a typical summer ambient temperature cycle has been submitted (<Figure>Figure 1</Figure>):
        </Paragraph>
        <View style={styles.Left}> 
          <Picture src={ImagURLs.find(p => p.Tag === 'load-profile').URL}/> 
          <Caption style={styles.caption}> Figure 1: Load and ambient temperature profiles </Caption> 
        </View>
      </View>
      <Subtitle> II. Processing </Subtitle>
      <Paragraph>
        Given the provided inputs, the transformer loading study is qualified for the <Figure>IEEE Std C57.12.91™ Clause 7 and Annex G</Figure>, 
        and the <Figure>IEC6060354</Figure> thermal models. For this study, the tap changer, the bushings, and the connectors are not considered as limiting factors
        for the transformer load, and as results their thermal models are not considered in the load calculations.
      </Paragraph>
      <StandardTableItems rowData = {loadStandards} />
        
      <Subtitle> III. Load Evaluations Results </Subtitle>
      <Paragraph>
        The submitted load profiles evaluated against the user-defined loading scenarios produces the loading curves presented next.
      </Paragraph>
      
      <SubtitleFlow> 3.1 Loading curves vs. Ambient temperature rise </SubtitleFlow>
      <Paragraph>
        Limits placed on loading contingencies are based on the IEEE/IEC Standards unless otherwise specified. Limiting factors are specified in loading scenarios, and are based on
        a combination of criteria including, but not limited to : Top oil, hot spot, load, loss-of-life, tap position, bubbling temperature, etc. <Figure>Figure 1</Figure> depicts the upper limits of 
        load at the various cooling stages, when the ambient temperature rises within predetermined range. <Figure>Figure 1</Figure> depicts the optimum load curves showcasing the load level at various 
        ambient temperature rises, and at the three stages of cooling capacity (0%-natural cooling, 50%-Stage1 cooling enabled, 100%: All cooling activated).
      </Paragraph>

      <SubtitleFlow> 3.2 24-h Thermal Forecast </SubtitleFlow>
      <Paragraph>
        <Figure>Figure 3</Figure> depicts an example of a daily temperature forecast for a 30 min short time emergency load.  
      </Paragraph> 

      <SubtitleFlow> 3.3 Optimal Load Margin </SubtitleFlow>
      <Paragraph> <Figure>Figure 5</Figure> showcases a 24-Hour available load margin variation over an entire 24-hours load cycle. The graph is painted in RED if there is no available margin, 
        signalling the need to reduce the load by at least the red margin value. A gray illustration of the load margin indicates that there is a room to increase the transformer 
        load within the delimited range.
      </Paragraph>

      <SubtitleFlow> 3.4 Aging </SubtitleFlow>
      <Paragraph>
        One of the main consequences of overloading power transformers is their accelerated aging. Thorough assessment of 
        the transformer aging should take into account the moisture in the insulation and the oxygen level, or at least a knowledge of
        whether the tank is sealed or free breathing. Based on the provided inputs, the loss-of-life rate/cycle and the Aging acceleration 
        are respectively given in <Figure> Figure 6 </Figure> and <Figure> Figure 7</Figure>.
      </Paragraph>

      <Subtitle> IV Summary </Subtitle>
      <Paragraph>
        The summary table in the Appendix provides a detailed report of the upper limits of load, temperatures, and loss of life, as well as the limitation of operation parameters value.  
        - Some of the presented emergency ratings can be implemented in protective relays so that they do not trip before the maximum emergency ratings of the transformer has been reached. 
        - By allowing protective relays to trip when the maximum rating has been reached, the transformer will not overheat, and the insulation damage will be prevented.
        - Knowledge of the time to over temperatures can help curb the thermal stress with early cooling system activation.
      </Paragraph>
      <Footer render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}`)} fixed />
    </Body>
     */}
    {/* ****Loading curves va Ambient and Time  */}
    <Body wrap orientation="landscape">
      <MainHeader fixed>
        <Logo src={logo} />
        <HeaderLeft/>
        <HeaderRigth >
          <HeaderText> The Digital Machine Developer</HeaderText>
          <HeaderText> www.hvsscorp.com</HeaderText>
          <HeaderText> info@hvsscorp.com</HeaderText>
        </HeaderRigth>
      </MainHeader>
      <View style={styles.row}>
            <View style={styles.Left}>
              <Picture src={ImagURLs.find(p => p.Tag === 'load-vs-ambient').URL}/> 
              <Caption style={styles.caption}> Figure 2: 24-h Load vs. Ambient temperature rise</Caption> 
            </View>
            <View style={styles.Right}>
              <Picture src={ImagURLs.find(p => p.Tag === 'temperature-vs-time').URL}/> 
              <Caption style={styles.caption}> Figure 3: Daily Temperatures Trends</Caption> 
            </View>
      </View>
      <View style={styles.rowb}>
            <View style={styles.Left}>
              <Picture src={ImagURLs.find(p => p.Tag === 'hotspot-vs-ambient').URL}/> 
              <Caption style={styles.caption}>Figure 4: Hot spot temperature vs. Ambient temperature rise </Caption> 
            </View>
            <View style={styles.Right}>
              <Picture src={ImagURLs.find(p => p.Tag === 'margin-vs-time').URL}/> 
              <Caption style={styles.caption}>Figure 5:  Daily Load Margin Trends </Caption> 
            </View>
      </View>
      <View style={styles.rowb}>
            <View style={styles.Left}>
              <Picture src={ImagURLs.find(p => p.Tag === 'loss-vs-ambient').URL}/> 
              <Caption style={styles.caption}> Figure 6: Loss of life rate/cycle vs. Ambient temperature rise</Caption> 
            </View>
            <View style={styles.Right}>
              <Picture src={ImagURLs.find(p => p.Tag === 'loss-vs-time').URL}/> 
              <Caption style={styles.caption}> Figure 7: Loss of life rate per cycle. </Caption> 
            </View>
      </View>
      {/* ========== */}
      <View style={styles.rowb}>
            <View style={styles.Left}>
              <Picture src={ImagURLs.find(p => p.Tag === 'load-vs-duration').URL}/> 
              <Caption style={styles.caption}> Figure 8: Maximum Load vs. overload duration at varying ambient temperature rise</Caption> 
            </View>
            <View style={styles.Right}>
              <Picture src={ImagURLs.find(p => p.Tag === 'loss-vs-duration').URL}/> 
              <Caption style={styles.caption}> Figure 9: Loss of life rate/cycle vs. overload duration at varying ambient temperature rise </Caption> 
            </View>
      </View>
    </Body>
    {/* /*** Loading Tables        ****************/}
    <Body wrap orientation="landscape">
    <MainHeader fixed>
        <Logo src={logo} />
        <HeaderLeft/>
        <HeaderRigth >
          <HeaderText> The Digital Machine Developer</HeaderText>
          <HeaderText> www.hvsscorp.com</HeaderText>
          <HeaderText> info@hvsscorp.com</HeaderText>
        </HeaderRigth>
      </MainHeader>
      <View >
        {
          Array.from(lNameSet).map(lName => 
          { 
            return (
              NamePlate.filter(p => p.name === lName).map((item, index) => 
              {
                return (
                  <LoadingTableItems rowData = {item.ieeeData} />
                )
              })
            )
          })
        }       
      </View>
    </Body>
  </Document>
    );
 }


