import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontSize: 9
    },
    HotSpot: {
        width: '7%',
        textAlign: 'center',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    Bottom: {
        width: '6%',
        textAlign: 'center',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    LossOfLife: {
        width: '8%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    Margin: {
        width: '9%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    TtpMVA: {
        width: '8%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    LimitedBy: {
        width: '17%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 2,
    },
  });

const LoadingTableRow = ({items}) => {
    const rows = items.map((item, index) => 
        <View style={styles.row} key={index}>
            <Text style={styles.TtpMVA}>{item.Ambient}</Text>
            <Text style={styles.Bottom}>{item.BottomOil}</Text>
            <Text style={styles.HotSpot}>{item.HotSpot}</Text>
            <Text style={styles.HotSpot}>{item.TopOil}</Text>


            <Text style={styles.HotSpot}>{item.basicMVA}</Text>
            <Text style={styles.HotSpot}>{item.MVA}</Text>
            <Text style={styles.Margin}>{item.Margin}</Text>
            <Text style={styles.LossOfLife}>{item.LOSSofLife}</Text>

            {/* <Text style={styles.HotSpot}> {item.Cooling} </Text> */}
            <Text style={styles.TtpMVA}>{item.TTPTop}</Text>
            <Text style={styles.TtpMVA}>{item.TTPHot}</Text>
            <Text style={styles.TtpMVA}>{item.TTPMva}</Text>
            <Text style={styles.LimitedBy}>{item.LimitedBy}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default LoadingTableRow
