import  { useHistory }  from "react-router-dom";
import React, { useState, useEffect, memo, useCallback }  from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { ApiURL, isEmpty, DefaultXfrm } from 'components/MainPanel/Utilities.jsx';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import {Typography, Box, IconButton, ButtonGroup, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import defaultXfrm from 'assets/img/XfrmItems/profile.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Dashboard from "views/Dashboard/Dashboard.jsx";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  accordion:
  {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    borderRadius:"0px"
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // backgroundColor: lightGreen[500],
    borderRadius:"0px",
  },
  button2: {
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3'
  },
 plugin:
 { 
  marginTop: "50%",
  padding: "1px",
  borderTopColor: "#d3d3d3",
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderBottomColor: "#d3d3d3",
  borderLeft: "0px",
  borderRight:"0px",
  width: '100%',
 },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6",
    width: '100%',
  },
  card: {
    margin: '0px',
    width: '100%',
    height:'80vh',
    overflow: 'auto',
    borderRadius:'0px',
    padding: '-100px'
  },
  cardheader:{
    padding: "1px",
    // backgroundColor : "#cccccc",
    borderRadius:'0px',
    // paddingLeft: "50px",
    borderTopLeftRadius: '0'
  },
  submitButton :
  {
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  small: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);



const XfrmHeader = (props)=> {
  let Cooling  = props.Stage1 + '/' + props.Stage2+ '/' + props.Stage3;
  let Rating = props.Rating1 + '/' + props.Rating2 + '/' + props.Rating3;
  return(
    <ButtonGroup variant="text" size="small" color="default" aria-label="small contained button group">
      <Button size="small" > {props.XfrmName} </Button>
      <Button size="small" > {props.XType} </Button>
      <Button size="small" > {props.VClass}&nbsp;(kV) </Button> 
      <Button size="small" >{Rating}&nbsp;(MVA) </Button> 
      <Button size="small" >{Cooling}</Button> 
    </ButtonGroup> 
  )
}



export default function  MultiDashboard (props) 
{
  var nXfrmer = props.location.state.nXfrmer;
  // console.log("= ====== " + JSON.stringify(nXfrmer));
  // var sessionId = props.userData.username;
  // if (!isEmpty(props.transFormers))
  //     transFormers                        = props.transFormers;
  // if (isEmpty(nXfrmer))
  var transFormers                        = [DefaultXfrm]
  let userData                            = props.userData;
  let history                             = useHistory();
  const classes                           = useStyles();
  const [stateVar, setStateVar]           = useState({ sessionId: userData.username, transFormers:[].concat(DefaultXfrm)}) //useState({ sessionId: userData.username, transFormers:[].concat(tempXrmer)});
  const [expanded, setExpanded] = useState(0);

  const handleExChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleListItemClick = (event, XfrmName) => {
    setSelectedIndex(XfrmName);
    if (!isEmpty(stateVar.transFormers))
    {
      let  transForm  = stateVar.transFormers.find(p => p.XfrmName === XfrmName);
      let  nXfrmer    = {
        XfrmName    : transForm.XfrmName,
        XType       : transForm.XType,
        XPhase      : transForm.XPhase,
        VClass      : transForm.VClass,
        Rating1     : transForm.Rating1,
        Rating2     : transForm.Rating2,
        Rating3     : transForm.Rating3,  
        Stage1      : transForm.Stage1,
        Stage2      : transForm.Stage2,
        Stage3      : transForm.Stage3,
        SolidInsulation: transForm.SolidInsul,
        LiquidInsulation: transForm.LiquidInsul,
       
        TankType      : transForm.TankType,       
        GPSLatitude   : transForm.GPSLatitude,
        GPSLongitude  : transForm.GPSLongitude,
        GPSAltitude   : transForm.GPSAltitude,
        Vintage       : transForm.Vintage,
      }
      history.push({pathname:"/admin/dashboard", state:{ nXfrmer: nXfrmer}});  
      props.setSideGuide("createXfrmOK");    
    }
  };

  const handleDelete = (event, XfrmName) =>
  {
      const url = ApiURL + '/DeleteXfrmer';  
      let newXrmer =
      { 
        sessionId:userData.username,
        XfrmName: XfrmName,
        XType       : '',
        XPhase      : '',
        VClass      : '',
        Rating1     : '',
        Rating2     : '',
        Rating3     : '',  
        Stage1      : '',
        Stage2      : '',
        Stage3      : '',
        SolidInsulation: '',
        LiquidInsulation: '',
       
        TankType      : '',       
        GPSLatitude   : '',
        GPSLongitude  : '',
        GPSAltitude   : '',
        Vintage       : '',
        coolingMode:'',
        Rating:''
      };

      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(newXrmer),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          let transfoList = stateVar.transFormers.filter(p => p.XfrmName!==XfrmName);
          setStateVar({...stateVar, transFormers:transfoList});
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
  }

  const handleRefresh = (event) =>
  {
      const url = ApiURL + '/GetXfrmers';  
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(sessionId),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          setStateVar({...stateVar, transFormers:[].concat(result)});
          // console.log("Created Transformers are : " + userData.username + " " + JSON.stringify(result));
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
  }

  useEffect(() =>
  {
    transFormers = stateVar.transFormers.concat(nXfrmer);
    setStateVar({...stateVar, transFormers:stateVar.transFormers.concat(nXfrmer)});
  }, [nXfrmer]);


  useEffect(() =>
  {
    async function onRefresh ()
    {
      const url = ApiURL + '/GetXfrmers';  
      try {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData.username),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          // if(rawResponse.ok)
          // {
            const result = await rawResponse.json();
            transFormers = stateVar.transFormers.concat(result)
            setStateVar({...stateVar, transFormers:stateVar.transFormers.concat(result)});
            // console.log("transFormers : " + userData.username + " ====>>> " + JSON.stringify(transFormers));

          // }
          // else
          //   return Promise.reject(rawResponse)
        })();
      }   
      catch (error) {
        console.error('Error:', error);
      }
    }
    onRefresh();
  }, []);


  return (
    <div  className={classes.root}>
      {/* <Button variant="outlined" style={{justifyContent: 'center'}} onClick={event => handleRefresh(event)} className={classes.button2} startIcon = {<RefreshIcon/>} >
          Your Assets List
      </Button> */}
      <Typography variant="h5">  Your Assets List </Typography>
      <hr style={{color: '#4486a3', backgroundColor: '#4486a3', marginLeft: 5, height: 5, width:'60%', borderColor : '#4486a3'}}/>    
       {
          stateVar.transFormers.filter(p => p.XfrmName !=="Template").map((xfrm, key) => {
            return (
              <Accordion expanded={expanded ===  xfrm.XfrmName} onChange={ handleExChange(xfrm.XfrmName)} className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={key} id={key}>
                  <XfrmHeader {...xfrm} />
                  {/* <IconButton edge="end" aria-label="delete" size='small' onClick = {event => handleDelete(event, xfrm.XfrmName)}><DeleteIcon /></IconButton> */}
                </AccordionSummary>
                <AccordionDetails>
                    {/* <Dashboard username ={sessionId} setDIYvalue = {setDIYvalue}  setGuideId = {setGuideId} setSideGuide ={this.setSideGuide} {...rest}/>                  */}
                    <Dashboard userData ={userData} nXfrmer={xfrm}/>                 
                </AccordionDetails>
              </Accordion>
            );
          })
        }
    </div>
  );
}




