import React from "react";
// import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings, VerifyContact, ForgotPassword, ConfirmSignIn } from "aws-amplify-react";


// const useStyles = makeStyles(theme => ({
//   paper: {
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', 
//     marginTop: theme.spacing(1),
//     borderRadius:"0px"
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
// }));



export default function  FixedSignUp (props) 
{
  const MyTheme = {
    signInButton: { 'backgroundColor': 'red', 'borderColor': 'red'}
  }

//  const handleAuthStateChange =(state, data) => {
//     if(!isEmpty(data))
//     {
//       console.log(state + "====" + JSON.stringify(data));
//       // let userData = {username: data.username, userstatus:state};
//       // props.setUserData(userData);
//       // props.setUserData(state, data);
//       // history.push({pathname:"/admin/loadinghub", state: {}}); 
//       // history.push({pathname:"/", state: {}}); 

//     } 
//   }

// const handleAuthStateChange =(state, data) => {
//     props.setUserData(state, data);
// }


  return (
    <Authenticator theme={MyTheme} hideDefault={true} >
      <SignIn/>
      <ConfirmSignIn/>
      {/* <VerifyContact/> */}
      <SignUp/>
      {/* <ConfirmSignUp/> */}
      <ForgotPassword/>
      {/* <Greetings/> */}
    </Authenticator>
  );
};


