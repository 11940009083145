import  { useHistory }  from "react-router-dom";
import React, { useState, useEffect, memo, useCallback }  from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles} from '@material-ui/core/styles';
import { Card, CardHeader, CardBody} from 'reactstrap';
import { ApiURL, isEmpty } from 'components/MainPanel/Utilities.jsx';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import {Typography, Avatar, Box, IconButton, Button, List, ListItemSecondaryAction, ListItem, ListItemText, ListItemAvatar} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import defaultXfrm from 'assets/img/XfrmItems/profile.png';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(-5),
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // backgroundColor: lightGreen[500],
    borderRadius:"0px",
  },
  button2: {
    color: '#FFFFFF',
    borderRadius:"0px",
    borderColor:'#FFFFFF'
  },
 plugin:
 { 
  marginTop: "50%",
  padding: "1px",
  borderTopColor: "#d3d3d3",
  borderTop: "1px solid",
  borderBottom: "1px solid",
  borderBottomColor: "#d3d3d3",
  borderLeft: "0px",
  borderRight:"0px",
  width: '100%',
 },
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6",
    width: '100%',
  },
  card: {
    margin: '0px',
    width: '100%',
    height:'80vh',
    overflow: 'auto',
    borderRadius:'0px',
    padding: '-100px'
  },
  cardheader:{
    padding: "1px",
    // backgroundColor : "#cccccc",
    borderRadius:'0px',
    // paddingLeft: "50px",
    borderTopLeftRadius: '0'
  },
  submitButton :
  {
    width: "100%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: "100%",
  },
  small: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const tempXrmer = [
{ 
  sessionId: '',
  XfrmName    : 'Template',
  XType       : '',
  XPhase      : '',
  VClass      : '',
  Rating1     : '',
  Rating2     : '',
  Rating3     : '',  
  Stage1      : '',
  Stage2      : '',
  Stage3      : '',
  SolidInsulation: '',
  LiquidInsulation: '',
 
  TankType      : '',       
  GPSLatitude   : '',
  GPSLongitude  : '',
  GPSAltitude   : '',
  Vintage       : '',
  coolingMode:'',
  Rating:''
}];

export default function  FixedPlugin (props) 
{
  let userData                            = props.userData;
  let history                             = useHistory();
  const classes                           = useStyles();
  const [selectedIndex, setSelectedIndex] = useState();
  const [stateVar, setStateVar]           = useState({ sessionId: userData.username, transFormers:[]}) //useState({ sessionId: userData.username, transFormers:[].concat(tempXrmer)});
  
  const handleClick = () => {
    props.handleFixedClick();
  }

  const handleListItemClick = (event, XfrmName) => {
    setSelectedIndex(XfrmName);
    if (!isEmpty(stateVar.transFormers))
    {
      let  transForm  = stateVar.transFormers.find(p => p.XfrmName === XfrmName);
      let  nXfrmer    = {
        XfrmName    : transForm.XfrmName,
        XType       : transForm.XType,
        XPhase      : transForm.XPhase,
        VClass      : transForm.VClass,
        Rating1     : transForm.Rating1,
        Rating2     : transForm.Rating2,
        Rating3     : transForm.Rating3,  
        Stage1      : transForm.Stage1,
        Stage2      : transForm.Stage2,
        Stage3      : transForm.Stage3,
        SolidInsulation: transForm.SolidInsul,
        LiquidInsulation: transForm.LiquidInsul,
       
        TankType      : transForm.TankType,       
        GPSLatitude   : transForm.GPSLatitude,
        GPSLongitude  : transForm.GPSLongitude,
        GPSAltitude   : transForm.GPSAltitude,
        Vintage       : transForm.Vintage,
      }
      history.push({pathname:"/admin/dashboard", state:{ nXfrmer: nXfrmer}});  
      props.setSideGuide("createXfrmOK");    
    }
  };

  const handleDelete = (event, XfrmName) =>
  {
      const url = ApiURL + '/DeleteXfrmer';  
      let newXrmer =
      { 
        sessionId:userData.username,
        XfrmName: XfrmName,
        XType       : '',
        XPhase      : '',
        VClass      : '',
        Rating1     : '',
        Rating2     : '',
        Rating3     : '',  
        Stage1      : '',
        Stage2      : '',
        Stage3      : '',
        SolidInsulation: '',
        LiquidInsulation: '',
       
        TankType      : '',       
        GPSLatitude   : '',
        GPSLongitude  : '',
        GPSAltitude   : '',
        Vintage       : '',
        coolingMode:'',
        Rating:''
      };

      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(newXrmer),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          let transfoList = stateVar.transFormers.filter(p => p.XfrmName!==XfrmName);
          setStateVar({...stateVar, transFormers:transfoList});
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
  }

  const handleRefresh = (event) =>
  {
      const url = ApiURL + '/GetXfrmers';  
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData.username),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          setStateVar({...stateVar, transFormers:[].concat(result)});
          // console.log("Created Transformers are : " + userData.username + " " + JSON.stringify(result));
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
  }

  useEffect(() =>
  {
    userData.username = props.userData.username;
    async function onRefresh ()
    {
      const url = ApiURL + '/GetXfrmers';  
      try 
      {
        (async () => {
          const rawResponse = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData.username),
            headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
          });
          const result = await rawResponse.json();
          setStateVar({...stateVar, transFormers:[].concat(result)});
          // console.log("Created Transformers are : " + userData.username + " " + JSON.stringify(result));
        })();
      }   
      catch (error) 
      {
        console.error('Error:', error);
      }
    }
    onRefresh();
  }, []);


  return (
    <div  className={classnames("fixed-plugin")}>
        <div id="fixedPluginClasses" className={props.fixedClasses}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div> 
            <Card  className={[classes.card,  "dropdown-menu"].join(' ')} >
              <CardHeader className={classes.cardheader}> 
                  <AppBar sx={{ position: 'sticky' }} style={{ backgroundColor: '#4486a3', color:'white',  maxHeight:'50px', boxShadow:'0px 0px 0px 0px'}}>
                    <Toolbar style={{ overflow: 'hidden', top:'-8px' }}>
                      <Button variant="outlined" style={{justifyContent: 'center'}} onClick={event => handleRefresh(event)} className={classes.button2} startIcon = {<RefreshIcon/>} >
                        YOUR TRANSFORMERS
                      </Button>
                    </Toolbar>
                  </AppBar>    
               </CardHeader>
              <CardBody className={classes.CardBody}>
                <List dense className={classes.controlBar} style={{top: '50px'}}>
                  {
                    stateVar.transFormers.filter(p => p.XfrmName !=="Template").map((o, key) => {
                      return (
                        <ListItem key={key} button selected={selectedIndex === o.XfrmName} >
                          <ListItemAvatar> <Avatar src={defaultXfrm} /></ListItemAvatar>
                          <ListItemText id={key} primary={o.XfrmName} onClick={event => handleListItemClick(event, o.XfrmName)}>{o.XfrmName} </ListItemText>
                          <IconButton edge="end" aria-label="delete" size='small' onClick = {event => handleDelete(event, o.XfrmName)}><DeleteIcon /></IconButton>
                        </ListItem>
                      );
                    })
                  }
                </List>
              </CardBody>
            </Card> 
        </div>
    </div>
  );
}

FixedPlugin.propTypes = {
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  rtlActive: PropTypes.bool,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func
};


