import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#cccccc',
        backgroundColor: '#cccccc',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontSize: 9,
        fontStyle: 'bold',
        textAlign: 'left',
    },

    HotSpot: {
        width: '7%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    LossOfLife: {
        width: '8%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    Bottom: {
        width: '6%',
        textAlign: 'center',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    Margin: {
        width: '9%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    TtpMVA: {
        width: '8%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    },
    LimitedBy: {
        width: '17%',
        borderRightColor:  '#cccccc',
        borderRightWidth: 1,
        textAlign: 'center',
    }
  });

  const LoadingTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.TtpMVA}>AMB SHIFT[K]</Text>
        <Text style={styles.Bottom}> BOT[℃]</Text>
        <Text style={styles.HotSpot}>TOT[℃]</Text>
        <Text style={styles.HotSpot}>HST[℃]</Text>

        <Text style={styles.HotSpot}>BASE-MVA</Text>
        <Text style={styles.HotSpot}>PEAK-MVA</Text>
        <Text style={styles.Margin}> MVA-MARGIN</Text>
        <Text style={styles.LossOfLife}>LOL[Hr]</Text>

        {/* <Text style={styles.HotSpot}> Cooling </Text> */}
        <Text style={styles.TtpMVA}>TTPTOT[Min]</Text>
        <Text style={styles.TtpMVA}>TTPHST[Min]</Text>
        <Text style={styles.TtpMVA}>TTPMVA[Min]</Text>
        <Text style={styles.LimitedBy}>LIMITED-BY</Text>
    </View>
  );
  
  export default LoadingTableHeader
