
/*eslint-disable*/
import  { useHistory }  from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
//import footerStyle from "assets/jss/material-dashboard-react/components/footerStyle.jsx";
import { defaultFont, container, primaryColor, grayColor } from "assets/jss/material-dashboard-react.jsx";
import { Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';


const footerStyle = {
  foot: {
  backgroundColor: "#FFFFFF",
  fontSize: "20px",
  color: "default",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "5px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "30px",
  width: "100%"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  right: {
    padding: "5px 0",
    margin: "0",
    fontSize: "14px",
    float: "right!important"
  },
  left: {
    padding: "0px 0",
    marginLeft : "300px",
    fontSize: "10px",
    float: "left!important"
  },
  button:{
    borderRadius:"0px",
    border: "0px"
  },
  phantomStyle : {
    display: "block",
    padding: "10px",
    height: "30px",
    width: "100%"
  },
};


function Footer({ ...props }) {
  const { classes } = props;
  let history   = useHistory();
  return (
    <div>
      <div style={footerStyle.phantomStyle} />
      <footer style={footerStyle.foot}>
        <Box className={classes.left} display="flex" flexDirection="row" >
          <Box>
            <Button variant="outlined" size="small" onClick={() => {  history.push({pathname:"/admin/about-us", state: {}});  }} className={classes.button} > About Us </Button>
          </Box>
          <Box > 
            <Button variant="outlined" size="small" onClick={() => {  history.push({pathname:"/admin/contact-us", state: {}});}} className={classes.button} > Contact Us </Button>
          </Box>
          <Box > 
            <Button variant="outlined" size="small" onClick={() => {  history.push({pathname:"/admin/eula", state: {}});}} className={classes.button} > Terms of Service </Button>
          </Box>
        </Box>
        <Typography component="div">      
          <p className={classes.right}>
            <span>
              &copy; {1900 + new Date().getYear()}{" "}
              Built with love by
              <a href="https://www.hvsscorp.com" target="_blank" className={classes.a}> HVSS </a>
              , The Digital Machine Developer.
            </span>
          </p></Typography>
        </footer>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
