
import React, {useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import RGL, { WidthProvider } from "react-grid-layout";
import { Card, CardHeader,CardBody} from 'reactstrap';
import {  AreaChart, LineChart,  Line,  Area, XAxis, YAxis, CartesianGrid, Legend,  BarChart, Bar,Tooltip, ResponsiveContainer} from 'recharts';
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import {Grid, Typography} from '@material-ui/core';
import { colors } from "components/MainPanel/Utilities.jsx";

import {Table, TableBody, TableContainer, Divider, Box, TableCell, TableRow, TableHead}  from '@material-ui/core';
import { isEmpty, onlyUnique } from 'components/MainPanel/Utilities';
import XfrmOverview from "assets/img/XfrmItems/XfrmOverview.png"
import thermal from "assets/img/XfrmItems/thermal.png"
import loadprofile from "assets/img/XfrmItems/loadprofile.png"
import insulation from "assets/img/XfrmItems/insulation.png"
import moisture from "assets/img/XfrmItems/moisture.png"
import cooling from "assets/img/XfrmItems/cooling.png"


const ReactGridLayout = WidthProvider(RGL);
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // marginTop:'-40px',
    backgroundColor : "#f6f6f6",
    minHeight: '100vh'
  },
  cardHeader:{
    padding: "0px",
    paddingLeft: "1px",
    fontSize: 12
  },
  card:{
    borderRadius: "0px",
    backgroundColor: '#eceff1',
  },
  playCard:{
    minHeight: '80vh',
    borderRadius: "0px",
    margin: 'auto'
  },
  CardBody:{
    padding: "1px",
    // paddingLeft: "-10px",
  },
  cardBot:{
    // padding: "2px",
    marginLeft: "1px",
    fontSize: 12
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  table: {
    minWidth: '90%',
  },
  avatar: {
    margin: '-25px',
  },

  Comment: {
    padding: "5px",
    // borderLeftColor: "#6699cc",
    // borderLeft: "8px solid",
    backgroundColor : '#eceff1'
  },

}));


function LandingBoard (props) {
  const classes                     = useStyles();
  var ambientSet                  =   [], durationSet = [], coolingSet =[];
  let ieeeGThermal = [], ieeeData =[], ieeeGTSlot =[]; 
  var ieeeG7Iec                    =   props.NamePlate;  
  var ieeeGTh7Iec                  =   props.ThermalResults;
  var condVar                      =   props.condVar;
  var xfrmer                       =   props.Xfrmer;
  var Diagnostics                  =   props.Diagnostics;

  var coolingData = [
    { Stage: xfrmer.Stage1, cPower: 0, noFans: 0, noPumps: 0},
    { Stage: xfrmer.Stage2, cPower: 0, noFans: 0, noPumps: 0},
    { Stage: xfrmer.Stage3, cPower: 0, noFans: 0, noPumps: 0},
  ];

  var mvaData     = Diagnostics.filter(p => p.categorie === "LOAD") || [];
  var agingData   = Diagnostics.filter(p => p.categorie === "AGING") || [];
  var hotData     = Diagnostics.filter(p => p.categorie === "HOTSPOT") || [];
  var topData     = Diagnostics.filter(p => p.categorie === "TOPOIL") || [];
  var botData     = Diagnostics.filter(p => p.categorie === "BOTTOM") || [];
  var moisData    = Diagnostics.filter(p => p.categorie === "MOISTURE") || [];


  let minArray =[], maxArray = [], minTArray =[], maxTArray = [];

  if(ieeeG7Iec.length > 0)
  {    
    durationSet = ieeeG7Iec[0].ieeeData.map(p => p.Overtime).filter(onlyUnique)
    ambientSet = ieeeG7Iec[0].ieeeData.map(p => p.Ambient).filter(onlyUnique)
    coolingSet = ieeeG7Iec[0].ieeeData.map(p => p.Cooling).filter(onlyUnique)

    ieeeGThermal = ieeeGTh7Iec[0].ieeeData.filter(p => p.Overtime === parseFloat(condVar.selTimeVar))
                                          .filter(q => q.mvaCooling === parseFloat(condVar.selCoolingVar[0]))
                                          .filter(r => r.mvaAmbient === parseFloat(condVar.selAmbientVar));
    
    ieeeGTSlot = ieeeGTh7Iec[0].ieeeData.filter(q => q.mvaCooling === parseFloat(condVar.selCoolingVar[0]))
                                          .filter(r => r.mvaAmbient === parseFloat(condVar.selAmbientVar));
  }


  const TOPCluster =() =>
  {
    if(isEmpty(topData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="Topoil" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> TOP OIL </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table"> </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      var topColumns = Object.getOwnPropertyNames(topData[0]).filter(p => p !== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="Topoil" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> TOP OIL </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    topColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  topData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        topColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const MVACluster =() =>
  {
    if(isEmpty(mvaData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="LOAD" src={loadprofile} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> LOADABILITY </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">

            </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      let mvaColumns = Object.getOwnPropertyNames(topData[0]).filter(p => p !== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="LOAD" src={loadprofile} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> LOADABILITY</Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    mvaColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  mvaData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        mvaColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const AGINGCluster =() =>
  {
    if(isEmpty(agingData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="AGING" src={insulation} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> AGING </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">

            </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      let agingColumns = Object.getOwnPropertyNames(agingData[0]).filter(p => p !== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="AGING" src={insulation} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> AGING</Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    agingColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  agingData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        agingColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const HOTCluster =() =>
  {
    if(isEmpty(hotData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="HOTSPOT" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> HOT SPOT </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">

            </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      let hotColumns = Object.getOwnPropertyNames(hotData[0]).filter(p => p !== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="AGING" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> HOT SPOT </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    hotColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  hotData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        hotColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const BOTTOMCluster =() =>
  {
    if(isEmpty(botData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="BOTTOM" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> BOTTOM </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">

            </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      let botColumns = Object.getOwnPropertyNames(botData[0]).filter(p => p !== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="BOTTOM" src={thermal} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> BOTTOM</Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    botColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  botData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        botColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const MOISTURECluster =() =>
  {
    if(isEmpty(moisData))
    {
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="MOISTURE" src={moisture} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> MOISTURE AND GAS </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">

            </Table>
          </div>
        </ResponsiveContainer>
      )
    }
    else
    {  
      let moisColumns = Object.getOwnPropertyNames(moisData[0]).filter(p => p!== "categorie");
      return (
        <ResponsiveContainer>
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="MOITURE" src={moisture} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> MOISTURE AND GAS</Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  { 
                    moisColumns.map(row => (
                        (row ==="varName")?(<TableCell> <Typography variant="caption">  </Typography></TableCell>):
                        (<TableCell> <Typography variant="caption"> {row} </Typography></TableCell>)
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>   
                {
                  moisData.map((row, key) => (
                    <TableRow key={key}>
                      {
                        moisColumns.map(col => (
                          <TableCell ><Typography variant="caption">{row[col]}</Typography></TableCell>
                        )) 
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </ResponsiveContainer>
        )
    }
  }

  const COOLINGCluster = () =>
  {
    return(
      <ResponsiveContainer> 
          <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="Cooling" src={cooling} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> COOLING </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell><Typography variant="caption">Stage</Typography></TableCell>
                <TableCell ><Typography variant="caption">Power</Typography></TableCell>
                <TableCell ><Typography variant="caption">Fans</Typography></TableCell>
                <TableCell ><Typography variant="caption">Pumps</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>    
                {coolingData.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell > <Typography variant="caption">{row.Stage}</Typography></TableCell>
                    <TableCell ><Typography variant="caption"> {row.cPower}</Typography></TableCell>
                    <TableCell > <Typography variant="caption">{row.noFans}</Typography> </TableCell>
                    <TableCell ><Typography variant="caption">{row.noPumps}</Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
      </ResponsiveContainer>
    )
  }

  const CONDITIONCluster = () =>
  {
    return (
      <ResponsiveContainer>
        <div className={classes.Comment}>
            <Grid container spacing={1}>
              <Grid item xs ={2}>
                <Avatar alt="Cooling" src={cooling} className={classes.small} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" display="inline"> CONDITIONS </Typography>
              </Grid>
            </Grid>
            <Divider/>
            <Table className={classes.table} padding='none' size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell><Typography variant="caption">Loading standard </Typography></TableCell>
                <TableCell ><Typography variant="caption"><strong>{condVar.selStdVar}</strong></Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="caption">Load profile </Typography></TableCell>
                <TableCell ><Typography variant="caption"><strong>{condVar.selProfileVar}</strong> </Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="caption">Ambient Shift (∆θa)</Typography></TableCell>
                <TableCell ><Typography variant="caption"><strong>{condVar.selAmbientVar}</strong></Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="caption">Scenario(s) </Typography></TableCell>
                <TableCell ><Typography variant="caption"><strong>{ condVar.selScenarioVar.map(p => { return `${p} `})}</strong></Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="caption">Cooling mode(s)</Typography></TableCell>
                <TableCell ><Typography variant="caption"><strong>{ condVar.selCoolingVar.map(q => { return `${q} `})} </strong></Typography></TableCell>
              </TableRow>
            </TableBody>
            </Table>
         </div>
      </ResponsiveContainer>  
    )
  }

  
  return ( 
        <Grid container spacing ={2}>
          <Grid item xs ={4}>
            <Card className={classes.card}>
                  <CardBody >
                    <ResponsiveContainer width='100%' aspect={3.0/1.5} >
                        <LineChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 0}} style={{ backgroundColor: "#f6f6f6" }} >
                            <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="Ambient" type="number" margin={{ bottom: 20 }} label={{ value: 'Ambient Temperature shift[℃]', 
                                    fontSize:12, position: 'insideCenter'}} tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                                <YAxis label={{ value: 'Peak Load [MVA]', fontSize:12, angle: -90,  position: 'insideCenter'}} tick={{fontSize: 11}}/>
                                <Tooltip/>
                                {/* <Legend /> */}
                                {    
                                  // durationSet.map((d, vind) => {
                                  //   return (
                                      coolingSet.map((u, ind) => {
                                        return (
                                              ieeeG7Iec.map((cid,index) => {
                                                return (
                                                  // <Line dataKey="MVA" data={cid.ieeeData.filter(p => p.Cooling === u).filter(p =>p.Overtime === d)
                                                  //   .filter(p => p.Overtime === parseFloat(condVar.selTimeVar))} name={cid.name + " Co  = " + u + " " + d}  
                                                    <Line dataKey="MVA" data={cid.ieeeData.filter(p => p.Cooling === u).filter(q => q.Overtime === parseFloat(condVar.selTimeVar))} 
                                                                name={cid.name + " Co  = " + u }  key={cid.name + " Co = " + u }  stroke={colors[ind]} strokeWidth={2}/>
                                                )                                
                                              })
                                        )
                                      // })
                                    // )
                                  })                         
                                }
                        </LineChart> 
                    </ResponsiveContainer>  
                </CardBody>
              </Card> 
          </Grid>
          <Grid item xs ={4}>
            <Card className={classes.card}>
                  <CardBody >
                    <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                        <LineChart syncId="anyId" margin={{top: 10, right: 30, left: 0, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }} >
                                  <CartesianGrid strokeDasharray="3 3"/>
                                  <XAxis dataKey="Ambient" type="number" label={{ value: 'Ambient Temperature shift[℃]', fontSize:12, position: 'insideCenter', marginTop:100 }} 
                                      tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                                  <YAxis label={{ value: 'Hot Spot Temperature[℃]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}} domain={[0, 200]}/>
                                  <Tooltip/>
                                  {/* <Legend/> */}
                                  {   
                                    // durationSet.map((d, vind) => 
                                    // {
                                    //   return (                                                                
                                        coolingSet.map((u, ind) => 
                                        {
                                          return (
                                            ieeeG7Iec.map((cid,index) => {
                                              return (
                                                <Line dataKey="HotSpot" data={cid.ieeeData.filter(p => p.Cooling === u).filter(q => q.Overtime===parseFloat(condVar.selTimeVar))} 
                                                    name={cid.name + " Co = " + u }  key={cid.name + " Co = " + u } stroke={colors[index]} strokeWidth={2}/>)
                                            })
                                          )
                                      //   })
                                      // )
                                    })
                                  }
                        </LineChart> 
                    </ResponsiveContainer>

                  </CardBody>
              </Card> 
          </Grid>
          <Grid item xs ={4}>
            <Card className={classes.card}>
                  <CardBody >
                    <ResponsiveContainer width='100%' aspect={3.0/1.5}>
                      <LineChart syncId="anyId" data={ieeeG7Iec} margin={{top: 5, right: 30, left: 20, bottom: 5}} style={{ backgroundColor: "#f6f6f6" }}>
                          <CartesianGrid strokeDasharray="3 3"/>
                          <XAxis dataKey="Ambient" type="number" label={{ value: 'Ambient Temperature shift[℃]', fontSize:12, position: 'insideCenter' }} 
                            tick={{fontSize: 11}} domain={[Math.min(...ambientSet), Math.max(...ambientSet)]}/>
                          <YAxis label={{ value: 'LOSSofLIFE [%]', fontSize:12, angle: -90,  position: 'insideCenter' }} tick={{fontSize: 11}}/>
                          <Tooltip/>
                          {/* <Legend verticalAlign="top"/> */}
                            {
                              coolingSet.map((u, ind) => 
                              {
                                  return (
                                    ieeeG7Iec.map((cid,index) => {
                                      return ( 
                                      <Line dataKey="LOSSofLife" data={cid.ieeeData.filter(p => p.Cooling === u).filter(p => p.Overtime === parseFloat(condVar.selTimeVar))} 
                                            name={cid.name + " Co  = " + u}  key={cid.name + " Co  = " + u}  stroke="#FF0000" strokeWidth={1}  />)                                    
                                    })
                                  )
                              })
                            }
                        </LineChart>              
                    </ResponsiveContainer>
                  </CardBody> 
              </Card> 
          </Grid>
          <Grid item xs ={12}>
            <ResponsiveContainer> 
              <Card className={classes.playCard}>
                <ReactGridLayout className="layout" cols={12} rowHeight={40} >             
                  <div key={"j"+ xfrmer} data-grid={{x: 4, y:1.5, w: 4, h: 8, static: true, minW: 2, minH:3}}>
                    {/* <ResponsiveContainer > */}
                      <img src={XfrmOverview} alt="transformer-model" style ={{ width:'100%', height:'auto'}}/>
                    {/* </ResponsiveContainer> */}
                  </div>
                  <div key={"k" + xfrmer.XfrmName} data-grid={{x: 0.1, y: 1, w: 2.5, h: 2, static: true, maxW: 4}} >
                      <MVACluster/>
                  </div>     
                  <div key={"e"+ xfrmer.XfrmName} data-grid={{x: 0.3, y: 5, w: 2.5, h: 2, static: true , maxW: 4}} >
                    <COOLINGCluster/>
                  </div>
                  <div key={"f"+ xfrmer.XfrmName} data-grid={{x: 0.8, y: 9, w: 2.5, h: 2, static: true , maxW: 4}} >
                    <MOISTURECluster/>
                  </div>
                  <div key={"g"+ xfrmer.XfrmName} data-grid={{x: 4.5, y: 10.2, w: 2.5, h: 2, static: true , maxW: 4}} >
                    <BOTTOMCluster/> 
                  </div>
                  <div key={"h"+ xfrmer.XfrmName} data-grid={{x: 8.1, y: 5.5, w: 2.5, h: 2, static: true , maxW: 4}} >
                    <HOTCluster/> 
                  </div>
                  <div key={"i"+ xfrmer.XfrmName} data-grid={{x: 8, y: 9.1, w: 2.5, h: 2, static: true , maxW: 4}} >
                    <TOPCluster/>
                  </div>
                  <div key={"l"+ xfrmer.XfrmName} data-grid={{x: 8.5, y: 4, w: 2.5, h: 2.5, static: true , maxW: 4}} >
                    <AGINGCluster/>
                  </div>
                  <div key={"m"+ xfrmer.XfrmName} data-grid={{x: 8.5, y: 0.5, w: 2.5, h: 2.5, static: true, maxW: 4}} >
                      <CONDITIONCluster/>
                  </div>
                </ReactGridLayout> 
              </Card> 
            </ResponsiveContainer>
          </Grid>
        </Grid>      
    );
  }

export default LandingBoard;
