import React from "react";
import { useHistory }  from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import {  makeStyles  } from "@material-ui/styles";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Welcome2 from "assets/img/XfrmItems/Welcome2.png"
import climate from "assets/img/faces/climate.png"
import constraint from "assets/img/faces/constraint.png"
import dashboard from "assets/img/faces/dashboard.png"
import curve from "assets/img/faces/curve.png"
import digital from "assets/img/faces/digital.png"
import performance from "assets/img/faces/performance.png"
import variable from "assets/img/faces/variable.png"

import './rotate.css';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px',
    borderRadius: '0px',
  },
  Welcome: {
    padding: "1px",
    borderLeft: "0px",
    borderRight:"0px",
    margin: "300px",
  },
  button:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
    border: "0px",
    color:"#FFFFFF",
  },
}));

export default function StartUp (props) {
    const classes  = useStyles();
    const history = useHistory(); 
    let userData = props.userData;
    switch (userData.userstatus)
    {
      case 'signUp':
        {       
          return ( 
            <div className="centered">
              <Typography variant="h3">  Welcome to Your Transformer Loading<strong>HUB™</strong> </Typography>         
              <Typography> We've sent you an email to confirm your registration. Please open your mailbox to validate your email address. 
              </Typography>
              <Button variant="outlined"  onClick={() => { history.push({pathname:"/admin/login", state:{ }});}} className={classes.button} startIcon = {<LockOpenIcon/>}> LogIn </Button>
              <br/>
              <img src={Welcome2} className="rotate" alt= "Welcome Banner" />
            </div>
          );
        }
        case 'signIn_failure':
        {
          return ( 
            <div className="centered">
              <Typography variant="h3">  Welcome to Your Transformer Loading<strong>HUB™</strong> </Typography>         
              <Typography> Oooops...It looks like you enter the wrong username or password...Please try again.
              </Typography>
              <Button variant="outlined" onClick={() => { history.push({pathname:"/admin/login", state:{ }});}} className={classes.button} startIcon = {<LockOpenIcon/>}> LogIn </Button>
              <br/>
              <img src={Welcome2} className="rotate" alt= "Welcome Banner" />
            </div>
          )
        }
        default:
          return ( 
            // <div className="centered">
            //   <img src={Welcome2} className="rotate" alt= "Welcome Banner" />
            //   <h3>Key Features</h3>
            // </div>
            <div className="centered">
              <h3>Key Features</h3>
              <div class="row">
                  <div class="column">
                      <div class="card">
                          <div style="margin-left:auto; margin-right:auto"> <h3> Optimum Load Assessment</h3></div>
                          <img src={ performance} class="center"/>
                          <ul>
                              <li> Unlimited transformer units creation and loading capability assessment </li>
                              <li> What-If User-defined loading scenarios with multiple limiting criteria </li>
                              <li> Optimize and output loading limits, load margins and temperatures for <i>Normal</i> and <i>Emergency</i> loading modes</li>
                              <li> Accurate evaluation of the insulation aging.</li>
                          </ul>
                      </div>
                      <br />
                      <div class="card">
                          <div class="title">
                              <h3> Load Profiles and Weather Service</h3>
                          </div>
                          
                              <table align="center">
                                  <tr>
                                      <td><img src={curve.png} /></td>
                                      <td><img src={climate} /></td>
                                  </tr>
                              </table>
                          <ul>
                              <li> Load profiles design and customization </li>
                              <li> Csv File Upload with <i>24-h-[Timestamp: HH:mm, Per Unit Load, Ambient]</i> inputs cycles </li>
                              <li> Ties in the weather forecast to Build and use a location-based 24-h ambient temperature cycle. </li>
                              <li> Multiple load profiles evaluation in batch mode.</li>
                          </ul>
                      </div>
                  </div>

                  <div class="column">
                      <div class="card">
                          <h3> Digitize the IEEE/IEC Loading Guides </h3>
                          <img src={ digital} class="center"/>
                          <ul>
                              <li> Turn the IEEE/IEC loading guides into actions </li>
                              <li> Selectable Thermal models </li>
                              <li> Implement the C57.109 loading guidelines as inputs to the thermal models, ranging from Annex A up to Annex I </li>
                              <li> Establish Alarms Benchmarks for the transformer condition monitoring. </li>
                          </ul>
                      </div>
                      <br />
                      <div class="card">
                          <h3> Impacts Evaluation of Key Factors </h3>
                          <img src={variable} class="center"/>
                          <ul>
                              <li> Assess the effects of ambient temperature rises </li>
                              <li> Experimental loads study on the transformer operating temperatures, and the Insulation Aging </li>
                              <li> Study the effect of the harmonics load on the transformer’s Aging.</li>
                              <li> Evaluate the insulation readiness to withstand the widespread adoption of EVs.</li>
                          </ul>
                      </div>
                  </div>

                  <div class="column">
                      <div class="card">
                          <h3> WHAT-IFs Loading Scenarios </h3>
                          <img src={ constraint} class="center"/>
                          <ul>
                              <li> Establish WHAT-IFs loading scenarios for fine-grained load optimization </li>
                              <li> Define limiting criteria on a range of critical operation parameters </li>
                              <li> Evaluate multiple loading scenarios in Batch mode, with a matrix of Loading scenarios vs. Load profiles.</li>
                          </ul>
                      </div>
                      <br />
                      <div class="card">
                          <h3> Loading study reports</h3>
                          <img src= {dashboard} class="center"/>
                          <ul>
                              <li> Produce a customizable and exportable pdf Report.</li>
                              <li> Highligth of operating conditions with Available Load Margins </li>
                              <li> Maximum Safe Loads, Insulation Aging,  and Loading Curves vs. Conditions </li>
                              <li> Snapshots summary results. </li>
                          </ul>
                      </div>
                  </div>
              </div>
            </div>
          )

    }
}


