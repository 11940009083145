import React, {useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, InputLabel, FormControl, NativeSelect, TextField, Typography, Divider, Box, } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NumericCellEditor from "components/MainPanel/NumericCellEditor.jsx";
import { AgGridReact } from '@ag-grid-community/react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import { coolMode, OpenTank, XfrmTypeList , XfrmVoltList, XfrmPhases, XfrmLiquidInsul, XfrmSolidInsul, isEmpty } from 'components/MainPanel/Utilities.jsx';

import { Card, CardHeader, CardFooter, CardBody} from 'reactstrap';

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    minHeight: '90vh'
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  layout: {
    width: 'auto',
    // border: "1px solid",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
  }},
  controlBar: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    border: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor : "#f6f6f6",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },

  Comment: {
    padding: "1px",
    borderTopColor: "#d3d3d3",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderBottomColor: "#d3d3d3",
    borderLeft: "0px",
    borderRight:"0px",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor : "#f6f6f6"
  },
  cardheader:{
    padding: "1px",
    paddingLeft: "5px",
    borderBottom: "0px",
  },
  card:{
    borderRadius: "0px",
  },
  Error :
  {
    width : "100%",
    //backgroundColor : "red"
  }
}));

const iXfrmSheet = [
  // Rated Voltages
   { VarName:'XfrmName', Description: 'Enter your transformer name', Value:'Template' },
   { VarName:'XType', Description:'Transformer type', Value:'Select' },
   { VarName:'XPhase', Description:'Number of phases', Value:'Select' },
   { VarName:'VClass', Description:'Voltage class', Value:'Select' },

   { VarName:'Vintage', Description:'Year of services', Value: 'Select' },
   { VarName:'SolidInsul', Description:'Type of solid isulation', Value: 'Select' },
   { VarName:'LiquidInsul', Description:'Type of liquid insulation', Value:'Select' },
   { VarName:'TankType', Description:'Transformer tank style', Value:'Select'},

   { VarName:'GPSLatitude', Description:'Transformer location latitude', Value:0.0},
   { VarName:'GPSLongitude', Description:'Transformer location longitude', Value:0.0},
   { VarName:'GPSAltitude', Description:'Transformer location altitude', Value:0.0},
]

const iXfrmRateSheet = [
  { VarName: 'Cooling', Description: 'Transformer cooling modes', Stage1: 'Select', Stage2: 'Select', Stage3: 'Select', Unit: ''},
  { VarName: 'Rating', Description: 'Transformer ratings', Stage1: 0.0,  Stage2: 0.0, Stage3: 0.0, Unit: 'MVA'},
]

export default function CreateForm(props) {
const classes = useStyles();
const [gridAApi, setGridAApi]     = useState();
const [gridBApi, setGridBApi]     = useState();
const [state, setState]           = useState(iXfrmSheet);
const [stateRate, setStateRate]   = useState(iXfrmRateSheet);
const [transForm, setValues] = useState({
  XfrmUser: '',
  XfrmName: '',
  XType: 'DISTRIBUTION',
  XPhase: '3-Phase',
  VClass:'<230kV',
  Vintage:'35',
  Stage1: '',
  Stage2: '',
  Stage3: '',
  Rating1: '',
  Rating2: '',
  Rating3: '',
  SolidInsul:'UPGRADED',
  LiquidInsul:'MINERAL OIL',
  TankType:'FREE-BREATHING',
  GPSLatitude: 0.0,
  GPSLongitude: 0.0,
  GPSAltitude: 0.0,
});

const Option2 ='The following required inputs values can typically be found in the transformer OEM commisionning and tests report'+ 
'(Factory Final Test Report, Heat-Run Test, outline drawing). Such report were typically sent to the transformer owner for approval.'

const XfrmRatedColumns =[
  { headerName: 'VarName', field: 'VarName', hide:true },
  { headerName: 'Description', field: 'Description'},
  { headerName: 'Stage 1', field: 'Stage1', editable: true, 
    cellEditorSelector:function (params)
    {
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },

  { headerName: 'Stage 2', field: 'Stage2', editable:true,
    cellEditorSelector:function (params)
    {
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },

  { headerName: 'Stage 3', field: 'Stage3', editable:true,
    cellEditorSelector:function (params)
    {
        // console.log ("Statge 1 : " + params.data.Variable + "  ===> New value is : " + params.data.Value);
        if (params.data.VarName === "Cooling") return {
            component: 'agSelectCellEditor',
            params: {values: coolMode.map((o => {return o.value}))}
        }
        if (params.data.VarName === "Rating" ) return { component: 'numericCellEditor' };
    }
  },
  { headerName: 'Unit', field: 'Unit'},
];

const XfrmStaticColumns = [
    { headerName: 'VarName', field: 'VarName', hide: true },
    { headerName: 'Description', field: 'Description'},
    { headerName: 'Value', field: 'Value', editable: true, 
      cellEditorSelector:function (params)
      {
        if (params.data.VarName === "LiquidInsul") return {
          component: 'agSelectCellEditor',
          params: {values: XfrmLiquidInsul.map(o => {return o.value})}
        };
        if (params.data.VarName === 'SolidInsul') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmSolidInsul.map(o => {return o.value})}
        };
        if (params.data.VarName === 'TankType') return {
          component: 'agSelectCellEditor',
          params: {values: OpenTank.map((o => {return o.value}))}
        };
        if (params.data.VarName === 'VClass') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmVoltList.map((o => {return o.value}))}
        };
        if (params.data.VarName === 'XType') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmTypeList.map((o => {return o.value}))}
        };       
        if (params.data.VarName === 'XPhase') return {
          component: 'agSelectCellEditor',
          params: {values: XfrmPhases.map((o => {return o.value}))}
        }; 

        if (params.data.VarName === 'GPSLatitude' 
         || params.data.VarName === 'GPSLongitude'
         || params.data.VarName === 'GPSAltitude'
         || params.data.VarName === 'Vintage') return { component: 'numericCellEditor' };       
      }
    }  
];

const [errlabel, setErrLabel] = useState('');


const handleSubmit = event => {
  event.preventDefault();
  transForm.XfrmName      = state.find(item => item.VarName ==='XfrmName').Value;
  transForm.XType         = state.find(item => item.VarName ==='XType').Value;
  transForm.XPhase        = state.find(item => item.VarName ==='XPhase').Value;
  transForm.VClass        = state.find(item => item.VarName ==='VClass').Value;
  transForm.SolidInsul    = state.find(item => item.VarName ==='SolidInsul').Value;
  transForm.LiquidInsul   = state.find(item => item.VarName ==='LiquidInsul').Value;
  transForm.TankType      = state.find(item => item.VarName ==='TankType').Value;
  
  transForm.GPSLatitude   = state.find(item => item.VarName ==='GPSLatitude').Value;
  transForm.GPSLongitude  = state.find(item => item.VarName ==='GPSLongitude').Value;
  transForm.GPSAltitude   = state.find(item => item.VarName ==='GPSAltitude').Value;

  transForm.Rating1       = stateRate.find(item => item.VarName ==='Rating').Stage1;
  transForm.Rating2       = stateRate.find(item => item.VarName ==='Rating').Stage2;
  transForm.Rating3       = stateRate.find(item => item.VarName ==='Rating').Stage3;

  transForm.Stage1        = stateRate.find(item => item.VarName ==='Cooling').Stage1;
  transForm.Stage2        = stateRate.find(item => item.VarName ==='Cooling').Stage2;
  transForm.Stage3        = stateRate.find(item => item.VarName ==='Cooling').Stage3;

  if(!isEmpty(transForm)) 
  {              
    if(isEmpty(transForm.XfrmName) || transForm.XfrmName ==='Template') 
    {
      alert ("Please enter a transformer name");
      return;
    }
    if(transForm.XType ==='Select') 
    {
      alert ("Please enter a transformer type");
      return;
    }
    if(transForm.XPhase ==='Select') 
    {
      alert ("Please select the number of phases.");
        return;
    }
    if(transForm.Vintage === 'Select') 
    {
      alert ("Please enter the years of services.");
      return;
    }
    if(transForm.VClass ==='Select') 
    {
      alert ("Please specify a transformer voltage class");
      return;
    }
    if(transForm.SolidInsul ==='Select') 
    {
      alert ("Please specify the type of solid insulation");
      return;
    }
    if(transForm.LiquidInsul ==='Select') 
    {
      alert ("Please specify the type of liquid insulation");
      return;
    }
        
    if(transForm.Stage1 ==='Select' && transForm.Stage2 ==='Select' && transForm.Stage3 ==='Select') 
    {
      alert ("You need to specify at least one cooling stage");
      return;
    }
    if(transForm.Rating1 === 0 && transForm.Rating2 === 0 && transForm.Rating3 === 0) 
    {
      alert ("The number of cooling stages must match the associated ratings ");
      return;
    }
    if(
        ((transForm.Stage1 === 'Select' && transForm.Rating1 !==0) || (transForm.Stage1!== 'Select' && transForm.Rating1 === 0))
          || ((transForm.Stage2 === 'Select' && transForm.Rating2 !==0) || (transForm.Stage2!== 'Select' && transForm.Rating2 === 0))
          || ((transForm.Stage3 === 'Select' && transForm.Rating3 !==0) || (transForm.Stage3!== 'Select' && transForm.Rating3 === 0))
      ) 
    {
      alert ("A stage must be associated with its corresponding rating, and vice-versa.");
      return;
    }      
          }
  setErrLabel ("");
  props.createXfrmer(transForm); 
  alert ("Transformer created.");
};

const onGridAReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridAApi({ api, columnApi });
    params.api.sizeColumnsToFit();
  },[]
);

const onGridBReady = useCallback((params) => {
    const { api, columnApi } = params;
    setGridBApi({ api, columnApi });
    params.api.sizeColumnsToFit();
  },[]
);

const frameworkComponents = {
numericCellEditor: NumericCellEditor
}

const onCellValueChanged = params => {
  var colId = params.column.getId();
  if (colId === "Value") {
    params.node.setDataValue(colId, params.data.Value); 
    return;
  }
}

const onCellStageChanged = params => {
let colId = params.column.getId();
  switch(colId)
  {
    case "Stage1": { 
      params.node.setDataValue(colId, params.data.Stage1); 
      break;
      }
    case "Stage2": { 
      params.node.setDataValue(colId, params.data.Stage2); 
      break;
    }
    case "Stage3": { 
      params.node.setDataValue(colId, params.data.Stage3); 
      break;
    }
  }  
}

return (
      <main className={classes.layout}>
        <br/>
         <form className={classes.form} autoComplete="off" >
          <Card className={classes.card}>
            <CardHeader className={classes.cardheader}>
              <div className={classes.comment}>
                <Typography variant="subtitle2"> {<InfoIcon/>} Create your transformer basic profile </Typography>
                  <Divider/>
                <Typography variant="caption" gutterBottom>
                    {Option2}
                </Typography> 
              </div>
            </CardHeader>
            <CardBody className={classes.CardBody}>   
             <div  className="ag-theme-balham" >
                <AgGridReact
                  modules={AllCommunityModules}
                  columnDefs={XfrmStaticColumns}
                  rowData={state}
                  domLayout="autoHeight"
                  onGridReady={onGridAReady}
                  onCellValueChanged={ onCellValueChanged} 
                  frameworkComponents = {frameworkComponents}
                />
              </div> 
              <hr style={{color: '#4486a3',backgroundColor: '#4486a3', marginLeft: 5, height: 3, width:'50%', borderColor : '#4486a3'}}/>
              <div  className="ag-theme-balham" >
                <AgGridReact
                  modules={AllCommunityModules}
                  columnDefs={XfrmRatedColumns}
                  rowData={stateRate}
                  domLayout="autoHeight"
                  onGridReady={onGridBReady}
                  onCellValueChanged={ onCellStageChanged} 
                  frameworkComponents = { frameworkComponents}
                />
              </div> 
            </CardBody>
            <CardFooter className={classes.CardBody}> 
              <Box display="flex" flexDirection="row">
                <Box p={1}> <Button variant="outlined"  onClick={handleSubmit} className={classes.button} startIcon = {<CloudUploadIcon/>}> Save </Button> </Box>
                <Box className = {classes.controlBar}> {errlabel} </Box>
              </Box>
            </CardFooter>
          </Card> 
        </form>
      </main>
  );
}
