import  { useHistory }  from "react-router-dom";
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {makeStyles  } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/core/Menu";
import Button from '@material-ui/core/Button';
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import CreateSession from 'components/Navbars/CreateSession.jsx';
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { ApiURL, isEmpty, onlyUnique } from "components/MainPanel/Utilities";
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EcoIcon from '@material-ui/icons/Eco';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  logbutton:{
    backgroundColor: '#4486a3',
    borderRadius:"0px",
  },
  button:{
    borderRadius:"0px",
    border: "0px"
  },
  button2: {
    color: '#4486a3',
    borderRadius:"0px",
    borderColor:'#4486a3'
  },
  hide: {
    display: 'none',
  },

}));


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '0px',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: "#6699cc", // theme.palette.common.black,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white, 
      },
    },
  },
}))(MenuItem);



function Header(props) {
  const  classes = useStyles(props);
  const [showSesDialog, setDialog]        = useState(false);
  const [state, setState]                 = useState({nXfrmer: {}}); 
  const [anchorEl, setAnchorEl]           = useState(null);
  const [anchorVersEl, setAnchorVerEl]    = useState(null);
  const [anchorUserEl, setAnchorUserEl]   = useState(null);
  const [stateVar, setStateVar]           = useState({ sessionId: props.userData.username, transFormers:[]})

  let history                           =  useHistory();
  let userData                          = props.userData;
  let userName                          = props.userData.username;
  
  useEffect(() => 
  {
    if(!isEmpty(userData))
    {
      switch (userData.userstatus) {
        case 'signIn':
        {
          history.push({pathname:"/admin/loadinghub", state: {}});   
          break;
        }
        case 'signUp':
        {       
          console.log ('===>>> user signed up');
          history.push({pathname:"/admin/startup", state: {}});  
          break;
        }
        case 'signOut':
        {
          // console.log ('===>>> user signed out');
          history.push({pathname:"/admin/loadinghub", state: {}});  
          break;
        }
        case 'signIn_failure':
        {
          history.push({pathname:"/admin/startup", state: {}});  
          break;
        }
        case 'configured':
        {
            console.log ('===>>> the Auth module is configured');
            break;
        }
        default: break;
      }
    }
    
  }, [userData, history]);

  
  const showSession = () => {
    setDialog(!showSesDialog)
    setAnchorEl(null);
  };

  const showEstimation = () => {
    if(!isEmpty(state.nXfrmer))
    {
      history.push({pathname:"/admin/lifestimation", state:{ nXfrmer: state.nXfrmer }});  
      setAnchorEl(null);
    }
  }

  const hideSession = (nXfrmer) => {
    if(nXfrmer.XfrmName !== undefined)
    {
      // console.log("=======>>>>>++++", nXfrmer.XfrmName, nXfrmer.XType, nXfrmer.VClass, nXfrmer.SolidInsul, 
      // nXfrmer.LiquidInsul, nXfrmer.Stage1, nXfrmer.Stage2, nXfrmer.Stage3, nXfrmer.Rating1, nXfrmer.Rating2, nXfrmer.Rating3);
      // props.clearDashboard();
        // history.push({pathname:"/admin/dashboard", state:{ nXfrmer: nXfrmer }}); 
        // if(!isEmpty(stateVar.transFormers) && stateVar.transFormers.indexOf(nXfrmer.XfrmName) === -1)
        // {
          setStateVar({...stateVar, transFormers:stateVar.transFormers.concat(nXfrmer)});
          // props.AddNewAsset(stateVar.transFormers.concat(nXfrmer));
          // history.push({pathname:"/admin/multidashboard", state:{ transFormers: stateVar.transFormers.concat(nXfrmer) }}); 
          history.push({pathname:"/admin/multidashboard", state:{ nXfrmer: nXfrmer }}); 
        // }
        // else
        //   console.log(" ========= This transformer alraedy exist === == == = = ==");

        setState({ nXfrmer: nXfrmer });
    } 

    setDialog(!showSesDialog);
  };

  const handleVersMenu = event => {
    setAnchorVerEl(event.currentTarget);
  };

  const handleVersClose = () => {
    setAnchorVerEl(null);
  };

  const handleStartMenu= event => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartClose = () => {
    setAnchorEl(null);
  };

  const handleUserClose =() => {
    setAnchorUserEl(null);
  }

  const handleSignIn = event => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleHome = event => {
    history.push({pathname:"/admin/loadinghub", state: {}});  
  };

  const MeSignIn = event => {
    setAnchorUserEl(null);
    history.push({pathname:"/admin/login", state:{}});  
  };
  
  const MeSignOut = event => {
    props.MeSignOut();  
    setAnchorUserEl(null);
    // history.push({pathname:"/admin/loadinghub", state: {}});  
  };
  
  const ShowUseCase = event => {
    history.push({pathname:"/admin/usecase", state:{}});  
    setAnchorVerEl(null);
  }

  const ShowRelease = event => {
    history.push({pathname:"/admin/release", state:{}}); 
    setAnchorVerEl(null); 
  }


  // useEffect(() =>
  // {
  //   console.log( "=== useEffect not warking");
  //   async function onRefresh ()
  //   {
  //     const url = ApiURL + '/GetXfrmers';  
  //     console.log("Created Transformers are : " + userData.username + " ====>>> " + JSON.stringify(stateVar.transFormers));

  //     try 
  //     {
  //       (async () => {
  //         const rawResponse = await fetch(url, {
  //           method: 'POST',
  //           body: JSON.stringify(userData.username),
  //           headers: {'Accept':'application/json', 'Content-Type': 'application/json'}
  //         });
  //         // if(rawResponse.ok)
  //         // {
  //           const result = await rawResponse.json();
  //           console.log("result : " + result);
  //           setStateVar({...stateVar, transFormers:[].concat(result)});
  //           props.AddNewAsset(stateVar.transFormers.concat(result));
  //         // }
  //         // else
  //         //   return Promise.reject(rawResponse)
  //       })();
  //     }   
  //     catch (error) 
  //     {
  //       console.error('Error:', error);
  //     }
  //   }
  //   onRefresh();
  // }, [props.userData]);


  return (
    <div className={classes.root}> 
    <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: props.open})}>
        <Toolbar style={{ marginTop: '-15px'}}>
            <CreateSession show={showSesDialog} hideSession={hideSession} />
              <IconButton color="inherit"  aria-label="open drawer"  edge="start" onClick={ props.handleDrawerOpen} 
                className={clsx(classes.menuButton, props.open && classes.hide)}
                >
                  <MenuIcon />
              </IconButton>
              <Grid container spacing={2} justify="flex-end">
              <Grid item >
                  <Button
                      // color={window.innerWidth > 959 ? "transparent" : "white"}
                      // justIcon={window.innerWidth > 959}
                      // simple={!(window.innerWidth > 959)}
                      size="small"    
                      variant="outlined"
                      color="black"
                      aria-haspopup="true"
                      aria-controls="version-menu"
                      href="https://www.hvsscorp.com"
                      className={classes.button2}
                      endIcon={<ArrowBackIcon />}
                      size="small">Back</Button>
                </Grid>
                <Grid item >
                  <Divider orientation="vertical"/> 
                </Grid>
                <Grid item >
                  <Button  size="small"        
                        aria-controls="home-menu" aria-haspopup="false"
                        onClick={handleHome} className={classes.button} startIcon = {<HomeIcon/>} >
                        Home
                  </Button>
                </Grid>
                <Grid item >
                    <Button size="small"        
                        aria-controls="start-menu" aria-haspopup="true" disabled = {props.startDIY}
                        onClick={handleStartMenu} className={classes.button} startIcon = {<AddBoxIcon/>} endIcon={<ArrowDropDownIcon />} >
                        Start
                    </Button>
                    <StyledMenu id="start-menu" anchorEl ={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleStartClose}>
                      <StyledMenuItem onClick={showSession}>
                        <ListItemIcon> <SendIcon fontSize="small" /> </ListItemIcon>
                        <ListItemText primary="Load Simulation Session " />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={showEstimation}>
                        <ListItemIcon><DateRangeIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary="Transformer Age Estimation" />
                      </StyledMenuItem>
                      <StyledMenuItem>
                        <ListItemIcon><EcoIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary="Cooling Capacity Upgrade" />
                      </StyledMenuItem>
                    </StyledMenu>
                </Grid>
                <Grid item >
                  <Button
                    // color={window.innerWidth > 959 ? "transparent" : "white"}
                    // justIcon={window.innerWidth > 959}
                    // simple={!(window.innerWidth > 959)}
                    aria-haspopup="true"
                    aria-controls="version-menu"
                    onClick={handleVersMenu}
                    className={classes.button}
                    endIcon={<ArrowDropDownIcon />}
                    size="small"> 
                    LoadingHUB v0.1-Beta 
                  </Button>
                  <StyledMenu id="version-menu" anchorEl ={anchorVersEl} keepMounted open={Boolean(anchorVersEl)} onClose={handleVersClose}>
                      <StyledMenuItem onClick={ShowRelease}>
                        <ListItemIcon> <SendIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary="Release Policy" />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={ShowUseCase}>
                        <ListItemIcon> <InboxIcon fontSize="small" /> </ListItemIcon>
                        <ListItemText primary="Use Cases" />
                      </StyledMenuItem>
                    </StyledMenu>
                </Grid>
                <Grid item >
                    <Button size="small" aria-controls="sign-menu" aria-haspopup="true" startIcon = {<AccountBoxIcon/>} endIcon={<ArrowDropDownIcon />}
                      onClick={handleSignIn} className={classes.button} >{userName}</Button>
                    <StyledMenu id="sign-menu" anchorEl ={anchorUserEl} keepMounted open={Boolean(anchorUserEl)} onClose={handleUserClose}>
                      <StyledMenuItem onClick={MeSignIn}>
                        <ListItemIcon> <SendIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary= "Sign In" />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={MeSignOut}>
                        <ListItemIcon> <InboxIcon fontSize="small" /></ListItemIcon>
                        <ListItemText primary="Sign Out" />
                      </StyledMenuItem>
                    </StyledMenu>
                </Grid>
              </Grid>         

          {/* <Hidden mdUp implementation="css">
            <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle} >
              <Menu />
            </IconButton>
          </Hidden> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
export default withStyles(headerStyle)(Header);
